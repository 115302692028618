import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { Box } from '@material-ui/core'

import Templates from '../Templates'
import Texts from '../Texts/index'
import TextStylings from '../TextStylings'
import Images from '../Images'
import Backgrounds from '../Backgrounds'
import Colors from '../Texts/Colors'
import Graphics from '../Graphics'
import ActiveIcons from '../ActiveIcons'
import SocialMediaIcons from '../SocialMediaIcons'
import Uploads from '../Uploads'
// import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'

import { NavigationContext } from '../../../../contexts/NavigationContext'

const Panel = props => {
  const [navigation, setNavigation] = useContext(NavigationContext)

  const handleSubPanelChange = index => {
    setNavigation({
      ...navigation,
      panel: index
    })
  }

  return (
    <Box flex="1 1 auto" display="flex" flexDirection="column" position="relative" width="260px">
      { navigation.activeTab === 0 ? <Templates subPanel={navigation.panel} onSubpanelChange={handleSubPanelChange} /> : null }
      { navigation.activeTab === 1 ? <Texts subPanel={navigation.panel} onSubpanelChange={handleSubPanelChange} /> : null  }
      { navigation.activeTab === 2 ? <TextStylings subPanel={navigation.panel} onSubpanelChange={handleSubPanelChange} /> : null  }
      { navigation.activeTab === 3 ? <Images subPanel={navigation.panel} onSubpanelChange={handleSubPanelChange} /> : null  }
      { navigation.activeTab === 4 ? <Backgrounds templateId={props.templateId} subPanel={navigation.panel} onSubpanelChange={handleSubPanelChange} /> : null  }
      { navigation.activeTab === 5 ? <ActiveIcons subPanel={navigation.panel} onSubpanelChange={handleSubPanelChange} /> : null  }
      { navigation.activeTab === 6 ? <SocialMediaIcons subPanel={navigation.panel} onSubpanelChange={handleSubPanelChange} /> : null  }
      { navigation.activeTab === 7 ? <Graphics /> : null  }
      { navigation.activeTab === 8 ? <Uploads /> : null  }
      { navigation.activePanel === 1 &&
        <Colors
          subPanel={navigation.panel}
          onSubpanelChange={handleSubPanelChange}
          colors={props.colors}
          onColorsChange={props.onColorsChange}
          textColor={navigation.color} />
      }
      {/* <Box position="absolute" display="flex" height="100%" left="100%" justifyContent="center">
        <KeyboardArrowLeftIcon />
      </Box> */}
    </Box>
  )
}

Panel.propTypes = {
  templateId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  colors: PropTypes.array,
  onColorsChange: PropTypes.func.isRequired,
}

export default Panel
