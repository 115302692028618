import React from 'react'
// import PropTypes from 'prop-types'

import Grid from '@material-ui/core/Grid'

const Default = () => {
  return (
    <Grid container justify="center" alignItems="center" spacing={1} />
  )
}

// Default.propTypes = {}

export default Default
