import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import Fab from '@material-ui/core/Fab'
import CloseIcon from '@material-ui/icons/Close'
import LogoIcon from '../../../icons/LogoIcon'

const useStyles = makeStyles(theme => ({
  header: {
    backgroundImage: 'linear-gradient(to right, #f9707e , #8493ff)'
  },
  logo: {
    fontSize: '2.7em'
  },
  fab: {
    position: 'absolute',
    width: theme.spacing(2),
    height: theme.spacing(2),
    minHeight: theme.spacing(2),
    top: theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 2
  },
  closeIcon: {
    fontSize: '1em'
  },
}))

const Overlay = props => {
  const classes = useStyles()

  return (
    <Dialog
      fullWidth
      open={props.open}
      onClose={props.onClose}
    >
      <Fab
        size="small"
        color="primary"
        aria-label="close"
        onClick={props.onClose}  
        className={classes.fab}>
        <CloseIcon className={classes.closeIcon} />
      </Fab>
      <Box position="relative" pb="29%" className={classes.header}>
        <Box
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          height={1}
          width={1}
        >
          <div><LogoIcon className={classes.logo} /></div>
          <Box component="span" pt={1/2} fontSize="1.13rem" fontWeight="normal" color="#fff"> 
          </Box>
        </Box>
      </Box>
      {props.children}
    </Dialog>
  )
}

Overlay.propTypes = {
  maxWidth: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

export default Overlay
