import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const BottomAlignIcon = props => {
  return (
    <SvgIcon {...props}>
      <path d="M3.9 19.6v1h17.2v-1H3.9zM7.1 6.1v11.6h4V6.1h-4zm6.6 5.2v6.4h4v-6.4h-4z"/>
    </SvgIcon>
  )
}

export default BottomAlignIcon
