import React, { useContext, useState, useEffect } from 'react'
// import PropTypes from 'prop-types'
import { fabric } from 'fabric'

import { makeStyles } from '@material-ui/core/styles'
import { Grid, Box, Tooltip } from '@material-ui/core'

import HttpClient from '../../../../services/HttpClient'
import AuthContext from '../../../../contexts/AuthContext'
import { ActionContext } from '../../../../contexts/ActionContext'
import SearchBar from '../SearchBar'
// import { DEFAULT_GRAPHICS } from '../../../../constants/DefaultGraphics'

const useStyles = makeStyles(() => ({
  graphicBox: {
    fill: 'white',
    cursor: 'pointer',
    backgroundColor: 'hsla(0,0%,100%,.6)',
    '& img': {
      maxWidth: '90%',
      maxHeight: '90%'
    },
    '&:hover': {
      backgroundColor: 'hsla(0,0%,100%,.85)',
    }
  },
}))

const Graphics = () => {
  const classes = useStyles()
  const [authData] = useContext(AuthContext)
  const [action, setAction] = useContext(ActionContext)
  const [graphics, setGraphics] = useState([])

  useEffect(() => iconsApi('Beauty Salon'), [])

  const iconsApi = q => {
    HttpClient.get(
      `/3rdParty/icons/${q}?limitToPublicDomain=1`,
      { Authorization: `Bearer ${authData.authToken}` }
    )
      .then(res => setGraphics(res))
      .catch(error => {
        console.log('Error loading graphics: ', error)
        setGraphics([])
      })
  }

  const handleAdd = graphicId => {
    HttpClient.get(
      `/3rdParty/icon/${graphicId}`,
      { Authorization: `Bearer ${authData.authToken}` }
    )
      .then(res => {
        fabric.loadSVGFromURL(res.icon_url, (objects, options) => {
          const obj = fabric.util.groupSVGElements(objects, options)
          if (obj.type !== 'group')
            obj.type = 'path'
          setAction({ type: 'addObject', value: obj })
        })
      })
      .catch(error => console.log('Error loading graphic: ', error))
  }

  return (
    <Box
      flex="1 1 auto"
      display="flex"
      flexDirection="column"
      overflow="hidden"
      bgcolor="#293039"
      color="#fff"
      zIndex="2"
    >
      <SearchBar onSearch={iconsApi} />
      <Box flex="1 1 auto" mx={1} overflow="auto" className="scrollbar-hidden">
        <Grid container spacing={1}>
          {graphics.map((graphic, index) => (
            <Grid item xs={6} key={index}>
              <Box
                position="relative"
                width={1}
                pb="100%"
                borderRadius={4}
                boxSizing="border-box"
              >
                <Tooltip title={graphic.attribution} arrow>
                  <Box
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    left={1}
                    right={1}
                    top={1}
                    bottom={1}
                    borderRadius={4}
                    className={classes.graphicBox}
                    onClick={() => handleAdd(graphic.id)}
                  >
                    <img src={graphic.preview_url} alt={graphic.attribution} />
                  </Box>
                </Tooltip>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  )
}

// Graphics.propTypes = {
//   subPanel: PropTypes.number.isRequired
// }

export default Graphics
