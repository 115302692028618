import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import { Grid, Box } from '@material-ui/core'
import { Button, IconButton, Tooltip } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { Menu, MenuItem } from '@material-ui/core'
import { Popover, Paper, Divider } from '@material-ui/core'
import TextColorIcon from '../../../../../icons/TextColorIcon'
import FormatBoldIcon from '@material-ui/icons/FormatBold'
import FormatItalicIcon from '@material-ui/icons/FormatItalic'
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined'
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter'
import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft'
import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify'
import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight'
import CopyStyleIcon from '../../../../../icons/CopyStyleIcon'
import TrashIcon from '../../../../../icons/TrashIcon'
import ForwardIcon from '../../../../../icons/ForwardIcon'
import BackwardIcon from '../../../../../icons/BackwardIcon'
import TopAlignIcon from '../../../../../icons/TopAlignIcon'
import LeftAlignIcon from '../../../../../icons/LeftAlignIcon'
import CenterAlignIcon from '../../../../../icons/CenterAlignIcon'
import MiddleAlignIcon from '../../../../../icons/MiddleAlignIcon'
import BottomAlignIcon from '../../../../../icons/BottomAlignIcon'
import RightAlignIcon from '../../../../../icons/RightAlignIcon'
import Typography from '@material-ui/core/Typography'

import { ActionContext } from '../../../../../contexts/ActionContext'

const useStyles = makeStyles(theme => ({
  button: {
    backgroundColor: '#F1F8FF',
    justifyContent: 'left',
    color: '#625E5E'
  },
  buttonIcon: {
    position: 'absolute',
    right: '8px',
    marginLeft: 0
  },
  fontSizeMnu: {
    maxHeight: 400
  }
}))

const fontSizes = [
  6, 8, 10, 12, 14, 16, 18, 21, 24, 28, 32, 35, 42, 48, 56, 64, 72, 80, 88, 96, 104, 120, 144
]

const Text = props => {
  const classes = useStyles()
  const [action, setAction] = useContext(ActionContext)
  const [fontSizeEl, setFontSizeEl] = useState(null)
  const [positioningEl, setPositioningEl] = useState(null)

  const handleOpenFontSizeMenu = e => {
    setFontSizeEl(e.currentTarget)
  }

  const handleCloseFontSizeMenu = () => {
    setFontSizeEl(null)
  }

  const handleFontSizeChange = size => {
    setFontSizeEl(null)
    setAction({
      type: 'text',
      property: 'fontSize',
      value: size
    })
  }

  const toggleFontWeight = e => {
    setAction({
      type: 'text',
      property: 'fontWeight',
      value: (props.toolbarData.fontWeight === 'normal' ? 'bold' : 'normal')
    })
  }

  const toggleFontStyle = e => {
    setAction({
      type: 'text',
      property: 'fontStyle',
      value: (props.toolbarData.fontStyle === 'normal' ? 'italic' : 'normal')
    })
  }

  const toggleUnderline = e => {
    setAction({
      type: 'text',
      property: 'underline',
      value: (props.toolbarData.underline ? false : true)
    })
  }

  const handleAlignmentChange = () => {
    const nextAlignment = {
      center: 'left',
      left: 'justify',
      justify: 'right',
      right: 'center'
    }[props.toolbarData.textAlign]

    setAction({
      type: 'text',
      property: 'textAlign',
      value: nextAlignment
    })
  }

  const handleOpenPositioningDialog = e => {
    setPositioningEl(e.currentTarget)
  }

  const handleClosePositioningDialog = () => {
    setPositioningEl(null)
  }

  const handleVerticalAlignmentChange = position => {
    setAction({ type: 'alignment', value: position })
  }

  const handleHorizontalAlignmentChange = position => {
    setAction({ type: 'alignment', value: position })
  }

  const handleLayering = direction => {
    setAction({type: 'alignment', value: direction })
  }

  const handleRemoval = () => {
    setAction({ type: 'remove' })
  }

  const currentAlignment = () => {
    return props.action && props.action.value
  }

  return (
    <Grid container spacing={0}>
      <Grid container item sm={2} alignItems="center">
        <Button
          fullWidth
          size="small"
          aria-label="font family"
          classes={{
            root: classes.button,
            endIcon: classes.buttonIcon
          }}
          onClick={() => props.onNavigationChange({ activeTab: 1, panel: 1, activePanel: 0 })}
          endIcon={<KeyboardArrowDownIcon />}
        >
          {props.toolbarData.fontFamily}
        </Button>
      </Grid>
      <Grid container item sm={1} alignItems="center">
        <Box ml={1}>
          <Button
            fullWidth
            size="small"
            aria-label="Font Size"
            aria-controls="font-size-menu"
            aria-haspopup="true"
            classes={{
              root: classes.button,
              endIcon: classes.buttonIcon
            }}
            onClick={handleOpenFontSizeMenu}
            endIcon={<KeyboardArrowDownIcon />}
          >
            {props.toolbarData.fontSize}
          </Button>
          <Menu
            id="font-size-menu"
            anchorEl={fontSizeEl}
            keepMounted
            open={Boolean(fontSizeEl)}
            onClose={handleCloseFontSizeMenu}
            className={classes.fontSizeMnu}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            {fontSizes.map((fontSize, index) => 
              <MenuItem key={index} onClick={() => handleFontSizeChange(fontSize)}>{fontSize}</MenuItem>  
            )}
          </Menu>
        </Box>
      </Grid>
      <Grid container item sm={3} justify="center">
        <Tooltip title="Text Color" arrow>
          <IconButton
            aria-label="Text Color"
            onClick={() => props.onNavigationChange({ activePanel: 1, color: props.toolbarData.textColor })}
          >
            <TextColorIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Bold" arrow>
          <IconButton
            color={props.toolbarData.fontWeight === 'bold' ? 'primary' : 'default'}
            onClick={() => toggleFontWeight()}
            aria-label="bold"
          >
            <FormatBoldIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Italics" arrow>
          <IconButton
            color={props.toolbarData.fontStyle === 'italic' ? 'primary' : 'default'}
            onClick={() => toggleFontStyle()}
            aria-label="Italics"
          >
            <FormatItalicIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Underline" arrow>
          <IconButton
            color={props.toolbarData.underline ? 'primary' : 'default'}
            onClick={() => toggleUnderline()}
            aria-label="Underline"
          >
            <FormatUnderlinedIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid item sm={1}>
        <IconButton
          onClick={() => handleAlignmentChange()}
          aria-label="Alignment"
        >
          {
            {
              'center': <FormatAlignCenterIcon fontSize="small" />,
              'left': <FormatAlignLeftIcon fontSize="small" />,
              'justify': <FormatAlignJustifyIcon fontSize="small" />,
              'right': <FormatAlignRightIcon fontSize="small" />
            }[props.toolbarData.textAlign]
          }
        </IconButton>
      </Grid>
      <Grid container item sm={1} alignItems="center">
        <Button
          size="small"
          aria-label="spacing"
        >
          Spacing
        </Button>
      </Grid>
      <Grid container item sm={1} alignItems="center">
        <Button
          size="small"
          aria-label="position"
          aria-describedby="positioning-controls"
          onClick={handleOpenPositioningDialog}
        >
          Position
        </Button>
        <Popover
          id="positioning-controls"
          open={Boolean(positioningEl)}
          anchorEl={positioningEl}
          onClose={handleClosePositioningDialog}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Paper variant="outlined">
            <Box m={1.5}>
              <Box my={1} mr={3}>
                <Grid container spacing={3}>
                  <Grid item sm={6}>
                    <Button
                      size="small"
                      aria-label="Forward"
                      onClick={() => handleLayering('forward')}
                      startIcon={
                        <ForwardIcon fontSize="small" />
                      }
                    >
                    Forward
                    </Button>
                  </Grid>
                  <Grid item sm={6}>
                    <Button
                      size="small"
                      aria-label="Backward"
                      onClick={() => handleLayering('backward')}
                      startIcon={
                        <BackwardIcon fontSize="small" />
                      }
                    >
                    Backward
                    </Button>
                  </Grid>
                </Grid>
              </Box>
              <Divider />
              <Box mx={0.5} mt={1} mb={0.6}>
                <Typography variant="caption" display="block" gutterBottom>Align to page</Typography>
              </Box>
              <Grid container spacing={1}>
                <Grid item sm={6}>
                  <Button
                    size="small"
                    aria-label="Top"
                    onClick={() => handleVerticalAlignmentChange('top')}
                    disabled={currentAlignment() === 'top'}
                    startIcon={
                      <TopAlignIcon fontSize="small" />
                    }
                  >
                    Top
                  </Button>
                </Grid>
                <Grid item sm={6}>
                  <Button
                    size="small"
                    aria-label="Left"
                    onClick={() => handleHorizontalAlignmentChange('left')}
                    disabled={currentAlignment() === 'left'}
                    startIcon={
                      <LeftAlignIcon fontSize="small" />
                    }
                  >
                    Left
                  </Button>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item sm={6}>
                  <Button
                    size="small"
                    aria-label="Middle"
                    onClick={() => handleVerticalAlignmentChange('middle')}
                    disabled={currentAlignment() === 'middle'}
                    startIcon={
                      <CenterAlignIcon fontSize="small" />
                    }
                  >
                    Middle
                  </Button>
                </Grid>
                <Grid item sm={6}>
                  <Button
                    size="small"
                    aria-label="Center"
                    onClick={() => handleHorizontalAlignmentChange('center')}
                    disabled={currentAlignment() === 'center'}
                    startIcon={
                      <MiddleAlignIcon fontSize="small" />
                    }
                  >
                    Center
                  </Button>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item sm={6}>
                  <Button
                    size="small"
                    aria-label="Bottom"
                    onClick={() => handleVerticalAlignmentChange('bottom')}
                    disabled={currentAlignment() === 'bottom'}
                    startIcon={
                      <BottomAlignIcon fontSize="small" />
                    }
                  >
                    Bottom
                  </Button>
                </Grid>
                <Grid item sm={6}>
                  <Button
                    size="small"
                    aria-label="Right"
                    onClick={() => handleHorizontalAlignmentChange('right')}
                    disabled={currentAlignment() === 'right'}
                    startIcon={
                      <RightAlignIcon fontSize="small" />
                    }
                  >
                    Right
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Popover>
      </Grid>
      <Grid container item sm={3} justify="flex-end">
        <Tooltip title="Copy Style" arrow>
          <IconButton aria-label="Copy Style">
            <CopyStyleIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete" arrow>
          <IconButton
            aria-label="Delete"
            onClick={handleRemoval}
          >
            <TrashIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  )
}

Text.propTypes =  {
  toolbarData: PropTypes.object.isRequired,
  onNavigationChange: PropTypes.func.isRequired
}

export default Text
