import React from 'react'
import {Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  title: {
    color: '#0E1F4A',
    font: 'lato',
    fontSize: '35px',
    lineHeight: 1.2,
    fontWeight: 900,
    alignSelf: 'center',
    textAlign: 'center',
    marginTop: 20,
  },
  live: {
    color: '#fff',
  },
  contact: {
    color: '#000039',
  },
}))

const LogoIcon = (props) => {
  const classes = useStyles()
  return (
    <>
      <Typography className={classes.title}>
        <span className={classes.live}> Live</span>
        <span className={classes.contact}>Contact</span>
      </Typography>
    </>
  )
}

export default LogoIcon
