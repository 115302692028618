import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Carousel from "react-material-ui-carousel";
import MetaTags from "react-meta-tags";
import { isEmpty, isNull } from "lodash/fp";
import { makeStyles } from "@material-ui/core/styles";
import mailLinkIcon from "../../images/email_enabled.png";
import download_top from "../../images/download-top.svg";
import mapIcon from "../../images/mapIcon.png";
import facebookFooter from "../../images/social-media-icons/fb_fotter.png";
import linkedinFooter from "../../images/social-media-icons/linkedin_fotter.png";
import instagramFooter from "../../images/social-media-icons/instagram_fotter.png";
import vimeoFooter from "../../images/social-media-icons/vimeo_fotter.png";
import youtubeFooter from "../../images/social-media-icons/youtube_fotter.png";
import tiktokFooter from "../../images/social-media-icons/tiktok_fotter.png";
import snapchatFooter from "../../images/social-media-icons/snapchat_fotter.png";
import skypeFooter from "../../images/social-media-icons/skype_fotter.png";
import twitterFooter from "../../images/social-media-icons/twitter_fotter.png";
import whatsappFooter from "../../images/social-media-icons/whatsapp_fotter.png";
import CarouselItem from "../../components/User/Common/Carousel";
import HttpClient from "../../services/HttpClient";
import { useParams } from "react-router-dom";
import userAvtar from "../../images/weblink-images/user-avtar.webp";
import "./style.css";
import cardPreviewNotFound from "../../images/weblink-images/not-available.png";
import { Button } from "@material-ui/core";
import DownloadAlertDialog from "./DownloadAlertDialog";
import { Typography } from "@material-ui/core";
import { ReactComponent as Back } from '../User/icons/Back.svg'
import { ReactComponent as Forward } from '../User/icons/Forward.svg'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import phoneLinkIcon from "../../images/phone_enabled.png";

// var Carousel = require('react-responsive-carousel').Carousel;

// import CardSideSlide from './CardSideSlide'
// import Arrow from './Arrow'

//@OPTIMIZE
const useStyles = makeStyles((theme) => ({
  cardmainWrapper: {
    overflowY: "auto",
    height: "100%",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: '10px',
    paddingRight: '10px',
    backgroundColor: "#ffffff",
  },
  hrWidth: {
    height: "3px",
    width: "100%",
    backgroundColor: "#d8d8d8",
    marginBottom: "30px",
    border: "1px solid #d8d8d8",
  },
  headerAlignment: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "8px",
    padding: "4px",
    alignItems: "center",
  },
  headerDetailsBox: {
    display: "flex",
    flexDirection: "column",
  },
  headerName: {
    color: "#000000",
    fontSize: "20px",
    fontWeight: "700",
  },
  headerDescription: {
    fontWeight: "bold",
    color: "#4C5D7D",
    fontSize: "13px",
  },
  profileInfoContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "3px 3px 3px 0px",
  },
  CardDetailsWrapper: {
    maxWidth: "470px",
    margin: "0 auto",
    padding: "30px 30px",
    borderRadius: "20px",
  },
  colorWhite: {
    backgroundColor: "#F4F4F4",
  },
  center: {
    margin: "0 auto",
    textAlign: "center",
  },
  webkitScrollbar: {
    width: "0",
    background: "transparent",
  },
  profileImage: {
    Width: "80px",
    height: "80px",
    borderRadius: "50%",
    objectFit: "cover",
    marginTop: "13px",
    marginLeft: "2%",
    boxShadow: "0px 0px 20px #ccc",
  },
  brandLogo: {
    width: "48px",
    height: "48px",
    borderRadius: "50%",
    marginLeft: "20px",
    objectFit: "cover",
  },
  userProfileName: {
    marginLeft: "15px",
    marginTop: "-12px",
    "& h4": {
      color: "#0E1F4A",
      fontWeight: "900",
      fontSize: "18px",
      fontWeight: 600,
    },
    "& p": {
      fontWeight: "400",
      fontSize: "12px",
      fontWeight: 600,
      color: "#4C5D7D",
    },
  },

  padding0: {
    padding: '0px',
  },
  size15: {
    height: '15px !important',
    width: '15px !important'
  },


  profileInfoAlignment: {
    lineHeight: "16px",
    marginTop: "7px",
    marginBottom: "7px",
  },
  contactLinks: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: "10px",
  },
  contactIcons: {
    width: "82px",
    height: "37px",
    marginLeft: "6px",
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "none",
    cursor: "pointer",
  },
  socialLinks: {
    justifyContent: "center",
    "& img": {
      width: "40px",
      height: "40px",
      marginLeft: "12px",
      marginRight: "12px",
      marginTop: "25px",
    },
  },
  connectedOn: {
    justifyContent: "center",
    marginTop: "25px",
    "& img": {
      width: "25px",
      height: "20px",
      marginRight: "24px",
      marginTop: "25px",
    },
  },
  personalInfoWrapper: {
    position: "relative",
    "& label": {
      fontSize: "14px",
      color: "#4a4a4a",
      marginBottom: "0px",
      fontWeight: 700,
      display: "inline-block",
    },
    "& p": {
      fontWeight: "400",
      fontSize: "18px",
      color: "#0E1F4A",
      lineHeight: "1.182",
      "& a": {
        textDecoration: "none",
        color: "#007AFF",
        fontSize: "20px",
      },
    },
  },
  siteSectionWrapper: {
    display: "flex",
    justifyContent: "flex-start",
  },
  siteSection: {
    width: "60px",
    height: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#d8d8d8",
    borderRadius: "5px",
    marginRight: "15px",
    "& span": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#0076FF",
      color: "#fff",
      padding: "0 5px",
      height: "20px",
      fontWeight: "600",
      borderRadius: "5px",
      "& svg": {
        fontSize: "14px",
      },
    },
  },
  noSpanBg: {
    position: "relative",
    "& span": {
      backgroundColor: "transparent",
    },
    "& svg": {
      color: "#0076FF",
    },
  },
  jobInfoWrapper: {
    display: "flex",
    justifyContent: "space-between",
    "& img": {
      width: "60px",
      height: "60px",
      borderRadius: "50%",
    },
  },
  socialProfile: {
    whiteSpace: 'pre-wrap', 
    overflowWrap: 'break-word', 
    fontSize: '16px', 
    textDecoration: "none"
  },
  socialProfileLinksWrapper: {
    // position: "relative",
    // "& label": {
    //   fontSize: "16px",
    // },
    // "& p": {
    //   fontSize: "14px",
    //   fontWeight: "400",
    // },
    // "& a": {
    //   fontSize: "13px",
    //   fontWeight: "500",
    // }
  },
  socialProfileLinks: {
    display: "flex",
    justifyContent: "center",
    flexDirection: 'column',
    marginRight: "20px",
    marginLeft: "20px",
    "& a": {
      fontSize: "13px",
      fontWeight: "900",
      color: 'blue'
    }
  },
  socialProfileIcon: {
    maxWidth: "50px",
    height: "50px",
    width: "100%",
  },
  ownCardTitle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "20px",
    "& img": {
      maxWidth: "50px",
      width: "100%",
      height: "50px",
      borderRadius: "50%",
    },
    "& h3": {
      fontSize: "24px",
      fontWeight: "700",
      maxWidth: "70%",
    },
    "& p": {
      fontSize: "20px",
      fontWeight: "400",
    },
  },
  downloadStoreSection: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: "20px",
    "& img": {
      maxWidth: "130px",
      marginLeft: "10px",
      marginRight: "10px",
    },
  },
  fullHeightHoverWrapper: {
    display: "none",
  },
  createCardTitle: {
    position: "relative",
    marginBottom: '5px',
    "& h4": {
      fontSize: "25px",
      fontWeight: "600",
      maxWidth: "100%",
    },
    "& p": {
      fontWeight: "300",
      fontSize: "25px",
    },
  },
  downloadCard: {
    backgroundColor: "#016BDF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "sticky",
    border: "none",
    textDecoration: "none",
    borderRadius: "10px",
    bottom: "0",
    "& h3": {
      fontSize: "20px",
      fontWeight: "300",
    },
    "& img": {
      width: "250px",
    },
    "& button": {
      fontSize: "18px",
      color: "#ffffff",
      paddingTop: "10px",
      paddingBottom: "10px",
      textDecoration: "none",
      backgroundColor: "#016BDF",
      border: "none",
      fontWeight: "400",
      lineHeight: "1.2222",
    },
  },
  ownCardTitleDescription: {
    fontSize: "18px !important",
    fontWeight: "55000 !important",
    color: "#000000 !important",
    "& span": {
      fontWeight: "bold",
    },
    padding: 0,
  },
  ownCardTitleDetail: {
    fontSize: "15px !important",
    fontWeight: "400 !important",
    color: "#000000 !important",
    "& span": {
      fontWeight: "bold",
    },
    padding: 0,
  },
  live: {
    color: "#007AFF",
    fontWeight: "bold",
  },
  title: {
    color: "#0E1F4A",
    font: "lato",
    fontSize: "35px",
    lineHeight: 1.2,
    fontWeight: "bold",
    marginBottom: 0,
  },
  contact: {
    color: "#29406B",
  },
  messageIcon: {
    width: "17px",
    height: "13px",
  },
  contactIconsIcon: {
    width: '16px',
    height: '12px',
  },
  contactPhoneIcon:{
    width: '15px',
    height: '14px',
  },
  download: {
    marginBottom: "37px",
    marginRight: "-16px",
  },
  carouserItem: {
    borderRadius: "5%",
  },
  ml20: {
    marginLeft: "20px",
  },
  mr20: {
    marginRight: "20px",
  },
  name: {
    marginTop: "20px",
    color: "#0E1F4A",
    fontWeight: 900,
  },
  mt20: {
    marginTop: "20px",
  },
  mb20: {
    marginBottom: "20px",
  },
  ml30: {
    marginLeft: "30px",
  },
  mr30: {
    marginRight: "30px",
  },
  mt30: {
    marginTop: "30px",
  },
  mb30: {
    marginBottom: "30px",
  },
  mt50: {
    marginTop: "50px",
  },
  liveContactio: {
    textAlign: "center",
    fontSize: "25px",
    color: "#2E2D2E",
    fontWeight: "400",
    marginTop: '10px'
  },
  tryIt: {
    textAlign: "center",
    fontSize: "20px",
    fontWeight: "900",
  },
  fs25: {
    fontSize: '30px',
  },
  txtCenter: {
    textAlign: 'center'
  },
  colorBlue: {
    color: 'blue'
  },
  font13: {
    fontSize: '13px',
    fontWeight: 900
  }
}));

const PublicCardView = () => {
  const { token } = useParams();
  const [card, setCard] = useState({});
  const [enabledPreference, setEnabledPreference] = useState([]);

  const [primaryPhone, setPrimaryPhone] = useState();
  const [primaryEmail, setPrimaryEmail] = useState();
  const [openDownloadModal, setOpenDownloadModal] = useState(false);

  useEffect(() => {
    HttpClient.get(`/cards/${token}/access`)
      .then((res) => {
        if (res.profile.preferences) setPreferences(res.profile.preferences);
        {
          res.profile.emails
            ? setPEmail(res.profile.emails)
            : setPrimaryEmail(null);
        }
        {
          res.profile.phones
            ? setPPhone(res.profile.phones)
            : setPrimaryPhone(null);
        }
        setCard(res);
      })
      .catch((error) => console.log(error));
  }, [token]);

  const setPreferences = (pref) => {
    let enabledPreference = [];
    for (let i = 0; i < pref.length; i++) {
      if (pref[i].enable === 1) enabledPreference.push(pref[i]);
    }
    setEnabledPreference(enabledPreference);
  };

  const setPEmail = (pEmail) => {
    let email = {};
    for (let i = 0; i < pEmail.length; i++) {
      if (pEmail[i].primary) email = pEmail[i].email;
    }
    setPrimaryEmail(email);
  };

  const setPPhone = (pPhone) => {
    let phone = {};
    if (pPhone.length > 1) {
      for (let i = 0; i < pPhone.length; i++) {
        if (pPhone[i].primary && pPhone[i].type === "mobile")
          phone = pPhone[i].phoneNumber;
      }
    } else if (!isEmpty(pPhone)) {
      phone = pPhone[0].phoneNumber;
    } else {
      phone = null;
    }
    setPrimaryPhone(phone);
  };

  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const downloadCard = (e) => {
    const fileDownload = require("js-file-download");
    e.preventDefault();
    HttpClient.download(`/cards/${token}/vcard`)
      .then((res) => res.blob())
      .then((response) => {
        fileDownload(
          response,
          `be-${card.profile.firstName}-${card.profile.lastName}-vcard.vcf`
        );
      })
      .catch((error) => console.log("Error template name update: ", error));
  };

  const downloadIconHandler = () => {
    setOpenDownloadModal(true);
  };

  const getSocialProfileIcon = (type) => {

    if (type === 'facebook')
    return facebookFooter
    else if (type === 'instagram')
    return instagramFooter
    else if (type === 'linkedin')
    return linkedinFooter
    else if (type === 'youtube')
    return youtubeFooter
    else if (type === 'skype')
    return skypeFooter
    else if (type === 'vimeo')
    return vimeoFooter
    else if (type === 'twitter')
    return twitterFooter
    else if (type === 'whatsapp')
    return whatsappFooter
    else if (type === 'snapchat')
    return snapchatFooter
    else if (type === 'tiktok')
    return tiktokFooter
  }

  const socialMediaLink = (type, value) => {
    if (type === 'whatsapp')
      return `whatsapp://send?phone=${value}`
    else if (type === 'skype')
      return `skype:${value}` 
    else if (type === 'instagram')
      return `instagram://user?username=${value}`;
    else if (type === 'snapchat')
      return `snapchat://add/${value}`
    else return value;
  }

  const showBottom = (socialProfiles) => {
    if (socialProfiles)
      for (const socialProfile of socialProfiles)
        if (!isNull(socialProfile.value) && !isEmpty(socialProfile.value))
          return true;
    return false
  }

  const classes = useStyles();

  return (
    <Box className={classes.cardmainWrapper}>
      <Box className={classes.CardDetailsWrapper + " " + classes.colorWhite}>
        <>
          {!isEmpty(card) && (
            <>
              <MetaTags>
                <title>{`${card.profile.firstName} ${card.profile.lastName}'s has sent you a contact card.`}</title>
                <meta
                  name="description"
                  content="ap to see the card and download the contact
                  information to your contacts.
                  Create a contact card for yourself now!"
                />
                <meta
                  property="og:title"
                  content={`${card.profile.firstName} ${card.profile.lastName}`}
                />
                <meta property="og:image" content={card.previewImagePath} />
              </MetaTags>

              <Box className={classes.headerAlignment}>
                <Box className={classes.headerDetailsBox}>
                  <Typography className={classes.title}>
                    <span className={classes.live}> Live</span>
                    <span className={classes.contact}>Contact</span>
                  </Typography>
                  <p
                    className={classes.headerName}
                  >{`${card.profile.firstName} ${card.profile.lastName}`}</p>

                  <p className={classes.headerDescription}>
                    Contact Information Always Up to Date
                  </p>
                </Box>
                <Button onClick={downloadIconHandler}>
                  <img
                    className={classes.download}
                    src={download_top}
                    alt={"Download"}
                  />
                </Button>
              </Box>

              <Carousel
                className={classes.carouserItem}
                navButtonsAlwaysVisible={false}
                indicators={false}
                animation={"slide"}
                autoPlay={false}
                cycleNavigation={false}
                autoPlay={false}
                NextIcon={<Forward />}
                PrevIcon={<Back />}
                navButtonsProps={{
                  // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
                  style: {
                    background: 'none',
                    borderRadius: 0,
                  },
                }}
              >
                {card.sides.map((item, i) => (
                  <CarouselItem
                    key={i}
                    item={
                      item.previewImagePath
                        ? item.previewImagePath
                        : cardPreviewNotFound
                    }
                  />
                ))}
              </Carousel>

              <Box>
                <Box className={classes.profileInfoContainer}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box>
                      <img
                        src={
                          !isNull(card.profile.profilePicture) && !isEmpty(card.profile.profilePicture)
                            ? card.profile.profilePicture
                            : userAvtar
                        }
                        className={classes.profileImage}
                      />
                    </Box>
                    <Box className={classes.userProfileName}>
                      <h4 className={classes.name}>
                        {card.profile.displayName}{" "}
                      </h4>
                      {enabledPreference.length > 0 ? (
                        <p className={classes.profileInfoAlignment}>
                          {enabledPreference[0].value} Designer
                        </p>
                      ) : null}
                      {enabledPreference.length > 1 ? (
                        <p>{enabledPreference[1].value}</p>
                      ) : null}
                    </Box>
                  </Box>
                  <Box>
                    {
                      !isNull(card.profile.brandLogo) && !isEmpty(card.profile.brandLogo) && (
                        <img
                      src={card.profile.brandLogo}
                      className={classes.brandLogo}
                    />
                      )
                    }
                    
                  </Box>
                </Box>

                {/* <Box className={classes.contactLinks}>
                  {primaryPhone ? (
                    <a href={`sms: ${primaryPhone}`}>
                      <Box className={classes.contactIcons}>
                        {" "}
                        <img
                          className={classes.messageIcon}
                          src={commentLinkIcon}
                        />
                      </Box>
                    </a>
                  ) : (
                    <Box className={classes.contactIcons}>
                      {" "}
                      <img
                        className={classes.phoneIcon}
                        src={commentLinkIconDisabled}
                      />
                    </Box>
                  )}
                  {primaryPhone ? (
                    <a href={`tel: ${primaryPhone}`}>
   
                      <Box className={classes.contactIcons}>
                        <img
                          className={classes.contactPhoneIcon}
                          src={phoneLinkIcon}
                        />
                      </Box>
                    </a>
                  ) : (
                    <Box className={classes.contactIcons}>
                      <img
                        className={classes.contactPhoneIcon}
                        src={phoneLinkIconDisabled}
                      />
                    </Box>
                  )}
                  {primaryEmail ? (
                    <a href={`mailto: ${primaryEmail}`}>
                      <Box className={classes.contactIcons}>
                        <img
                          className={classes.contactIconsIcon}
                          src={mailLinkIcon}
                        />
                      </Box>
                    </a>
                  ) : (
                    <Box className={classes.contactIcons}>
                      <img
                        className={classes.contactIconsIcon}
                        src={mailLinkIconDisabled}
                      />
                    </Box>
                  )}
                  {primaryPhone ? (
                    <a href={`tel: ${primaryPhone}`}>
                      <Box className={classes.contactIcons}>
                        <img
                          className={classes.contactIconsIcon}
                          src={videoLinkIcon}
                        />
                      </Box>
                    </a>
                  ) : (
                    <Box className={classes.contactIcons}>
                      <img
                        className={classes.contactIconsIcon}
                        src={videoLinkIconDisabled}
                      />
                    </Box>
                  )}
                </Box>

                <Box className={classes.socialLinks + " " + classes.center}>
                  {card.facebookLink ? (
                    <a href={card.facebookLink}>
                      <img
                        style={{ borderRadius: "8px" }}
                        src={SocialProfileType["facebook"]}
                        alt={"facebook"}
                      />
                    </a>
                  ) : (
                    <a href={card.facebookLink}>
                      <img
                        style={{ borderRadius: "8px" }}
                        src={SocialProfileType["facebookDisabled"]}
                        alt={"facebook"}
                      />
                    </a>
                  )}
                  {card.instagramLink ? (
                    <a href={card.instagramLink}>
                      <img
                        src={SocialProfileType["instagram"]}
                        alt={"instagram"}
                      />
                    </a>
                  ) : (
                    <a href={card.instagramLink}>
                      <img
                        src={SocialProfileType["instagramDisabled"]}
                        alt={"instagram"}
                      />
                    </a>
                  )}

                  {card.linkedinLink ? (
                    <a href={card.linkedinLink}>
                      <img src={card.linkedinLink} alt={"linkedin"} />
                    </a>
                  ) : (
                    <a href={card.linkedinLink}>
                      <img
                        src={SocialProfileType["linkedinDisabled"]}
                        alt={"linkedin"}
                      />
                    </a>
                  )}

                  {card.vimeoLink ? (
                    <a href={card.vimeoLink}>
                      <img src={card.vimeoLink} alt={"vimeo"} />
                    </a>
                  ) : (
                    <a href={card.vimeoLink}>
                      <img
                        src={SocialProfileType["vimeoDisabled"]}
                        alt={"vimeo"}
                      />
                    </a>
                  )}
                </Box> */}

                <Box className={classes.personalInfoWrapper} mt={3}>
                  {card.profile.description ? (
                    <Box mb={3}>
                      <label style={{ marginBottom: "12px" }}>Insight:</label>
                      <p>{card.profile.description}</p>
                    </Box>
                  ) : null}
                  {card.profile.displayName ? (
                    <Box mb={3}>
                      <label>Full Name: </label>
                      <p>
                        {!isNull(card.profile.firstName) && !isEmpty(card.profile.firstName)  && (<span>{card.profile.firstName} </span>)}
                        {!isNull(card.profile.middleName) && !isEmpty(card.profile.middleName) && (<span>{card.profile.middleName} </span>)}
                        {!isNull(card.profile.lastName) && !isEmpty(card.profile.lastName) && (<span>{card.profile.lastName}</span>)}
                        </p>
                    </Box>
                  ) : null}
                  {card.profile.personalExpression ? (
                    <Box mb={3}>
                      <label>Personal Expression: </label>
                      <p>{card.profile.personalExpression}</p>
                    </Box>
                  ) : null}
                  {card.profile.expressStatus ? (
                    <Box mb={3}>
                      <label>Status: </label>
                      <p>Married</p>
                    </Box>
                  ) : null}
                  {card.profile.myFavouritePoem ? (
                    <Box mb={3}>
                      <label>My Favourite Poem: </label>
                      <p>{card.profile.myFavouritePoem}</p>
                    </Box>
                  ) : null}
                  {card.profile.lifeMotto ? (
                    <Box mb={3}>
                      <label>Life motto: </label>
                      <p>{card.profile.lifeMotto}</p>
                    </Box>
                  ) : null}
                  {card.profile.description ? (
                    <Box mb={3}>
                      <label>Description: </label>
                      <p>{card.profile.description}</p>
                    </Box>
                  ) : null}
                  {card.profile.dateOfBirth ? (
                    <Box mb={3}>
                      <label>Birthday: </label>
                      <p>{card.profile.dateOfBirth}</p>
                    </Box>
                  ) : null}
                  {card.profile.website ? (
                    <Box mb={3} className={classes.siteSectionWrapper}>
                      <Box className={classes.siteSection}>
                        <span>www</span>
                      </Box>
                      <Box>
                        <label>Website: </label>
                        <p>
                          <a>{card.profile.website}</a>
                        </p>
                      </Box>
                    </Box>
                  ) : null}
                  {/* {card.border1? <Box className={classes.hrWidth}></Box>: null} */}
                  <Box className={classes.hrWidth}></Box>
                  {card.profile.nickname ? (
                    <Box mb={3}>
                      <label>Nick Name: </label>
                      <p>{card.profile.nickname}</p>
                    </Box>
                  ) : null}
                  {card.profile.maidenName ? (
                    <Box mb={3}>
                      <label>Miaden Name: </label>
                      <p>{card.profile.maidenName}</p>
                    </Box>
                  ) : null}
                  {card.profile.phoneticFirstName ? (
                    <Box mb={3}>
                      <label>Phonetic First Name: </label>
                      <p>{card.profile.phoneticFirstName}</p>
                    </Box>
                  ) : null}
                  {card.profile.phoneticLastName ? (
                    <Box mb={3}>
                      <label>Phonetic Last Name: </label>
                      <p>{card.profile.phoneticLastName}</p>
                    </Box>
                  ) : null}
                  {card.profile.pronunciationFirstName ? (
                    <Box mb={3}>
                      <label>Pronunciation First Name: </label>
                      <p>{card.profile.pronunciationFirstName}</p>
                    </Box>
                  ) : null}
                  {card.profile.pronunciationLastName ? (
                    <Box mb={3}>
                      <label>Pronunciation Last Name: </label>
                      <p>{card.profile.pronunciationLastName}</p>
                    </Box>
                  ) : null}

                  {card.profile.employments
                    ? card.profile.employments.map((item, i) => {
                        return (
                          <>
                            {item.title ? (
                                <Box mb={3}>
                                  <label>Title: </label>
                                  <p>{item.title}</p>
                                </Box>
                              ) : null}
                            <Box className={classes.jobInfoWrapper} mb={3}>
                              {item.profession ? (
                                <Box>
                                  <label>Profession: </label>
                                  <p>{item.profession}</p>
                                </Box>
                              ) : null}
                              {item.companyLogo ? (
                                <img
                                  src={item.companyLogo}
                                  alt={"makeup artist"}
                                />
                              ) : null}
                            </Box>
                            {item.company ? (
                              <Box mb={3}>
                                <label>Company: </label>
                                <p>{item.company}</p>
                              </Box>
                            ) : null}
                            {item.departmentDescription ? (
                              <Box mb={3}>
                                <label>Department Description: </label>
                                <p>{item.departmentDescription}</p>
                              </Box>
                            ) : null}
                            {item.companyWebsite ? (
                              <Box
                                mb={3}
                                className={classes.siteSectionWrapper}
                              >
                                <Box className={classes.siteSection}>
                                  <span>www</span>
                                </Box>
                                <Box>
                                  <label>Website: </label>
                                  <p>
                                    <a style={{color: 'blue', fontSize: '13px'}} href={item.companyWebsite}>{item.companyWebsite}</a>
                                  </p>
                                </Box>
                              </Box>
                            ) : null}

                            <Box mb={3} className={classes.siteSectionWrapper}>
                              <Box className={classes.siteSection}>
                                <img src={mapIcon} width={22} />
                              </Box>
                              <Box>
                                <label>Work Location: </label>
                                <p>
                                  {!isNull(item.company) && !isEmpty(item.company) && (<span className={classes.font13}>{item.company}  <br /> </span>) }
                                  {!isNull(item.address1) && !isEmpty(item.address1) && (<span className={classes.font13}>{item.address1}  <br /> </span>) }
                                  {!isNull(item.address2) && !isEmpty(item.address2) && (<span className={classes.font13}>{item.address2}  <br /> </span>) }
                                  {!isNull(item.address3) && !isEmpty(item.address3) && (<span className={classes.font13}>{item.address3}  <br /> </span>) }
                                  {!isNull(item.aptNumber) && !isEmpty(item.aptNumber) && (<span className={classes.font13}>{item.aptNumber}  <br /> </span>) }
                                  {!isNull(item.zipCode) && !isEmpty(item.zipCode) && (<span className={classes.font13}>{item.zipCode}  <br /> </span>) }
                                  {!isNull(item.city) && !isEmpty(item.city) && (<span className={classes.font13}>{item.city}  <br /> </span>) }
                                  {!isNull(item.state) && !isEmpty(item.state) && (<span className={classes.font13}>{item.state}  <br /> </span>) }
                                  {!isNull(item.country) && !isEmpty(item.country) && (<span className={classes.font13}>{item.country}  <br /> </span>) }
                                </p>
                              </Box>
                            </Box>
                            <Box className={classes.hrWidth}
                             key={i}
                            ></Box>
                          </>
                        );
                      })
                    : null}

                  {/* {card.border3 ? (
                    <Box className={classes.hrWidth}></Box>
                  ) : null} */}

                  {card.profile.phones
                    ? card.profile.phones.map((item, i) => {
                        return (
                          <Box
                            key={12}
                            mb={3}
                            className={
                              classes.siteSectionWrapper +
                              " " +
                              classes.noSpanBg
                            }
                          >
                            <Box className={classes.siteSection}>
                              <img src={phoneLinkIcon} width={22} />
                              {/*<span><PhoneIcon/></span>*/}
                            </Box>
                            <Box>
                              <label>{capitalize(item.type)}: </label>
                              <p>
                                <a style={{ color: 'blue', fontSize: '13px', fontWeight: 900 }} href={`tel: ${item.phoneNumber}`}>
                                  {item.phoneNumber}
                                </a>
                              </p>
                            </Box>
                          </Box>
                        );
                      })
                    : null}

                  {card.profile.emails
                    ? card.profile.emails.map((mailRow, i) => {
                        return (
                          <Box
                            mb={3}
                            key={i}
                            className={
                              classes.siteSectionWrapper +
                              " " +
                              classes.noSpanBg
                            }
                          >
                            <Box className={classes.siteSection}>
                              <img src={mailLinkIcon} width={22} />
                            </Box>
                            <Box>
                              <label>{capitalize(mailRow.type)}: </label>
                              <p>
                                <a style={{ color: 'blue', fontSize: '13px', fontWeight: 900 }} href={`mailto: ${mailRow.mail}`}>
                                  {mailRow.mail}
                                </a>
                              </p>
                            </Box>
                          </Box>
                        );
                      })
                    : null}

                  {card.profile.addresses
                    ? card.profile.addresses.map((addressRow, i) => {
                        return (
                          <Box
                            key={addressRow.id}
                            mb={3}
                            className={
                              classes.siteSectionWrapper +
                              " " +
                              classes.noSpanBg
                            }
                          >
                            <Box className={classes.siteSection}>
                              <img src={mapIcon} width={22} />
                            </Box>
                            <Box>
                              <label>{capitalize(addressRow.type)} Address: </label>
                              <p>
                                {!isNull(addressRow.address1) && !isEmpty(addressRow.address1) && (<span className={classes.font13}>{addressRow.address1}  <br /> </span>) }
                                {!isNull(addressRow.address2) && !isEmpty(addressRow.address1) && (<span className={classes.font13}>{addressRow.address1}  <br /> </span>) }
                                {!isNull(addressRow.address3) && !isEmpty(addressRow.address3) && (<span className={classes.font13}>{addressRow.address3}  <br /> </span>) }
                                {!isNull(addressRow.house) && !isEmpty(addressRow.house) && (<span className={classes.font13}>{addressRow.house}  <br /> </span>) }
                                {!isNull(addressRow.building) && !isEmpty(addressRow.building) && (<span className={classes.font13}>{addressRow.building}  <br /> </span>) }
                                {!isNull(addressRow.street) && !isEmpty(addressRow.street) && (<span className={classes.font13}>{addressRow.street}  <br /> </span>) }
                                {!isNull(addressRow.city) && !isEmpty(addressRow.city) && (<span className={classes.font13}>{addressRow.city}  <br /> </span>) }
                                {!isNull(addressRow.state) && !isEmpty(addressRow.state) && (<span className={classes.font13}>{addressRow.state}  <br /> </span>) }
                                {!isNull(addressRow.aptNumber) && !isEmpty(addressRow.aptNumber) && (<span className={classes.font13}>{addressRow.aptNumber}  <br /> </span>) }
                                {!isNull(addressRow.zipCode) && !isEmpty(addressRow.zipCode) && (<span className={classes.font13}>{addressRow.zipCode}  <br /> </span>) }
                                {!isNull(addressRow.country) && !isEmpty(addressRow.country) && (<span className={classes.font13}>{addressRow.country}  <br /> </span>) }
                              </p>
                            </Box>
                          </Box>
                        );
                      })
                    : null}

                    {!isNull(card.profile.addresses) && !isEmpty(card.profile.addresses) && 
                    (
                      <Box className={classes.hrWidth}></Box>
                    )}

                   
                    {card.profile.socialProfiles && (
                      <>
                      { card.profile.socialProfiles.map((socialProfile, i) => {
                        return (
                          <>
                            {!isNull(socialProfile.value) && !isEmpty(socialProfile.value) &&
                              <a href={socialMediaLink(socialProfile.type, socialProfile.value)}  className={classes.socialProfile}>
                                <Box
                                  mb={3}
                                  className={
                                    classes.siteSectionWrapper
                                  }>
                                      <img src={getSocialProfileIcon(socialProfile.type)} alt={socialProfile.type} className={classes.socialProfileIcon} />
                                    {/* </Box> */}
                                    <Box className={classes.socialProfileLinks}>
                                      <label>Visit {socialProfile.type} profile: </label>
                                        <a>{socialProfile.value}</a>
                                    </Box>
                                </Box>
                              </a>
                            }
                          </>
                        )}
                      )}
                      </>
                    )}

                  {showBottom(card.profile.socialProfiles) &&
                  (
                    <Box className={classes.hrWidth}></Box>
                  )}

                  <Box className={classes.fs25 + ' ' + classes.mb20 + ' ' + classes.txtCenter}>
                    <h4><span className={classes.live}> Live</span>
                    <span className={classes.contact}>Contact</span></h4>
                  </Box>

                  <p mb={3} className={classes.ownCardTitleDescription}>
                  The new contact manager app for friends, family and business. 
                  </p>

                  <List className={classes.mt20 + ' ' + classes.mb20}>
                    <ListItem style={{padding: 0, alignItems: 'start'}}>
                      <ListItemIcon style={{marginTop: '2px', minWidth: '25px'}}>
                        <FiberManualRecordIcon style={{width: '10px', height: '10px'}} />
                      </ListItemIcon>
                      <ListItemText style={{margin: 0}}><p className={classes.ownCardTitleDetail}>Create gorgeous cards to share your contact info.</p> </ListItemText>
                    </ListItem>
                    <ListItem style={{padding: 0, alignItems: 'start', marginTop: '10px'}}>
                      <ListItemIcon style={{marginTop: '2px', minWidth: '25px'}}>
                        <FiberManualRecordIcon   style={{width: '10px', height: '10px'}} />
                      </ListItemIcon>
                      <ListItemText style={{margin: 0}}><p className={classes.ownCardTitleDetail}>Use a different card for each occasion.</p></ListItemText>
                    </ListItem>
                    <ListItem style={{padding: 0, alignItems: 'start', marginTop: '10px'}}>
                      <ListItemIcon style={{marginTop: '2px', minWidth: '25px'}}>
                        <FiberManualRecordIcon   style={{width: '10px', height: '10px'}} />
                      </ListItemIcon>
                      <ListItemText style={{margin: 0}}><p className={classes.ownCardTitleDetail}>Share your cards through Live Exchange in person or online, or via email and weblinks.</p> </ListItemText>
                    </ListItem>
                    <ListItem style={{padding: 0, alignItems: 'start', marginTop: '10px'}}>
                      <ListItemIcon style={{marginTop: '2px', minWidth: '25px'}}>
                        <FiberManualRecordIcon  style={{width: '10px', height: '10px'}} />
                      </ListItemIcon>
                      <ListItemText style={{margin: 0}}><p className={classes.ownCardTitleDetail}>Contacts update live each time a card is edited.</p></ListItemText>
                    </ListItem>
                  </List>

                  <h3 className={classes.tryIt}>Create Your Own Card</h3>
                  <h3 className={classes.tryIt + ' ' + classes.mb30}>Try it today for Free!</h3>
                
                 
                  {/* <Box className={classes.downloadStoreSection}>
                    <img src={appStoreImage} alt={"appStore"} />
                    <img src={googlePlayImage} alt={"googlePlay"} />
                  </Box> */}

                  <a href="https://www.livecontact.io/beta" style={{textDecoration:'none'}}>
                    <Box className={classes.downloadCard}>
                      <Button style={{textDecoration:'none'}}>Create Your Own Card</Button>
                    </Box>
                  </a>

                  <h6 style={{marginTop: '25px'}} className={classes.mt50 + ' ' + classes.mb30 + ' ' + classes.liveContactio}>
                    <a style={{color: 'black', textDecoration: 'none'}}  href="https://www.livecontact.io">LiveContact.io</a>
                  </h6>
                </Box>
              </Box>
            </>
          )}
        </>
      </Box>
      <DownloadAlertDialog
        onClose={setOpenDownloadModal}
        downloadCard={downloadCard}
        open={openDownloadModal}
      />
    </Box>
    // </Box>
  );
};

export default PublicCardView;
