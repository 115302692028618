import React from 'react'
import { Route } from 'react-router-dom'
import PropTypes from 'prop-types'

import New from './Card/New/index'
import Editor from './Card/Editor'
import DefaultPreview from './Card/DefaultPreview'

const UserPanel = ({ match }) => {
  return (
    <>
      <Route path={`${match.path}/cards/new/:cardId`} component={New} />
      <Route path={`${match.path}/cards/:id/design`} component={Editor} />
      <Route path={`${match.path}/cards/:id/preview`} component={DefaultPreview} />
    </>
  )
}

UserPanel.propTypes = {
  match: PropTypes.object.isRequired,
}

export default UserPanel
