import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import HttpClient from '../../../../services/HttpClient'
import AuthContext from '../../../../contexts/AuthContext'
import FormatSelector from './FormatSelector'
import DummySkeleton from './DummySkeleton'
import TemplatesSection from './TemplatesSection'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {camelCaseKeysUnderscore} from './Common'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'grid',
    rowGap: theme.spacing(2),
    columnGap: theme.spacing(2),
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
}))

const SearchResult = ({ url, onClose, cardId  }) => {
  const classes = useStyles()
  const [authData] = useContext(AuthContext)
  const [loading, setLoading] = useState(true)
  const [templates, setTemplates] = useState(true)
  const history = useHistory()
  const [format, setFormat] = useState('2')
  const notify = message => toast(message);

  useEffect(() => {
    setLoading(true)
    HttpClient.get(url, { Authorization: `Bearer ${authData.authToken}` })
      .then(res => {
        camelCaseKeysUnderscore(res)
        setTemplates(res)
        setLoading(false)
      })
      .catch(error => {
        console.log('Error getting templates: ', error)
        setTemplates([])
        setLoading(false)
      })
  }, [authData.authToken, url])

  const handleCreateCard = (templateId, formatType, categoryId, categoryType) => {
    HttpClient.put(
      `/cards/${cardId}/design`,
      { templateId: templateId, formatType: parseInt(formatType) },
      { Authorization: `Bearer ${authData.authToken}` }
    )
      .then(res => {
        history.push(`/user/cards/${res.id}/design?dashboard=true&timestamp=${new Date().toISOString()}`)
      }).catch(error => {
        if (error.response.status === 424) {
          error.response.text().then(response => {
            notify(JSON.parse(response).error.message)
          })
        } else notify('No Side with the selected template format.')
        console.log('Error creating create: ', error)
      })
  }

  return ( 
    <> 
     <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <FormatSelector setFormat={format => setFormat(format)} />
      {loading ? 
        <DummySkeleton />
        :
        <Box p={2} className={classes.root}>
          <TemplatesSection 
            templates={templates} 
            showTitles={false} 
            type='grid'
            format={format} 
            onClick={(templateId, format, categoryId, categoryType) => handleCreateCard(templateId, format, categoryId, categoryType)} />
        </Box>
           
      }
    </>
  )
}

SearchResult.propTypes = {
  url: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  cardId: PropTypes.string.isRequired,
}

export default SearchResult
