import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import HorizontalIcon from '../../../../icons/HorizontalIcon'
import SquareIcon from '../../../../icons/SquareIcon'
import VerticalIcon from '../../../../icons/VerticalIcon'


const useStyles = makeStyles(() => ({
  button: {
    color: 'white'
  }
}))
const formats = ['Horizontal', 'Vertical', 'Square']
const formatsToComponentsMap = {
  'Horizontal': HorizontalIcon,
  'Vertical': VerticalIcon,
  'Square': SquareIcon,
}

const FormatButton = ({ type, active, onFormatChange }) => {
  const classes = useStyles()
  const IconComponent = formatsToComponentsMap[formats[type-1]]
  return (
    <Button
      color="inherit"
      size="large"
      aria-label={`${formats[type-1]} Format`}
      startIcon={<IconComponent />}
      classes={active ? { root: classes.button } : {}}
      onClick={() => {
        if(!active) {
          onFormatChange(type)
        }
      }}
    >
      {formats[type-1]}
    </Button>
  )
}

FormatButton.propTypes = {
  type: PropTypes.number.isRequired,
  active: PropTypes.bool.isRequired,
  onFormatChange: PropTypes.func.isRequired,
}

export default FormatButton
