import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import { Grid, Box, Toolbar } from '@material-ui/core'
import { DialogContent, DialogActions } from '@material-ui/core/'
import { TextField, Button, IconButton } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import Overlay from '../Overlay'

import HttpClient from '../../../../services/HttpClient'
import AuthContext from '../../../../contexts/AuthContext'
import SaveAs from './SaveAs'
import Publish from './Publish'

const useStyles = makeStyles(() => ({
  toolbar: {
    color: '#FFF',
    backgroundColor: '#8BA8F0',
    minHeight: '42px'
  },
  button: {
    color: 'white'
  }
}))

const TitleBar = ({ textStyling, onTextStylingChange }) => {
  const classes = useStyles()
  const [authData] = useContext(AuthContext)
  const [textStylingName, setTextStylingName] = useState('')
  const [editing, setEditing] = useState(false)
  const [savingAs, setSavingAs] = useState(false)
  const [publishing, setPublishing] = useState(false)

  useEffect(() => {
    setTextStylingName(textStyling.name)
  }, [textStyling.name])

  const handleSubmit = e => {
    e.preventDefault()
    if(textStylingName !== '') {
      if( Number.isInteger(textStyling.id) ) {
        HttpClient.put(`/text_stylings/${textStyling.id}`, { name: textStylingName }, { Authorization: `Bearer ${authData.authToken}` })
          .then(res => {
            setEditing(false)
            onTextStylingChange({ name: res.name })
          })
          .catch(error => console.log('Error text styling name update: ', error))
      }
    }
  }

  const handleNameUpdate = (name) =>{
    textStyling.name=name
  }

  return (
    <Toolbar variant="dense" className={classes.toolbar}>
      <Grid container>
        <Grid item sm={4}>
          {textStyling.name !== '' ? textStyling.name : 'Name Your Text Styling'}
          <IconButton
            color="inherit"
            aria-label="Edit Text Styling Name"
            component="span"
            onClick={() => setEditing(true)}
          >
            <EditIcon fontSize="small" />
          </IconButton>
          <Overlay
            maxWidth="xs"
            open={editing}
            onClose={() => setEditing(false)}
          >
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <TextField
                  id="text-styling-name"
                  label="Name"
                  value={textStylingName||''}
                  onChange={e => setTextStylingName(e.currentTarget.value)}
                  margin="normal"
                  variant="outlined"
                  size="small"
                  fullWidth
                  autoFocus
                  required
                />
              </DialogContent>
              <DialogActions>
                <Button type="submit" variant="contained" color="primary" size="small">Save</Button>
              </DialogActions> 
            </form>
          </Overlay>
        </Grid>
        <Grid item sm={5} />
        <Grid item sm={3}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              color="inherit"
              size="large"
              aria-label="save text styling as"
              onClick={() => setSavingAs(true)}
            >
              Save As
            </Button>
            <Button
              color="inherit"
              size="large"
              aria-label="publish text styling"
              onClick={() => setPublishing(true)}
            >
              Publish
            </Button>
            {
              publishing ?
                <Overlay
                  maxWidth="sm"
                  open
                  onClose={() => setPublishing(false)}
                >
                  <Publish
                    nameUpdate={handleNameUpdate}
                    textStyling={textStyling}
                    onTextStylingChange={onTextStylingChange}
                    onClose={() => setPublishing(false)}
                  />
                </Overlay>
                : null
            }
            {savingAs ?
              <SaveAs nameUpdate={handleNameUpdate} textStylingId={textStyling.id} onClose={() => setSavingAs(false)} />
              : null
            }
          </Box>
        </Grid>
      </Grid>
    </Toolbar>
  )
}

TitleBar.propTypes = {
  textStyling: PropTypes.object.isRequired,
  onTextStylingChange: PropTypes.func.isRequired,
}

export default TitleBar
