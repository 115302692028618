import React, { useContext, useState, useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { makeStyles } from '@material-ui/core/styles'
import { Box, Typography } from '@material-ui/core'

import DashboardTemplates from './DashBoardTemplates'
import DashboardHeader from './DashboardHeader'
import HttpClient from '../../../../services/HttpClient'
import AuthContext from '../../../../contexts/AuthContext'
import { getCategoryTypeKey, CustomCategoryTitle } from './Common'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'grid',
    columnGap: theme.spacing(2),
    rowGap: theme.spacing(2),
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  headings: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#4A4A4A ',
    marginLeft: '30px',
    marginBottom: '10px',
  },
}))

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

const KeepItSimple = () => {
  const classes = useStyles()
  const query = useQuery()
  const [templates, setTemplates] = useState([])
  const [authData] = useContext(AuthContext)
  const [loading, setLoading] = useState(true)
  const history = useHistory()
  const { cardId } = useParams()
  const notify = message => toast(message)

  const changeTemplate = (templateId, formatType) => {
    setLoading(true)

    HttpClient.put(
      `/cards/${cardId}/design`,
      { templateId: templateId, formatType: formatType },
      {
        Authorization: `Bearer ${authData.authToken}`,
      }
    )
      .then(res => {
        history.push(`/user/cards/${res.id}/design?dashboard=true&timestamp=${new Date().toISOString()}`)
      })
      .catch(error => {
        if (error.response.status === 424) {
          error.response.text().then(response => {
            notify(JSON.parse(response).error.message)
          })
        } else notify('No Side with the selected template format.')
        console.log("Error creating create: ", error);
      });
  };

  useEffect(() => {
    setLoading(true)
    HttpClient.get(
      `/templates/published?categoryTitle=${
        CustomCategoryTitle.keepItSimple
      }&categoryType=${getCategoryTypeKey('Styles')}`,
      {
        Authorization: `Bearer ${authData.authToken}`,
      }
    )
      .then(resCard => {
        setTemplates(resCard)
        setLoading(false)
      })
      .catch(error => {
        console.log('Error getting industries: ', error)
        setTemplates([])
        setLoading(false)
      })
  }, [authData.authToken])

  const search = searchQuery => {
    HttpClient.get(
      `/templates/published?categoryTitle=${
        CustomCategoryTitle.keepItSimple
      }&categoryType=${getCategoryTypeKey('Styles')}&search=${searchQuery}`,
      {
        Authorization: `Bearer ${authData.authToken}`,
      }
    )
      .then(res => {
        setTemplates(res)
        setLoading(false)
      })
      .catch(error => {
        console.log('Error getting templates: ', error)
        setTemplates([])
        setLoading(false)
      })
  }

  return (
    <>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <DashboardHeader onChange={searchQuery => search(searchQuery)} />
      <Typography className={classes.headings}>Keep it simple</Typography>

      <Box p={2} className={classes.root}>
        <DashboardTemplates
          cardPreview={query.get('preview')}
          onClick={(templateId, formatType) => {
            changeTemplate(templateId, formatType)
          }}
          templates={templates}
        />
      </Box>
    </>
  )
}

export default KeepItSimple
