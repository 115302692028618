export const DEFAULT_SOCIAL_MEDIA_CATEGORIES = [
  'WhatsApp',
  'Facebook',
  'Instagram',
  'YouTube',
  'Vimeo',
  'WeChat',
  'TikTok',
  'UpWork',
  'Pinterest',
  'Skype',
  'Spotify',
  'Behance',
  'LinkedIn',
  'Messenger',
  'SnapChat',
  'Twitter'
]
