import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'

import AuthContext from './contexts/AuthContext'
import useAuthentication from './hooks/useAuthentication'

const PrivateRouteUser = ({ component: Component, ...rest }) => {
  // const jwtToken = new URLSearchParams(rest.location.search).get('auth')
  // if(jwtToken !== null) {
  //   localStorage.setItem('jwtToken', jwtToken)
  // }
  const authToken = localStorage.getItem('jwtToken')
  const [authData, signOut] = useAuthentication(authToken)
  const dateNow = new Date()

  if (authData.initializing) {
    return <div>Loading</div>;
  }

  return (
    <Route
      {...rest}
      render={props =>
        (authData.authUser && authData.authUser.exp >= dateNow.getTime()/1000) ? (
          <AuthContext.Provider value={[authData, signOut]}>
            <Component {...props} />  
          </AuthContext.Provider>
        ) : (
          <Redirect exact to={{
            pathname: '/',
            state: { from: props.location }
          }}
          />
        )
      }
    />
  )
}

PrivateRouteUser.propTypes = {
  component: PropTypes.func.isRequired,
}

export default PrivateRouteUser
