import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import { Box, Paper, AppBar } from '@material-ui/core'

import Navigation from './Navigation'
import TitleBar from './TitleBar'

import HttpClient from '../../../../services/HttpClient'
import AuthContext from '../../../../contexts/AuthContext'

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    width: '100%',
    height: 0,
    paddingTop: '100%',
    outline: 'none',
    boxShadow: '0 0 16px 0 #d3d4d5'
  },
  container: {
    '& svg': {
      maxWidth: '100%',
      maxHeight: '100%',
    },
  }
}))

const SocialMedia = () => {
  const classes = useStyles()
  const [authData] = useContext(AuthContext)
  const [icons, setIcons] = useState([])
  const [socialMediaIcon, setSocialMediaIcon] = useState({
    id: 'temp-id-1',
    name: 'Facebook',
    svg: '<svg xmlns="http://www.w3.org/2000/svg"></svg>',
    type: 1,
    state: 0,
  })
  const [navigation, setNavigation] = useState({
    activeTab: 6,
    panel: 0,
  })
  
  useEffect(() => {
    HttpClient.get('/myicons?type=1', { Authorization: `Bearer ${authData.authToken}` })
      .then(res => setIcons(res))
      .catch(error => console.log(error))
  }, [])

  const handleNavigationChange = nav => setNavigation({ ...navigation, ...nav })
  const handleIconChange = newIcon => {
    const updatedIcon = { ...socialMediaIcon, ...newIcon }
    const _icons = [ ...icons ]
    if (Number.isInteger(socialMediaIcon.id)) {
      if (socialMediaIcon.state === 1 && updatedIcon.state === 2) {
        HttpClient.post(`/icons/${socialMediaIcon.id}/publish`, {}, { Authorization: `Bearer ${authData.authToken}` })
          .then(res => {
            setSocialMediaIcon(res)
            const iconIndex = _icons.findIndex(i => i.id === socialMediaIcon.id)
            _icons[iconIndex] = res
            setIcons(_icons)
          })
          .catch(error => console.log('Error icon update: ', error))
      } else {
        const { id, designerId, type, state, createdAt, updatedAt, ...apiData } = updatedIcon
        HttpClient.put(`/icons/${socialMediaIcon.id}`, apiData, { Authorization: `Bearer ${authData.authToken}` })
          .then(res => {
            setSocialMediaIcon(res)
            const iconIndex = _icons.findIndex(i => i.id === socialMediaIcon.id)
            _icons[iconIndex] = res
            setIcons(_icons)
          })
          .catch(error => console.log('Error icon update: ', error))
      }
    } else {
      const { id, state, ...apiData } = updatedIcon
      HttpClient.post('/icons', apiData, { Authorization: `Bearer ${authData.authToken}` })
        .then(res => {
          setSocialMediaIcon(res)
          _icons.push(res)
          setIcons(_icons)
        })
        .catch(error => console.log('Error icon update: ', error))
    }
  }

  return (
    <React.Fragment>
      <Navigation
        navigation={navigation}
        onNavigationChange={handleNavigationChange}
        icons={icons}
        icon={socialMediaIcon}
        onIconChange={handleIconChange}
        onSelectIcon={setSocialMediaIcon}
      />
      <Box position="relative" ml="345px" width="calc(100% - 345px)" height={1}>
        <Box display="flex" flexDirection="column" height={1}>
          <AppBar position="static" elevation={0}>
            <TitleBar
              icon={socialMediaIcon}
              onIconChange={handleIconChange}
            />
          </AppBar>
          <Box
            flex="1 1 auto"
            width={1}
            height={1}
            overflow="auto"
          >
            <Box
              display="flex"
              width={0.5}
              m="0 auto"
              alignItems="center"
              paddingTop="2%"
            >
              <Paper square elevation={0} className={classes.root}>
                <Box
                  position="absolute"
                  top={0}
                  bottom={0}
                  width={1}
                  className={classes.container}
                  dangerouslySetInnerHTML={{ __html: socialMediaIcon.svg }}
                />
              </Paper>
            </Box>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  )
}

SocialMedia.propTypes = {
  match: PropTypes.object.isRequired,
}

export default SocialMedia
