import React, { useState, useEffect } from 'react'

import { Box, ImageList, ImageListItem } from '@material-ui/core'

import SearchBar from '../SearchBar'
import Image from './Image'

const Images = () => {
  const [images, setImages] = useState([])

  useEffect(() => pexels('sport'), [])

  // const pixaybay = q => {
  //   fetch(`https://pixabay.com/api/?key=11095386-871fd43c33a92700d9bffb82d&q=${q}&image_type=photo&pretty=true&page=1&per_page=24&safesearch=true`)
  //     .then(res => res.json())
  //     .then(res => setImages(res.hits))
  //     .catch(err => setImages([]))
  // }
  const pexels = q => {
    fetch(`https://api.pexels.com/v1/search?query=${q}&per_page=40`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: process.env.REACT_APP_PEXELS_API_KEY
      }
    })
      .then(res => res.json())
      .then(res => setImages(res.photos))
      .catch(() => setImages([]))
  }

  // const unsplash = () => {
  //   fetch('https://api.unsplash.com/search/photos/?client_id=d180e1acc68141f91058bc9098f8bfcbfad8d2297a1568ee11e86b66ebc123ca&query=sports&per_page=24&page=1')
  //     .then(res => res.json())
  //     .then(res => setImages(res.results))
  //     .catch(err => {
  //       console.log('Error!', err)
  //       setImages([])
  //     })
  // }

  return (
    <>
      <SearchBar onSearch={pexels} />
      <Box
        flex="1 1 auto"
        p={1}
        overflow="scroll"
        bgcolor="#293039"
        className="scrollbar-hidden"
      >
        <ImageList rowHeight={85} cols={2}>
          {images.map((image, index) => (
            <ImageListItem key={index} cols={1}>
              <Image image={image} />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
    </>
  )
}

export default Images
