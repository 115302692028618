import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import { DialogContent, DialogActions } from '@material-ui/core/'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import CancelIcon from '@material-ui/icons/Cancel'

import HttpClient from '../../../../../services/HttpClient'
import AuthContext from '../../../../../contexts/AuthContext'
import Autocomplete from '../../../../Autocomplete'

const useStyles = makeStyles(() => ({
  root: { cursor: 'pointer' },
  icon: {
    cursor: 'pointer',
    verticalAlign: 'middle',
    marginLeft: '3px'
  }
}))

const Styles = props => {
  const classes = useStyles()
  const [authData] = useContext(AuthContext)
  const [styles, setStyles] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    HttpClient.get('/categories/search/2', { Authorization: `Bearer ${authData.authToken}` })
      .then(res => {
        setStyles(res)
        setLoading(false)
      })
      .catch(error => {
        console.log('Error loading styles: ', error)
        setStyles([])
        setLoading(false)
      })
  }, [])

  const handleAddCategory = category => {
    const _categories = [...props.categories]
    _categories.push(category)
    setStyles(styles.filter(i => i.id !== category.id))
    props.onCategoriesChange(_categories)
  }

  const handleRemoveCategory = category => {
    const _styles = [...styles]
    _styles.push(category)
    setStyles(_styles)
    props.onCategoriesChange(props.categories.filter(c => c.id !== category.id))
  }

  const sortedStyles = () => styles.sort((a, b) => (a.title > b.title) ? 1 : -1)

  return (
    <>
      <DialogContent>
        <Box my={2}>What are the visual styles of your template</Box>
        <Autocomplete options={styles} placeholder="Search" onAddCategory={handleAddCategory} />
        <Box my={2}>
          {props.categories.filter(c => c.type === 2).map((category, index) => (
            <Box
              key={index}
              component="span"
              display="inline-block"
              bgcolor="#e9e3ff"
              py={0.5}
              px={1}
              m={0.5}
              borderRadius={4}
              className={classes.root}
            >
              {category.title}
              <CancelIcon fontSize="small" onClick={() => handleRemoveCategory(category)} className={classes.icon} />
            </Box>
          ))}
        </Box>
        <Box my={2}>
          <Divider />
        </Box>
        <Box my={2}>
          {sortedStyles().map((industry, index) => (
            <Box
              key={index}
              component="span"
              display="inline-block"
              bgcolor="#e9e3ff"
              py={0.5}
              px={1}
              m={0.5}
              borderRadius={4}
              className={classes.root}
              onClick={() => handleAddCategory(industry)}
            >
              {industry.title}
            </Box>
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="small"
          disabled={props.categories.filter(c => c.type === 2).length === 0}
          onClick={props.onDone}
        >
          Next
        </Button>
      </DialogActions>
    </> 
  )
}

Styles.propTypes = {
  template: PropTypes.object.isRequired,
  onTemplateChange: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  onCategoriesChange: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
}

export default Styles
