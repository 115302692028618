import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { fabric } from 'fabric'

import { makeStyles } from '@material-ui/core/styles'
import { Grid, Box, Tooltip } from '@material-ui/core'

import { ActionContext } from '../../../../../contexts/ActionContext'
import SearchBar from '../../SearchBar'
import { DEFAULT_GRAPHICS } from '../../../../../constants/DefaultGraphics'

const useStyles = makeStyles(theme => ({
  graphicBox: {
    fill: 'white',
    cursor: 'pointer',
    backgroundColor: 'hsla(0,0%,100%,.07)',
    '& svg': {
      maxWidth: '90%',
      maxHeight: '90%'
    },
    '&:hover': {
      backgroundColor: 'hsla(0,0%,100%,.15)',
    }
  }
}))

const Graphics = () => {
  const classes = useStyles()
  const [action, setAction] = useContext(ActionContext)
  
  const handleSearch = q => {}

  const handleAdd = graphic => {
    fabric.loadSVGFromString(graphic, (objects, options) => {
      const obj = fabric.util.groupSVGElements(objects, options)
      setAction({ type: 'addObject', value: obj })
    })
  }

  return (
    <Box
      flex="1 1 auto"
      display="flex"
      flexDirection="column"
      overflow="hidden"
      bgcolor="#293039"
      color="#fff"
      zIndex="2"
    >
      <SearchBar onSearch={handleSearch} />
      <Box flex="1 1 auto" mx={1} overflow="auto">
        <Grid container spacing={1}>
          {DEFAULT_GRAPHICS.map((graphic, index) => (
            <Grid item xs={6} key={index}>
              <Box
                position="relative"
                width={1}
                pb="100%"
                borderRadius={4}
                boxSizing="border-box"
              >
                <Tooltip title={graphic.name} arrow>
                  <Box
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    left={1}
                    right={1}
                    top={1}
                    bottom={1}
                    borderRadius={4}
                    className={classes.graphicBox}
                    onClick={() => handleAdd(graphic.svg)}
                    dangerouslySetInnerHTML={{__html: graphic.svg}}
                  />
                </Tooltip>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  )
}

Graphics.propTypes = {
  subPanel: PropTypes.number.isRequired
}

export default Graphics
