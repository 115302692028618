export function getCategoryTypeValue(categoryType) {
  let categoryTypeHash = new Map([
    ["1", "Styles"],
    ["2", "Personal Expression"],
    ["3", "Business"],
    ["4", "Themes"],
    ["5", "Occasions"],
    ["6", "Trending"],
    ["7", "Industory"],
  ]);
  return categoryTypeHash.get(categoryType);
}

export function getCategoryTypeKey(categoryType) {
  let categoryTypeHash = new Map([
    ["Styles", 1],
    ["Personal Expression", 2],
    ["Business", 3],
    ["Themes", 4],
    ["Occasions", 5],
    ["Trending", 6],
    ["Industory", 7],
  ]);
  return categoryTypeHash.get(categoryType);
}

export const CustomCategory = {
  keepItSimple: "keep it simple",
  myFeaturedImages: "my featured images",
};

export const CustomCategoryTitle = {
  keepItSimple: "keep it simple",
  featured: "featured",
};

export const TemplateType = {
  free: 1,
  multiSided: 2,
  premium: 3,
};

export const PlanType = {
  basic: 'basic',
  pro: 'pro',
};

export function camelCaseKeysUnderscore(obj) {
  if (typeof(obj) != "object") return obj;
  for(const UnderscoreCase in obj){
      // Camel to underscore
      let camelCase = UnderscoreCase.replace(/([A-Z])/g, function($1){return "_"+$1.toLowerCase();});

      // Only process if names are different
      if (camelCase != UnderscoreCase) {
          // Check for the old property name to avoid a ReferenceError in strict mode.
          if (obj.hasOwnProperty(UnderscoreCase)) {
              obj[camelCase] = obj[UnderscoreCase];
              delete obj[UnderscoreCase];
          }
      }
      // Recursion
      if (typeof(obj[camelCase]) == "object") {
          obj[camelCase] = camelCaseKeysUnderscore(obj[camelCase]);
      }
  }
  return obj;
}

export const svgImages = {
  previewSvg : `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="338" height="422.5" viewBox="0 0 4320 5400" xml:space="preserve">
<desc>Created with Fabric.js 3.6.6</desc>
<defs><style type="text/css">@import url('https://fonts.googleapis.com/css?family=Libre+Baskerville');</style>
</defs>
<g transform="matrix(1.44 0 0 1.44 2147.06 2691.36)"  >
  <image style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;"  xlink:href="https://images.pexels.com/photos/773063/pexels-photo-773063.jpeg" x="-2800" y="-1869" width="5600" height="3738"></image>
</g>
<g transform="matrix(6.36 0 0 6.36 2167.95 1957.8)" style="" id="DataField-1"  >
    <text xml:space="preserve" font-family="Libre Baskerville" font-size="48" font-style="normal" font-weight="normal" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(115,115,115); fill-rule: nonzero; opacity: 1; white-space: pre;" ><tspan x="-120.82" y="15.08" >Loading...</tspan></text>
</g>
</svg>`};

export const getTemplatePreview = (format, template) => {
  let preview;
  switch (format) {
    case "1":
      preview = template.horizontal_preview || template.horizontalPreview;
      break;

    case "2":
      preview = template.vertical_preview || template.verticalPreview;
      break;

    case "3":
      preview = template.square_preview || template.squarePreview;
      break;

    default:
      preview = template.vertical_preview || template.verticalPreview;
      break;
  }
  return preview;
};

export const getTemplatePreviewPath = (format, template) => {
  let preview;
  switch (format) {
    case "1":
      preview =
        template.horizontal_preview_image_path ||
        template.horizontalPreviewImagePath;
      break;

    case "2":
      preview =
        template.vertical_preview_image_path ||
        template.verticalPreviewImagePath;
      break;

    case "3":
      preview =
        template.square_preview_image_path || template.squarePreviewImagePath;
      break;

    default:
      preview =
        template.vertical_preview_image_path ||
        template.verticalPreviewImagePath;
      break;
  }
  return preview;
};

export const toUpperCase = (sentence) => {
  const words = sentence.split(" ");
  try {
    return words
      .map((word) => {
        if (word.length === 0) return "";
        return word[0]?.toUpperCase() + word.substring(1);
      })
      .join(" ");
  } catch (e) {
    return sentence;
  }
};
