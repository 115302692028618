import React, { useContext, useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { fabric } from 'fabric'
import 'fabric-history'
import ReactResizeDetector from 'react-resize-detector'
import WebFont from 'webfontloader'

import { makeStyles } from '@material-ui/core/styles'
import { Grid, Box, Paper, IconButton, Tooltip } from '@material-ui/core'
import UndoIcon from '../../../../../icons/UndoIcon'
import RedoIcon from '../../../../../icons/RedoIcon'
import AddIcon from '@material-ui/icons/Add'
import DeleteSideIcon from '../../../../../icons/DeleteSideIcon'

import HttpClient from '../../../../../services/HttpClient'
import AuthContext from '../../../../../contexts/AuthContext'
import { SideContext } from '../../../../../contexts/SideContext'
import { ActionContext } from '../../../../../contexts/ActionContext'

const useStyles = makeStyles(() => ({
  format_1: {
    position: 'relative',
    height: 0,
    paddingTop: '60%',
    outline: 'none',
  },
  format_2: {
    position: 'relative',
    height: 0,
    paddingTop: '125%',
    outline: 'none',
  },
  format_3: {
    position: 'relative',
    height: 0,
    paddingTop: '100%',
    outline: 'none',
  },
  canvas: {
    position: 'absolute',
    top: 0,
    boxShadow: '0 0 16px 0 #d3d4d5',
  },
}))

// For Data Fields
// fabric.Textbox.prototype.toObject = (function(toObject) {
//   return function() {
//     return fabric.util.object.extend(toObject.call(this), {
//       id: this.id
//     })
//   }
// })(fabric.Image.prototype.toObject)

const Side = (props) => {
  const classes = useStyles()
  const [authData] = useContext(AuthContext)
  const [activeSide, setActiveSide] = useContext(SideContext)
  const [action, setAction] = useContext(ActionContext)
  const [formatType, setFormatType] = useState(props.formatType)
  const [side, setSide] = useState({ canvas: null, undo: 0, redo: 0 })

  const sideBoxRef = useRef(null)
  const canvasRef = useRef(null)

  useEffect(() => {
    setFormatType(props.formatType)
  }, [props.formatType])

  useEffect(() => {
    if (
      side.canvas === null ||
      (props.side.json !== null && props.side.json !== '')
    ) {
      // Initialize Fabric canvas once only, due to it's own internal state
      initCanvas()
    }
  }, [props.side.id, props.side.json])

  useEffect(() => {
    if (activeSide === props.side.id && side.canvas !== null) {
      if (Object.keys(action).length === 0) {
        side.canvas.discardActiveObject()
        side.canvas.requestRenderAll()
      }
      applyAction()
    }
  }, [props.side.id, activeSide, action])

  useEffect(() => {
    if (side.canvas) {
      side.canvas.setDimensions({ borderRadius: props.formatRadius })
    }
  }, [props.formatRadius])

  const initCanvas = () => {
    let fabricCanvas = null
    const settings = props.side.settings
    if (side.canvas === null) {
      fabricCanvas = new fabric.Canvas(canvasRef.current, {
        preserveObjectStacking: true,
        renderOnAddRemove: false,
        backgroundColor: '#ffffff',
        width: settings.width,
        height: settings.height,
      })
    }
    const sideBoxWidth = sideBoxRef.current.offsetWidth
    const ratio = fabricCanvas.getWidth() / fabricCanvas.getHeight()
    const scale = sideBoxWidth / fabricCanvas.getWidth()
    const zoom = fabricCanvas.getZoom() * scale
    if (side.canvas === null) {
      fabricCanvas.setDimensions({
        width: sideBoxWidth,
        height: sideBoxWidth / ratio,
      })
      fabricCanvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0])
    }
    if (props.side.json !== null && props.side.json !== '') {
      let fontFamilies = []
      const sideJSON = JSON.parse(props.side.json)
      if (sideJSON.objects !== undefined) {
        const textObjects = sideJSON.objects.filter(
          (o) => o.type === 'textbox' && o.fontFamily !== 'Times New Roman'
        )
        fontFamilies = textObjects.map((o) => o.fontFamily.replace(/\s/g, '+'))
      }
      if (side.canvas !== null) {
        side.canvas.setDimensions({
          width: sideBoxRef.current.offsetWidth,
          height: sideBoxRef.current.offsetHeight,
        })
        side.canvas.id = 'loading'
        if (fontFamilies.length) {
          WebFont.load({
            google: { families: fontFamilies },
            active: () => {
              side.canvas.loadFromJSON(props.side.json, () => {
                debugger
                side.canvas.id = 'loaded'
                side.canvas.renderAll.bind(side.canvas)
              })
            },
          })
        } else {
          side.canvas.loadFromJSON(props.side.json, () => {
            side.canvas.id = 'loaded'
            side.canvas.renderAll.bind(side.canvas)
          })
        }
      } else {
        fabricCanvas.offHistory()
        if (settings) {
          fabricCanvas.setDimensions({
            width: settings.width,
            height: settings.height,
          })
        }
        fabricCanvas.id = 'loading'
        if (fontFamilies.length) {
          WebFont.load({
            google: { families: fontFamilies },
            active: () => {
              fabricCanvas.loadFromJSON(props.side.json, () => {
                fabricCanvas.id = 'loaded'
                fabricCanvas.renderAll.bind(fabricCanvas)
              })
              if (settings) {
                fabricCanvas.setDimensions({
                  width: sideBoxWidth,
                  height: sideBoxWidth / ratio,
                })
                fabricCanvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0])
              }
            },
          })
        } else {
          fabricCanvas.loadFromJSON(props.side.json, () => {
            fabricCanvas.id = 'loaded'
            fabricCanvas.renderAll.bind(fabricCanvas)
          })
          if (settings) {
            fabricCanvas.setDimensions({
              width: sideBoxWidth,
              height: sideBoxWidth / ratio,
            })
            fabricCanvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0])
          }
        }
        fabricCanvas.clearHistory()
        fabricCanvas.historyUndo = JSON.parse(props.side.historyUndo)
        fabricCanvas.historyRedo = JSON.parse(props.side.historyRedo)
        fabricCanvas.historyNextState = props.side.historyNextState
      }
    }
    if (side.canvas === null) {
      attachHandlers(fabricCanvas)
      side.canvas = fabricCanvas
      setSide({
        ...side,
        undo: side.canvas.historyUndo.length,
        redo: side.canvas.historyRedo.length,
      })
    }
    if (activeSide === null) setActiveSide(props.side.id)

    saveSide(side.canvas)
  }

  const attachHandlers = (fabricCanvas) => {
    fabricCanvas.on({
      // 'mouse:move': options => {
      //   if(options.target && !options.target.selected) {
      //     options.target.set({
      //       hasControls: false,
      //     })
      //     options.target._renderControls(options.target.canvas.contextContainer)
      //   }
      // },
      // 'mouse:out': options => {
      //   if(options.target && !options.target.selected) {
      //     options.target.canvas.discardActiveObject()
      //     options.target.canvas.requestRenderAll()
      //   }
      // },
      'selection:created': handleSelectionCreated,
      'selection:updated': handleSelectionCreated,
      'selection:cleared': handleSelectionClear,
      'object:added': handleCanvasUpdate,
      'object:removed': handleCanvasUpdate,
      'object:modified': handleCanvasUpdate,
      'mouse:dblclick': handleDoubleClick,
      'text:changed': handleCanvasUpdate,
    })
    sideBoxRef.current.tabIndex = 1000 // To focus canvas container for cathing keydown event
    sideBoxRef.current.addEventListener('keydown', (e) => {
      if (e.key === 'Backspace' || e.key === 'Delete') {
        const selection = fabricCanvas.getActiveObject()
        if (selection) {
          if (selection.type === 'activeSelection') {
            // if group selected
            selection.forEachObject((element) => fabricCanvas.remove(element))
          } else {
            fabricCanvas.remove(selection)
          }
          fabricCanvas.discardActiveObject()
          fabricCanvas.requestRenderAll()
        }
      }
    })
  }

  const applyAction = () => {
    const activeObj = side.canvas.getActiveObject()
    if (Object.keys(action).length !== 0) {
      // side.canvas.onHistory()
      switch (action.type) {
        case 'addObject': {
          if (action.value.id && action.value.id.startsWith('Icon-')) {
            const iconId = parseInt(action.value.id.split('-').pop())
            HttpClient.post(
              `/sides/${props.side.id}/icons`,
              { id: iconId },
              { Authorization: `Bearer ${authData.authToken}` }
            ).catch((error) => console.error('Error side icon update: ', error))
          }
          const zoomFactor = 1 / side.canvas.getZoom()
          if (action.property && action.property === 'image')
            action.value.scaleToWidth((side.canvas.getWidth() / 2) * zoomFactor)
          else {
            action.value.scaleX = zoomFactor
            action.value.scaleY = zoomFactor
          }
          side.canvas.viewportCenterObject(action.value)
          side.canvas.add(action.value).setActiveObject(action.value)
          // props.onToolbarChange( getObjectData(action.value) )
          break
        }
        case 'addTextStyling':
          // console.log('JSON.parse(action.value).objects: ', JSON.parse(action.value).objects)
          fabric.util.enlivenObjects(
            JSON.parse(action.value).objects,
            (objects) => {
              const group = new fabric.Group(objects, {
                originX: 'center',
                originY: 'center',
                subTargetCheck: true,
                objectCaching: false,
              })
              side.canvas.add(group)
              group.center().setCoords()
              side.canvas.setActiveObject(group)
            }
          )
          break
        case 'group':
          activeObj.toGroup()
          activeObj.type = 'group'
          props.onToolbarChange(getObjectData(activeObj))
          break
        case 'ungroup': {
          const objectsInGroup = activeObj.getObjects()
          activeObj.destroy()
          side.canvas.remove(activeObj)
          objectsInGroup.forEach((obj) => side.canvas.add(obj))
          break
        }
        case 'text':
          if (activeObj) {
            if (action.property === 'fill') {
              activeObj.set({
                // styles: {},
                [action.property]: action.value.rgba,
              })
              if (activeObj.type === 'group')
                activeObj
                  .getObjects()
                  .forEach((obj) =>
                    obj.set({ [action.property]: action.value.rgba })
                  )
            } else {
              activeObj.set({
                styles: {},
                [action.property]: action.value,
              })
            }
            props.onToolbarChange(getObjectData(activeObj))
          }
          break
        case 'background':
          if (action.property === 'image') {
            const zoomFactor = 1 / side.canvas.getZoom()
            action.value.scaleX = 1 / zoomFactor
            action.value.scaleY = 1 / zoomFactor
            const canvasAspect =
              side.canvas.getWidth() / side.canvas.getHeight()
            const imgAspect = action.value.width / action.value.height
            let left, top, scaleFactor
            if (canvasAspect >= imgAspect) {
              scaleFactor = side.canvas.getWidth() / action.value.width
              left = 0
              top =
                -(
                  (action.value.height * scaleFactor -
                    side.canvas.getHeight()) /
                  2
                ) * zoomFactor
            } else {
              scaleFactor = side.canvas.getHeight() / action.value.height
              top = 0
              left =
                -(
                  (action.value.width * scaleFactor - side.canvas.getWidth()) /
                  2
                ) * zoomFactor
            }
            scaleFactor = scaleFactor / side.canvas.getZoom()
            side.canvas.setBackgroundImage(
              action.value,
              side.canvas.renderAll.bind(side.canvas),
              {
                top: top,
                left: left,
                originX: 'left',
                originY: 'top',
                scaleX: scaleFactor,
                scaleY: scaleFactor,
              }
            )
            side.canvas.setBackgroundColor(null)
          } else if (action.property === 'color') {
            let sideColors
            if (props.side.colors === null || props.side.colors === undefined) {
              sideColors = []
            } else {
              sideColors = [
                ...props.side.colors.filter((c) => c.type === undefined),
              ]
            }
            if (side.canvas.backgroundColor !== '') {
              sideColors = sideColors.filter(
                (c) => c.hex !== side.canvas.backgroundColor
              )
            }
            side.canvas.backgroundImage = false
            side.canvas.backgroundColor = action.value.hex
            sideColors.push(action.value)
            side.canvas.colors = sideColors
          }
          side.canvas._historySaveAction() // For undo, redo history, fabric-history plugin
          break
        case 'alignment':
          if (activeObj) {
            const bb = activeObj.getBoundingRect()
            switch (action.value) {
              case 'forward':
                side.canvas.bringToFront(activeObj)
                break
              case 'backward':
                side.canvas.sendBackwards(activeObj)
                break
              case 'top':
                activeObj.set({
                  styles: {},
                  originY: 'top',
                  top: 0,
                })
                break
              case 'middle':
                activeObj.viewportCenterV()
                break
              case 'bottom':
                activeObj.setPositionByOrigin(
                  {
                    x: activeObj.left,
                    y:
                      (side.canvas.height - bb.height / 2) *
                      (1 / side.canvas.getZoom()),
                  },
                  activeObj.originX,
                  'center'
                )
                break
              case 'left':
                activeObj.set({
                  styles: {},
                  originX: 'left',
                  left: 0,
                })
                break
              case 'center':
                activeObj.viewportCenterH()
                break
              case 'right':
                activeObj.setPositionByOrigin(
                  {
                    x:
                      (side.canvas.width - bb.width / 2) *
                      (1 / side.canvas.getZoom()),
                    y: activeObj.top,
                  },
                  'center',
                  activeObj.originY
                )
                break
            }
          }
          props.onToolbarChange(getObjectData(activeObj))
          break
        case 'flip':
          activeObj.set({
            [action.property]: action.value,
          })
          props.onToolbarChange(getObjectData(activeObj))
          break
        case 'duplicate':
          activeObj.clone((clonedObj) => {
            side.canvas.discardActiveObject()
            clonedObj.set({
              left: clonedObj.left + 10,
              top: clonedObj.top + 10,
              evented: true,
            })
            if (clonedObj.type === 'activeSelection') {
              // active selection needs a reference to the canvas.
              clonedObj.canvas = side.canvas
              clonedObj.forEachObject((obj) => side.canvas.add(obj))
              // this should solve the unselectability
              clonedObj.setCoords()
            } else {
              side.canvas.add(clonedObj)
            }
            side.canvas.setActiveObject(clonedObj)
          })
          break
        case 'transparency':
          activeObj.set({ opacity: action.value })
          // props.onToolbarChange(getObjectData(activeObj))
          break
        case 'toggleLock':
          if (activeObj) {
            activeObj.set({
              lockMovementX: !activeObj.lockMovementX,
              lockMovementY: !activeObj.lockMovementY,
            })
            props.onToolbarChange(getObjectData(activeObj))
            if (activeObj.lockMovementX) side.canvas.discardActiveObject()
          }
          break
        case 'remove':
          if (activeObj) {
            if (activeObj.type === 'activeSelection') {
              // if group selected
              activeObj.forEachObject((element) => removeObject(element))
            } else {
              removeObject(activeObj)
            }
            side.canvas.discardActiveObject()
          }
          break
      }
      side.canvas.requestRenderAll()
      setSide({
        ...side,
        undo: side.canvas.historyUndo.length,
        redo: side.canvas.historyRedo.length,
      })
      saveSide(side.canvas)
    }
  }

  const removeObject = (obj) => {
    if (obj.id && obj.id.startsWith('Icon-')) {
      const iconId = obj.id.split('-').pop()
      HttpClient.delete(`/sides/${props.side.id}/icons/${iconId}`, {
        Authorization: `Bearer ${authData.authToken}`,
      }).catch((error) => console.error('Error side icon delete: ', error))
    }
    side.canvas.remove(obj)
  }

  const handleSelectionCreated = (options) => {
    const selectedObject = options.target
    selectedObject.set({
      hasControls: true,
    })
    props.onToolbarChange(getObjectData(selectedObject))
  }

  const handleDoubleClick = (options) => {
    if (options.target !== null && options.target.type === 'group') {
      const activeObject = options.target.canvas.getActiveObject();
      const items = activeObject._objects;
      activeObject._restoreObjectsState();
      activeObject.hasControls = true;
      for (var i = 0; i < items.length; i++) {
        if (items[i].type === 'textbox') {
          options.target.canvas.add(items[i]);
          options.target.canvas.item(options.target.canvas.size() - 1).hasControls = true;
        }
      }
    }
    options.target.objectCaching = false
    options.target.canvas.requestRenderAll()
  }

  const handleSelectionClear = () => {
    props.onNavigationChange({ activePanel: 0 })
    props.onToolbarChange({
      type: 'default',
    })
  }

  const handleCanvasUpdate = (e) => {
    const canvas = e.target.canvas

    setSide({
      ...side,
      undo: canvas.historyUndo.length,
      redo: canvas.historyRedo.length,
    })
    saveSide(canvas)
    return true // successfully return control even if remote call saveSide doesn't work
  }

  const saveSide = (canvas) => {
    if (canvas.id !== 'loading') {
      // not save while loading from JSON
      const colors = canvas.colors !== undefined ? canvas.colors : []
      let canvasSVG = canvas.toSVG({ suppressPreamble: true })
      let fontFamilies = []
      canvas.getObjects().forEach((o) => {
        if (o.type === 'textbox' && o.fontFamily !== 'Times New Roman') {
          fontFamilies.push(o.fontFamily.replace(/\s/g, '+'))
        }
      })
      if (fontFamilies.length > 0) {
        canvasSVG = canvasSVG.replace(
          '<defs>',
          `<defs><style type="text/css">@import url('https://fonts.googleapis.com/css?family=${fontFamilies.join(
            '|'
          )}');</style>`
        )
      }
      let sideData = {
        position: props.side.position,
        svg: canvasSVG,
        json: JSON.stringify(canvas.toDatalessJSON(['id', 'subTargetCheck'])),
        historyUndo: JSON.stringify(canvas.historyUndo),
        historyRedo: JSON.stringify(canvas.historyRedo),
        historyNextState: canvas.historyNextState,
        colors,
      }
      if (Number.isInteger(props.side.id)) {
        HttpClient.put(
          `/formats/${props.formatId}/sides/${props.side.id}`,
          sideData,
          { Authorization: `Bearer ${authData.authToken}` }
        )
          .then((res) => props.onSideUpdate({ ...props.side, ...res }))
          .catch((error) =>
            console.error('Error side update: ', error.response.status)
          )
      } else {
        sideData.settings = props.side.settings
        HttpClient.post(`/formats/${props.formatId}/sides`, sideData, {
          Authorization: `Bearer ${authData.authToken}`,
        })
          .then((res) =>
            props.onSideUpdate(props.side.id, { ...props.side, ...res })
          )
          .catch((error) =>
            console.error('Error side creation: ', error.response.status)
          )
      }
    }
  }

  const getObjectData = (selectedObj) => {
    switch (selectedObj.type) {
      case 'text':
      case 'textbox':
        return {
          type: 'text',
          fontFamily: selectedObj.get('fontFamily'),
          fontSize: selectedObj.get('fontSize'),
          lineHeight: selectedObj.get('lineHeight'),
          charSpacing: selectedObj.get('charSpacing'),
          fontWeight: selectedObj.get('fontWeight'),
          textColor: selectedObj.get('fill'),
          fontStyle: selectedObj.get('fontStyle'),
          underline: selectedObj.get('underline'),
          textAlign: selectedObj.get('textAlign'),
          scaleX: selectedObj.get('scaleX'),
          scaleY: selectedObj.get('scaleY'),
          top: selectedObj.get('top'),
          bottom: selectedObj.get('bottom'),
        }
      case 'image':
      case 'group':
      case 'path':
      case 'activeSelection':
        return {
          type: selectedObj.type,
          color: selectedObj.get('fill'),
          flipX: selectedObj.get('flipX'),
          flipY: selectedObj.get('flipY'),
          lockMovementX: selectedObj.get('lockMovementX'),
          lockMovementY: selectedObj.get('lockMovementY'),
          opacity: selectedObj.get('opacity'),
        }
      default:
        return { type: 'default' }
    }
  }

  const undo = () => {
    side.canvas.undo()
    setSide({
      ...side,
      undo: side.canvas.historyUndo.length,
      redo: side.canvas.historyRedo.length,
    })
  }

  const redo = () => {
    side.canvas.redo()
    setSide({
      ...side,
      undo: side.canvas.historyUndo.length,
      redo: side.canvas.historyRedo.length,
    })
  }

  const handleResize = (width) => {
    const ratio = side.canvas.getWidth() / side.canvas.getHeight()
    const scale = width / side.canvas.getWidth()
    const zoom = side.canvas.getZoom() * scale
    side.canvas.setDimensions({ width, height: width / ratio })
    side.canvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0])
  }

  const handleSideActivation = () => {
    if (props.side.id !== activeSide) setAction({})
    setActiveSide(props.side.id)
  }

  return (
    <Box my="2.25%" onClick={handleSideActivation}>
      <Box my="8px">
        <Grid container>
          <Grid item sm={6}>
            <Tooltip title="Undo" arrow>
              <span>
                <IconButton
                  size="small"
                  aria-label="Undo"
                  disabled={!side.undo}
                  onClick={undo}
                >
                  <UndoIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Redo" arrow>
              <span>
                <IconButton
                  size="small"
                  aria-label="Redo"
                  disabled={!side.redo}
                  onClick={redo}
                >
                  <RedoIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
          <Grid container item xs={6} justifyContent="flex-end">
            {props.removeAble ? (
              <Tooltip title="Delete side" arrow>
                <IconButton
                  size="small"
                  aria-label="Delete side"
                  onClick={() => props.onRemove(props.side)}
                >
                  <DeleteSideIcon />
                </IconButton>
              </Tooltip>
            ) : null}
            <Tooltip title="Add side" arrow>
              <IconButton
                size="small"
                aria-label="Add side"
                onClick={() => props.onAdd(props.side.id, props.side.position)}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
      <Paper
        square
        elevation={0}
        className={classes[`format_${formatType}`]}
        style={{
          borderRadius: `${props.formatRadius}px`,
          border:
            activeSide === props.side.id
              ? '2px solid #1bd2da'
              : '2px solid transparent',
        }}
      >
        <Box
          id={`${formatType}-${props.side.id}`}
          ref={sideBoxRef}
          position="absolute"
          top={0}
          bottom={0}
          width={1}
          style={{ borderRadius: 'inherit' }}
        >
          <ReactResizeDetector handleWidth skipOnMount onResize={handleResize}>
            <canvas
              id={`canvas-${props.side.id}`}
              ref={canvasRef}
              className={classes.canvas}
              style={{ borderRadius: `${props.formatRadius}px` }}
            />
          </ReactResizeDetector>
        </Box>
      </Paper>
    </Box>
  )
}

Side.propTypes = {
  side: PropTypes.object.isRequired,
  removeAble: PropTypes.bool.isRequired,
  formatId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  formatType: PropTypes.number.isRequired,
  formatRadius: PropTypes.number.isRequired,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onSideUpdate: PropTypes.func.isRequired,
  onNavigationChange: PropTypes.func.isRequired,
  onToolbarChange: PropTypes.func,
}

export default Side
