import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import { Grid, Box, TextField, Button, Input } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  root:{
    paddingTop: '25px',
  },
  rootPasscodeBox: {
    textDecoration: 'none'
  },
  passcodeBox: {
    borderWidth: '3px',
    borderStyle: 'solid',
    borderRadius: '10px',
    height: '65px',
    width: '50px',
    textAlign: 'center',
    fontSize: '18px',
    fontWeight: 500,
    textDecoration: 'none',
    '& :focus': {
      border: '3px solid #979797',
    }
  },
}))

const AuthenticationCode = ({ onCodeEntered }) => {
  const classes = useStyles()
  
  const [passcode1, setPasscode1] = useState('')
  const [passcode2, setPasscode2] = useState('')
  const [passcode3, setPasscode3] = useState('')
  const [passcode4, setPasscode4] = useState('')
  const [passcode5, setPasscode5] = useState('')

  const [borderPasscode1, setBorderPasscode1] = useState('0')
  const [borderPasscode2, setBorderPasscode2] = useState('0')
  const [borderPasscode3, setBorderPasscode3] = useState('0')
  const [borderPasscode4, setBorderPasscode4] = useState('0')
  const [borderPasscode5, setBorderPasscode5] = useState('0')
	
  const borderColorRed = '#FF0000'
  const borderColorBlack = '#979797'

  const setValidations = index => {
    let isError = false
    for (let i = 1; i <= index; i++) {
      if (i === 1)
        if (passcode1 === '') {
          isError = true
          setBorderPasscode1('1')
        } else {
          setBorderPasscode1('0')
        } 
      if (i === 2)
        if (passcode2 === '') {
          isError = true
          setBorderPasscode2('1')
        } else {
          setBorderPasscode2('0')
        } 
      if (i === 3)
        if (passcode3 === '') {
          isError = true
          setBorderPasscode3('1')
        } else {
          setBorderPasscode3('0')
        } 
      if (i === 4)
        if (passcode4 === '') {
          isError = true
          setBorderPasscode4('1')
        } else {
          setBorderPasscode4('0')
        } 
      if (i === 5)
        if (passcode5 === '') {
          isError = true
          setBorderPasscode5('1')
        } else {
          setBorderPasscode5('0')
        } 
    }
    return !isError
  }

  const concatinatePasscodes = () => {
    return  passcode1  +  passcode2  +  passcode3  +  passcode4  +  passcode5 
  }

  const shiftFocus = e => {
    const { maxLength, value, name } = e.target
    const [fieldName, fieldIndex] = name.split('-')
  
    // Check if they hit the max character length
    if (value.length >= maxLength) {
      // Check if it's not the last input field
      if (parseInt(fieldIndex, 10) < 5) {
        // Get the next input field
        const nextSibling = document.querySelector(
          `input[name=passcode-${parseInt(fieldIndex, 10) + 1}]`
        )
  
        // If found, focus the next field
        if (nextSibling !== null) {
          nextSibling.focus()
        }
      }
    }
  }

  const handlePaste = (e, passcodeIndex) => {
    const pastedData = e.clipboardData.getData('Text')
    const pastedDataAsArray = Array.from(pastedData)

    console.log('on paste: ' + pastedData)
  
    // Check if it's not the last input field
    if (passcodeIndex > 0 && passcodeIndex <= 5) {
      // Get the next input field

      for (let index = 1; index <= 5; index++){
        const nextSibling = document.querySelector(
          `input[name=passcode-${index}]`
        )
  
        // If found, focus the next field
        if (nextSibling !== null) {
          if (pastedDataAsArray.length >= index){
            // nextSibling.value = pastedDataAsArray[index - 1]
            if (index === 1)
              setPasscode1(pastedDataAsArray[index - 1])
            if (index === 2)
              setPasscode2(pastedDataAsArray[index - 1])
            if (index === 3)
              setPasscode3(pastedDataAsArray[index - 1])
            if (index === 4)
              setPasscode4(pastedDataAsArray[index - 1])
            if (index === 5)
              setPasscode5(pastedDataAsArray[index - 1])
          }
        }
      }
    }
  }

  const handleIfBackspace = (e, passcodeIndex) => {
    if (e.keyCode === 8) {
      if (passcodeIndex === 1)
        setPasscode1('')
      if (passcodeIndex === 2)
        setPasscode2('')
    	if (passcodeIndex === 3)
        setPasscode3('')
    	if (passcodeIndex === 4)
        setPasscode4('')
	  	if (passcodeIndex === 5)
        setPasscode5('')
      
      if (passcodeIndex > 0) {
        // Get the next input field
        const previousSibling = document.querySelector(
          `input[name=passcode-${passcodeIndex - 1}]`
        )
  
        // If found, focus the next field
        if (previousSibling !== null) 
          previousSibling.focus()
      }
    }
  }
	
  /** on eachpasscode box value change */
  const handleChange = ( e, index ) => {

    if (index === 1)
		  setPasscode1(e.currentTarget.value)
    if (index === 2)
		  setPasscode2(e.currentTarget.value)
    if (index === 3)
		  setPasscode3(e.currentTarget.value)
    if (index === 4)
		  setPasscode4(e.currentTarget.value)
    if (index === 5)
		  setPasscode5(e.currentTarget.value)

    shiftFocus(e)
  }

  /** actual api hit will be on this */
  const handleKeyUp = () => {
    setValidations(1)
    setValidations(2)
    setValidations(3)
    setValidations(4)
    setValidations(5)
    if (passcode1 !== '' && passcode2 !== '' && passcode3 !== '' && passcode4 !== '' && passcode5 !== '')
    {
      onCodeEntered(concatinatePasscodes())
    }
  }

  return (
    <Box display="flex" justifyContent="space-evenly" className={classes.root}>
      <input
        maxLength={1}
        className={ classes.passcodeBox }
        style={ borderPasscode1 === '0' ? { borderColor: borderColorBlack } : { borderColor: borderColorRed }}
        value={passcode1}
        name="passcode-1"
        onChange={e =>
        {
          handleChange(e, 1)
        }
        }
        onKeyUp={e =>
        {
          handleKeyUp()
        }
        }
        onKeyDown={e =>
          handleIfBackspace(e, 1)
        }
        onPaste={e => {
          handlePaste(e, 1)
        }}
        onInput={e => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }}
        
      />
      <input
        maxLength={1}
        className={ classes.passcodeBox }
        style={ borderPasscode2 === '0' ? { borderColor: borderColorBlack } : { borderColor: borderColorRed }}
        value={passcode2}
        name="passcode-2"
        onChange={e =>
        {
          handleChange(e, 2)
        }
        }
        onKeyUp={e =>
        {
          handleKeyUp()
        }
        }
        onKeyDown={e =>
          handleIfBackspace(e, 2)
        }
        onPaste={e => {
          handlePaste(e, 2)
        }}
        onInput={e => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }}
      />

      <input
        maxLength={1}
        className={classes.passcodeBox  }
        style={ borderPasscode3 === '0' ? { borderColor: borderColorBlack } : { borderColor: borderColorRed }}
        value={passcode3}
        name="passcode-3"
        onChange={e =>
        {
          handleChange(e, 3)
        }
        }
        onKeyUp={e =>
        {
          handleKeyUp()
        }
        }
        onKeyDown={e =>
          handleIfBackspace(e, 3)
        }
        onPaste={e => {
          handlePaste(e, 3)
        }}
        onInput={e => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }}
        
      />

      <input
        maxLength={1}
        className={ classes.passcodeBox }
        style={ borderPasscode4 === '0' ? { borderColor: borderColorBlack } : { borderColor: borderColorRed }}
        value={passcode4}
        name="passcode-4"
        onChange={e =>
        {
          handleChange(e, 4)
        }
        }
        onKeyUp={e =>
        {
          handleKeyUp()
        }
        }
        onKeyDown={e =>
          handleIfBackspace(e, 4)
        }
        onPaste={e => {
          handlePaste(e, 4)
        }}
        onInput={e => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }}
        
      />

      <input
        maxLength={1}
        className={ classes.passcodeBox }
        style={ borderPasscode5 === '0' ? { borderColor: borderColorBlack } : { borderColor: borderColorRed }}
        value={passcode5}
        name="passcode-5"
        onChange={e =>
        {
          handleChange(e, 5)
        }
        }
        onKeyUp={e =>
        {
          handleKeyUp()
        }
        }
        onKeyDown={e =>
          handleIfBackspace(e, 5)
        }
        onPaste={e => {
          handlePaste(e, 5)
        }}
        onInput={e => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }}
        
      />
    </Box>
  )
}

AuthenticationCode.propTypes = {
  onCodeEntered: PropTypes.func.isRequired
}
  
export default AuthenticationCode
