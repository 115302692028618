import React, { useContext, useState ,useEffect} from 'react'
import { Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import {getCategoryTypeKey, CustomCategoryTitle} from '../User/Card/New/Common'
import Box from '@material-ui/core/Box'
import Loader from "../Home/Loader";
import HttpClient from '../../services/HttpClient'
import AuthContext from '../../contexts/AuthContext'
import TopMenu from './Topbar/TopMenu'
import Dashboard from './Dashboard/index'
import Template from './Tool/Template'
import TemplateSubmitForm from './Dashboard/TemplateSubmitForm'
import TextStyling from './Tool/TextStyling'
import ActiveIcon from './Tool/ActiveIcon'
import SocialMedia from './Tool/SocialMedia'
import NewTemplate from '../Designer/Tool/Template/New'

const DesignerPanel = ({ match, history }) => {
  const [authData] = useContext(AuthContext)
  const [newTemplateDialogOpen, setNewTemplateDialogOpen] = useState(false)

  const handleOpenNewTemplateDialog = () => {
    setNewTemplateDialogOpen(true)
  }

  const handleCreateTemplate = templateId => {
    handleCloseNewTemplateDialog()
    history.push(`/designer/template/${templateId}?timestamp=${new Date().toISOString()}`)
  }

  const handleCloseNewTemplateDialog = () => {
    setNewTemplateDialogOpen(false)
  }

  const handleCreateTextStyling = () => {
    const textStylingData = {
      svg: '',
    }
    HttpClient.post('/text_stylings', textStylingData, {
      Authorization: `Bearer ${authData.authToken}`,
    })
      .then(res => history.push(`/designer/text_styling/${res.id}?timestamp=${new Date().toISOString()}`))
      .catch(error => console.log('Error creating template: ', error))
  }

  return (
    <Box display="flex" flexDirection="column" height={1}>
      <Box flex="0 1 auto">
        <TopMenu
          onOpenNewTemplateDialog={handleOpenNewTemplateDialog}
          onCreateTextStyling={handleCreateTextStyling}
        />
      </Box>
      <Box flex="1 1 auto" display="flex" overflow="hidden">
        <Route
          exact
          path={`${match.path}`}
          render={props => (
            <Dashboard
              {...props}
              onOpenNewTemplateDialog={handleOpenNewTemplateDialog}
              onCreateTextStyling={handleCreateTextStyling}
            />
          )}
        />
        <Route path={`${match.path}/template/:id?`} component={Template} />
        <Route
          path={`${match.path}/templates/:id/publish`}
          component={TemplateSubmitForm}
        />
        <Route
          path={`${match.path}/text_styling/:id?`}
          component={TextStyling}
        />
        <Route path={`${match.path}/active_icon/:id?`} component={ActiveIcon} />
        <Route
          path={`${match.path}/social_media/:id?`}
          component={SocialMedia}
        />
      </Box>
      {newTemplateDialogOpen ? (
        <NewTemplate
          onClose={handleCloseNewTemplateDialog}
          onCreate={handleCreateTemplate}
        />
      ) : null}
    </Box>
  )
}

DesignerPanel.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

export default DesignerPanel
