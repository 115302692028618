import React, {useContext, useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import {Box, Tooltip} from '@material-ui/core'
import {getTemplatePreviewPath, PlanType, TemplateType, toUpperCase} from './Common'
import multiSidedIcon from '../../../../images/multiSidedIcon.png'
import premiumIcon from '../../../../images/pro.png'
import freeIcon from '../../../../images/free.png'
import PremiumDialog from '../Editor/PremiumDialog'
import SubscriptionDialouge from '../Editor/SubscriptionDialouge'
import HttpClient from '../../../../services/HttpClient'
import AuthContext from '../../../../contexts/AuthContext'
import MultiSidedDialog from '../Editor/MultiSidedDialog'

const useStyles = makeStyles(() => ({
  card: {
    display: 'block',
    fontSize: '7px',
    color: 'black',
    position: 'relative'
  },
  marginRight: {
    marginRight: '.90rem',
  },
  image: {
    height: 'auto',
    width: window.innerWidth / 2.3 + 'px',
    verticalAlign: 'middle',
    borderRadius: '10px',
    boxShadow: '0 0 10px 5px #F9F9F9',
  },
  title: {
    fontSize: '1.5em',
    lineHeight: '1.5',
  },
  templateIcon: {
    color: 'black',
    position: 'absolute',
    float: 'right',
    transform: 'translate(-100%, -100%)'
  },
  badgePositionList: {
    top: '91%',
    paddingRight: '3%',
  },
  badgePositionGrid: {
    top: '97%',
    paddingRight: '3%',
  }
}))

const TemplatesSection = ({templates, type, showTitles, format, onClick}) => {
  const [openPremiumDialoug, setOpenPremiumDialoug] = useState(false)
  const [openMultiSidedDialoug, setOpenMultiSidedDialoug] = useState(false)
  const [authData] = useContext(AuthContext)
  const [loading, setLoading] = useState(true)
  const classes = useStyles()
  const [subscription, setSubscription] = useState({})
  const [subscribedTemplates, setSubscribeTemplates] = useState([])
  const [loadingSubscription, setLoadingSubscription] = useState(true)
  const [templateId, setTemplateId] = useState(0)
  const [openSubscriptionDialoug, setOpenSubscriptionDialoug] = useState(false)
  const trimTitles = (title) => {
    return title.length > 12 ? title.substr(0, 12) + '...' : title
  }

  const filterTemplates = (templates) => {
    return templates.filter((template) => {
      if (getTemplatePreviewPath(format, template)) return template
    })
  }


  useEffect(() => {
    setLoadingSubscription(true)
    HttpClient.get('/subscription', {
      Authorization: `Bearer ${authData.authToken}`,
    })
      .then((res) => {
        // setSubscription(res)
        setLoadingSubscription(false)
      })
      .catch((error) => {
        console.log('subscription error: ', error)
        // setSubscription()
        setLoadingSubscription(false)
      })
    setLoading(true)
  }, [authData.authToken])

  useEffect(() => {
    HttpClient.get('/templates/purchased', {
      Authorization: `Bearer ${authData.authToken}`,
    })
      .then((res) => {
        setSubscribeTemplates(res)
      })
      .catch((error) => {
        console.log('subscription error: ', error)
        setSubscribeTemplates([])
      })
    setLoading(true)
  }, [authData.authToken])

  const handleTemplateClick = (subscription, template) => {
    if (!subscription) {
      setOpenSubscriptionDialoug(!openSubscriptionDialoug)
      return
    }
    const templateId = template.id?template.id:template.template_id;
    const templateType = template.type?template.type:template.template_type;
      setTemplateId(templateId)
      if (subscribedTemplate(templateId)) {
        return onClick(
          templateId,
          format,
          template.category_id,
          template.category_type
        )
      } else {
        subscription.planType === PlanType.basic && templateType !== TemplateType.free
          ? templateType === TemplateType.premium
            ? setOpenPremiumDialoug(!openPremiumDialoug)
            : setOpenMultiSidedDialoug(!openMultiSidedDialoug)
          : onClick(
            templateId,
            format,
            template.category_id,
            template.category_type
          )
      }
  }

  const getTemplateBadge = (template) => {
    const templateType = template.type?template.type:template.template_type;
    if (templateType === TemplateType.free) return freeIcon
    else if (templateType === TemplateType.multiSided) return multiSidedIcon
    else if (templateType === TemplateType.premium) return premiumIcon
  }

  const subscribedTemplate = (templateId) => {
    if (subscribedTemplates.every((value) => value.templateId !== templateId))
      return false
    else return true
  }

  return (
    <React.Fragment>
      <>
        {templates &&
          filterTemplates(templates).map((template, index) => (
            <Box
              key={`${template.category_id}_${template.template_id}_${index}`}
              className={
                type === 'list'
                  ? `${classes.card} ${classes.marginRight}`
                  : classes.card
              }
            >
              <img
                src={getTemplatePreviewPath(format, template)}
                alt={template.category_title}
                className={classes.image}
                onClick={() =>
                  handleTemplateClick(subscription, template, index)
                }
              />

              {subscribedTemplates.every(
                (value) => value.templateId !== template.template_id
              ) && (
                <img
                  src={getTemplateBadge(template)}
                  className={type === 'list'
                    ? `${classes.templateIcon} ${classes.badgePositionList}`
                    : `${classes.templateIcon} ${classes.badgePositionGrid}`}
                />
              )}
              {showTitles && (
                <Tooltip title={toUpperCase(template.category_title)}>
                  <p className={classes.title}>
                    {' '}
                    {trimTitles(toUpperCase(template.category_title))}{' '}
                  </p>
                </Tooltip>
              )}
            </Box>
          ))}

        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
      </>
      <SubscriptionDialouge
        open={openSubscriptionDialoug}
        close={setOpenSubscriptionDialoug}
      ></SubscriptionDialouge>
      <PremiumDialog
        open={openPremiumDialoug}
        close={setOpenPremiumDialoug}
        templateId={templateId}
      ></PremiumDialog>
      <MultiSidedDialog
        open={openMultiSidedDialoug}
        close={setOpenMultiSidedDialoug}
        templateId={templateId}
      ></MultiSidedDialog>
    </React.Fragment>
  )
}

TemplatesSection.propTypes = {
  templates: PropTypes.array.isRequired,
  showTitles: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  format: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default TemplatesSection
