import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const LeftAlignIcon = props => {
  return (
    <SvgIcon {...props}>
      <path d="M4.7 4v17.2h1V4h-1zm2.9 3.2v4h11.6v-4H7.6zm0 6.6v4h6.3v-4H7.6z"/>
    </SvgIcon>
  )
}

export default LeftAlignIcon
