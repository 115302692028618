import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const SocialMediaIcon = props => {
  return (
    <SvgIcon {...props}>
      <path d="M13.86 22.23v-9.12h3.12l.47-3.55h-3.58V7.28c0-1.03.29-1.73 1.79-1.73h1.92V2.38c-.33-.04-1.47-.14-2.79-.14-2.76 0-4.65 1.66-4.65 4.7v2.62H7v3.55h3.12v9.12h3.74z" fill="none" stroke="currentColor" strokeWidth="1.479" strokeMiterlimit={10} />
    </SvgIcon>
  )
}

export default SocialMediaIcon
