import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import HttpClient from '../../../../services/HttpClient'
import AuthContext from '../../../../contexts/AuthContext'
import CategoryHeading from './CategoryHeading'
import DummySkeleton from './DummySkeleton'
import { useHistory } from 'react-router-dom'
import TemplatesSection from './TemplatesSection'

const useStyles = makeStyles(() => ({
  scroller: {
    overflowX: 'auto',
    overflowY: 'hidden',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    marginLeft: '16px',
    paddingTop: '10px',
  },
  link: {
    color: '#6495ED',
    textDecoration: 'none',
  },
  format_1: {
    position: 'relative',
    paddingBottom: '60%',
  },
  format_3: {
    flex: '0 1 auto',
    position: 'relative',
    paddingBottom: '100%',
  },
  format_2: {
    flex: '0 1 auto',
    position: 'relative',
    textDecoration: 'none',
    fontSize: '7px',
    lineHeight: '1rem',
    color: 'black',

    // paddingBottom: '125%',
  },
  svg: {
    '& svg': {
      maxWidth: '100%',
      maxHeight: '100%',
      borderRadius: 'inherit',
    },
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
    verticalAlign: 'middle',
  },
  categoryTitle: {
    fontSize: '15px',
    textDecoration: 'none !important',
  },
  typography: {
    fontSize: '6px',
    textDecoration: 'none !important',
  },
  breadcrumb: {
    fontSize: '12px',
    textDecoration: 'none !important',
    fontWeight: '400',
    paddingLeft: '1rem',
  },
}))

const CategoryHome = ({ category, cardId }) => {
  const classes = useStyles()
  const [authData] = useContext(AuthContext)
  const [templates, setTemplates] = useState(true)
  const [loading, setLoading] = useState(true)
  const history = useHistory()

  useEffect(() => {
    setLoading(true)
    HttpClient.get(`/templates/published?categoryType=${category.type}`, {
      Authorization: `Bearer ${authData.authToken}`,
    })
      .then(resTemplates => {
        setTemplates(resTemplates)
        setLoading(false)
      })
      .catch(error => {
        console.log('Error getting industries: ', error)
        setTemplates([])
        setLoading(false)
      })
  }, [authData.authToken, category])

  const templateClick = (
    templateId,
    formatType = '2',
    categoryId,
    categoryType
  ) => {
    history.push(
      `/user/cards/new/${cardId}/subcategory?id=${categoryId}&type=${categoryType}&format=${formatType}&timestamp=${new Date().toISOString()}`
    )
  };

  return (
    <>
      {!loading && (
        <Box py={2} borderBottom="1px solid #d8d8d8">
          <CategoryHeading
            title={category.name}
            to={`/user/cards/new/${cardId}/category?type=${category.type}&format=${category.format}`}
          />

          <Box display="flex" width={1} className={classes.scroller}>
            {loading ? (
              <DummySkeleton />
            ) : (
              <TemplatesSection
                templates={templates}
                showTitles={true}
                type='list'
                format={category.format}
                onClick={(templateId, format, categoryId, categoryType) =>
                  templateClick(templateId, format, categoryId, categoryType)
                }
              />
            )}
          </Box>
        </Box>
      )}
    </>
  )
};

CategoryHome.propTypes = {
  category: PropTypes.object.isRequired,
  cardId: PropTypes.string.isRequired,
}

export default CategoryHome
