import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { fabric } from 'fabric'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'

import { ActionContext } from '../../../../contexts/ActionContext'

const useStyles = makeStyles(() => ({
  draggable: {
    '-moz-user-select': 'none',
    '-khtml-user-select': 'none',
    '-webkit-user-select': 'none',
    userSelect: 'none',
    /* Required to make elements draggable in old WebKit */
    '-khtml-user-drag': 'element',
    '-webkit-user-drag': 'element',
    cursor: 'pointer'
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
    verticalAlign: 'middle',
  }
}))

const Image = ({ image }) => {
  const classes = useStyles()
  const [action, setAction] = useContext(ActionContext)
  const [dragging, setDragging] = useState(false)

  const handleDragStart = e => {
    setDragging(true)
  }

  // const handleDragEnd = e => {
  //   setDragging(true)
  // }

  const add = () => {
    fabric.Image.fromURL(image.path,
      oImg => {
        setAction({
          type: 'addObject', property: 'image', value: oImg
        })
      }, { crossOrigin: 'anonymous' }
    )
  }

  return (
    <Box
      border="1px solid #c7c2c2"
      borderRadius={4}
      className={`${classes.draggable} ${dragging ? 'dragging' : ''}`}
      onDragStart={handleDragStart}
      onClick={add}
    >
      <img src={image.path} alt="" className={classes.image} />
    </Box>
  )
}

Image.propTypes = {
  image: PropTypes.object.isRequired
}

export default Image
