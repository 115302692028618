import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { fabric } from 'fabric'

import { makeStyles } from '@material-ui/core/styles'
import { Box, Typography } from '@material-ui/core'
import { GridList, GridListTile } from '@material-ui/core'

import HttpClient from '../../../../../../services/HttpClient'
import AuthContext from '../../../../../../contexts/AuthContext'
import { DEFAULT_SOCIAL_MEDIA_CATEGORIES } from '../../../../../../constants/DefaultSocialMediaCategories'
import Autocomplete from '../../../../../Autocomplete'

const useStyles = makeStyles(() => ({
  heading: {
    textAlign: 'center'
  },
  container: {
    overflowY: 'auto'
  },
  category: {
    fontSize: '0.8em',
    textAlign: 'center',
    '& img': {
      maxWidth: '66%',
      maxHeight: '100%',
      paddingBottom: '2px',
    },
  },
  categoryTitle: {
    display: 'inline-block',
    width: '100%',
    fontSize: '0.65em'
  },
  
}))

const SocialMediaIcons = ({ onAddAction }) => {
  const classes = useStyles()
  const [authData] = useContext(AuthContext)
  const [categories, setCategories] = useState(DEFAULT_SOCIAL_MEDIA_CATEGORIES)
  const [category, setCategory] = useState(null)
  const [icons, setIcons] = useState([])
  const [loading, setLoading] = useState(true)

  // useEffect(() => {
  //   setLoading(true)
  //   HttpClient.get('/icon_categories?type=1', { Authorization: `Bearer ${authData.authToken}` })
  //     .then(res => {
  //       setCategories(res)
  //       setLoading(false)
  //     })
  //     .catch(error => {
  //       console.log('Error load active icons: ', error)
  //       setCategories([])
  //       setLoading(false)
  //     })
  // }, [authData.authToken])

  useEffect(() => {
    if (category !== null) {
      setLoading(true)
      HttpClient.get(`/icons?type=1&state=3&category=${category}`, { Authorization: `Bearer ${authData.authToken}` })
        .then(res => {
          setIcons(res)
          setLoading(false)
        })
        .catch(error => {
          console.log('Error loading social media icons: ', error)
          setIcons([])
          setLoading(false)
        })
    }
  }, [authData.authToken, category])

  // const handleSelection = cat => setCategory(cat)

  const handleAdd = icon => {
    fabric.loadSVGFromString(icon.svg, (objects, options) => {
      const obj = fabric.util.groupSVGElements(objects, options)
      obj.id = `Icon-${icon.id}`
      onAddAction({ type: 'addObject', value: obj })
    })
  }

  return (
    <React.Fragment>
      <Box
        flex="0 1 auto"
        px="12%"
        py={1.5}
        className={classes.heading}
      >
        Use social media icons to create interactive buttons on your card!
      </Box>
      <Box mx={1} mb={1}>
        <Autocomplete
          placeholder="Search for social media app" 
          options={categories}
          onAddCategory={setCategory}
        />
      </Box>
      <Box mx={1}>
        {category === null ?
          <GridList cellHeight={55} cols={6} spacing={0}>
            {categories.map((category, i) => (
              <GridListTile
                key={i}
                cols={1}
                className={classes.category}
                onClick={() => setCategory(category)}
              >
                <img src={require(`../../../../../../images/social-media-icons/${category.toLowerCase()}.svg`)} alt={category} />
                <Typography variant="caption" className={classes.categoryTitle}>{category}</Typography>
              </GridListTile>
            ))}
          </GridList>
          :
          <React.Fragment>
            <Box pt={2} pb={1}>
              <Typography variant="body1">{category}</Typography>
            </Box>
            <GridList cellHeight={55} cols={6} spacing={0}>
              {icons.map((icon, i) => (
                <GridListTile
                  key={i}
                  cols={1}
                  className={classes.category}
                  onClick={() => handleAdd(icon)}
                >
                  <Box dangerouslySetInnerHTML={{ __html: icon.svg }} />
                </GridListTile>
              ))}
            </GridList>
          </React.Fragment>
        }
        
      </Box>
    </React.Fragment>
  )
}

SocialMediaIcons.propTypes = {
  onAddAction: PropTypes.func.isRequired,
}

export default SocialMediaIcons
