import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import WebFont from 'webfontloader'

import Box from '@material-ui/core/Box'
import Link from '@material-ui/core/Link'

import { ActionContext } from '../../../../contexts/ActionContext'

const Font = ({ font }) => {
  const [action, setAction] = useContext(ActionContext)
  const fontName = font.replace(/\+/g, ' ')

  useEffect(() => {
    WebFont.load({
      google: {
        families: [font]
      },
    })
  }, [font])

  const add = () => {
    setAction({
      type: 'text', property: 'fontFamily', value: fontName
    })
  }

  return (
    <Box
      p={1}
      onClick={add}
      style={{ fontFamily: `"${fontName}"` }}
    >
      <Link
        href="#"
        color="inherit"
        underline="none"
      >{fontName}</Link>
    </Box>
  )
}

Font.propTypes = {
  font: PropTypes.string.isRequired
}

export default Font
