import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import { Box, Grid } from '@material-ui/core'
import Slider from '@material-ui/core/Slider'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'

const useStyles = makeStyles(() => ({
  slider: {
    padding: 0
  },
  input: {
    '& .MuiOutlinedInput-root input': {
      paddingTop: '5.5px',
      paddingBottom: '5.5px',
      fontSize: '0.8rem',
    },
  },
}))

const Spacing = props => {
  const classes = useStyles()
  const [charSpacing, setCharSpacing] = useState(props.charSpacing)
  const [lineHeight, setLineHeight] = useState(props.lineHeight)

  const handleCharSpacingChange = value => {
    setCharSpacing(value)
    props.onAddAction({
      type: 'text', property: 'charSpacing', value
    })
  }

  const handleLineHeightChange = size => {
    setLineHeight(size)
    props.onAddAction({
      type: 'text', property: 'lineHeight', value: size
    })
  }

  return (
    <>
      <Box width="300px" py={1}>
        <Grid container spacing={1}>
          <Grid item sm={3}>
            <Typography variant="caption">Letter</Typography>
          </Grid>
          <Grid item sm={6}>
            <Slider
              value={charSpacing}
              max={150}
              onChange={(e, value) => setCharSpacing(value)}
              onChangeCommitted={(e, value) => handleCharSpacingChange(value)}
              className={classes.slider}
            />
          </Grid>
          <Grid item sm={3}>
            <TextField
              id="font-size"
              type="number"
              size="small"
              fullWidth
              variant="outlined"
              value={charSpacing}
              onChange={e => handleCharSpacingChange(e.currentTarget.value)}
              className={classes.input}
            />
          </Grid>
        </Grid>
      </Box>
      <Box py={1}>
        <Grid container spacing={1}>
          <Grid item sm={3}>
            <Typography variant="caption">Line Height</Typography>
          </Grid>
          <Grid item sm={6}>
            <Slider
              value={lineHeight}
              min={0.5}
              max={2.5}
              step={0.01}
              onChange={(e, value) => setLineHeight(value)}
              onChangeCommitted={(e, value) => handleLineHeightChange(value)}
              className={classes.slider}
            />
          </Grid>
          <Grid item sm={3}>
            <TextField
              id="line-height"
              type="number"
              size="small"
              variant="outlined"
              value={lineHeight}
              onChange={e => handleLineHeightChange(e.currentTarget.value)}
              className={classes.input}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

Spacing.propTypes =  {
  charSpacing: PropTypes.number.isRequired,
  lineHeight: PropTypes.number.isRequired,
  onAddAction: PropTypes.func.isRequired,
}

export default Spacing
