import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ChromePicker } from 'react-color'

import { makeStyles } from '@material-ui/core/styles'
import { Box, Tooltip } from '@material-ui/core'
import Popover from '@material-ui/core/Popover'
import PaletteOutlinedIcon from '@material-ui/icons/PaletteOutlined'

import { DEFAULT_COLORS } from '../../../../../../constants/DefaultColors'

const useStyles = makeStyles(() => ({
  slider: {
    overflowX: 'auto',
    overflowY: 'hidden',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
  },
  colorBox: {
    flex: 'none',
    cursor: 'pointer',
    border: '1px solid #ccc',
    transition: 'box-shadow .2s ease-in-out',
    '&:hover': {
      boxShadow: '0 0 0 2px #00c4cc, inset 0 0 0 2px #fff',
    }
  },
  selected: {
    cursor: 'pointer',
    transition: 'box-shadow .2s ease-in-out',
    boxShadow: '0 0 0 2px #00c4cc, inset 0 0 0 2px #fff',
  },
  colorPicker: {
    display: 'flex',
    flex: 'none',
    cursor: 'pointer',
    border: '1px solid #ccc',
    borderRadius: 4,
  },
  colorPickerBtn: {
    flex: 'auto'
  }
}))

const componentToHex = c => {
  const hex = c.toString(16)
  return hex.length === 1 ? `0${hex}` : hex
}

const rgbToHex = color => {
  if( color !== undefined ) {
    if( color.startsWith('rgb') ) {
      const hex = color.replace(/\brgb\b|\(|\)/g, '').split(',')
      return `#${componentToHex(hex[0])}${componentToHex(hex[1])}${componentToHex(hex[2])}`
    }
    return color
  }
}

const colorTitle = c => {
  return c.name !== undefined ? `${c.name} ${c.hex}` : c.hex
}

const TextColor = ({ textColor, onAddAction }) => {
  const classes = useStyles()
  const colour = rgbToHex(textColor)
  const [color, setColor] = useState(colour)
  const [colorPickerEl, setColorPickerEl] = useState(null)

  const handleColorUpdate = clr => {
    setColor(clr.hex)
    onAddAction({
      type: 'text', property: 'fill', value: clr
    })
  }

  const hanldeColorPickerOpen = e => setColorPickerEl(e.currentTarget)
  const hanldeColorPickerClose = () => setColorPickerEl(null)

  const colorPickerOpen = Boolean(colorPickerEl)
  const colorPickerId = colorPickerOpen ? 'color-picker' : undefined

  return (
    <Box display="flex" width={1} paddingTop={0.2} paddingBottom={3} className={classes.slider}>
      <Box px="8px">
        <Box
          display="flex"
          alignItems="center"
          width="32px"
          height="32px"
          className={classes.colorPicker}
          onClick={hanldeColorPickerOpen}
        >
          <PaletteOutlinedIcon className={classes.colorPickerBtn} />
        </Box>
        <Popover
          id={colorPickerId}
          open={colorPickerOpen}
          anchorEl={colorPickerEl}
          onClose={hanldeColorPickerClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <ChromePicker
            disableAlpha
            color={color}
            onChangeComplete={handleColorUpdate}
          />
        </Popover>
      </Box>
      {DEFAULT_COLORS.map((c, index) => (
        <Box
          key={index}
          paddingRight="8px">
          <Tooltip title={colorTitle(c)} arrow>
            <Box
              width="32px"
              height="32px"
              borderRadius={4}
              className={classes.colorBox}
              style={{ backgroundColor: c.hex }}
              onClick={() => handleColorUpdate(c)} />
          </Tooltip>
        </Box>
      ))}
    </Box>
  )
}

TextColor.propTypes = {
  textColor: PropTypes.string,
  onAddAction: PropTypes.func.isRequired,
}

export default TextColor
