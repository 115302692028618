import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const RightAlignIcon = props => {
  return (
    <SvgIcon {...props}>
      <path d="M17.7 3v17.2h1V3h-1zM4.2 6.2v4h11.7v-4H4.2zm5.3 6.6v4h6.4v-4H9.5z"/>
    </SvgIcon>
  )
}

export default RightAlignIcon
