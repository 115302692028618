import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import HttpClient from '../../../../../services/HttpClient'
import AuthContext from '../../../../../contexts/AuthContext'

const useStyles = makeStyles(() => ({
  button: {
    backgroundColor: '#333840',
    justifyContent: 'left',
    borderRadius: 4,
    '&:hover': {
      backgroundColor: '#7c7f84',
    },
  },
}))

const New = ({ onAdd }) => {
  const classes = useStyles()
  const [authData] = useContext(AuthContext)
  const [categories, setCategories] = useState([])
  let fileReader
  
  useEffect(() => {
    HttpClient.get('/icon_categories?type=1', { Authorization: `Bearer ${authData.authToken}` })
      .then(res => setCategories(res))
      .catch(error => console.log(error))
  }, [])

  const add = category => {
    const content = fileReader.result
    onAdd({
      category,
      svg: content
    })
  }

  const handleFileChosen = (category, file) => {
    fileReader = new FileReader()
    fileReader.onloadend = () => add(category)
    fileReader.readAsText(file)
  }

  return (
    <Box height="calc(100% - 117px)" px={1} overflow="scroll" color="white" bgcolor="#293039">
      <Typography variant="subtitle2">Most popular:</Typography>
      {categories.map((category, index) => (
        <Box my={1} key={index}>
          <Button
            color="inherit"
            variant="contained"
            component="label"
            fullWidth
            disableElevation
            className={classes.button}
          >
            {category}
            <input
              type="file"
              accept="image/svg+xml"
              hidden
              onChange={event => handleFileChosen(category, event.target.files[0])}
            />
          </Button>
        </Box>
      ))}
    </Box>
  )
}

New.propTypes = {
  onAdd: PropTypes.func.isRequired
}

export default New
