import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { fabric } from "fabric";
import { useSpring, a, config } from "react-spring";
import { useDrag } from "react-use-gesture";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
// import PhoneIcon from '@material-ui/icons/Phone'
import CategoryOutlinedIcon from "@material-ui/icons/CategoryOutlined";
// import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined'

import MenuBox from "./MenuBox";
// import TemplatesIcon from '../../../../icons/TemplatesIcon'
import TextsIcon from "../../../../icons/TextsIcon";
import TextStylingsIcon from "../../../../icons/TextStylingsIcon";
import ImagesIcon from "../../../../icons/ImagesIcon";
import BackgroundIcon from "../../../../icons/BackgroundIcon";
import SocialMediaIcon from "../../../../icons/SocialMediaIcon";

const useStyles = makeStyles(() => ({
  root: {
    touchAction: "pan-y",
    bottom: 0,
    zIndex: 2,
    position: "absolute",
    width: "100%",
    background:
      "linear-gradient(to right, rgb(218 218 208 / 0.5) 31%, rgb(197 204 197 / 0.5))",
    backdropFilter: "blur(24px)",
    transition:
      "transform .25s cubic-bezier(0,0,.2,1),-webkit-transform .25s cubic-bezier(0,0,.2,1)",
  },
}));

const Menu = ({ onPanelChange, onAddAction, onClose }) => {
  const classes = useStyles();
  const [menuHeight, setMenuHeight] = useState(0);
  const menuRef = useRef(null);
  const [{ y }, set] = useSpring(() => ({}));

  useEffect(() => {
    setMenuHeight(menuRef.current.offsetHeight);
    open({ canceled: false });
  }, []);

  const open = ({ canceled }) => {
    // when cancel is true, it means that the user passed the upwards threshold
    // so we change the spring config to create a nice wobbly effect
    set({
      y: 0,
      immediate: false,
      config: {
        ...(canceled ? config.wobbly : config.molasses),
        duration: 150,
      },
    });
  };

  const close = (velocity = 0) => {
    set({
      y: menuHeight,
      immediate: false,
      config: {
        ...config.molasses,
        velocity,
        duration: 150,
      },
      onRest: () => onClose(),
    });
  };

  const bind = useDrag(
    ({ event, last, vxvy: [, vy], movement: [, my], cancel, canceled }) => {
      // if the user drags up passed a threshold, then we cancel
      // the drag so that the sheet resets to its open position
      if (my < 0) cancel();

      if (
        event.target.id === "drag-handle" ||
        event.target.closest("#drag-handle")
      ) {
        // when the user releases the sheet, we check whether it passed
        // the threshold for it to close, or if we reset it to its open position
        if (last) {
          my > menuHeight * 0.4 || vy > 0.4 ? close(vy) : open({ canceled });
        }
        // when the user keeps dragging, we just move the sheet according to
        // the cursor position
        else set({ y: my, immediate: true });
      } else {
        cancel();
      }
    },
    {
      initial: () => [0, y.get()],
      filterTaps: true,
      bounds: { top: 0 },
      rubberband: true,
    }
  );

  const addTextFied = () => {
    const field = new fabric.Textbox("Text", {
      fontSize: 42,
      textAlign: "center",
      originX: "center",
      originY: "center",
    });
    onAddAction({ type: "addObject", value: field });
    onClose();
  };

  return (
    <React.Fragment>
      <Box
        position="absolute"
        left={0}
        top={0}
        right={0}
        bottom={0}
        bgcolor="rgb(221 221 221 / 21%)"
        zIndex={1}
        onClick={close}
      />
      <a.div ref={menuRef} {...bind()} className={classes.root} style={{ y }}>
        <Box id="drag-handle" width={1} padding="4% 0" marginTop="-4%">
          <Box width={1} padding="2% 0" bgcolor="#a0a2a3">
            <Box
              width="20%"
              margin="0 auto 1%"
              paddingBottom="1%"
              bgcolor="#c7c7c8"
              borderRadius={5}
            />
            <Box
              width="20%"
              margin="0 auto"
              pb="1%"
              bgcolor="#c7c7c8"
              borderRadius={5}
            />
          </Box>
        </Box>
        <Box display="flex" width={1} px="15%" py="5%" flexWrap="wrap">
          {/* <Box
            display="flex"
            flex="1 0 33.33%"
            alignItems="center"
            color="#fff"
            alignContent="center"
            onClick={() => {}}
          >
            <MenuBox icon={TemplatesIcon} label="Templates" />
          </Box> */}
          <Box
            display="flex"
            flex="1 0 33.33%"
            alignItems="center"
            color="#fff"
            alignContent="center"
            onClick={addTextFied}
          >
            <MenuBox icon={TextsIcon} label="Texts" />
          </Box>
          <Box
            display="flex"
            flex="1 0 33.33%"
            alignItems="center"
            color="#fff"
            alignContent="center"
            onClick={() => onPanelChange(2)}
          >
            <MenuBox icon={TextStylingsIcon} label="Text Styling" />
          </Box>
          <Box
            display="flex"
            flex="1 0 33.33%"
            alignItems="center"
            color="#fff"
            alignContent="center"
            onClick={() => onPanelChange(6)}
          >
            <MenuBox icon={SocialMediaIcon} label="Social Media Icons" />
          </Box>
          <Box
            display="flex"
            flex="1 0 33.33%"
            alignItems="center"
            color="#fff"
            alignContent="center"
            onClick={() => onPanelChange(3)}
          >
            <MenuBox icon={ImagesIcon} label="Images" />
          </Box>
          <Box
            display="flex"
            flex="1 0 33.33%"
            alignItems="center"
            color="#fff"
            alignContent="center"
            onClick={() => onPanelChange(4)}
          >
            <MenuBox icon={BackgroundIcon} label="Background" />
          </Box>
          {/* <Box
            display="flex"
            flex="1 0 33.33%"
            alignItems="center"
            color="#fff"
            alignContent="center"
            onClick={() => onPanelChange(5)}
          >
            <MenuBox icon={PhoneIcon} label="Active Icons" />
          </Box> */}
          <Box
            display="flex"
            flex="1 0 33.33%"
            alignItems="center"
            color="#fff"
            alignContent="center"
            onClick={() => onPanelChange(7)}
          >
            <MenuBox icon={CategoryOutlinedIcon} label="Graphics" />
          </Box>
          {/* <Box
            display="flex"
            flex="1 0 33.33%"
            alignItems="center"
            color="#fff"
            alignContent="center"
            onClick={() => {}}
          >
            <MenuBox icon={CloudUploadOutlinedIcon} label="Uploads" />
          </Box> */}
        </Box>
      </a.div>
    </React.Fragment>
  );
};

Menu.propTypes = {
  onPanelChange: PropTypes.func.isRequired,
  onAddAction: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Menu;
