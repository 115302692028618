import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { fabric } from 'fabric'

import { makeStyles } from '@material-ui/core/styles'
import { Grid, Box, Tooltip } from '@material-ui/core'
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import HttpClient from '../../../../services/HttpClient'
import AuthContext from '../../../../contexts/AuthContext'
import { ActionContext } from '../../../../contexts/ActionContext'
import SearchBar from '../SearchBar'

const useStyles = makeStyles(theme => ({
  tabs: {
    cursor: 'pointer'
  },
  tabActive: {
    backgroundColor: '#293039'
  },
  iconBox: {
    fill: 'white',
    cursor: 'pointer',
    backgroundColor: 'hsla(0,0%,100%,.07)',
    '& svg': {
      maxWidth: '100%',
      maxHeight: '100%'
    },
    '&:before': {
      display: 'none',
    },
  },
  accordion: {
    color: '#fff',
    backgroundColor: '#191d23',
    borderTop: '2px solid #293039',
    '&$accordionExpanded': {
      margin: 'auto',
    },
  },
  accordionExpanded: {},
  accordionSummary: {
    minHeight: 20,
    '&$expanded': {
      minHeight: 20,
    },
  },
  expanded: {},
  accordionSummaryContent: {
    margin: `${theme.spacing(1)/2}px 0`,
    '&$expanded': {
      margin: `${theme.spacing(1)/2}px 0`,
    },
  },
  accordionExpandIcon: {
    padding: `${theme.spacing(1)/2}px`
  },
  accordionIcon: {
    color: '#8ba8f0',
    fontSize: theme.typography.body1.fontSize,
  },
  accordionDetails: {
    backgroundColor: 'hsla(0,0%,100%,.07)',
  }
}))

const groupByCategory = icons => {
  return icons.reduce((r, ico) => {
    r[ico.category] = r[ico.category] || []
    r[ico.category].push(ico)
    return r
  }, Object.create(null))
}

const ActiveIcons = props => {
  const classes = useStyles()
  const [authData] = useContext(AuthContext)
  const [action, setAction] = useContext(ActionContext)
  const [icons, setIcons] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    HttpClient.get('/myicons?type=2&state=3', { Authorization: `Bearer ${authData.authToken}` })
      .then(res => {
        setIcons(res)
        setLoading(false)
      })
      .catch(error => {
        console.error('Error active icons loading: ', error)
        setIcons([])
        setLoading(false)
      })
  }, [authData.authToken])
  
  const handleSearch = () => {}

  const handleAdd = icon => {
    fabric.loadSVGFromString(icon.svg, (objects, options) => {
      const obj = fabric.util.groupSVGElements(objects, options)
      // obj.be = { id: icon.id, type: 'ActiveIcon' }
      obj.id = `Icon-${icon.id}`
      setAction({ type: 'addObject', value: obj })
    })
  }

  const categorized = groupByCategory(icons)
  return (
    <>
      <SearchBar onSearch={handleSearch} />
      <Box mx={2} color="white">
        <Box
          py={1}
          width={0.5}
          display="inline-block"
          borderRadius="4px 4px 0 0"
          className={props.subPanel === 0 ? classes.tabActive : classes.tabs}
          textAlign="center"
          onClick={() => props.onSubpanelChange(0)}
        >Published</Box>
        <Box
          py={1}
          width={0.5}
          display="inline-block"
          borderRadius="4px 4px 0 0"
          className={props.subPanel === 1 ? classes.tabActive : classes.tabs}
          textAlign="center"
        >Standard</Box>
      </Box>
      <Box
        flex="1 1 auto"
        p={1}
        overflow="scroll"
        bgcolor="#293039"
        color="white"
        className="scrollbar-hidden"
      >
        <Grid container spacing={1}>
          {Object.keys(categorized).map((category, i) => (
            <Box width={1} key={i}>
              <Accordion square classes={{
                root: classes.accordion,
                expanded: classes.accordionExpanded
              }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className={classes.accordionIcon} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  classes={{
                    root: classes.accordionSummary,
                    expanded: classes.expanded,
                    content: classes.accordionSummaryContent,
                    expandIcon: classes.accordionExpandIcon
                  }}
                >
                  <Typography variant="caption">{category}</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                  <Grid container spacing={1}>
                    {categorized[category].map(icon => (
                      <Grid item xs={4} key={icon.id}>
                        <Box
                          position="relative"
                          width={1}
                          pb="100%"
                          borderRadius={4}
                          boxSizing="border-box"
                        >
                          <Tooltip title={icon.name} arrow>
                            <Box
                              position="absolute"
                              left={1}
                              right={1}
                              top={1}
                              bottom={1}
                              borderRadius={4}
                              className={classes.iconBox}
                              dangerouslySetInnerHTML={{ __html: icon.svg }}
                              onClick={() => handleAdd(icon)}
                            />
                          </Tooltip>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Box>
          ))}
          <Box width={1}>
            <Accordion square classes={{
              root: classes.accordion,
              expanded: classes.accordionExpanded
            }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.accordionIcon} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                classes={{
                  root: classes.accordionSummary,
                  expanded: classes.expanded,
                  content: classes.accordionSummaryContent,
                  expandIcon: classes.accordionExpandIcon
                }}
              >
                <Typography variant="caption">All</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                <Grid container spacing={1}>
                  {icons.map(icon => (
                    <Grid item xs={4} key={icon.id}>
                      <Box
                        position="relative"
                        width={1}
                        pb="100%"
                        borderRadius={4}
                        boxSizing="border-box"
                      >
                        <Tooltip title={icon.name} arrow>
                          <Box
                            position="absolute"
                            left={1}
                            right={1}
                            top={1}
                            bottom={1}
                            borderRadius={4}
                            className={classes.iconBox}
                            dangerouslySetInnerHTML={{ __html: icon.svg }}
                            onClick={() => handleAdd(icon)}
                          />
                        </Tooltip>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Grid>
      </Box>
    </>
  )
}

ActiveIcons.propTypes = {
  subPanel: PropTypes.number.isRequired,
  onSubpanelChange: PropTypes.func.isRequired
}

export default ActiveIcons
