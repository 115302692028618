import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {useHistory} from 'react-router-dom'

import {makeStyles} from '@material-ui/core/styles'
import {Box, Grid} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import {CircularProgress} from '@material-ui/core'

import {ReactComponent as Back} from '../../icons/Back.svg'
import SecondDialouge from './SecondDialouge'
import {ReactComponent as Info} from '../../icons/InfoBlack.svg'
import DraftDialog from './DraftDialoug'

const useStyles = makeStyles(() => ({
  button: {
    color: '#6454FF',
    minWidth: '90px',
    marginRight: '10px',
  },
  btnLabel: {
    flexDirection: 'column',
  },
  btnStartIcon: {
    margin: 0,
  },
  icon: {
    fontSize: '2em !important',
    width: '20px',
    height: '25px',
  },
  flex: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  padding: {
    paddingLeft: 5,
  },
  height: {
    height: '40px',
  },
}))

const Topbar = ({card, callSaveSide, savingInProgress, event}) => {
  const classes = useStyles()
  const [openDialougue, setOpenDialougue] = useState(false)
  const [openDraftDialoug, setOpenDraftDialoug] = useState(false)
  const history = useHistory()

  const handleDraftDialog = () => {
    if (card.status === 1) {
      console.log('draft')
      event('close-drfat')
    } else {
      console.log('publish')
      event('close-publish')
    }
    setOpenDraftDialoug(!openDraftDialoug)
  }

  const handlePublish = (e) => {
    e.preventDefault()
    e.stopPropagation()
    event('close-save')
    callSaveSide(true)
  }

  const handleInfoDialog = (state) => {
    setOpenDialougue(state)
  }

  const handleBackClick = (status) => {
    const dashboard = new URLSearchParams(window.location.search).get(
      'dashboard'
    )
    if (dashboard)
      history.push(
        `/user/cards/new/${card.id}?timestamp=${new Date().toISOString()}`
      )
    else if (status === 2) {
      event('close-published')
      if (window.Print !== undefined) window.Print.postMessage('close-publish')
    } else {
      event('close-draft')
      if (window.Print !== undefined) window.Print.postMessage('close-draft')
    }
  }

  return (
    <Grid style={{display: 'flex', justifyContent: 'space-between'}}>
      <Grid container item xs={2} style={{alignItems: 'center'}}>
        <span className={classes.padding}>
          {' '}
          <Back onClick={() => handleBackClick(card.status)} />
        </span>
      </Grid>
      <Grid
        container
        item
        xs={10}
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Box className={classes.flex}>
          <Button
            classes={{
              root: classes.button,
            }}
            className={classes.height}
            variant="contained"
            color="primary"
            onClick={() => handleDraftDialog()}
          >
            <Box display="inline" fontSize="14px" color="white">
              Save draft
            </Box>
          </Button>
          <Button
            classes={{
              root: classes.button,
              label: classes.btnLabel,
              startIcon: classes.btnStartIcon,
            }}
            style={{
              backgroundColor: '#9933ff',
              height: '40px',
            }}
            variant="contained"
            onClick={handlePublish}
          >
            <Box
              display="inline"
              fontSize="14px"
              fontWeight="900"
              color="white"
            >
              Save{' '}
              {savingInProgress && (
                <CircularProgress
                  color="inherit"
                  style={{width: '12px', height: '12px'}}
                />
              )}
            </Box>
          </Button>

          <Box display="inline-flex" alignItems="center">
            <Info
              className={classes.icon}
              onClick={() => handleInfoDialog(true)}
            ></Info>
          </Box>
        </Box>
      </Grid>

      <SecondDialouge
        open={openDialougue}
        handleState={handleInfoDialog}
      ></SecondDialouge>

      <DraftDialog
        open={openDraftDialoug}
        close={setOpenDraftDialoug}
        card={card}
        savingInProgress={savingInProgress}
        saveSide={(callToSaveSide) => {
          callSaveSide(callToSaveSide)
        }}
      ></DraftDialog>
    </Grid>
  )
}

Topbar.propTypes = {
  card: PropTypes.object.isRequired,
}

export default Topbar
