import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import MuiToolbar from '@material-ui/core/Toolbar'

// import { NavigationContext } from '../../../../../contexts/NavigationContext'
import Default from './Default'
import Text from './Text'
import Group from './Group'

const useStyles = makeStyles(theme => ({
  toolbar: {
    backgroundColor: '#FFF',
    minHeight: '44px',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)'
  },
}))

const Toolbar = ({ toolbar, onNavigationChange }) => {
  const classes = useStyles()

  return (
    <MuiToolbar variant="dense" className={classes.toolbar}>
      {toolbar.type === 'default' && <Default /> }
      {toolbar.type === 'text' &&
        <Text
          toolbarData={toolbar}
          onNavigationChange={onNavigationChange} />
      }
      {['image', 'group', 'path', 'circle', 'polygon', 'rect', 'activeSelection'].includes(toolbar.type) &&
        <Group
          toolbarData={toolbar}
          onNavigationChange={onNavigationChange} />
      }
    </MuiToolbar>
  )
}

Toolbar.propTypes = {
  toolbar: PropTypes.object.isRequired,
  onNavigationChange: PropTypes.func.isRequired
}

export default Toolbar
