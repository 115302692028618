import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import { Grid, Box, Tooltip } from '@material-ui/core'

import HttpClient from '../../../../../services/HttpClient'
import AuthContext from '../../../../../contexts/AuthContext'
import { ActionContext } from '../../../../../contexts/ActionContext'
import SearchBar from '../../SearchBar'

const useStyles = makeStyles(() => ({
  stylingBox: {
    fill: 'white',
    cursor: 'pointer',
    backgroundColor: 'hsla(0,0%,100%,.07)',
    '& svg': {
      maxWidth: '100%',
      maxHeight: '100%'
    },
    '&:hover': {
      backgroundColor: 'hsla(0,0%,100%,.15)',
    }
  }
}))

const TextStylings = () => {
  const classes = useStyles()
  const [authData] = useContext(AuthContext)
  const [action, setAction] = useContext(ActionContext)
  const [textStylings, setTextStylings] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    HttpClient.get('/text_stylings?status=2', { Authorization: `Bearer ${authData.authToken}` })
      .then(res => {
        setTextStylings(res)
        setLoading(false)
      })
      .catch(error => {
        console.log('Error getting Text Stylings: ', error)
        setTextStylings([])
        setLoading(false)
      })
  }, [authData.authToken])
  
  const handleSearch = q => {}

  const handleAdd = textStyling => {
    setAction({ type: 'addTextStyling', value: textStyling.json })
  }

  return (
    <React.Fragment>
      <SearchBar onSearch={handleSearch} />
      <Box flex="1 1 auto" p={1} overflow="scroll" bgcolor="#293039" color="white">
        <Grid container spacing={1}>
          {textStylings.map((textStyling, index) => (
            <Grid item xs={6} key={index}>
              <Box
                position="relative"
                width={1}
                pb="100%"
                borderRadius={4}
                boxSizing="border-box"
              >
                <Tooltip title={textStyling.name || 'Unnamed'} arrow>
                  <Box
                    position="absolute"
                    left={1}
                    right={1}
                    top={1}
                    bottom={1}
                    borderRadius={4}
                    className={classes.stylingBox}
                    onClick={() => handleAdd(textStyling)}
                    dangerouslySetInnerHTML={{ __html: textStyling.svg }}
                  />
                </Tooltip>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </React.Fragment>
  )
}

TextStylings.propTypes = {
  subPanel: PropTypes.number.isRequired
}

export default TextStylings
