import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const GroupIcon = props => {
  return (
    <SvgIcon {...props}>
      <path d="M8.5 2.9c-1.2 0-2.2 1-2.2 2.3l.1 11.4c0 1.3 1 2.4 2.2 2.4h10.9c1.3 0 2.3-1.1 2.3-2.4V5.2c0-1.3-1-2.3-2.3-2.3h-11zm0 1.2h11c.6 0 1 .4 1 1.1v11.4c0 .7-.4 1.2-1 1.2H8.6c-.5 0-1-.5-1-1.2L7.5 5.2c0-.7.5-1.1 1-1.1zM6.3 5.5c-1.3 0-2.4 1-2.4 2.3v11.4c0 1.4 1.1 2.4 2.4 2.4h11c.7 0 1.2-.3 1.6-.7.6-.7.6-1.5.6-1.7v-.9h-1.2v.9c0 .2 0 .5-.3.8-.1.2-.6.4-.7.4h-11c-.7 0-1.1-.5-1.1-1.2V7.8c0-.7.4-1.1 1.1-1.1h1V5.5h-1z" />
    </SvgIcon>
  )
}

export default GroupIcon
