import React from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { getCategoryTypeValue } from "./Common";
import { useHistory } from "react-router-dom";
import Link from "@material-ui/core/Link";
import { toUpperCase } from "./Common";

const useStyles = makeStyles(() => ({
  typography: {
    fontSize: "6px",
    textDecoration: "none !important",
  },
  link: {
    color: "black",
    textDecoration: "none !important",
  },
  breadcrumb: {
    fontSize: "12px",
    textDecoration: "none !important",
    fontWeight: "500",
    paddingLeft: "1rem",
  },
}));

const Breadcrumb = ({ categoryType, categoryTitle, cardId }) => {
  const classes = useStyles();
  const history = useHistory();

  const goToHome = () => {
    history.push(`/user/cards/new/${cardId}/designs?dashboard=true&timestamp=${new Date().toISOString()}`);
  };

  const goToCategory = () => {
    history.push(`/user/cards/new/${cardId}/category?type=${categoryType}&timestamp=${new Date().toISOString()}`);
  };

  return (
    <Box py={2} borderBottom="1px solid #d8d8d8">
      <Typography gutterBottom className={classes.breadcrumb}>
        <Link className={classes.link} onClick={() => goToHome()}>
          {" "}
          All |{" "}
        </Link>
        <Link className={classes.link} onClick={() => goToCategory()}>
          {categoryTitle ? (
            getCategoryTypeValue(categoryType)
          ) : (
            <strong>{getCategoryTypeValue(categoryType)}</strong>
          )}
        </Link>
        {categoryTitle && (
          <strong>
            {" "}
            | {categoryTitle ? toUpperCase(categoryTitle) : null}
          </strong>
        )}
      </Typography>
    </Box>
  );
};

Breadcrumb.propTypes = {
  categoryType: PropTypes.string.isRequired,
  categoryTitle: PropTypes.string,
  cardId: PropTypes.string.isRequired,
};

export default Breadcrumb;
