import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import MuiToolbar from '@material-ui/core/Toolbar'

import { NavigationContext } from '../../../../../contexts/NavigationContext'
// import Default from './Default'
import Text from './Text/index'
import Group from './Group'

const useStyles = makeStyles(() => ({
  toolbar: {
    backgroundColor: '#FFF',
    minHeight: '44px',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)'
  },
}))

const Toolbar = props => {
  const classes = useStyles()
  const [navigation, setNavigation] = useContext(NavigationContext)

  const handleNavigationChange = nav => {
    setNavigation({
      ...navigation,
      ...nav
    })
  }

  return (
    <MuiToolbar variant="dense" className={classes.toolbar}>
      {/* {props.toolbar.type === 'default' && <Default cornerRadius={props.cornerRadius} onRadiusUpdate={props.onRadiusUpdate} /> } */}
      {props.toolbar.type === 'text' &&
        <Text
          toolbarData={props.toolbar}
          navigation={navigation}
          onNavigationChange={handleNavigationChange} />
      }
      {['image', 'group', 'path', 'activeSelection'].includes(props.toolbar.type) &&
        <Group
          toolbarData={props.toolbar}
          navigation={navigation}
          onNavigationChange={handleNavigationChange} />
      }
    </MuiToolbar>
  )
}

Toolbar.propTypes = {
  toolbar: PropTypes.object.isRequired,
  // cornerRadius: PropTypes.number.isRequired,
  // onRadiusUpdate: PropTypes.func.isRequired
}

export default Toolbar
