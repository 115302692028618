import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useSpring, a, config } from 'react-spring'
import { useDrag } from 'react-use-gesture'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import { Button, IconButton } from '@material-ui/core'

import TextColorIcon from '../../../../../../icons/TextColorIcon'
import FormatBoldIcon from '@material-ui/icons/FormatBold'
import FormatItalicIcon from '@material-ui/icons/FormatItalic'
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined'
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter'
import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft'
import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify'
import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight'
import DuplicateIcon from '../../../../../../icons/DuplicateIcon'
import CopyStyleIcon from '../../../../../../icons/CopyStyleIcon'
import TrashIcon from '../../../../../../icons/TrashIcon'
import FontFamily from './FontFamily'
import FontSize from './FontSize'
import TextColor from './TextColor'
import Spacing from './Spacing'
import Position from './Position'

const useStyles = makeStyles(() => ({
  root: {
    overflowX: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
  },
  button: {
    flex: '0 0 auto',
    height: '30px',
    borderRadius: 0,
    '&.MuiButton-containedPrimary': {
      color: 'inherit',
      backgroundColor: '#edf6ff',
      boxShadow: 'none',
    }
  },
  iconButton: {
    height: '30px',
    paddingTop: '6px',
    paddingBottom: '6px',
    borderRadius: 0,
    '&.MuiButton-containedPrimary': {
      color: 'inherit',
      backgroundColor: '#edf6ff',
      boxShadow: 'none',
    }
  }
}))

const Text = ({ toolbarData, onAddAction, onCopiedStyleChange }) => {
  const classes = useStyles()
  const [subSection, setSubSection] = useState(null)
  const containerRef = useRef(null)
  const [{ y }, set] = useSpring(() => ({ y: 0 }))

  const toggleFontWeight = e => {
    onAddAction({
      type: 'text',
      property: 'fontWeight',
      value: (toolbarData.fontWeight === 'normal' ? 'bold' : 'normal')
    })
  }

  const toggleFontStyle = e => {
    onAddAction({
      type: 'text',
      property: 'fontStyle',
      value: (toolbarData.fontStyle === 'normal' ? 'italic' : 'normal')
    })
  }

  const toggleUnderline = e => {
    onAddAction({
      type: 'text',
      property: 'underline',
      value: (toolbarData.underline ? false : true)
    })
  }

  const handleAlignmentChange = () => {
    const nextAlignment = {
      center: 'left',
      left: 'justify',
      justify: 'right',
      right: 'center'
    }[toolbarData.textAlign]

    onAddAction({
      type: 'text',
      property: 'textAlign',
      value: nextAlignment
    })
  }

  const handleDuplicate = () => onAddAction({ type: 'duplicate' })

  const handleCopyStyle = () => {
    onCopiedStyleChange({
      charSpacing: toolbarData.charSpacing,
      fontFamily: toolbarData.fontFamily,
      fontSize: toolbarData.fontSize,
      fontStyle: toolbarData.fontStyle,
      fontWeight: toolbarData.fontWeight,
      lineHeight: toolbarData.lineHeight,
      underline: toolbarData.underline,
      textAlign: toolbarData.textAlign,
      fill: toolbarData.textColor,
      scaleX: toolbarData.scaleX,
      scaleY: toolbarData.scaleY,
    })
  }

  const handleRemoval = () => onAddAction({ type: 'remove' })

  const open = ({ canceled }) => {
    // when cancel is true, it means that the user passed the upwards threshold
    // so we change the spring config to create a nice wobbly effect
    set({
      y: 0,
      immediate: false,
      config: {
        ...(canceled ? config.wobbly : config.molasses),
        duration: 150
      }
    })
  }

  const close = (velocity = 0) => {
    set({
      y: containerRef.current.offsetHeight,
      immediate: false,
      config: {
        ...config.molasses,
        velocity,
        duration: 150
      }
    })
  }

  const bind = useDrag(
    ({ event, last, vxvy: [, vy], movement: [, my], cancel, canceled }) => {
      // if the user drags up passed a threshold, then we cancel
      // the drag so that the sheet resets to its open position
      if (my < 0) cancel()
      
      if (event.target.id === 'drag-handle' || event.target.closest('#drag-handle')) {
        // when the user releases the sheet, we check whether it passed
        // the threshold for it to close, or if we reset it to its open position
        if (last) {
          my > containerRef.current.offsetHeight * 0.2 || vy > 0.2 ? close(vy) : open({ canceled })
        }
        // when the user keeps dragging, we just move the sheet according to
        // the cursor position
        else set({ y: my, immediate: true })
      } else {
        cancel()
      }
    },
    { initial: () => [0, y.get()], filterTaps: true, bounds: { top: 0 }, rubberband: true }
  )

  return (
    <a.div
      ref={containerRef}
      {...bind()}
      style={{
        touchAction: 'pan-y',
        zIndex: 2,
        position: (subSection === null) ? 'relative' : 'absolute',
        width: '100%',
        bottom: 0,
        backgroundColor: '#fff',
        paddingBottom: '16px',
        y
      }}
    >
      <Box
        id="drag-handle"
        width={1}
        padding="2% 0"
        bgcolor="#a0a2a3"
        style={{
          display: subSection === null ? 'none' : ''
        }}
      >
        <Box
          width="20%"
          margin="0 auto 1%"
          pb="1%"
          bgcolor="#c7c7c8"
          borderRadius={5} />
        <Box
          width="20%"
          margin="0 auto"
          pb="1%"
          bgcolor="#c7c7c8"
          borderRadius={5} />
      </Box>
      <Box display="flex" alignItems="center" width={1} p={2} className={classes.root}>
        <Button
          size="small"
          variant={subSection === 'font-family' ? 'contained' : 'text'}
          color={subSection === 'font-family' ? 'primary' : 'default'}
          aria-label="font family"
          className={classes.button}
          onClick={() => setSubSection('font-family')}
        >
          {toolbarData.fontFamily}
        </Button>
        <Button
          size="small"
          variant={subSection === 'font-size' ? 'contained' : 'text'}
          color={subSection === 'font-size' ? 'primary' : 'default'}
          aria-label="font size"
          className={classes.button}
          onClick={() => setSubSection('font-size')}
        >
          {toolbarData.fontSize} pt
        </Button>
        <IconButton
          aria-label="Text Color"
          className={classes.iconButton}
          onClick={() => setSubSection('text-color')}
        >
          <TextColorIcon fontSize="small" />
        </IconButton>
        <IconButton
          color={toolbarData.fontWeight === 'bold' ? 'primary' : 'default'}
          onClick={() => toggleFontWeight()}
          aria-label="bold"
          className={classes.iconButton}
        >
          <FormatBoldIcon fontSize="small" />
        </IconButton>
        <IconButton
          color={toolbarData.fontStyle === 'italic' ? 'primary' : 'default'}
          onClick={() => toggleFontStyle()}
          aria-label="Italics"
          className={classes.iconButton}
        >
          <FormatItalicIcon fontSize="small" />
        </IconButton>
        <IconButton
          color={toolbarData.underline ? 'primary' : 'default'}
          onClick={() => toggleUnderline()}
          aria-label="Underline"
          className={classes.iconButton}
        >
          <FormatUnderlinedIcon fontSize="small" />
        </IconButton>
        <IconButton
          onClick={() => handleAlignmentChange()}
          aria-label="Alignment"
          className={classes.iconButton}
        >
          {
            {
              'center': <FormatAlignCenterIcon fontSize="small" />,
              'left': <FormatAlignLeftIcon fontSize="small" />,
              'justify': <FormatAlignJustifyIcon fontSize="small" />,
              'right': <FormatAlignRightIcon fontSize="small" />
            }[toolbarData.textAlign]
          }
        </IconButton>
        <Button
          size="small"
          variant={subSection === 'spacing' ? 'contained' : 'text'}
          color={subSection === 'spacing' ? 'primary' : 'default'}
          aria-label="spacing"
          className={classes.button}
          onClick={() => setSubSection('spacing')}
        >
        Spacing
        </Button>
        <Button
          size="small"
          variant={subSection === 'position' ? 'contained' : 'text'}
          color={subSection === 'position' ? 'primary' : 'default'}
          aria-label="position"
          aria-describedby="positioning-controls"
          className={classes.button}
          onClick={() => setSubSection('position')}
        >
        Position
        </Button>
        <IconButton
          aria-label="Duplicate"
          className={classes.iconButton}
          onClick={() => handleDuplicate()}
        >
          <DuplicateIcon fontSize="small" />
        </IconButton>
        <IconButton
          aria-label="Copy Style"
          className={classes.iconButton}
          onClick={handleCopyStyle}
        >
          <CopyStyleIcon fontSize="small" />
        </IconButton>
        <IconButton
          aria-label="Delete"
          className={classes.iconButton}
          onClick={handleRemoval}
        >
          <TrashIcon fontSize="small" />
        </IconButton>
      </Box>
      {subSection === 'font-family' ?
        <FontFamily family={toolbarData.fontFamily} onAddAction={onAddAction} />
        : null
      }
      {subSection === 'font-size' ?
        <FontSize fontSize={toolbarData.fontSize} onAddAction={onAddAction} />
        : null
      }
      {subSection === 'text-color' ?
        <TextColor textColor={toolbarData.textColor} onAddAction={onAddAction} />
        : null
      }
      {subSection === 'spacing' ?
        <Spacing
          charSpacing={toolbarData.charSpacing}
          lineHeight={toolbarData.lineHeight}
          onAddAction={onAddAction}
        />
        : null
      }
      {subSection === 'position' ?
        <Position onAddAction={onAddAction} />
        : null
      }
    </a.div>
  )
}

Text.propTypes =  {
  toolbarData: PropTypes.object.isRequired,
  onAddAction: PropTypes.func.isRequired,
  onCopiedStyleChange: PropTypes.func.isRequired,
}

export default Text
