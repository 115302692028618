import React, {useContext, useState, useEffect} from 'react'
import {useHistory, useLocation, useParams} from 'react-router-dom'
import {makeStyles, Typography} from '@material-ui/core'
import HttpClient from '../../../../services/HttpClient'
import AuthContext from '../../../../contexts/AuthContext'
import {Box} from '@material-ui/core'
import {isEmpty} from 'lodash/fp'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import {
  getCategoryTypeKey,
  CustomCategoryTitle,
  getCategoryTypeValue,
} from './Common'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles(() => ({
  root: {
    padding: '27px',
    backgroundColor: '#fff',
  },
  cardDesign: {
    fontSize: '34px',
    fontWeight: '500',
    marginTop: '7px',
    marginBottom: '20px',
  },
  headings: {
    fontSize: '1.2500em',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: '8px',
    color: '#4A4A4A',
  },
  images: {
    display: 'block',
    height: '50%',
    marginLeft: 'auto',
    borderRadius: '10px',
    marginBottom: '10px',
    marginRight: 'auto',
    width: '43%',
  },
  svgBox: {
    display: 'flex',
    marginRight: 'auto',
    marginLeft: 'auto',
    justifyContent: 'center',
    '& svg': {
      height: '50%',
      borderRadius: '10px',
      marginBottom: '10px',
      width: '43%',
    },
  },
  button: {
    color: '#A9A9A9',
    height: '25px',
    marginLeft: '-11px',
    width: '25px',
  },
  flex: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  box: {
    background: '#E8E8E8',
    height: '100vh',
    width: '200wh',
  },
}))

const Dashboard = () => {
  const [featured, setFeatured] = useState([])
  const [trending, setTrending] = useState([])
  const [authData] = useContext(AuthContext)
  const [loading, setLoading] = useState(false)
  const [card, setCard] = useState({})
  const [simple, setKeepItSimple] = useState([])
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const {cardId} = useParams()

  const handleHome = () => {
    history.push(
      `${
        location.pathname
      }/designs?dashboard=true&timestamp=${new Date().toISOString()}`
    )
  }

  const handleClose = () => {
    if (window.Print !== undefined)
      card.status === 2
        ? window.Print.postMessage('close-publish')
        : window.Print.postMessage('close-draft')
  }

  useEffect(() => {
    setLoading(true)
    Promise.all([
      HttpClient.get(
        `/templates/published?${getCategoryTypeValue(1)}&categoryTitle=Feature your photo`,
        {Authorization: `Bearer ${authData.authToken}`}
      ),
      HttpClient.get(
        `/templates/published?categoryTitle=${
          CustomCategoryTitle.keepItSimple
        }&categoryType=${getCategoryTypeKey('Styles')}`,
        {
          Authorization: `Bearer ${authData.authToken}`,
        }
      ),
      HttpClient.get(
        `/templates/published?${getCategoryTypeValue(6)}&categoryTitle=Trending`,
        {Authorization: `Bearer ${authData.authToken}`}
      ),
    ])
      .then(
        ([resFeatureTemplates, resSimpleTemplates, resTrendingTemplates]) => {
          setFeatured(resFeatureTemplates)
          setKeepItSimple(resSimpleTemplates)
          setTrending(resTrendingTemplates)
          setLoading(false)
        }
      )
      .catch((error) => {
        console.error('Error getting templates: ', error)
      })
  }, [authData.authToken])

  const handleKeepItSimple = () => {
    localStorage.setItem('cardpreview', card.previewUrl)
    history.push(
      `${location.pathname}/keepitsimple?timestamp=${new Date().toISOString()}`
    )
  }

  const handleMyImageDesign = () => {
    history.push(
      `${location.pathname}/featured?timestamp=${new Date().toISOString()}`
    )
  }

  useEffect(() => {
    HttpClient.get(`/cards/${cardId}`, {
      Authorization: `Bearer ${authData.authToken}`,
    })
      .then((resCard) => {
        setCard(resCard)
      })
      .catch((error) => {
        console.error('Error getting industries: ', error)
        setCard([])
      })
  }, [authData.authToken])

  return (
    <>
      {loading ? (
        <Box className={`${classes.flex} + ${classes.box}`}>
          <Box className={classes.flex}>
            <CircularProgress color="inherit" size="20px" />
          </Box>
        </Box>
      ) : (
        <Box className={classes.root}>
          <ArrowBackIosIcon
            onClick={() => handleClose()}
            className={classes.button}
          />
          <Typography className={classes.cardDesign}>Card Design</Typography>
          <br />

          <Box>
            <Typography className={classes.headings}>Keep it simple</Typography>
            <Box>
              {simple &&
                !isEmpty(simple) &&
                simple[0].vertical_preview_image_path && (
                  <img
                    className={classes.images}
                    onClick={() => handleKeepItSimple(true)}
                    src={simple[0].vertical_preview_image_path}
                    alt={`${simple[0].vertical_preview_image_path}`}
                  ></img>
                )}
              <br />
            </Box>
          </Box>
          <Typography className={classes.headings}>
            Feature your photo
          </Typography>
          <Box>
            {featured &&
              !isEmpty(featured) &&
              featured[0].vertical_preview_image_path && (
                <img
                  className={classes.images}
                  onClick={() => handleMyImageDesign(true)}
                  src={featured[0].vertical_preview_image_path}
                  alt={`${featured[0].vertical_preview_image_path}`}
                ></img>
              )}
            <br />
          </Box>
          <Box>
            <Typography className={classes.headings}>
              {' '}
              See all designs
            </Typography>
            <Box>
              {trending &&
                !isEmpty(trending) &&
                trending[0].vertical_preview_image_path && (
                  <img
                    className={classes.images}
                    onClick={() => handleHome(true)}
                    src={trending[0].vertical_preview_image_path}
                    alt={`${trending[0].vertical_preview_image_path}`}
                  ></img>
                )}
              <br />
            </Box>
          </Box>
        </Box>
      )}
    </>
  )
}

export default Dashboard
