import React, { useState, useEffect, useContext } from 'react'

import { Box, Typography } from '@material-ui/core'

import HttpClient from '../../../../services/HttpClient'
import AuthContext from '../../../../contexts/AuthContext'
import DataField from './DataField'

const DataFields = () => {
  const [authData] = useContext(AuthContext)
  const [fields, setFields] = useState([])

  useEffect(() => {
    HttpClient.get('/data_fields', { Authorization: `Bearer ${authData.authToken}` })
      .then(res => setFields(groupByCategory(res)))
      .catch(error => console.log(error))
  }, [authData.authToken])

  const groupByCategory = fields => {
    return fields.reduce((r, a) => {
      if( /Phone|Email/.test(a.category) ) {
        a.value = `${a.category} ${a.name}`
      } else {
        a.value = a.name
      }
      
      r[a.category] = r[a.category] || []
      r[a.category].push(a)
      return r
    }, Object.create(null))
  }

  return (
    <React.Fragment>
      <Box m={1}>
        <DataField dataField={{ name: 'Text (without db link)', value: 'Text' }} />
      </Box>
      {Object.keys(fields).map((category, i) => (
        <Box mx={1} my={3} key={i}>
          <Typography variant="subtitle2">{category}</Typography>
          {fields[category].map((field, index) => (
            <Box my={1} key={index}>
              <DataField dataField={field} />
            </Box>
          ))}
        </Box>
      ))}
    </React.Fragment>
  )
}

export default DataFields
