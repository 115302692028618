import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useSpring, a, config } from 'react-spring'
import { useDrag } from 'react-use-gesture'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import { Button, IconButton } from '@material-ui/core'
import { Menu, MenuItem, ListItemIcon } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import DuplicateIcon from '../../../../../icons/DuplicateIcon'
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser'
import CropIcon from '@material-ui/icons/Crop'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'

import SquareIcon from '../../../../../icons/SquareIcon'
import CopyStyleIcon from '../../../../../icons/CopyStyleIcon'
import GroupIcon from '../../../../../icons/GroupIcon'
import UnGroupIcon from '../../../../../icons/UnGroupIcon'
import TrashIcon from '../../../../../icons/TrashIcon'
import TextColor from './Text/TextColor'
import Position from './Text/Position'

const useStyles = makeStyles(() => ({
  root: {
    overflowX: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
  },
  button: {
    flex: '0 0 auto',
    height: '30px',
    borderRadius: 0,
    '&.MuiButton-containedPrimary': {
      color: 'inherit',
      backgroundColor: '#edf6ff',
      boxShadow: 'none',
    }
  },
  iconButton: {
    height: '30px',
    paddingTop: '6px',
    paddingBottom: '6px',
    borderRadius: 0,
    '&.MuiButton-containedPrimary': {
      color: 'inherit',
      backgroundColor: '#edf6ff',
      boxShadow: 'none',
    }
  }
}))

const Group = ({ toolbarData, onAddAction }) => {
  const classes = useStyles()
  const [flipEl, setFlipEl] = useState(null)
  const [subSection, setSubSection] = useState(null)
  const containerRef = useRef(null)
  const [{ y }, set] = useSpring(() => ({ y: 0 }))

  const handleOpenFlipMenu = e => setFlipEl(e.currentTarget)
  const handleCloseFlipMenu = () => setFlipEl(null)

  const handleFlipChange = direction => {
    setFlipEl(null)
    onAddAction({
      type: 'flip', property: direction, value: !toolbarData[direction]
    })
  }

  const handleDuplicate = () => onAddAction({ type: 'duplicate' })

  const handleUnGrouping = () => onAddAction({ type: 'ungroup' })

  const handleGrouping = () => onAddAction({ type: 'group' })

  const toggleLocking = () => onAddAction({ type: 'toggleLock' })

  const handleRemoval = () => onAddAction({ type: 'remove' })

  const open = ({ canceled }) => {
    // when cancel is true, it means that the user passed the upwards threshold
    // so we change the spring config to create a nice wobbly effect
    set({
      y: 0,
      immediate: false,
      config: {
        ...(canceled ? config.wobbly : config.molasses),
        duration: 150
      }
    })
  }

  const close = (velocity = 0) => {
    set({
      y: containerRef.current.offsetHeight,
      immediate: false,
      config: {
        ...config.molasses,
        velocity,
        duration: 150
      }
    })
  }

  const bind = useDrag(
    ({ event, last, vxvy: [, vy], movement: [, my], cancel, canceled }) => {
      // if the user drags up passed a threshold, then we cancel
      // the drag so that the sheet resets to its open position
      if (my < 0) cancel()
      
      if (event.target.id === 'drag-handle' || event.target.closest('#drag-handle')) {
        // when the user releases the sheet, we check whether it passed
        // the threshold for it to close, or if we reset it to its open position
        if (last) {
          my > containerRef.current.offsetHeight * 0.2 || vy > 0.2 ? close(vy) : open({ canceled })
        }
        // when the user keeps dragging, we just move the sheet according to
        // the cursor position
        else set({ y: my, immediate: true })
      } else {
        cancel()
      }
    },
    { initial: () => [0, y.get()], filterTaps: true, bounds: { top: 0 }, rubberband: true }
  )

  return (
    <a.div
      ref={containerRef}
      {...bind()}
      style={{
        touchAction: 'pan-y',
        zIndex: 2,
        position: (subSection === null) ? 'relative' : 'absolute',
        width: '100%',
        bottom: 0,
        backgroundColor: '#fff',
        paddingBottom: '16px',
        y
      }}
    >
      <Box
        id="drag-handle"
        width={1}
        padding="2% 0"
        bgcolor="#a0a2a3"
        style={{
          display: subSection === null ? 'none' : ''
        }}
      >
        <Box
          width="20%"
          margin="0 auto 1%"
          pb="1%"
          bgcolor="#c7c7c8"
          borderRadius={5} />
        <Box
          width="20%"
          margin="0 auto"
          pb="1%"
          bgcolor="#c7c7c8"
          borderRadius={5} />
      </Box>
      <Box display="flex" alignItems="center" width={1} p={2} className={classes.root}>
        {toolbarData.lockMovementX !== true ?
          <>
            {(toolbarData.type === 'path' || toolbarData.type === 'group') ? 
              <IconButton
                size="small"
                aria-label="Color"
                className={classes.iconButton}
                style={{
                  color: `${toolbarData.color}`
                }}
                onClick={() => setSubSection('color')}
              >
                <SquareIcon />
              </IconButton>
              : null
            }
            <Button
              size="small"
              aria-label="Flip"
              aria-controls="flip-menu"
              aria-haspopup="true"
              className={classes.button}
              onClick={handleOpenFlipMenu}
            >
              Flip
            </Button>
            <Menu
              id="flip-menu"
              anchorEl={flipEl}
              keepMounted
              getContentAnchorEl={null}
              open={Boolean(flipEl)}
              onClose={handleCloseFlipMenu}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <MenuItem onClick={() => handleFlipChange('flipX')}>
                <ListItemIcon>
                  <ExitToAppIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">Flip horizontal</Typography>
              </MenuItem>
              <MenuItem onClick={() => handleFlipChange('flipY')}>
                <ListItemIcon>
                  <OpenInBrowserIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">Flip vertical</Typography>
              </MenuItem>
            </Menu>
            <Button
              size="small"
              variant={subSection === 'position' ? 'contained' : 'text'}
              color={subSection === 'position' ? 'primary' : 'default'}
              aria-label="position"
              aria-describedby="positioning-controls"
              className={classes.button}
              onClick={() => setSubSection('position')}
            >
          Position
            </Button>
            <IconButton
              aria-label="Duplicate"
              onClick={() => handleDuplicate()}
              className={classes.iconButton}
            >
              <DuplicateIcon fontSize="small" />
            </IconButton>
            {toolbarData.type === 'group' && 
          <IconButton
            aria-label="Ungroup"
            onClick={() => handleUnGrouping()}
            className={classes.iconButton}
          >
            <UnGroupIcon fontSize="small" />
          </IconButton>
            }
            {toolbarData.type === 'activeSelection' && 
          <IconButton
            aria-label="Group"
            onClick={() => handleGrouping()}
            className={classes.iconButton}
          >
            <GroupIcon fontSize="small" />
          </IconButton>
            }
            <IconButton
              aria-label="Crop"
              className={classes.iconButton}
            >
              <CropIcon fontSize="small" />
            </IconButton>
            <IconButton
              aria-label="Copy Style"
              className={classes.iconButton}
            >
              <CopyStyleIcon fontSize="small" />
            </IconButton>
          </>
          : null}
        <IconButton
          aria-label="Toggle Movement"
          onClick={toggleLocking}
          className={classes.iconButton}
        >
          {toolbarData.lockMovementX ? <LockOpenIcon fontSize="small" /> : <LockOutlinedIcon fontSize="small" />}
        </IconButton>
        {toolbarData.lockMovementX !== true ?
          <IconButton
            aria-label="Delete"
            onClick={handleRemoval}
            className={classes.iconButton}
          >
            <TrashIcon fontSize="small" />
          </IconButton>
          : null}
      </Box>
      {subSection === 'color' ?
        <TextColor textColor={toolbarData.textColor} onAddAction={onAddAction} />
        : null
      }
      {subSection === 'position' ?
        <Position onAddAction={onAddAction} />
        : null
      }
    </a.div>
  )
}

Group.propTypes =  {
  toolbarData: PropTypes.object.isRequired,
  onAddAction: PropTypes.func.isRequired,
}

export default Group
