import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import { Box, Menu, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import TemplatesIcon from '../../../icons/TemplatesIcon'
import TextStylingsIcon from '../../../icons/TextStylingsIcon'
import PhoneIcon from '@material-ui/icons/Phone'
import SocialMediaIcon from '../../../icons/SocialMediaIcon'
import BusinessIcon from '@material-ui/icons/Business'
import Typography from "@material-ui/core/Typography";
import LogoIcon from '../../../icons/LogoIcon'

const useStyles = makeStyles(theme => ({
  logo: {
    verticalAlign: 'middle',
    fontSize: '2.7rem'
  },
  menus: {
    flexGrow: 1,
  },
  menuItem: {
    display: 'inline-block'
  },
  listIcon: {
    minWidth: '35px'
  },
  live: {
    color: "#49AEDD",
  },
  contact:{
    color:'#29406B',
  },
  title: {
    color: "#0E1F4A",
    font: "lato",
    fontSize: "35px",
    lineHeight: 1.2,
    fontWeight: 900,
    alignSelf: "center",
    textAlign: "center",
  },
  button: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    fontSize: '1.125rem'
  }
}))

const NavTabs = ({ onOpenNewTemplateDialog, onCreateTextStyling }) => {
  const classes = useStyles()
  const [registerEl, setRegisterEl] = useState(null)
  const [editEl, setEditEl] = useState(null)

  const isRegisterMenuOpen = Boolean(registerEl)
  const isEditMenuOpen = Boolean(editEl)

  const handleRegisterMenuOpen = event => setRegisterEl(event.currentTarget)
  const handleRegisterMenuClose = () => setRegisterEl(null)

  const handleEditMenuOpen = event => setEditEl(event.currentTarget)
  const handleEditMenuClose = () => setEditEl(null)

  const handleOpenNewTemplateDialog = () => {
    onOpenNewTemplateDialog()
    handleRegisterMenuClose()
  }

  const handleCreateTextStyling = () => {
    onCreateTextStyling()
    handleRegisterMenuClose()
  }

  return (
    <div className={classes.menus}>
      <MenuItem component={Link} to="/designer" className={classes.menuItem}>
             <Typography className={classes.title}>
            <span className={classes.live}> Live</span>
            <span className={classes.contact}>Contact</span> 
          </Typography>
      </MenuItem>
       <Button
        aria-controls="templates-menu"
        onClick={handleRegisterMenuOpen}
        endIcon={<KeyboardArrowDownIcon />}
        className={classes.button}
      >New</Button>
      {/* <Button
        aria-controls="discover-menu"
        // onClick={handleEditMenuOpen}
        endIcon={<KeyboardArrowDownIcon />}
        className={classes.button}
      >Discover</Button>
      <Button
        aria-controls="learn-menu"
        endIcon={<KeyboardArrowDownIcon />}
        className={classes.button}
      >Learn</Button>
      <Button
        aria-controls="help-menu"
        endIcon={<KeyboardArrowDownIcon />}
        className={classes.button}
      >Help</Button> */}
      <Menu
        anchorEl={registerEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        id="templates-menu"
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        open={isRegisterMenuOpen}
        onClose={handleRegisterMenuClose}
      >
        <MenuItem onClick={handleOpenNewTemplateDialog}>
          <ListItemIcon className={classes.listIcon}>
            <TemplatesIcon />
          </ListItemIcon>
          <ListItemText primary="Card Template" />
        </MenuItem>
        <MenuItem onClick={handleCreateTextStyling}>
          <ListItemIcon className={classes.listIcon}>
            <TextStylingsIcon />
          </ListItemIcon>
          <ListItemText primary="Text Styling" />
        </MenuItem>
        <MenuItem component={Link} to="/designer/active_icon" onClick={handleRegisterMenuClose}>
          <ListItemIcon className={classes.listIcon}>
            <PhoneIcon />
          </ListItemIcon>
          <ListItemText primary="Active Icon" />
        </MenuItem>
        <MenuItem component={Link} to="/designer/social_media" onClick={handleRegisterMenuClose}>
          <ListItemIcon className={classes.listIcon}>
            <SocialMediaIcon />
          </ListItemIcon>
          <ListItemText primary="Social Media Icon" />
        </MenuItem>
        {/* <MenuItem component={Link} to="/designer/corporate" onClick={handleRegisterMenuClose}>
          <ListItemIcon className={classes.listIcon}>
            <BusinessIcon />
          </ListItemIcon>
          <ListItemText primary="Corporate Project" />
        </MenuItem> */}
      </Menu>
      <Menu
        anchorEl={editEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        id="discover-menu"
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        open={isEditMenuOpen}
        onClose={handleEditMenuClose}
      >
        <MenuItem component={Link} to="/discover" onClick={handleEditMenuClose}>
          Discover
        </MenuItem>
      </Menu>
    </div>
  )
}

NavTabs.propTypes = {
  onOpenNewTemplateDialog: PropTypes.func.isRequired,
  onCreateTextStyling: PropTypes.func.isRequired,
}

export default NavTabs
