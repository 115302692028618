import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const DuplicateIcon = props => {
  return (
    <SvgIcon {...props}>
      <path d="M5.54 3.43c-1.07 0-1.96.9-1.96 2.04l.13 11.08c0 .58.18 1.11.55 1.49.52.6 1.2.62 1.35.62H7.8v-.72H5.61c-.15 0-.47.02-.81-.39l-.01-.01-.01-.01c-.21-.2-.33-.54-.33-.98L4.3 5.47c0-.76.57-1.32 1.24-1.32h10.79c.67 0 1.24.56 1.24 1.32 0 .54.04 1 .01 1.16l.71.16c.1-.42 0-.84 0-1.32 0-1.14-.89-2.04-1.96-2.04H5.54z" />
      <path d="M7.51 8.17c0-.95.66-1.6 1.46-1.6h10.21c.8 0 1.46.73 1.46 1.6v10.72c0 .95-.66 1.6-1.46 1.6H9.11c-.8 0-1.46-.73-1.46-1.6L7.51 8.17z" fill="none" stroke="currentColor" strokeWidth=".729" strokeMiterlimit="10"/>
      <path d="M14.73 9.99v3.06h2.99v.81h-2.99v3.13h-.88v-3.13h-2.91v-.81h2.99V9.99z"/>
    </SvgIcon>
  )
}

export default DuplicateIcon
