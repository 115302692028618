import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const SquareIcon = props => {
  return (
    <SvgIcon {...props}>
      <rect x="0" y="0" rx="4" ry="4" width="24" height="24" />
    </SvgIcon>
  )
}

export default SquareIcon
