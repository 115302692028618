import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Name from './Name'
import Industries from './Industries'
import Styles from './Styles'
import Final from './Final'

const Publish = ({ template, onTemplateChange }) => {
  const [step, setStep] = useState(1)
  const [categories, setCategories] = useState([])
  const [toFinalStep, setToFinalStep] = useState(false)

  const handleNameChange = name => {
    if(toFinalStep) {
      setStep(4)
    } else {
      setStep(2)
    }
    onTemplateChange({ name })
  }

  const handleStep3Completion = () => {
    setToFinalStep(true)
    setStep(4)
  }

  return (
    <React.Fragment>
      {step === 1 ?
        <Name template={template} onNameChange={handleNameChange} />
        : null
      }
      {step === 2 ?
        <Industries
          template={template}
          onTemplateChange={onTemplateChange}
          categories={categories}
          onCategoriesChange={setCategories}
          onDone={() => toFinalStep ? setStep(4) : setStep(3)}
        />
        : null
      }
      {step === 3 ?
        <Styles
          template={template}
          onTemplateChange={onTemplateChange}
          categories={categories}
          onCategoriesChange={setCategories}
          onDone={handleStep3Completion}
        />
        : null
      }
      {step === 4 ?
        <Final
          template={template}
          categories={categories}
          onNameEdit={() => setStep(1)}
          onIndustriesEdit={() => setStep(2)}
          onStylesEdit={() => setStep(3)}
        />
        : null
      }
    </React.Fragment>
  )
}

Publish.propTypes = {
  template: PropTypes.object.isRequired,
  onTemplateChange: PropTypes.func.isRequired
}

export default Publish
