import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const CenterAlignIcon = props => {
  return (
    <SvgIcon {...props}>
      <path d="M3.8 11.4v1H21v-1H3.8zm10-5.4v11.6h4V6h-4zM7.2 8.6V15h4V8.6h-4z"/>
    </SvgIcon>
  )
}

export default CenterAlignIcon
