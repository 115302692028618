import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'

import HttpClient from '../../../../services/HttpClient'
import AuthContext from '../../../../contexts/AuthContext'
import CategoryHeading from './CategoryHeading'
import DummySkeleton from './DummySkeleton'
import TemplatesSection from './TemplatesSection'
import { toUpperCase } from './Common'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const useStyles = makeStyles(() => ({
  scroller: {
    overflowX: 'auto',
    overflowY: 'hidden',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    paddingTop: '10px',
  },
  svg: {
    '& svg': {
      maxWidth: '100%',
      maxHeight: '100%',
      borderRadius: 'inherit',
    }, 
  },
}))


const SubCategory = ({ category, cardId }) => {
  const classes = useStyles()
  const [authData] = useContext(AuthContext)
  const [loading, setLoading] = useState(true)
  const [templates, setTemplates] = useState(true)
  const history = useHistory()
  const notify = message => toast(message);
  

  useEffect(() => {
    setLoading(true)
    HttpClient.get(`/templates/published?categoryType=${category.type}&categoryId=${category.id}`, { Authorization: `Bearer ${authData.authToken}` })
      .then(resTemplates => {
        setTemplates(resTemplates)
        setLoading(false)
      })
      .catch(error => {
        console.log('Error getting industries: ', error)
        setTemplates([])
        setLoading(false)
      })
  }, [authData.authToken, category])

  const handleCreateCard = (templateId, formatType = 2, categoryId, categoryType) => {
    HttpClient.post(
      '/cards',
      { templateId: templateId, formatType: parseInt(formatType) },
      { Authorization: `Bearer ${authData.authToken}` }
    )
      .then(res => {
        history.push(`/user/cards/${res.id}/design?dashboard=true&timestamp=${new Date().toISOString()}`)
      })
      .catch(error => {
        if (error.response.status === 424) {
          error.response.text().then(response => {
            notify(JSON.parse(response).error.message)
          })
        } else notify('No Side with the selected template format.')
        console.error('Error creating card: ', error)
      })
  }

  return (
    <>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {!loading &&
      <Box py={2} borderBottom="1px solid #d8d8d8">
        <CategoryHeading
          title={toUpperCase(category.name)}
          to={`/user/cards/new/${cardId}/subcategory?id=${category.id}&type=${category.type}&format=${category.format}`}
        />
                  
        <Box display="flex" width={1} px={1} className={classes.scroller}>
          {loading ? 
            <DummySkeleton/>
            :
            <TemplatesSection templates={templates} showTitles={false} type='list' format={category.format}
              onClick={(templateId, format, categoryId, categoryType) => handleCreateCard(templateId, format, categoryId, categoryType)} />
          }
        </Box>
      </Box>
      }
    </>
  )
}

SubCategory.propTypes = {
  category: PropTypes.object.isRequired,
  cardId: PropTypes.string.isRequired
}

export default SubCategory
