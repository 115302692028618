import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'

import { Grid, Box } from '@material-ui/core'
import { Button, IconButton, Tooltip } from '@material-ui/core'
import { Menu, MenuItem, ListItemIcon } from '@material-ui/core'
import { Popover, Paper, Divider } from '@material-ui/core'
import SquareIcon from '../../../../../icons/SquareIcon'
import DuplicateIcon from '../../../../../icons/DuplicateIcon'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser'
import CopyStyleIcon from '../../../../../icons/CopyStyleIcon'
import GroupIcon from '../../../../../icons/GroupIcon'
import UnGroupIcon from '../../../../../icons/UnGroupIcon'
import TrashIcon from '../../../../../icons/TrashIcon'
import ForwardIcon from '../../../../../icons/ForwardIcon'
import BackwardIcon from '../../../../../icons/BackwardIcon'
import TopAlignIcon from '../../../../../icons/TopAlignIcon'
import LeftAlignIcon from '../../../../../icons/LeftAlignIcon'
import CenterAlignIcon from '../../../../../icons/CenterAlignIcon'
import MiddleAlignIcon from '../../../../../icons/MiddleAlignIcon'
import BottomAlignIcon from '../../../../../icons/BottomAlignIcon'
import RightAlignIcon from '../../../../../icons/RightAlignIcon'
import Typography from '@material-ui/core/Typography'

import { ActionContext } from '../../../../../contexts/ActionContext'

const Group = ({ toolbarData, onNavigationChange }) => {
  const [action, setAction] = useContext(ActionContext)
  const [flipEl, setFlipEl] = useState(null)
  const [positioningEl, setPositioningEl] = useState(null)

  const handleOpenFlipMenu = e => {
    setFlipEl(e.currentTarget)
  }

  const handleCloseFlipMenu = () => {
    setFlipEl(null)
  }

  const handleFlipChange = direction => {
    setFlipEl(null)
    setAction({
      type: 'flip',
      property: direction,
      value: !toolbarData[direction]
    })
  }

  const handleOpenPositioningDialog = e => {
    setPositioningEl(e.currentTarget)
  }

  const handleClosePositioningDialog = () => {
    setPositioningEl(null)
  }

  const handleVerticalAlignmentChange = position => {
    setAction({
      type: 'alignment',
      value: position
    })
  }

  const handleHorizontalAlignmentChange = position => {
    setAction({
      type: 'alignment',
      value: position
    })
  }

  const handleLayering = direction => {
    setAction({
      type: 'alignment',
      value: direction
    })
  }

  const handleDuplicate = () => {
    setAction({ type: 'duplicate' })
  }

  const handleUnGrouping = () => {
    setAction({ type: 'ungroup' })
  }

  const handleGrouping = () => {
    setAction({ type: 'group' })
  }

  const handleRemoval = () => {
    setAction({ type: 'remove' })
  }

  const currentAlignment = () => {
    return action && action.value
  }

  return (
    <Grid container spacing={0}>
      <Grid container item sm={4} alignItems="center">
        {['group', 'path', 'circle', 'polygon', 'rect'].includes(toolbarData.type) && 
          <Tooltip title="Color" arrow>
            <IconButton
              size="small"
              aria-label="Color"
              style={{
                color: `${toolbarData.color}`
              }}
              onClick={() => onNavigationChange({ activePanel: 1, color: toolbarData.color })}
            >
              <SquareIcon />
            </IconButton>
          </Tooltip>
        }
        <Button
          size="small"
          aria-label="Flip"
          aria-controls="flip-menu"
          aria-haspopup="true"
          onClick={handleOpenFlipMenu}
        >
          Flip
        </Button>
        <Menu
          id="flip-menu"
          anchorEl={flipEl}
          keepMounted
          getContentAnchorEl={null}
          open={Boolean(flipEl)}
          onClose={handleCloseFlipMenu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuItem onClick={() => handleFlipChange('flipX')}>
            <ListItemIcon>
              <ExitToAppIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Flip horizontal</Typography>
          </MenuItem>
          <MenuItem onClick={() => handleFlipChange('flipY')}>
            <ListItemIcon>
              <OpenInBrowserIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Flip vertical</Typography>
          </MenuItem>
        </Menu>
        <Button
          size="small"
          aria-label="Position"
          aria-describedby="positioning-controls"
          onClick={handleOpenPositioningDialog}
        >
          Position
        </Button>
        <Popover
          id="positioning-controls"
          open={Boolean(positioningEl)}
          anchorEl={positioningEl}
          onClose={handleClosePositioningDialog}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Paper variant="outlined">
            <Box m={1.5}>
              <Box my={1} mr={3}>
                <Grid container spacing={3}>
                  <Grid item sm={6}>
                    <Button
                      size="small"
                      aria-label="Forward"
                      onClick={() => handleLayering('forward')}
                      startIcon={
                        <ForwardIcon fontSize="small" />
                      }
                    >
                    Forward
                    </Button>
                  </Grid>
                  <Grid item sm={6}>
                    <Button
                      size="small"
                      aria-label="Backward"
                      onClick={() => handleLayering('backward')}
                      startIcon={
                        <BackwardIcon fontSize="small" />
                      }
                    >
                    Backward
                    </Button>
                  </Grid>
                </Grid>
              </Box>
              <Divider />
              <Box mx={0.5} mt={1} mb={0.6}>
                <Typography variant="caption" display="block" gutterBottom>Align to page</Typography>
              </Box>
              <Grid container spacing={1}>
                <Grid item sm={6}>
                  <Button
                    size="small"
                    aria-label="Top"
                    onClick={() => handleVerticalAlignmentChange('top')}
                    disabled={currentAlignment() === 'top'}
                    startIcon={
                      <TopAlignIcon fontSize="small" />
                    }
                  >
                    Top
                  </Button>
                </Grid>
                <Grid item sm={6}>
                  <Button
                    size="small"
                    aria-label="Left"
                    onClick={() => handleHorizontalAlignmentChange('left')}
                    disabled={currentAlignment() === 'left'}
                    startIcon={
                      <LeftAlignIcon fontSize="small" />
                    }
                  >
                    Left
                  </Button>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item sm={6}>
                  <Button
                    size="small"
                    aria-label="Middle"
                    onClick={() => handleVerticalAlignmentChange('middle')}
                    disabled={currentAlignment() === 'middle'}
                    startIcon={
                      <CenterAlignIcon fontSize="small" />
                    }
                  >
                    Middle
                  </Button>
                </Grid>
                <Grid item sm={6}>
                  <Button
                    size="small"
                    aria-label="Center"
                    onClick={() => handleHorizontalAlignmentChange('center')}
                    disabled={currentAlignment() === 'center'}
                    startIcon={
                      <MiddleAlignIcon fontSize="small" />
                    }
                  >
                    Center
                  </Button>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item sm={6}>
                  <Button
                    size="small"
                    aria-label="Bottom"
                    onClick={() => handleVerticalAlignmentChange('bottom')}
                    disabled={currentAlignment() === 'bottom'}
                    startIcon={
                      <BottomAlignIcon fontSize="small" />
                    }
                  >
                    Bottom
                  </Button>
                </Grid>
                <Grid item sm={6}>
                  <Button
                    size="small"
                    aria-label="Right"
                    onClick={() => handleHorizontalAlignmentChange('right')}
                    disabled={currentAlignment() === 'right'}
                    startIcon={
                      <RightAlignIcon fontSize="small" />
                    }
                  >
                    Right
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Popover>
      </Grid>
      <Grid container item sm={4} justify="center">
        <Tooltip title="Duplicate" arrow>
          <IconButton
            onClick={() => handleDuplicate()}
            aria-label="Duplicate"
          >
            <DuplicateIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        {toolbarData.type === 'group' && 
          <Tooltip title="Ungroup" arrow>
            <IconButton
              onClick={() => handleUnGrouping()}
              aria-label="Ungroup"
            >
              <UnGroupIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        }
        {toolbarData.type === 'activeSelection' && 
          <Tooltip title="Group" arrow>
            <IconButton
              onClick={() => handleGrouping()}
              aria-label="Group"
            >
              <GroupIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        }
      </Grid>
      <Grid container item sm={4} justify="flex-end">
        <Tooltip title="Copy Style" arrow>
          <IconButton aria-label="Copy Style">
            <CopyStyleIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete" arrow>
          <IconButton
            aria-label="Delete"
            onClick={handleRemoval}
          >
            <TrashIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  )
}

Group.propTypes =  {
  toolbarData: PropTypes.object.isRequired,
  onNavigationChange: PropTypes.func.isRequired,
}

export default Group
