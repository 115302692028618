import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import { Box, Tabs, Tab } from '@material-ui/core'
import PhoneIcon from '@material-ui/icons/Phone'
import CategoryOutlinedIcon from '@material-ui/icons/CategoryOutlined'
import FolderOpenIcon from '@material-ui/icons/FolderOpen'
import TemplatesIcon from '../../../../icons/TemplatesIcon'
import TextsIcon from '../../../../icons/TextsIcon'
import TextStylingsIcon from '../../../../icons/TextStylingsIcon'
import ImagesIcon from '../../../../icons/ImagesIcon'
import BackgroundIcon from '../../../../icons/BackgroundIcon'
import SocialMediaIcon from '../../../../icons/SocialMediaIcon'
import UploadsIcon from '../../../../icons/UploadsIcon'
import Texts from '../Texts'
import TextStylings from './TextStylings'
import ActiveIcons from './ActiveIcons'
import SocialMediaIcons from './SocialMediaIcons'
import Graphics from './Graphics'

const verticalTabProps = index => (
  {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  }
)

const useStyles = makeStyles(() => ({
  tabs: {
    height: '100%',
    color: '#fff'
  },
  tabVertical: {
    position: 'absolute',
    zIndex: 1
  },
  tabIndicator: {
    width: '100%',
    backgroundColor: '#293039'
  },
  tab: {
    textTransform: 'none',
    minWidth: '80px',
    minHeight: '85px',
    padding: 0,
  },
  wrapper: {
    width: '90%'
  },
  icon: {
    fontSize: 30,
    marginBottom: '0 !important'
  }
}))

const Navigation = ({ navigation, onNavigationChange }) => {
  const classes = useStyles()
  
  const handleTabChange = (event, newTabIndex) => {
    onNavigationChange({
      activeTab: newTabIndex,
      activePanel: 0
    })
  }

  const handleSubPanelChange = index => {
    onNavigationChange({
      panel: index
    })
  }

  return (
    <React.Fragment>
      <Box flex="0 1 auto" width="80px">
        <Tabs
          orientation="vertical"
          value={navigation.activeTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          variant="scrollable"
          scrollButtons="off"
          aria-label="Toolbar menu"
          classes={{
            root: classes.tabs,
            flexContainerVertical: classes.tabVertical,
            indicator: classes.tabIndicator
          }}
        >
          <Tab
            label="Templates"
            icon={<TemplatesIcon className={classes.icon} />}
            {...verticalTabProps(0)}
            className={classes.tab}
            disabled
          />
          <Tab
            label="Texts"
            {...verticalTabProps(1)}
            icon={<TextsIcon className={classes.icon} />}
            className={classes.tab}
          />
          <Tab
            label="Texts Styling"
            {...verticalTabProps(2)}
            icon={<TextStylingsIcon fontSize="large" className={classes.icon} />}
            className={classes.tab}
            disabled
          />
          <Tab
            label="Images"
            icon={<ImagesIcon className={classes.icon} />}
            {...verticalTabProps(3)}
            className={classes.tab}
            disabled
          />
          <Tab
            label="Background"
            icon={<BackgroundIcon className={classes.icon} />}
            {...verticalTabProps(4)}
            className={classes.tab}
            disabled
          />
          <Tab
            label="Active Icons"
            icon={<PhoneIcon className={classes.icon} />}
            {...verticalTabProps(5)}
            className={classes.tab}
            disabled
          />
          <Tab
            label="Social Media Icons"
            icon={<SocialMediaIcon className={classes.icon} />}
            {...verticalTabProps(6)}
            className={classes.tab}
            disabled
          />
          <Tab
            label="Graphics"
            icon={<CategoryOutlinedIcon className={classes.icon} />}
            {...verticalTabProps(7)}
            className={classes.tab}
            disabled
          />
          {/* <Tab
            label="Folders"
            icon={<FolderOpenIcon className={classes.icon} />}
            {...verticalTabProps(9)}
            className={classes.tab}
            disabled
          /> */}
          <Tab
            label="Uploads"
            icon={<UploadsIcon className={classes.icon} />}
            {...verticalTabProps(9)}
            className={classes.tab}
            disabled
          />
        </Tabs>
      </Box>
      <Box flex="1 1 auto" display="flex" flexDirection="column" position="relative" width="260px">
        { navigation.activeTab === 1 &&
            <Texts
              subPanel={navigation.panel}
              onSubpanelChange={handleSubPanelChange}
            />
        }
        { navigation.activeTab === 2 && <TextStylings subPanel={navigation.panel} onSubpanelChange={handleSubPanelChange} /> }
        { navigation.activeTab === 5 ? <ActiveIcons subPanel={navigation.panel} onSubpanelChange={handleSubPanelChange} /> : null  }
        { navigation.activeTab === 6 ? <SocialMediaIcons subPanel={navigation.panel} onSubpanelChange={handleSubPanelChange} /> : null  }
        { navigation.activeTab === 7 && <Graphics subPanel={navigation.panel} onSubpanelChange={handleSubPanelChange} /> }
      </Box>
    </React.Fragment>
  )
}

Navigation.propTypes = {
  navigation: PropTypes.object.isRequired,
  onNavigationChange: PropTypes.func.isRequired,
}

export default Navigation
