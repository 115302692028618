import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'

import Container from '@material-ui/core/Container'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import Avatar from '@material-ui/core/Avatar'
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'

import AuthContext from '../../../contexts/AuthContext'
import NavTabs from './NavTabs'
import DesignerMenu from './DesignerMenu'

const useStyles = makeStyles(theme => ({
  toolbar: {
    padding: 0,
  },
  avatar: {
    marginRight: theme.spacing(1)
  }
}))

const TopMenu = ({ onOpenNewTemplateDialog, onCreateTextStyling }) => {
  const classes = useStyles()
  const [authData, signOut] = useContext(AuthContext)
  const [accountEl, setAccountEl] = useState(null)

  const handleAccountMenuClose = () => setAccountEl(null)

  return (
    <>
      <AppBar color="inherit" position="relative" title="LiveContact">
        <Container>
          <Toolbar variant="dense" className={classes.toolbar}>
            <NavTabs
              onOpenNewTemplateDialog={onOpenNewTemplateDialog}
              onCreateTextStyling={onCreateTextStyling}
            />
            <MenuItem onClick={ event => setAccountEl(event.currentTarget) }>
              <Avatar
                alt={authData.authUser.name || authData.authUser.email}
                src={localStorage.getItem('profilePicture')?localStorage.getItem('profilePicture'):require('../../../images/profile-pic.png')}
                className={classes.avatar} />
              <p>{authData.designerName || authData.authUser.email}</p>
              <KeyboardArrowDownIcon />
            </MenuItem>
          </Toolbar>
        </Container>
      </AppBar>
      <DesignerMenu
        parentEl={accountEl}
        open={Boolean(accountEl)}
        onClose={handleAccountMenuClose}
        authData={authData}
        onLogout={signOut}
      />
    </>
  )
}

TopMenu.propTypes = {
  onOpenNewTemplateDialog: PropTypes.func.isRequired,
  onCreateTextStyling: PropTypes.func.isRequired,
}

export default TopMenu
