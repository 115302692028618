import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSpring, a, config } from 'react-spring'
import { useDrag } from 'react-use-gesture'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    top: 26,
    bottom: 0,
    width: '100%',
    background: 'linear-gradient(to right, rgb(218 218 208 / 0.5) 31%, rgb(197 204 197 / 0.5))',
    backdropFilter: 'blur(24px)',
  },
}))

const PanelTemplate = props => {
  const classes = useStyles()
  const [panelHeight, setPanelHeight] = useState(0)
  const panelRef = useRef(null)
  const [{ y }, set] = useSpring(() => ({
    y: '20%',
    onRest: {
      y: values => {
        if(values.value === panelRef.current.offsetHeight)
          props.onClose()
      }
    }
  }))

  useEffect(() => {
    setPanelHeight(panelRef.current.offsetHeight)
    open({ canceled: false })
  }, [])

  const open = ({ canceled }) => {
    // when cancel is true, it means that the user passed the upwards threshold
    // so we change the spring config to create a nice wobbly effect
    set({
      y: 0,
      immediate: false,
      config: {
        ...(canceled ? config.wobbly : config.molasses),
        duration: 150
      }
    })
  }

  const close = (velocity = 0) => {
    set({
      y: panelHeight,
      immediate: false,
      config: {
        ...config.molasses,
        velocity,
        duration: 150
      }
    })
  }

  const bind = useDrag(
    ({ event, last, vxvy: [, vy], movement: [, my], cancel, canceled }) => {
      // if the user drags up passed a threshold, then we cancel
      // the drag so that the sheet resets to its open position
      if (my < 0) cancel()
      
      if (event.target.id === 'drag-handle' || event.target.closest('#drag-handle')) {
        // when the user releases the sheet, we check whether it passed
        // the threshold for it to close, or if we reset it to its open position
        if (last) {
          my > panelHeight * 0.2 || vy > 0.2 ? close(vy) : open({ canceled })
        }
        // when the user keeps dragging, we just move the sheet according to
        // the cursor position
        else set({ y: my, immediate: true })
      } else {
        cancel()
      }
    },
    { initial: () => [0, y.get()], filterTaps: true, bounds: { top: 0 }, rubberband: true }
  )

  return (
    <React.Fragment>
      <Box
        position="absolute"
        left={0}
        top={0}
        right={0}
        bottom={0}
        zIndex={1}
        onClick={close}
      />
      <a.div
        ref={panelRef}
        {...bind()}
        style={{
          touchAction: 'pan-y',
          zIndex: 2,
          position: 'absolute',
          width: '100%',
          top: 'calc(30% + 72px)',
          bottom: 0,
          y
        }}
      >
        <Box
          id="drag-handle"
          width={1}
          padding="2% 0"
          bgcolor="#a0a2a3"
        >
          <Box
            width="20%"
            margin="0 auto 1%"
            pb="1%"
            bgcolor="#c7c7c8"
            borderRadius={5} />
          <Box
            width="20%"
            margin="0 auto"
            pb="1%"
            bgcolor="#c7c7c8"
            borderRadius={5} />
        </Box>
        <Paper square elevation={3} className={classes.paper}>
          <Box
            display="flex"
            flexDirection="column"
            height={1}
          >{props.children}</Box>
        </Paper>
      </a.div>
    </React.Fragment>
  )
}

PanelTemplate.propTypes = {
  onClose: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

export default PanelTemplate

