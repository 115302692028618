const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data)
  let byteArrays = []
  // OPTIMIZE: Convert for loop to functional programming
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)
    const byteNumbers = [...slice].map((char, i) => slice.charCodeAt(i))
    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }
  return new Blob(byteArrays, { type: contentType })
}

export const dataURIToBlob = dataURI => {
  dataURI = dataURI.replace(/^data:/, '')

  const type = dataURI.match(/image\/[^;]+/)[0]
  return b64toBlob(dataURI.replace(/^[^,]+,/, ''), type)
}
