import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '60vh',
  },
  logo: {
    fontSize: '4.4rem',
    marginTop: '16%',
    marginBottom: '3%',
  },
  actions: {
    marginTop: '20%',
    marginBottom: '20%',
    justifyContent: 'center',
  },
  brandBox: {
    marginTop: 20,
    fontSize: '4rem',
  },
  brandFirst: {
    color: '#000000',
    fontWeight: '950',
    fontSize: '30px',
  },
  brandSecond: {
    color: '#2C2A84',
    fontWeight: '950',
    fontSize: '30px',
  },

  brandThird: {
    color: '#000000',
    fontWeight: '400',
    fontSize: '30px',
    paddingLeft: '5px',
  },
  marginTop20: {
    marginTop: '20px',
  },
  marginTop40: {
    marginTop: '40px',
  },
  getStartedButton: {
    width: 150,
    height: 50,
    fontSize: 20,
    borderRadius: 30,
  },
  fontWeight200: {
    fontWeight: 200,
  },
}))

const Terms = () => {
  const classes = useStyles()

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={true}
      aria-labelledby="sign-in-dialog-title"
      classes={{paper: classes.root}}
    >
      <Box
        display="flex"
        justifyContent="center"
        component="span"
        className={classes.brandBox + ' ' + classes.marginTop40}
      >
        <span className={classes.brandFirst}>Live</span>
        <span className={classes.brandSecond}>Contact</span>
      </Box>
      <Grid container>
        <Grid item xs={2}></Grid>
        <Grid item xs={8}>
          <DialogContent className={classes.marginTop40}>
            <Typography
              mt={3}
              variant="h4"
              component="h4"
              color="textPrimary"
              gutterBottom
            >
              Terms
            </Typography>
            <DialogContentText>
              <Typography
                className={classes.fontWeight200}
                variant="h5"
                component="h5"
                color="textPrimary"
                gutterBottom
              >
                App terms and conditions are an important legal document for app
                developers and owners, as they establish the rules and
                restrictions for app use.
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.actions}></DialogActions>
        </Grid>

        <Grid item xs={2}></Grid>
      </Grid>
    </Dialog>
  )
}

export default Terms
