import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { fabric } from 'fabric'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'

import HttpClient from '../../../../../../services/HttpClient'
import AuthContext from '../../../../../../contexts/AuthContext'

const useStyles = makeStyles(() => ({
  heading: {
    textAlign: 'center'
  },
  container: {
    overflowY: 'auto'
  },
  category: {
    fontSize: '0.8em',
    textAlign: 'center'
  },
  iconsContainer: {
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
  },
  icon: {
    cursor: 'pointer',
    '& svg': {
      maxWidth: '100%',
      maxHeight: '100%'
    },
  },
}))

const groupByCategory = icons => {
  return icons.reduce((r, ico) => {
    r[ico.category] = r[ico.category] || []
    r[ico.category].push(ico)
    return r
  }, Object.create(null))
}

const ActiveIcons = ({ onAddAction }) => {
  const classes = useStyles()
  const [authData] = useContext(AuthContext)
  const [icons, setIcons] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    HttpClient.get('/icons?type=2&state=3', { Authorization: `Bearer ${authData.authToken}` })
      .then(res => {
        setIcons(res)
        setLoading(false)
      })
      .catch(error => {
        console.log('Error load active icons: ', error)
        setIcons([])
        setLoading(false)
      })
  }, [authData.authToken])


  const handleAdd = icon => {
    fabric.loadSVGFromString(icon.svg, (objects, options) => {
      const obj = fabric.util.groupSVGElements(objects, options)
      obj.id = `Icon-${icon.id}`
      onAddAction({ type: 'addObject', value: obj })
    })
  }

  const categorized = groupByCategory(icons)
  return (
    <React.Fragment>
      <Box
        flex="0 1 auto"
        px="12%"
        py={1.5}
        className={classes.heading}
      >
        Use active icons to create interactive buttons on your card!
      </Box>
      <Box display="flex" flex="1 1 auto" height={1} className={classes.container}>
        {Object.keys(categorized).map((category, i) => (
          <Box
            key={`category-${i}`}
            display="flex"
            flexDirection="column"
            flexGrow="1"
            flexBasis="0"
            border="1px solid #d8d8d8"
            borderLeft={i === 0 ? 0 : '1px solid #d8d8d8'}
            borderRight="0"
          >
            <Box
              flex="0 1 auto"
              py={1}
              borderBottom="1px solid #d8d8d8"
              className={classes.category}
            >{category}</Box>
            <Box flex="1 1 auto" className={classes.iconsContainer}>
              {categorized[category].map(icon => (
                <Box
                  key={`icon-${icon.id}`}
                  p={1}
                  className={classes.icon}
                  dangerouslySetInnerHTML={{ __html: icon.svg }}
                  onClick={() => handleAdd(icon)}
                />
              ))}
            </Box>      
          </Box>
        ))}
      </Box>
      <Box>
      </Box>
    </React.Fragment>
  )
}

ActiveIcons.propTypes = {
  onAddAction: PropTypes.func.isRequired,
}

export default ActiveIcons
