import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import { Box, Tabs, Tab } from '@material-ui/core'
import PhoneIcon from '@material-ui/icons/Phone'
import CategoryOutlinedIcon from '@material-ui/icons/CategoryOutlined'
import FolderOpenIcon from '@material-ui/icons/FolderOpen'

import TemplatesIcon from '../../../../icons/TemplatesIcon'
import TextsIcon from '../../../../icons/TextsIcon'
import TextStylingsIcon from '../../../../icons/TextStylingsIcon'
import ImagesIcon from '../../../../icons/ImagesIcon'
import BackgroundIcon from '../../../../icons/BackgroundIcon'
import SocialMediaIcon from '../../../../icons/SocialMediaIcon'
import UploadsIcon from '../../../../icons/UploadsIcon'

import { NavigationContext } from '../../../../contexts/NavigationContext'
import Panel from './Panel'

const verticalTabProps = index => (
  {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  }
)

const useStyles = makeStyles(theme => ({
  tabs: {
    height: '100%',
    color: '#fff'
  },
  tabVertical: {
    position: 'absolute',
    zIndex: 1
  },
  tabIndicator: {
    width: '100%',
    backgroundColor: '#293039'
  },
  tab: {
    textTransform: 'none',
    minWidth: '80px',
    minHeight: '85px',
    padding: 0,
  },
  wrapper: {
    width: '90%'
  },
  icon: {
    fontSize: 30,
    marginBottom: '0 !important'
  }
}))

const Navigation = props => {
  const classes = useStyles()
  const [navigation, setNavigation] = useContext(NavigationContext)

  const handleTabChange = (event, newTabIndex) => {
    setNavigation({
      ...navigation,
      activeTab: newTabIndex,
      activePanel: 0
    })
  }

  return (
    <React.Fragment>
      <Box flex="0 1 auto" width="80px">
        <Tabs
          orientation="vertical"
          value={navigation.activeTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          variant="scrollable"
          scrollButtons="off"
          aria-label="Toolbar menu"
          classes={{
            root: classes.tabs,
            flexContainerVertical: classes.tabVertical,
            indicator: classes.tabIndicator
          }}
        >
          <Tab
            label="Templates"
            icon={<TemplatesIcon className={classes.icon} />}
            {...verticalTabProps(0)}
            className={classes.tab}
          />
          <Tab
            label="Texts"
            {...verticalTabProps(1)}
            icon={<TextsIcon className={classes.icon} />}
            className={classes.tab}
          />
          <Tab
            label="Texts Styling"
            {...verticalTabProps(2)}
            icon={<TextStylingsIcon fontSize="large" className={classes.icon} />}
            className={classes.tab}
          />
          <Tab
            label="Images"
            icon={<ImagesIcon className={classes.icon} />}
            {...verticalTabProps(3)}
            className={classes.tab}
          />
          <Tab
            label="Background"
            icon={<BackgroundIcon className={classes.icon} />}
            {...verticalTabProps(4)}
            className={classes.tab}
          />
          <Tab
            label="Active Icons"
            icon={<PhoneIcon className={classes.icon} />}
            {...verticalTabProps(5)}
            className={classes.tab}
          />
          <Tab
            label="Social Media Icons"
            icon={<SocialMediaIcon className={classes.icon} />}
            {...verticalTabProps(6)}
            className={classes.tab}
          />
          <Tab
            label="Graphics"
            icon={<CategoryOutlinedIcon className={classes.icon} />}
            {...verticalTabProps(7)}
            className={classes.tab}
          />
          <Tab
            label="Uploads"
            icon={<UploadsIcon className={classes.icon} />}
            {...verticalTabProps(8)}
            className={classes.tab}
          />

        </Tabs>
      </Box>
      <Panel templateId={props.templateId} colors={props.colors} onColorsChange={props.onColorsChange} />
    </React.Fragment>
  )
}

Navigation.propTypes = {
  templateId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  colors: PropTypes.array.isRequired,
  onColorsChange: PropTypes.func.isRequired,
}

export default Navigation
