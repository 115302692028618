import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Container, Grid, Box } from '@material-ui/core'
import { AppBar, Toolbar } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Authentication from './Authentication'
import Registration from './Registration'

const useStyles = makeStyles(() => ({
  header: {
    backgroundColor: 'inherit'
  },
  logo: {
    fontSize: '4.4rem'
  },
  getStarted: {
    fontSize: 20,
    borderRadius: 30,
    width:150,
    height:50
  },

  brandBox: {
    marginTop: 50,
    fontSize: "4rem",
  },
  brandFirst: {
    color: "#000000",
    fontWeight: "950",
    fontSize: "70px",
  },
  brandSecond: {
    color: "#3A60AC",
    fontWeight: "950",
    fontSize: "70px",
  },

  brandThird: {
    color: "#000000",
    fontWeight: "400",
    fontSize: "70px",
    paddingLeft: '10px'
  },
    flex: {
    display:'flex',
    justifyContent:"flex-end"
  }
}))

const TopMenu = ({ onAuthenticate }) => {
  const classes = useStyles()
  const [loginDialogOpen, setLoginDialogOpen] = useState(false)
  const [registrationDialogOpen, setRegistrationDialogOpen] = useState(false)
  const [designerEmail, setDesignerEmail] = useState(null)

  const handleLoginDialogClose = () => {
    setLoginDialogOpen(false)
  }

  const handleRegistrationDialogClose = (openLogin, designerEmail) => {
    setLoginDialogOpen(openLogin)
    setRegistrationDialogOpen(false)
    setDesignerEmail(designerEmail)
  }

  return (
    <React.Fragment>
      <AppBar position="relative" elevation={0} title="Be" className={classes.header}>
        <Container>
          <Toolbar>
          <Grid
              container
              justifyContent="flex-end"
              alignItems="center"
              className={classes.flex}
            >
              <Grid item style={{marginTop: 20}}>
                <Button
                  color="primary"
                  size="large"
                  aria-label="Log in"
                  onClick={() => setLoginDialogOpen(true)}
                  style={{
                    fontSize: 20,
                    marginRight: 20,
                    borderRadius:30
                  }}
                >
                Log in
                </Button>
                <Authentication
                  open={loginDialogOpen}
                  onClose={handleLoginDialogClose}
                  onAuthenticate={onAuthenticate}
                  data={{ email: designerEmail }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className={classes.getStarted}
                  aria-label="Get Started"
                  onClick={() => setRegistrationDialogOpen(true)}
                >
                Get Started
                </Button>
                <Registration open={registrationDialogOpen} onClose={handleRegistrationDialogClose}/>
              </Grid>
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>
      <Container maxWidth="md">
        <Grid container>
          <Grid item>
          <Box display='flex' component="span" className={classes.brandBox}>
                <span className={classes.brandFirst}>Live</span>
                <span className={classes.brandSecond}>Contact</span>
              {/* <span className={classes.brandThird}>Contributor</span> */}
              </Box>
            <Box mt={6} mb={6} fontSize="h3.fontSize" fontWeight="fontWeightLight">
              <Box>Making it easier and more fun to manage</Box>
              <Box>your contacts to everyone</Box>
            </Box>
            <Typography variant="h3" component="h1">Digital Business Cards</Typography>
            <Box mt={6}>
              <Grid container spacing={3} alignItems="center">
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.getStarted}
                    aria-label="Get Started"
                    onClick={() => setRegistrationDialogOpen(true)}
                  >
                  Get Started
                  </Button>
                </Grid>
                <Grid style={{
                  fontSize: 20
                }}>You&apos;ll love it!</Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>)
}

TopMenu.propTypes = {
  onAuthenticate: PropTypes.func.isRequired
}

export default TopMenu
