import React from 'react'
import PropTypes from 'prop-types'
import { css } from "@emotion/core";
import { ClipLoader } from "react-spinners";

const Loader = color => {
    const override = css`
            margin-Left: 10px;
            width: 1rem;
            height: 1rem;
            font-size: 15px;
            `

    return <ClipLoader css={override} color={color} />
}

Loader.propTypes = {
  color: PropTypes.string,
}
  

export default Loader