import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import { TextField, InputAdornment } from '@material-ui/core'
import MuiAutocomplete from '@material-ui/lab/Autocomplete'
import SearchIcon from '@material-ui/icons/Search'

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#fff',
    borderRadius: '0.3em'
  },
  input: {
    color: '#000000',
    paddingLeft: '10px',
    borderRadius: '0.3em',
    fontSize: '14px',
    '& input': {
      paddingTop: '9px',
      paddingBottom: '9px'
    },
    '& fieldset': {
      borderColor: '#ededef',
    },
  },
}))


const optionLabel = option => {
  if(typeof(option) === 'object')
    return option.title
  return option
}

const Autocomplete = props => {
  const classes = useStyles()
  const [value, setValue] = useState(null)
  const [options, setOptions] = useState(props.options)
  useEffect(() => {
    setOptions(props.options)
  }, [props.options])

  const handleChange = (event, category) => {
    props.onAddCategory(category)
    if (props.isCategory && props.params && props.params.categoryIds){
      props.params.categoryIds.push(category.id)
    }
    setTimeout(() => {
      setValue('')
      setValue(null)
    }, 100);
  }

  const handleInputChange = (event, value) => {
    if (props.handleCategoryValidation){
      props.handleCategoryValidation();
    }
  }

  return (
    <MuiAutocomplete
      id="autocomplete"
      size="small"
      options={options}
      value={value}
      getOptionLabel={option => optionLabel(option)}
      onChange={handleChange}
      onInputChange={handleInputChange}
      renderInput={params =>
        <TextField
          {...params}
          variant="outlined"
          size="small"
          placeholder={props.placeholder}
          className={classes.root}
          InputProps={{
            ...params.InputProps,
            className: classes.input,
            startAdornment: (
              <InputAdornment position="start">
                {!props.hideIcon ? <SearchIcon /> : null}
              </InputAdornment>
            ),
          }}
        />
      }
    />
  )
}

Autocomplete.propTypes = {
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  onAddCategory: PropTypes.func.isRequired,
}

export default Autocomplete
