import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const TopAlignIcon = props => {
  return (
    <SvgIcon {...props}>
      <path d="M2.9 5v1h17.2V5H2.9zm3.2 2.8v11.6h4V7.8h-4zm6.6 0v6.4h4V7.8h-4z"/>
    </SvgIcon>
  )
}

export default TopAlignIcon
