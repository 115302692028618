import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import HttpClient from '../../services/HttpClient'

import { makeStyles } from '@material-ui/core/styles'
import { Grid, TextField, Button, Box } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Typography from '@material-ui/core/Typography'
import MuiAlert from '@material-ui/lab/Alert'

import ReConfirmation from './ReConfirmation'
import { useHistory } from 'react-router-dom'

import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormControl from '@material-ui/core/FormControl'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { isNull } from 'lodash/fp'
import Loader from './Loader'

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '70vh',
  },
  logo: {
    fontSize: '4.4rem',
    marginTop: '16%',
    marginBottom: '3%',
  },
  actions: {
    marginTop: '2%',
    marginBottom: '20%',
    justifyContent: 'flex-end',
  },

  verificationActions: {
    display: 'flex',
    marginTop: '20%',
    marginBottom: '20%',
    justifyContent: 'flex-end',
  },
  pointer: {
    cursor: 'pointer'
  },
  brandBox: {
    marginTop:theme.spacing(2),
    fontSize: '4rem',
  },
  brandFirst: {
    color: '#000000',
  },
  brandSecond: {
    color: '#3A60AC',
  },
  brandThird: {
    color: '#000000',
    fontWeight: '400',
    fontSize: '35px',
    paddingLeft: '5px'
  },
  brandFont: {
    fontWeight: '950',
    fontSize: '55px',
  },
  marginTop20: {
    marginTop: '20px'
  },
  loginButton: {
    width:100,
    height:40,
    fontSize: 20
  },
  fontSize: {
    fontSize:'25px'
  },
  font:{
    fontSize:'18px'
  }

}))

const initialAuthData = {
  email: '',
  password: '',
}

const initialErrorMessages = {
  email: '',
  password: ''
}

const Authentication = props => {
  const classes = useStyles()
  const [designer, setDesigner] = useState(initialAuthData)
  const [errors, setErrors] = useState(initialErrorMessages)
  const [authError, setAuthError] = useState(null)
  const [showPassword, setShowPassword] = useState(false)
  const history = useHistory()
  const { open, onClose, data } = props
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setErrors(initialErrorMessages)
    setAuthError(null)
  }, [open])

  useEffect(() => {
    if (data.email) {
      setDesigner({ ...designer, email: data.email })
    }
  }, [data])

  const handleSubmit = e => {
    e.preventDefault()
    const _errors = { ...errors, ...initialErrorMessages }
    let isValid = true
    if (designer.email === '') {
      isValid = false
      _errors.email = 'Required'
    }
    if (designer.password === '') {
      isValid = false
      _errors.password = 'Password can\'t be blank'
    }

    if (isValid) {
      setLoading(true)
      HttpClient.post('/login', designer)
        .then(res => {
          setLoading(false)
          localStorage.setItem('jwtToken', res.token)
          localStorage.setItem('beDesignerName', res.designer.brandName)
          localStorage.setItem('profilePicture', res.account.profiles[0].profilePicture)
          props.onAuthenticate()
        })
        .catch(error => {
          setLoading(false)
          if (error.response.status === 401) {
            error.response.text().then(response => {
              setAuthError(JSON.parse(response).error.message)
            })
          }
          if (error.response.status === 422) {
            setAuthError('Invalid email or password.')
          }
        })
    } else {
      setErrors(_errors)
    }
  }

  const forgotPassword = () => {
    history.push(`/registration/password/forgot?timestamp=${new Date().toISOString()}`)
    onClose()
  }

  const handleClickShowPassword = type => {
    if (type === 'password')
      setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }
  

  return (
    <>
      {!isNull(authError) && authError === 'Error : Email is not verified' &&
        <ReConfirmation email={designer.email} onAuthErrorChange={setAuthError} />
      }
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={open}
        onClose={onClose}
        aria-labelledby="sign-in-dialog-title"
        classes={{ paper: classes.root }}
      >
        <Grid container>
          <Grid item xs={2}></Grid>
          <Grid item xs={8}>
            <DialogTitle id="registration-dialog-title">
              <Box display='flex' justifyContent='center' component="span" className={classes.brandBox}>
                <span className={classes.brandFirst + ' ' + classes.brandFont}>Live</span>
                <span className={classes.brandSecond + ' ' + classes.brandFont}>Contact</span>
              </Box>
            </DialogTitle>
            <form onSubmit={handleSubmit}>
              <Grid container>
                <Grid item xs={1}></Grid>
                <Grid item xs={10}>
                  <DialogContent>
                    <Typography mt={10} variant="h5" component="h2" gutterBottom>Log in to your account:</Typography>
                    {!isNull(authError) ?
                      <MuiAlert severity="error">{authError}</MuiAlert>
                      : null
                    }
                    <TextField
                      id="email"
                      label="E-mail"
                      type="email"
                      autoComplete="username"
                      value={designer.email}
                      onChange={e => setDesigner({ ...designer, email: e.currentTarget.value })}
                      margin="normal"
                      fullWidth
                      required
                      className={classes.fontSize}
                      error={errors.email !== ''}
                      helpertext={errors.email} />
                    <FormControl
                      fullWidth
                      className={classes.marginTop20}
                      error={errors.password !== ''}
                      helpertext={errors.password}
                    >
                      <InputLabel htmlFor="standard-adornment-password">Password*</InputLabel>
                      <Input
                        id="standard-adornment-password"
                        type={showPassword ? 'text' : 'password'}
                        value={designer.password}
                        fullWidth
                        required
                        className={classes.font}
                        onChange={(e) => setDesigner({ ...designer, password: e.currentTarget.value })}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => handleClickShowPassword('password')}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        }
                        inputProps={
                          { minLength: 5 }
                        }
                        error={errors.password !== ''}
                        helpertext='Enter your password. Minimum 5 characters required.'
                      />
                    </FormControl>
                    <Box mt={6} className={classes.verificationActions}>
                      <Button type="submit" className={classes.loginButton} variant="contained" color="primary" size="small">Log in 
                        {loading && <Loader />}
                      </Button>
                    </Box>
                    <Typography
                      variant="body1"
                      component="h3"
                      gutterBottom
                      mt={6}
                      className={ classes.pointer }
                      onClick={() => forgotPassword()}
                    >
                        forgot password
                    </Typography>
                  </DialogContent>
                </Grid>
                <Grid item xs={1}></Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
        <Grid item xs={2}></Grid>
      </Dialog>
    </>
  )
}

Authentication.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAuthenticate: PropTypes.func.isRequired,
  data: PropTypes.object
}

export default Authentication
