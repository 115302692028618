import React from 'react'
import PropTypes from 'prop-types'

import { DialogContent, DialogActions, Typography } from '@material-ui/core/'
import Button from '@material-ui/core/Button'
import Overlay from '../Overlay'

const Publish = props => {
  const handleSubmit = e => {
    e.preventDefault()
    if( Number.isInteger(props.icon.id) ) {
      props.onIconChange({ state: 2 })
    }
  }

  return (
    <Overlay
      maxWidth="xs"
      open={props.open}
      onClose={props.onClose}
    >
      {props.icon.state === 1 ?
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Typography variant="h6" gutterBottom>Submit your Social Media Icon to publish.</Typography>
            <Typography variant="body2">It will be published after our team review and approve it.</Typography>
          </DialogContent>
          <DialogActions>
            <Button type="submit" variant="contained" color="primary" size="small">Publish</Button>
          </DialogActions> 
        </form>
        :
        <React.Fragment>
          <DialogContent>
            <Typography variant="h6" gutterBottom>Thanks for submitting your Social Media Icon.</Typography>
            <Typography variant="body2">We'll notify you as soon as your icon is approved and published.</Typography>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={props.onClose}
            >
              Got it!
            </Button>
          </DialogActions> 
        </React.Fragment>
      }
      
    </Overlay>
  )
}

Publish.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  icon: PropTypes.object.isRequired,
  onIconChange: PropTypes.func.isRequired,
}

export default Publish
