import React, { useState }  from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import verticle from '../../../../images/verticle.png'
import horizontal from '../../../../images/horizontal.png'
import square from '../../../../images/square.png'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    paddingTop: '15px',
    marginLeft: '10%',
    marginRight: '10%',
    justifyContent: 'space-around',
    '& img': {
      width: '40px',
      height: '40px',
      marginLeft: '0px',
      marginRight: '0px',
      
    },
    '& span': {
      fontSize: '12px',
      textDecoration: 'none !important',
      fontWeight: '400',
    }
  },
  image: {
    maxWidth: '25%',
    maxHeight: '60%',
    verticalAlign: 'middle',
  },
}))

const FormatSelector = ({ setFormat }) => {
  const classes = useStyles()
  const [currentFormat, setCurrentFormat] = useState('2')

  const formatSetter = format => {
    setCurrentFormat(format)
    setFormat(format)
  }

  return (
    <React.Fragment>
      <Box className={classes.header} borderBottom="1px solid #d8d8d8">
        <Box
          onClick={()=> formatSetter('2')}
          display="flex"
          justifyContent="space-around"
          paddingTop="10px"
          borderTop={currentFormat==='2' ? '2px solid #6495ED' : null}
        >
          <span> Vertical </span>
          <img src={verticle} alt="" className={classes.image} />
        </Box>
        <Box
          onClick={()=> formatSetter('3')}
          display="flex"
          justifyContent="space-around" 
          paddingTop="10px"
          borderTop={currentFormat==='3' ? '2px solid #6495ED' : null}
        >
          <span>Square</span>
          <img src={square} alt="" className={classes.image} />
        </Box>
        <Box
          onClick={()=> formatSetter('1')}
          display="flex"
          justifyContent="space-around"
          paddingTop="10px"
          borderTop={currentFormat==='1' ? '2px solid #6495ED' : null} 
        >
          <span> Horizontal </span>
          <img src={horizontal} alt="" className={classes.image} />
        </Box>
      </Box>          
    </React.Fragment>
  )
}

FormatSelector.propTypes = {
  setFormat: PropTypes.func.isRequired
}

export default FormatSelector
