import React, {useContext, useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import CategoryHome from './CategoryHome'
import HttpClient from '../../../../services/HttpClient'
import AuthContext from '../../../../contexts/AuthContext'
import FormatSelector from './FormatSelector'
import DummySkeleton from './DummySkeleton'
import {getCategoryTypeValue} from './Common'
import {useParams} from 'react-router-dom'
import DashboardHeader from './DashboardHeader'
import SearchResult from './SearchResult'
import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles(() => ({
  marginTop: {
    marginTop: '-100px',
  },
}))

const Home = () => {
  const [templates, setTemplates] = useState([])
  const [format, setFormat] = useState('2')
  const [authData] = useContext(AuthContext)
  const [loading, setLoading] = useState(true)
  const {cardId} = useParams()
  const [search, setSearch] = useState('')
  const classes = useStyles()

  useEffect(() => {
    setLoading(true)
    HttpClient.get('/templates/published', {
      Authorization: `Bearer ${authData.authToken}`,
    })
      .then((resTemplates) => {
        setTemplates(resTemplates)
        setLoading(false)
      })
      .catch((error) => {
        console.log('Error getting industries: ', error)
        setTemplates([])
        setLoading(false)
      })
  }, [authData.authToken])

  return (
    <React.Fragment>
      <DashboardHeader onChange={(searchQuery) => setSearch(searchQuery)} />
      {search !== '' ? (
        <SearchResult
          url={`/templates/published?search=${search}`}
          onClose={() => setSearch('')}
          cardId={cardId}
        />
      ) : loading ? (
        <DummySkeleton />
      ) : (
        <>
          <FormatSelector
            className={classes.marginTop}
            setFormat={(format) => setFormat(format)}
          />
          {templates &&
            templates.map((template, index) => (
              <CategoryHome
                key={index}
                category={{
                  name: `${getCategoryTypeValue(
                    template.category_type.toString()
                  )}`,
                  type: `${template.category_type}`,
                  format: format,
                }}
                cardId={cardId}
              />
            ))}
          {/* <img src={free} className={classes.type} /> */}
        </>
      )}
    </React.Fragment>
  )
}

Home.propTypes = {
  history: PropTypes.object.isRequired,
}

export default Home
