import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid } from "@material-ui/core";
import Sides from "../../icons/Sides.svg";
import AddMore from "../../icons/AddMore.svg";
import AddMoreGif from "../../icons/AddMore.gif";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles(() => ({
  navItem: {
    color: "#000",
    cursor: "pointer",
    textDecoration: "none",
  },

  backgroundWhite: {
    backgroundColor: "#fff",
  },
  backgroundPurple: {
    backgroundColor: "#9933ff",
  },
  itemsCountBox: {
    display: "flex",
    height: "53px",
    width: "53px",
    borderRadius: "50%",
    "& span": {
      position: "relative",
      margin: "auto",
      "& img": {
        width: "30px",
        height: "auto",
        paddingTop: "0px",
        display: "block",
        marginLeft: "auto",
        marginTop: "auto",
      },
      "& p": {
        fontSize: "15px",
        position: "absolute",
        top: "60%",
        left: "44%",
        transform: "translate(-50%, -50%)",
      },
    },
  },
  gif: {
    height: "100%",
    width: "100%",
    marginTop: "-10px",
  },
  flex: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const Navigation = ({ sidesCount, onNavigationChange }) => {
  const classes = useStyles();
  const [showGif, setShowGif] = useState(true);
  const gifHandler = () => {
    setShowGif(!showGif);
  };
  const search = useLocation().search;
  const categoryTitle = new URLSearchParams(search).get("featured");

  return (
    <Box px={2} paddingBottom={2}>
      <Grid container>
        <Box>
          {showGif && categoryTitle && (
            <img className={classes.gif} src={AddMoreGif} />
          )}
        </Box>
        <Grid item xs={4}>
          <Box
            boxShadow={1}
            className={classes.itemsCountBox + " " + classes.backgroundWhite}
            onClick={() => onNavigationChange(1)}
          >
            <span>
              <img src={Sides} alt="" />
              <p zindex={2}>{sidesCount}</p>
            </span>
          </Box>
        </Grid>
        <Grid container item xs={4} justifyContent="center"></Grid>
        <Grid
          container
          item
          xs={4}
          className={classes.flex}
        >
          <Box
            className={classes.itemsCountBox + " " + classes.backgroundPurple}
            onClick={() => onNavigationChange(2)}
          >
            <span>
              <img onClick={() => gifHandler()} src={AddMore} alt="" />
            </span>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

Navigation.propTypes = {
  onNavigationChange: PropTypes.func.isRequired,
  sidesCount: PropTypes.number.isRequired,
};

export default Navigation;
