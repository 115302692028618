import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const BackwardIcon = props => {
  return (
    <SvgIcon {...props}>
      <path d="M0 15.9L12 9l12 6.9-12 6.5z"/>
      <path transform="matrix(.92084 0 0 .92068 .95 2.354)" fill="#fff" strokeWidth="1.2" stroke="currentColor" d="M0 6.7l12 6.6 12-6.6L12 0z"/>
    </SvgIcon>
  )
}

export default BackwardIcon
