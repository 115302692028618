import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const RedoIcon = props => {
  return (
    <SvgIcon {...props}>
      <path d="M14.6 13.96c.12 0 .24.05.32.13.08.08.13.19.13.31v2.37l5.65-4.58-5.65-4.57v2.37c0 .24-.2.44-.45.44-6.48.06-8.89 3.93-9.6 6.65 2.8-3.12 6.98-3.12 9.6-3.12zm-8.54-.84c1.3-1.55 3.76-3.4 8.09-3.56V6.69c0-.17.1-.33.26-.4s.35-.05.48.06l6.82 5.5c.1.08.17.21.17.34 0 .13-.06.26-.16.34l-6.82 5.53c-.14.11-.32.13-.48.06a.438.438 0 01-.26-.4v-2.87c-2.83.01-7.15.22-9.44 4.18-.08.14-.23.22-.39.22-.04 0-.08 0-.11-.01a.439.439 0 01-.33-.43c-.02-1.49.55-3.75 2.17-5.69z" />
    </SvgIcon>
  )
}

export default RedoIcon
