import React, {useState, useEffect, useContext} from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import AddIcon from '@material-ui/icons/Add'
import HttpClient from '../../../../services/HttpClient'
import DeleteIcon from '../../icons/Delete'
import SidesIcon from '../../icons/Sides'
import {makeStyles} from '@material-ui/core/styles'
import SideAddDialog from './SideAddDialog'
import AuthContext from '../../../../contexts/AuthContext'

const useStyles = makeStyles(() => ({
  font: {
    fontSize: '8px',
    color: '#000',
  },
}))

const SidesMenu = ({
  sidesLength,
  onCreateSide,
  onDuplicateSide,
  onDeleteSide,
}) => {
  const [openDialoug, setOpenDialoug] = useState(false)
  const classes = useStyles()
  const [subscription, setSubscription] = useState()
  const [authData] = useContext(AuthContext)

  const handleDialog = () => {
    setOpenDialoug(!openDialoug)
  }

  useEffect(() => {
    HttpClient.get('/subscription', {
      Authorization: `Bearer ${authData.authToken}`,
    })
      .then((res) => {
        setSubscription(res)
      })
      .catch((error) => {
        console.log('subscription error: ', error)
        setSubscription()
      })
  }, [authData.authToken])

  const handleCreateNewSideClick = () => {
    if (subscription.planType === 'basic') {
      handleDialog()
    } else onCreateSide()
  }

  const handleDuplicateClick = () => {
    if (subscription.planType === 'basic') {
      handleDialog()
    } else onDuplicateSide()
  }

  return (
    <Grid container>
      <Grid item xs={4}>
        <IconButton disabled={sidesLength === 1} onClick={onDeleteSide}>
          <DeleteIcon />
        </IconButton>
        <Box className={classes.font}> Delete Side </Box>
      </Grid>
      <Grid container item xs={4} justifyContent="center">
        <IconButton onClick={() => handleDuplicateClick()}>
          <SidesIcon />
        </IconButton>
        <Box className={classes.font}> Duplicate Side </Box>
      </Grid>
      <Grid container item xs={4} justifyContent="center">
        <IconButton onClick={() => handleCreateNewSideClick()}>
          <AddIcon />
        </IconButton>
        <Box className={classes.font}> Add Side </Box>
      </Grid>
      <SideAddDialog open={openDialoug} close={setOpenDialoug}></SideAddDialog>
    </Grid>
  )
}

SidesMenu.propTypes = {
  sidesLength: PropTypes.number.isRequired,
  onCreateSide: PropTypes.func.isRequired,
  onDuplicateSide: PropTypes.func.isRequired,
  onDeleteSide: PropTypes.func.isRequired,
}

export default SidesMenu
