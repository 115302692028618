import React, { useContext, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import { Box, ImageList, ImageListItem } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import IconButton from '@material-ui/core/IconButton'
import TrashIcon from '../../../../icons/TrashIcon'

import HttpClient from '../../../../services/HttpClient'
import AuthContext from '../../../../contexts/AuthContext'
import SearchBar from '../SearchBar'

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#444',
    borderRadius: '0.5em'
  },
  tabs: {
    cursor: 'pointer'
  },
  tabActive: {
    backgroundColor: '#293039'
  },
  link: {
    '& svg': {
      maxWidth: '100%',
      maxHeight: '100%',
      borderRadius: '8px',
    },
  },
  listTitle: {
    '&:hover .MuiIconButton-root': {
      display: 'inline',
    },
  },
  iconButton: {
    display: 'none',
    position: 'absolute',
    right: 0,
    color: 'white',
    background:
    'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
    'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
}))

const Templates = () => {
  const classes = useStyles()
  const [authData] = useContext(AuthContext)
  const [templates, setTemplates] = useState([])
  const [status, setStatus] = useState(1)
  const [loading, setLoading] = useState(true)
  const defaultPreviewImage = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" xml:space="preserve"><rect x="0" y="0" width="100%" height="100%" fill="#ffffff" /></svg>'

  useEffect(() => {
    setLoading(true)
    HttpClient.get(`/mytemplates?status=${status}`, { Authorization: `Bearer ${authData.authToken}` })
      .then(res => {
        setTemplates(res)
        setLoading(false)
      })
      .catch(error => {
        console.error('Error getting My Templates: ', error)
        setTemplates([])
        setLoading(false)
      })
  }, [authData.authToken, status])

  const handleSearch = () => {}

  const handleRemove = template => {
    HttpClient.delete(`/templates/${template.id}`, { Authorization: `Bearer ${authData.authToken}` })
      .then(() => {
        setTemplates(templates.filter(t => t.id !== template.id))
      })
      .catch(error => {
        console.error('Error mytemplate delete: ', error)
      })
  }

  return (
    <>
      <SearchBar onSearch={handleSearch} />
      <Box mx={1} color="white">
        <Box
          py={1}
          width={0.5}
          display="inline-block"
          borderRadius="4px 4px 0 0"
          className={status === 1 ? classes.tabActive : classes.tabs}
          textAlign="center"
          onClick={() => setStatus(1)}
        >Drafts</Box>
        <Box
          py={1}
          width={0.5}
          display="inline-block"
          borderRadius="4px 4px 0 0"
          className={status === 2 ? classes.tabActive : classes.tabs}
          onClick={() => setStatus(2)}
          textAlign="center"
        >Published</Box>
      </Box>
      <Box
        flex="1 1 auto"
        p={1}
        overflow="scroll"
        bgcolor="#293039"
        color="white"
        className="scrollbar-hidden"
      >
        {loading ? 
          <ImageList rowHeight={77} cols={2}>
            <ImageListItem cols={1}>
              <Skeleton variant="rect" width="100%" height={118} />
            </ImageListItem>
            <ImageListItem cols={1}>
              <Skeleton variant="rect" width="100%" height={118} />
            </ImageListItem>
          </ImageList>  
          :
          <ImageList rowHeight={77} cols={2}>
            {templates.map((template, index) => (
              <ImageListItem key={index} cols={1} className={classes.listTitle}>
                <Link
                  to={`/designer/template/${template.id}`}
                  dangerouslySetInnerHTML={{ __html: (template.horizontal && template.horizontal.previewImage) || defaultPreviewImage }}
                  className={classes.link}
                />
                <IconButton
                  size="small"
                  aria-label="info"
                  className={classes.iconButton}
                  onClick={() => handleRemove(template)}
                >
                  <TrashIcon fontSize="small" />
                </IconButton>
              </ImageListItem>
            ))}
          </ImageList>
        }
      </Box>
    </>
  )
}

export default Templates
