import React, { useContext, useEffect, useState, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import { useParams, useHistory } from 'react-router-dom'
import { fabric } from 'fabric'
import PropagateLoader from 'react-spinners/PropagateLoader'
import { isEmpty, get } from 'lodash/fp'
import WebFont from 'webfontloader'

import { makeStyles } from '@material-ui/core/styles'
import {
  Box,
  Grid,
  Link,
  Typography,
  Divider,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Close'

import RadioButton from '../../RadioButton'
import Autocomplete from '../../Autocomplete'
import HttpClient from '../../../services/HttpClient'
import AuthContext from '../../../contexts/AuthContext'
import {
  EDITABLE_STATUSES,
  REQUEST_STATUS,
  DRAFT_STATUS,
  PUBLISHED_STATUS
} from '../../../constants/Constants'
import { dataURIToBlob } from '../../../utils'

const useStyles = makeStyles(theme => ({
  paddingX: {
    padding: '0 15px',
  },
  mainCardWrapper: {
    maxWidth: '1140px',
    padding: '15px',
    position: 'relative',
    margin: '0 auto',
  },
  pageTitle: {
    fontSize: '20px',
    fontWeight: '500',
    margin: '25px 0px -25px 0',
    color: '#000000',
  },
  cardContentWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  contentLeftSection: {
    maxWidth: '230px',
    flex: '0 0 230px',
    paddingTop: '60px',
    paddingBottom: 16,
    '& svg': {
      width: '230px',
      height: '100%',
    },
  },
  contentRightSection: {
    maxWidth: 'calc(100% - 270px)',
    flex: '0 0 calc(100% - 270px)',
    paddingTop: '15px',
    '& label': {
      fontSize: '20px',
      fontWeight: '500',
      color: '#000000',
    },
  },
  saveDraftsBtn: {
    maxWidth: '150px',
    width: '100%',
    padding: '0 15px',
    height: '40px',
    lineHeight: '40px',
    fontSize: '14px',
    fontWeight: '700',
    color: '#000',
    backgroundColor: '#F0F0F0',
    border: '1px solid #979797',
    borderRadius: '4px',
    cursor: 'pointer',
    marginLeft: '15px',
  },
  submittingsBtn: {
    maxWidth: '200px',
    width: '100%',
    padding: '0 15px',
    height: '40px',
    lineHeight: '40px',
    fontSize: '14px',
    fontWeight: '600',
    color: '#fff',
    backgroundColor: '#000',
    marginLeft: '15px',
    border: '1px solid #000',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  error: {
    marginLeft: '5px',
    fontSize: '13px',
    color: 'red',
  },
  inputStyling: {
    width: '100%',
    height: '30px',
    lineHeight: '30px',
    fontSize: '14px',
    fontWeight: '400',
    border: '1px solid #D0D0D0',
    borderRadius: '5px',
    color: '#000',
    padding: '0 15px',
    '&:focus': {
      outline: 'none',
    },
    '&::placeholder': {
      color: '#000',
    },
  },
  titleDescription: {
    fontSize: '14px',
    color: '#000000',
  },
  marginBottomHundred: {
    marginBottom: '100px',
  },
  marginBottom30: {
    marginBottom: '30px',
  },
  marginBottom60: {
    marginBottom: '60px',
  },
  textareaWidth: {
    width: '100%',
    height: '240px',
    overflowY: 'auto',
    padding: '15px',
    fontSize: '14px',
    lineHeight: '1.5',
    resize: 'none',
    fontWeight: '400',
    border: '1px solid #d0d0d0',
    borderRadius: '5px',
    fontFamily: ['Lato', 'sans-sarif'],
    '&::placeholder': {
      color: '#000',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  textMuted: {
    color: '#000000',
    opacity: '31%',
  },
  detailedText: {
    fontSize: '20px',
    fontWeight: '500',
    marginBottom: '60px',
    color: '#000000',
  },
  chip: {
    position: 'relative',
    padding: '5px 10px',
    width: 'auto',
    fontSize: '14px',
    fontweight: '400',
    backgroundColor: '#fff',
    color: '#000',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: '1px solid #ddd',
  },
  borderRight: {
    position: 'absolute',
    height: '100%',
    background: '#ddd',
    right: '40px',
    top: '0',
  },
  thankyouMsg: {
    '& p': {
      fontSize: '28px',
    },
    '& span': {
      fontSize: '18px',
    },
  },
  inputTags: {
    '& input': {
      width: '100%',
      height: '30px',
      lineHeight: '30px',
      fontSize: '14px',
      fontWeight: '400',
      border: '1px solid #D0D0D0',
      borderRadius: '5px',
      color: '#000',
      padding: '0 15px',
      '&:focus': {
        outline: 'none',
      },
      '&::placeholder': {
        color: '#989c9c',
      },
    },
  },

  [theme.breakpoints.down('xs')]: {
    body: {
      overflowY: 'auto !important',
    },
  },
  typoText: {
    color: '#000000',
    fontWeight: 500,
  },
  dividerBottom: {
    margin: '10px 0 25px 0',
  },
  btnTextColor: {
    color: '#000000',
    fontWeight: 600,
  },
  loader: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    zIndex: '9999999',
    transform: 'translate(-50%, -50%)',
  },
}))

const TemplateSubmitForm = () => {
  const { id } = useParams()
  const history = useHistory()
  const classes = useStyles()
  const [authData] = useContext(AuthContext)
  const [personalExpressions, setPersonalExpressions] = useState([])
  const [selectedPersonalExpression, setSelectedPersonalExpression] = useState(
    []
  )

  const ReactTags = require('react-tag-input').WithOutContext;
  const [styles, setStyles] = useState([])
  const [selectedStyle, setSelectedStyle] = useState([])
  const [currentTemplate, setCurrentTemplate] = useState({})

  const [industries, setIndustries] = useState([])
  const [selectedIndustries, setSelectIndustries] = useState([])

  // const [categories, setCategories] = useState([])
  const [themes, setThemes] = useState([])
  const [selectedThemes, setSelectThemes] = useState([])
  const [occasions, setOccasions] = useState([])
  const [selectedOccasions, setSelectOccasions] = useState([])
  const [selectKeywords, setSelectKeywords] = useState([])
  const [keywords, setKeywords] = useState([])
  const [isPublished, setIsPublished] = useState(false)
  const [formIsValid, setFormIsValid] = useState(false)
  const [errors, setErrors] = useState({})
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [validations, setValidations] = useState([{}])
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const canvasVerticalRef = useRef(null)
  const canvasSquareRef = useRef(null)
  const canvasHorizontalRef = useRef(null)
  // useEffect(() => {
  //   if(currentTemplate && currentTemplate.vertical) {
  //     let fontFamilies = []
  //     // Vertical format
  //     const verticalSide = currentTemplate.formats.find(f => f.type === 2).sides[0]
  //     fontFamilies = fontFamilies.concat(getSideFonts(verticalSide.json))
  //     // Square format
  //     const squareSide = currentTemplate.formats.find(f => f.type === 3).sides[0]
  //     fontFamilies = fontFamilies.concat(getSideFonts(squareSide.json))
  //     // Horizontal format
  //     const horizontalSide = currentTemplate.formats.find(f => f.type === 1).sides[0]
  //     fontFamilies = fontFamilies.concat(getSideFonts(horizontalSide.json))

  //     fontFamilies = [...new Set(fontFamilies)] // removing duplicates to get unique font families
  //     if(fontFamilies.length) {
  //       WebFont.load({
  //         google: { families: fontFamilies },
  //         active: () => {
  //           loadFormatCanvas(canvasVerticalRef, verticalSide, 'verticalPreviewImagePath')
  //           loadFormatCanvas(canvasSquareRef, squareSide, 'squarePreviewImagePath')
  //           loadFormatCanvas(canvasHorizontalRef, horizontalSide, 'horizontalPreviewImagePath')
  //         }
  //       })
  //     } else {
  //       loadFormatCanvas(canvasVerticalRef, verticalSide, 'verticalPreviewImagePath')
  //       loadFormatCanvas(canvasSquareRef, squareSide, 'squarePreviewImagePath')
  //       loadFormatCanvas(canvasHorizontalRef, horizontalSide, 'horizontalPreviewImagePath')
  //     }
  //   }
  // }, [authData.authToken, currentTemplate])

  // const loadFormatCanvas = (canvasReference, formatSide, templateImgAttribute) => {
  //   const width = 230
  //   const fabricCanvas = new fabric.Canvas(canvasReference.current, {
  //     preserveObjectStacking: true,
  //     renderOnAddRemove: false,
  //     backgroundColor: '#ffffff',
  //     width: formatSide.settings.width,
  //     height: formatSide.settings.height,
  //   })
  //   const ratio = fabricCanvas.getWidth() / fabricCanvas.getHeight()
  //   const scale = width / fabricCanvas.getWidth()
  //   const zoom  = fabricCanvas.getZoom() * scale
  //   fabricCanvas.setDimensions({ width, height: (width / ratio) })
  //   fabricCanvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0])
  //   fabricCanvas.loadFromJSON(formatSide.json, () => {
  //     fabricCanvas.renderAll.bind(fabricCanvas)
  //     const sideJpeg = fabricCanvas.toDataURL({
  //       format: 'jpeg',
  //       quality: 0.7,
  //       enableRetinaScaling: true
  //     })
  //     const jpegBlob = dataURIToBlob(sideJpeg)
  //     const formData = new FormData()
  //     formData.append(
  //       'file',
  //       jpegBlob,
  //       `${formatSide.id}.jpeg`
  //     )

  // console.log("before api call")

  //     HttpClient.post(
  //       `/upload?storageableType=Side&storageableId=${formatSide.id}`,
  //       formData,
  //       { Authorization: `Bearer ${authData.authToken}` }
  //     )
  //       .then(res => {
  //         let templateUpdateData = { tags: [], categoryIds: [] }
  //         templateUpdateData[templateImgAttribute] = res[0].path
  //         HttpClient.put(
  //           `/templates/${currentTemplate.id}`,
  //           templateUpdateData,
  //           { Authorization: `Bearer ${authData.authToken}` }
  //         )
  //           .catch(error => console.log('Error template update: ', error.response.status))
  //       })
  //   })

  //   console.log("After api call ******************************************")

  // }
  // Add refs for canvas
  // const canvasVerticalRef = useRef(null);
  // const canvasSquareRef = useRef(null);
  // const canvasHorizontalRef = useRef(null);


  const [verticalImagePath, setVerticalImagePath] = useState('aa');
  const [squareImagePath, setSquareImagePath] = useState('bb');
  const [horizontalImagePath, setHorizontalImagePath] = useState('cc');
  const [imagePaths, setImagePaths] = useState({
    verticalPath: '',
    squarePath: '',
    horizontalPath: ''
  });

  const loadAllCanvases = useCallback(async () => {
    const verticalSide = currentTemplate?.formats?.find(f => f.type === 2)?.sides?.[0];
    const squareSide = currentTemplate?.formats?.find(f => f.type === 3)?.sides?.[0];
    const horizontalSide = currentTemplate?.formats?.find(f => f.type === 1)?.sides?.[0];

    try {

      // Initialize local paths
      const newImagePaths = {
        verticalPath: '',
        squarePath: '',
        horizontalPath: ''
      };

      // Load images and update local paths
      if (verticalSide) {
        newImagePaths.verticalPath = await loadFormatCanvas(canvasVerticalRef, verticalSide, 'verticalPreviewImagePath');
      }

      if (squareSide) {
        newImagePaths.squarePath = await loadFormatCanvas(canvasSquareRef, squareSide, 'squarePreviewImagePath');
      }

      if (horizontalSide) {
        newImagePaths.horizontalPath = await loadFormatCanvas(canvasHorizontalRef, horizontalSide, 'horizontalPreviewImagePath');
      }

      setImagePaths(newImagePaths);
      setVerticalImagePath(newImagePaths.verticalPath);
      setSquareImagePath(newImagePaths.squarePath);
      setHorizontalImagePath(newImagePaths.horizontalPath);
    } catch (error) {
      console.error('Error loading canvases: ', error);
    }
  }, [currentTemplate, authData.authToken]);

  useEffect(() => {
    loadAllCanvases();
  }, [loadAllCanvases]);

  useEffect(() => {
    const updateTemplateIfPathsAvailable = async () => {
      if (verticalImagePath && horizontalImagePath && squareImagePath) {
        try {
          await handleUpdateTemplate();
        } catch (error) {
          console.error('Error updating template previews:', error);
        }
      }
    };
    updateTemplateIfPathsAvailable();
  }, [verticalImagePath, horizontalImagePath, squareImagePath]);


  const handleUpdateTemplate = async () => {
    try {
      const templateUpdateData = {
        tags: [],
        categoryIds: [],
        verticalPreviewImagePath: imagePaths.verticalPath,
        squarePreviewImagePath: imagePaths.squarePath,
        horizontalPreviewImagePath: imagePaths.horizontalPath
      };

      currentTemplate.id = Number(id);
      const updateResponse = await HttpClient.put(
        `/templates/${currentTemplate.id}`,
        templateUpdateData,
        { Authorization: `Bearer ${authData.authToken}` }
      );
    } catch (error) {
      console.error('Error updating template: ', error);
    }
  };

  const loadFormatCanvas = async (canvasReference, formatSide, templateImgAttribute) => {
    const width = 230;
    const fabricCanvas = new fabric.Canvas(canvasReference.current, {
      preserveObjectStacking: true,
      renderOnAddRemove: false,
      backgroundColor: '#ffffff',
      width: formatSide.settings.width,
      height: formatSide.settings.height,
    });

    const ratio = fabricCanvas.getWidth() / fabricCanvas.getHeight();
    const scale = width / fabricCanvas.getWidth();
    const zoom = fabricCanvas.getZoom() * scale;

    fabricCanvas.setDimensions({ width, height: width / ratio });
    fabricCanvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0]);

    try {
      const sideJpeg = await new Promise((resolve, reject) => {
        fabricCanvas.loadFromJSON(formatSide.json, () => {
          fabricCanvas.renderAll.bind(fabricCanvas)

          const sideJpeg = fabricCanvas.toDataURL({
            format: 'jpeg',
            quality: 1,
            enableRetinaScaling: true,
          });

          resolve(sideJpeg);
        });
      });

      const jpegBlob = dataURIToBlob(sideJpeg);
      if (!jpegBlob) {
        throw new Error(`Blob conversion failed for ${templateImgAttribute}`);
      }

      const formData = new FormData();
      formData.append('file', jpegBlob, `${formatSide.id}.jpeg`);

      const uploadResponse = await HttpClient.post(
        `/upload?storageableType=Side&storageableId=${formatSide.id}`,
        formData,
        { Authorization: `Bearer ${authData.authToken}` }
      );

      if (Array.isArray(uploadResponse) && uploadResponse.length > 0) {
        return uploadResponse[0].path;
      } else {
        throw new Error(`Unexpected upload response for ${templateImgAttribute}`);
      }
    } catch (error) {
      throw error;
    }
  };


  const getSideFonts = jsonString => {
    if (jsonString && jsonString !== '') {
      const sideJSON = JSON.parse(jsonString)
      if (sideJSON.objects !== undefined) {
        const textObjects = sideJSON.objects.filter(o => o.type === 'textbox' && o.fontFamily !== 'Times New Roman')
        return textObjects.map(o => o.fontFamily.replace(/\s/g, '+'))
      }
    }
    return []
  }

  const handleClickOpen = () => {
    for (let i = 0; i < validations.length; i++) {
      if (
        Object.keys(errors).length === 0 &&
        validations[i].requiredMinOne &&
        validations[i].value &&
        isEmpty(validations[i].value)
      ) {
        setOpen(true)
      } else if (
        Object.keys(errors).length === 0 &&
        validations[i].requiredMinOne &&
        !isEmpty(validations[i].value)
      ) {
        setOpen(false)
        return true
      }
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  const [params, setParams] = useState({
    name: get('name', currentTemplate),
    description: '',
    price: 0,
    categoryIds: [],
    tags: [],
  })

  const KeyCodes = {
    comma: 188,
    enter: 13,
  }

  const delimiters = [KeyCodes.comma, KeyCodes.enter]

  const selectableArrayHash = [
    {
      id: 0,
      value: setSelectKeywords,
      type: selectKeywords,
      paramsType: 'tags',
      errorKey: 'Keyword',
      table: 'tags',
    },
    { id: 1, value: setSelectedStyle, type: selectedStyle },
    {
      id: 2,
      value: setSelectedPersonalExpression,
      type: selectedPersonalExpression,
      paramsType: 'categoryIds',
      wordMaxLimit: 2,
      errorKey: 'Personal Expression',
      setResult: setPersonalExpressions,
      table: 'categories',
      typeId: 2,
    },
    { id: 3, value: setSelectIndustries, type: selectedIndustries },
    {
      id: 4,
      value: setSelectThemes,
      type: selectedThemes,
      paramsType: 'categoryIds',
      wordMaxLimit: 3,
      errorKey: 'Theme',
      setResult: setThemes,
      table: 'categories',
      typeId: 4,
    },
    {
      id: 5,
      value: setSelectOccasions,
      type: selectedOccasions,
      paramsType: 'categoryIds',
      wordMaxLimit: 3,
      errorKey: 'Occasion',
      setResult: setOccasions,
      table: 'categories',
      typeId: 5,
    },
  ]

  const handleCategoryValidation = useCallback(() => {
    let error = {}
    setErrors(error)
    for (let i = 0; i < validations.length; i++) {
      if (
        validations[i].value === '' ||
        (!validations[i].requiredMinOne &&
          validations[i].value &&
          isEmpty(validations[i].value))
      ) {
        setFormIsValid(false)
        error[validations[i].key] = [validations[i].key] + ' cannot be blank'
        setErrors(error)
      }
    }
  }, [validations])

  useEffect(() => {
    if (get('name', currentTemplate)) {
      setValidations([
        { key: 'Name', value: params.name },
        { key: 'Description', value: params.description },
        {
          key: 'Personal Expression',
          value: selectedPersonalExpression,
          requiredMinOne: true,
        },
        { key: 'Design Style', value: selectedStyle },
        { key: 'Industry', value: selectedIndustries, requiredMinOne: true },
        { key: 'Theme', value: selectedThemes, requiredMinOne: true },
        { key: 'Occasion', value: selectedOccasions, requiredMinOne: true },
      ])
    }
  }, [
    currentTemplate,
    params.name,
    params.description,
    selectedStyle,
    selectedPersonalExpression,
    selectedOccasions,
    selectedThemes,
    selectedIndustries,
    setFormIsValid,
  ])

  useEffect(() => {
    setLoading(true)
    HttpClient.get('/categories/search/3?approved=true', {
      Authorization: `Bearer ${authData.authToken}`,
    })
      .then(res => {
        setLoading(false)
        omitObject(res, selectableArrayHashById(3))
        setIndustries(res)
      })
      .catch(() => {
        setLoading(false)
        setIndustries([])
      })
  }, [authData.authToken, selectedIndustries])

  useEffect(() => {
    setLoading(true)
    HttpClient.get('/categories/search/1?approved=true', {
      Authorization: `Bearer ${authData.authToken}`,
    })
      .then(res => {
        setLoading(false)
        omitObject(res, selectableArrayHashById(1))
        setStyles(res)
      })
      .catch(() => {
        setLoading(false)
        setStyles([])
      })
  }, [authData.authToken, selectedStyle])

  const omitObject = (res, category) => {
    for (let i = 0; i < category.type.length; i++) {
      let index = res.findIndex(function (o) {
        return o.id === parseInt(category.type[i].id, 10)
      })
      if (index !== -1) res.splice(index, 1)
    }
    return res
  }

  const searchCategories = (text, category) => {
    if (/\S/.test(text)) {
      setLoading(true)
      HttpClient.get('/categories/search/' + category.id + '?approved=true&filter=' + text, {
        Authorization: `Bearer ${authData.authToken}`,
      })
        .then(res => {
          setLoading(false)
          omitObject(res, category)
          category.setResult([...tagHash(res)])
        })
        .catch(() => {
          setLoading(false)
          category.setResult([])
        })
    }
  }

  const fetchTags = text => {
    if (/\S/.test(text)) {
      setLoading(true)
      HttpClient.get('/tags?title=' + text, {
        Authorization: `Bearer ${authData.authToken}`,
      })
        .then(res => {
          setLoading(false)
          omitObject(res, selectableArrayHashById(0))
          setKeywords([...tagHash(res)])
        })

        .catch(() => {
          setLoading(false)
          setKeywords([])
        })
    }
  }

  const tagHash = res => {
    const allTags = []
    for (let i = 0; i < res.length; i++) {
      allTags.push({ id: '' + res[i].id, text: res[i].title })
    }
    return allTags
  }

  useEffect(() => {
    if (id) {
      setLoading(true)
      HttpClient.get(`/templates/${id}`, {
        Authorization: `Bearer ${authData.authToken}`,
      })
        .then(res => {
          setLoading(false)
          setCurrentTemplate(res)
        })
        .catch(() => {
          setLoading(false)
        })
    }
  }, [authData.authToken, id])

  const handleAddCategory = object => {
    setStyles([...styles, object])
  }

  const handleSelectedPersonalExpression = object => {
    setSelectedPersonalExpression([...selectedPersonalExpression, object])
  }

  const handleSelectedStyle = object => {
    setSelectedStyle([...selectedStyle, object])
  }

  const handleSelectedIndustry = object => {
    setSelectIndustries([...selectedIndustries, object])
  }

  const handleSelectedTheme = object => {
    setSelectThemes([...selectedThemes, object])
  }

  const handleSelectedOccasion = object => {
    setSelectOccasions([...selectedOccasions, object])
  }

  const handleSelectedKeyword = object => {
    setSelectKeywords([...selectKeywords, object])
  }

  const removeObject = (id, data, setData, type) => {
    const arr = data.filter(item => item.id !== id)
    setData(arr)
    let index = params[type].indexOf(parseInt(id, 10))
    if (index !== -1) {
      params[type].splice(index, 1)
    }
    handleCategoryValidation()
  }

  const draft = () => {
    HttpClient.put(`/templates/${id}`, params, { Authorization: `Bearer ${authData.authToken}` })
      .then(() => {
        goToTemplate()
      })
      .catch(error => console.log('Error publishing template: ', error))
  }

  const publish = () => {
    HttpClient.post(`/templates/${id}/publish_request`, params, { Authorization: `Bearer ${authData.authToken}` })
      .then(() => {
        setIsPublished(true)
      })
      .catch(error => console.log('Error publishing template: ', error))
  }

  const createTag = (tagParams, hash) => {
    setLoading(true)
    HttpClient.post(`/${hash.table}`, tagParams, {
      Authorization: `Bearer ${authData.authToken}`,
    })
      .then(res => {
        setLoading(false)
        hash.value([...hash.type, { id: '' + res.id, text: res.title }])
        params[hash.paramsType].push(parseInt(res.id, 10))
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const onInputChange = evt => {
    setParams({
      ...params,
      [evt.target.name]: evt.target.value,
    })
    handleCategoryValidation()
  }

  const onSubmit = e => {
    e.preventDefault()
    handleCategoryValidation()
    setIsSubmitted(true)
    if (formIsValid && handleClickOpen()) {
      params.price = parseFloat(params.price, 10)
      publish()
    }
  }

  const onDraftSubmit = e => {
    e.preventDefault()
    handleCategoryValidation()
    setIsSubmitted(true)
    if (formIsValid && handleClickOpen()) {
      params.price = parseFloat(params.price, 10)
      params['status'] = DRAFT_STATUS
      draft()
    }
  }

  const goToTemplate = () => {
    history.push(`/designer/template/${id}?timestamp=${new Date().toISOString()}`)
  }

  const goToDesignerPage = () => {
    history.push(`/designer?timestamp=${new Date().toISOString()}`)
  }

  const onKeyPress = e => {
    if (e.which === 13) {
      e.preventDefault()
    }
  }

  const addTag = (type, hash) => {
    let tag = document.getElementById(type)
    if (tag && tag.value) {
      tag = { id: '' + tag.value, text: tag.value }
      handleAddition(tag, hash)
      document.getElementById(type).value = ''
    }
  }

  useEffect(() => {
    if (get('name', currentTemplate)) {
      params.name = get('name', currentTemplate)
      params.description = get('description', currentTemplate) || ''
      params.price = get('price', currentTemplate) || params.price
      if (!isEmpty(currentTemplate.categories)) {
        for (let i = 0; i < currentTemplate.categories.length; i++) {
          if (currentTemplate.categories[i]) {
            params.categoryIds.push(currentTemplate.categories[i].categoryId)
          }
        }
        for (let i = 0; i < selectableArrayHash.length; i++) {
          let array = []
          for (let j = 0; j < currentTemplate.categories.length; j++) {
            if (currentTemplate.categories[j].category.type === selectableArrayHash[i].id) {
              let v = selectableArrayHash[i].value
              array.push(currentTemplate.categories[j].category)
              v(array)
            }
          }
        }
      }
      if (!isEmpty(currentTemplate.tags)) {
        let array = []
        for (let i = 0; i < currentTemplate.tags.length; i++) {
          params.tags.push(currentTemplate.tags[i].tagId)
          array.push(currentTemplate.tags[i].tag)
          setSelectKeywords(array)
        }
      }
    }
  }, [currentTemplate])

  useEffect(() => {
    if (Object.keys(errors).length === 0) {
      setFormIsValid(true)
    } else {
      setFormIsValid(false)
    }
  }, [errors])

  useEffect(() => {
    handleCategoryValidation()
  }, [isSubmitted, currentTemplate, validations])

  const handleAddition = (tag, hash) => {
    let error = {}
    if (errors[hash.errorKey]) {
      delete errors[hash.errorKey]
    }
    if (!parseInt(tag.id, 10)) {
      if (tag.text.split(' ').length > hash.wordMaxLimit) {
        error[hash.errorKey] =
          'You can add maximum ' + hash.wordMaxLimit + ' words'
        setErrors(error)
        return false
      }
      createTag({ title: tag.text, type: hash.typeId }, hash)
    } else {
      hash.value([...hash.type, { id: '' + tag.id, text: tag.text }])
      params[hash.paramsType].push(parseInt(tag.id, 10))
    }
  }

  const selectableArrayHashById = id => {
    return selectableArrayHash.find(x => x.id === id)
  }

  const sortByName = data => {
    data.sort((a, b) =>
      a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1
    )
    return data
  }

  return (
    <>
      <div className={classes.loader}>
        <PropagateLoader color={'#bdbdbd'} loading={loading} size={25} />
      </div>
      <Box overflow="auto" width="100%">
        {currentTemplate && currentTemplate.horizontal && (
          <form onSubmit={onSubmit} onKeyPress={onKeyPress}>
            <Box className={classes.mainCardWrapper}>
              <h3 className={classes.pageTitle}>Publish your template</h3>
              <Box className={classes.cardContentWrapper}>
                <Box className={classes.contentLeftSection}>
                  <Box marginBottom={2}>
                    <canvas
                      id={`canvas-vertical-${currentTemplate.id}`}
                      ref={canvasVerticalRef}
                      style={{ borderRadius: `${currentTemplate.vertical.cornerRadius}px` }}
                    />
                  </Box>
                  <Box marginBottom={2}>
                    <canvas
                      id={`canvas-square-${currentTemplate.id}`}
                      ref={canvasSquareRef}
                      style={{ borderRadius: `${currentTemplate.square.cornerRadius}px` }}
                    />
                  </Box>
                  <Box marginBottom={2}>
                    <canvas
                      id={`canvas-horizontal-${currentTemplate.id}`}
                      ref={canvasHorizontalRef}
                      style={{ borderRadius: `${currentTemplate.horizontal.cornerRadius}px` }}
                    />
                  </Box>
                </Box>
                <Box className={classes.contentRightSection}>
                  {!isPublished ? (
                    <div>
                      {EDITABLE_STATUSES.includes(
                        get('status', currentTemplate)
                      ) && (
                          <Box align={'right'} mb={6}>
                            {/* <button
                            type="button"
                            onClick={() => history.push(`/designer?timestamp=${new Date().toISOString()}`)}
                            className={classes.saveDraftsBtn}
                          >
                            Cancel
                          </button>
                          <button
                            onClick={onDraftSubmit}
                            className={classes.saveDraftsBtn}
                          >
                            Save to Drafts
                          </button>
                          <button className={classes.submittingsBtn}>
                            Submit for Publishing
                          </button> */}
                          </Box>
                        )}
                      {REQUEST_STATUS === get('status', currentTemplate) && (
                        <Box align={'right'} mb={4}>
                          <button
                            onClick={onDraftSubmit}
                            className={classes.saveDraftsBtn}
                          >
                            Cancel Request
                          </button>
                        </Box>
                      )}
                      {PUBLISHED_STATUS === get('status', currentTemplate) && (
                        <Box align={'right'} mb={6}>
                          <button
                            type="button"
                            onClick={() => history.push(`/designer?timestamp=${new Date().toISOString()}`)}
                            className={classes.saveDraftsBtn}
                          >
                            Cancel
                          </button>
                          <button className={classes.submittingsBtn}>
                            Submit for Publishing
                          </button>
                        </Box>
                      )}
                      <Grid container className={classes.marginBottomHundred}>
                        <Grid item xs={12} md={3}>
                          <label>
                            Card Display name<span>*</span>
                          </label>
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <input
                            autoFocus
                            type={'text'}
                            className={classes.inputStyling}
                            defaultValue={currentTemplate.name}
                            onChange={onInputChange}
                            name="name"
                          />
                          {isSubmitted && (
                            <span className={classes.error}>
                              {errors['Name']}
                            </span>
                          )}
                        </Grid>
                      </Grid>
                      <Grid container className={classes.marginBottom30}>
                        <Grid item xs={12} md={3}>
                          <label>
                            Short Description<span>*</span>
                          </label>
                          <Box className={classes.titleDescription} mt={2}>
                            A short desription that describes your card.
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <textarea
                            className={classes.textareaWidth}
                            onChange={onInputChange}
                            name="description"
                            defaultValue={currentTemplate.description}
                          // handleCategoryValidation={handleCategoryValidation}
                          />
                          {isSubmitted && (
                            <span className={classes.error}>
                              {errors['Description']}
                            </span>
                          )}
                        </Grid>
                      </Grid>
                      <Typography
                        variant="h5"
                        component="h2"
                        className={classes.typoText}
                      >
                        Enter a style description(s) for your design
                      </Typography>
                      <Divider className={classes.dividerBottom} />
                      <Grid container className={classes.marginBottom60}>
                        <Grid item xs={12} md={3}>
                          <label>
                            Design Style(s)<span>*</span>
                          </label>
                          <Box className={classes.titleDescription} mt={2}>
                            Choose the style descriptions you think apply to
                            this design.
                          </Box>
                          <Box className={classes.textMuted}>
                            Choose as many as you think apply.
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <Autocomplete
                            hideIcon={true}
                            options={sortByName(styles)}
                            placeholder="Select styles that apply to this design?"
                            onAddCategory={handleSelectedStyle}
                            params={params}
                            isCategory={true}
                            handleCategoryValidation={handleCategoryValidation}
                            selectedValues={selectedStyle}
                          />
                          {isSubmitted && (
                            <span className={classes.error}>
                              {errors['Design Style']}
                            </span>
                          )}
                          <Box my={2} display="flex">
                            {selectedStyle.map((style, index) => (
                              <Box
                                key={index}
                                component="span"
                                display="inline-block"
                                bgcolor="#e9e3ff"
                                py={0.5}
                                px={1}
                                m={0.5}
                                borderRadius={4}
                                className={classes.chip}
                              >
                                {style.title}
                                <span className={classes.borderRight}></span>
                                <CancelIcon
                                  onClick={() =>
                                    removeObject(
                                      style.id,
                                      selectedStyle,
                                      setSelectedStyle,
                                      'categoryIds'
                                    )
                                  }
                                  fontSize="small"
                                  className={classes.icon}
                                />
                              </Box>
                            ))}
                          </Box>
                        </Grid>
                      </Grid>
                      <Typography
                        variant="h5"
                        component="h2"
                        className={classes.typoText}
                      >
                        Choose a category (1 or more) for your design, then
                        select or enter descriptions
                      </Typography>
                      <Divider className={classes.dividerBottom} />
                      <Grid container className={classes.marginBottomHundred}>
                        <Grid item xs={12} md={3}>
                          <label>Personal Expression</label>
                          <Box className={classes.titleDescription} mt={2}>
                            Give a description to the personal expression you’ve
                            developed.
                          </Box>
                        </Grid>
                        <Grid item xs={10} md={8}>
                          <div className={classes.inputTags}>
                            <ReactTags
                              suggestions={personalExpressions}
                              handleAddition={event =>
                                handleAddition(
                                  event,
                                  selectableArrayHashById(2)
                                )
                              }
                              delimiters={delimiters}
                              autofocus={false}
                              handleInputChange={event =>
                                searchCategories(
                                  event,
                                  selectableArrayHashById(2)
                                )
                              }
                              placeholder="Enter a 1 or 2 word expression that describes your design?"
                              id={'personalExpressionTag'}
                            />
                          </div>
                          <span className={classes.error}>
                            {errors['Personal Expression']}
                          </span>
                          <Box my={2} display="flex">
                            {selectedPersonalExpression.map(
                              (personalExpression, index) => (
                                <Box
                                  key={index}
                                  component="span"
                                  display="inline-block"
                                  bgcolor="#e9e3ff"
                                  py={0.5}
                                  px={1}
                                  m={0.5}
                                  borderRadius={4}
                                  className={classes.chip}
                                >
                                  {personalExpression.text ||
                                    personalExpression.title}
                                  <span className={classes.borderRight}></span>
                                  <CancelIcon
                                    onClick={() =>
                                      removeObject(
                                        personalExpression.id,
                                        selectedPersonalExpression,
                                        setSelectedPersonalExpression,
                                        'categoryIds'
                                      )
                                    }
                                    fontSize="small"
                                    className={classes.icon}
                                  />
                                </Box>
                              )
                            )}
                          </Box>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          md={1}
                          onClick={() =>
                            addTag(
                              'personalExpressionTag',
                              selectableArrayHashById(2)
                            )
                          }
                        >
                          <Button
                            className={classes.btnTextColor}
                            size="small"
                            variant="outlined"
                          >
                            Add
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid container className={classes.marginBottom60}>
                        <Grid item xs={12} md={3}>
                          <label>Business</label>
                          <Box className={classes.titleDescription} mt={2}>
                            Define which industry or industries you think this
                            design might be best for.
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <Autocomplete
                            hideIcon={true}
                            options={sortByName(industries)}
                            placeholder="Choose business types or industries where your design could apply?"
                            onAddCategory={handleSelectedIndustry}
                            params={params}
                            isCategory={true}
                            handleCategoryValidation={handleCategoryValidation}
                            selectedValues={selectedIndustries}
                          />
                          {isSubmitted && (
                            <span className={classes.error}>
                              {errors['Industry']}
                            </span>
                          )}
                          <Box my={2} display="flex">
                            {selectedIndustries.map((industry, index) => (
                              <Box
                                key={index}
                                component="span"
                                display="inline-block"
                                bgcolor="#e9e3ff"
                                py={0.5}
                                px={1}
                                m={0.5}
                                borderRadius={4}
                                className={classes.chip}
                              >
                                {industry.title}
                                <span className={classes.borderRight}></span>
                                <CancelIcon
                                  onClick={() =>
                                    removeObject(
                                      industry.id,
                                      selectedIndustries,
                                      setSelectIndustries,
                                      'categoryIds'
                                    )
                                  }
                                  fontSize="small"
                                  className={classes.icon}
                                />
                              </Box>
                            ))}
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid container className={classes.marginBottom60}>
                        <Grid item xs={12} md={3}>
                          <label>Theme</label>
                          <Box className={classes.titleDescription} mt={2}>
                            Define the theme/concept.
                          </Box>
                        </Grid>
                        <Grid item xs={10} md={8}>
                          <div className={classes.inputTags}>
                            <ReactTags
                              suggestions={themes}
                              handleAddition={event =>
                                handleAddition(
                                  event,
                                  selectableArrayHashById(4)
                                )
                              }
                              delimiters={delimiters}
                              autofocus={false}
                              handleInputChange={event =>
                                searchCategories(
                                  event,
                                  selectableArrayHashById(4)
                                )
                              }
                              placeholder="Enter a 1 to 3 words that describes theme of your design?"
                              id={'themeTag'}
                            />
                          </div>
                          <span className={classes.error}>
                            {errors['Theme']}
                          </span>
                          <Box my={2} display="flex">
                            {selectedThemes.map((theme, index) => (
                              <Box
                                key={index}
                                component="span"
                                display="inline-block"
                                bgcolor="#e9e3ff"
                                py={0.5}
                                px={1}
                                m={0.5}
                                borderRadius={4}
                                className={classes.chip}
                              >
                                {theme.text || theme.title}
                                <span className={classes.borderRight}></span>
                                <CancelIcon
                                  onClick={() =>
                                    removeObject(
                                      theme.id,
                                      selectedThemes,
                                      setSelectThemes,
                                      'tags'
                                    )
                                  }
                                  fontSize="small"
                                  className={classes.icon}
                                />
                              </Box>
                            ))}
                          </Box>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          md={1}
                          onClick={() =>
                            addTag('themeTag', selectableArrayHashById(4))
                          }
                        >
                          <Button
                            className={classes.btnTextColor}
                            size="small"
                            variant="outlined"
                          >
                            Add
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid container className={classes.marginBottom60}>
                        <Grid item xs={12} md={3}>
                          <label>Occasion</label>
                          <Box className={classes.titleDescription} mt={2}>
                            Add to cards designed for the occasion, for example
                            graduation party decor.
                          </Box>
                        </Grid>
                        <Grid item xs={10} md={8}>
                          <div className={classes.inputTags}>
                            <ReactTags
                              suggestions={occasions}
                              handleAddition={event =>
                                handleAddition(
                                  event,
                                  selectableArrayHashById(5)
                                )
                              }
                              delimiters={delimiters}
                              autofocus={false}
                              handleInputChange={event =>
                                searchCategories(
                                  event,
                                  selectableArrayHashById(5)
                                )
                              }
                              placeholder="Enter a 1 to 3 words that describes the occasion."
                              id={'occasionTag'}
                            />
                          </div>
                          <span className={classes.error}>
                            {errors['Occasion']}
                          </span>
                          <Box my={2} display="flex">
                            {selectedOccasions.map((occasion, index) => (
                              <Box
                                key={index}
                                component="span"
                                display="inline-block"
                                bgcolor="#e9e3ff"
                                py={0.5}
                                px={1}
                                m={0.5}
                                borderRadius={4}
                                className={classes.chip}
                              >
                                {occasion.title || occasion.text}
                                <span className={classes.borderRight}></span>
                                <CancelIcon
                                  onClick={() =>
                                    removeObject(
                                      occasion.id,
                                      selectedOccasions,
                                      setSelectOccasions,
                                      'tags'
                                    )
                                  }
                                  fontSize="small"
                                  className={classes.icon}
                                />
                              </Box>
                            ))}
                          </Box>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          md={1}
                          onClick={() =>
                            addTag('occasionTag', selectableArrayHashById(5))
                          }
                        >
                          <Button
                            className={classes.btnTextColor}
                            size="small"
                            variant="outlined"
                          >
                            Add
                          </Button>
                        </Grid>
                      </Grid>
                      <Typography
                        variant="h5"
                        component="h2"
                        className={classes.typoText}
                      >
                        Enter keywords that might apply (optional)
                      </Typography>
                      <Divider className={classes.dividerBottom} />
                      <Grid container className={classes.marginBottom60}>
                        <Grid item xs={12} md={3}>
                          <label>Key Words</label>
                          <Box className={classes.titleDescription} mt={2}>
                            Define keywords that apply. Maximum 10 key words.
                          </Box>
                        </Grid>
                        <Grid item xs={10} md={8}>
                          <div className={classes.inputTags}>
                            <ReactTags
                              suggestions={keywords}
                              handleAddition={event =>
                                handleAddition(
                                  event,
                                  selectableArrayHashById(0)
                                )
                              }
                              delimiters={delimiters}
                              handleInputChange={fetchTags}
                              autofocus={false}
                              placeholder="Shape, color, type, function, etc. "
                              readOnly={selectKeywords.length === 10}
                              id={'keywordTag'}
                            />
                          </div>
                          <span className={classes.error}>
                            {errors['Keyword']}
                          </span>
                          <Box my={2} display="flex">
                            {selectKeywords.map((keyword, index) => (
                              <Box
                                key={index}
                                component="span"
                                display="inline-block"
                                bgcolor="#e9e3ff"
                                py={0.5}
                                px={1}
                                m={0.5}
                                borderRadius={4}
                                className={classes.chip}
                              >
                                {keyword.title || keyword.text}
                                <span className={classes.borderRight}></span>
                                <CancelIcon
                                  onClick={() =>
                                    removeObject(
                                      keyword.id,
                                      selectKeywords,
                                      setSelectKeywords,
                                      'tags'
                                    )
                                  }
                                  fontSize="small"
                                  className={classes.icon}
                                />
                              </Box>
                            ))}
                          </Box>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          md={1}
                          onClick={() =>
                            addTag('keywordTag', selectableArrayHashById(0))
                          }
                        >
                          <Button
                            className={classes.btnTextColor}
                            size="small"
                            variant="outlined"
                          >
                            Add
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid container className={classes.marginBottom60}>
                        <Grid item xs={12} md={3}>
                          <label>
                            Sale Price<span>*</span>
                          </label>
                          <Box className={classes.titleDescription} mt={2}>
                            Choose the price you like for this design.
                          </Box>
                          <Box className={classes.titleDescription} mt={2}>
                            You will received 60% of the sale price.
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <RadioButton
                            onInputChange={onInputChange}
                            defaultValue={params.price}
                          />
                        </Grid>
                      </Grid>
                      {EDITABLE_STATUSES.includes(
                        get('status', currentTemplate)
                      ) && (
                          <Box align={'right'} mb={4}>
                            <button
                              type="button"
                              onClick={() => history.push(`/designer?timestamp=${new Date().toISOString()}`)}
                              className={classes.saveDraftsBtn}
                            >
                              Cancel
                            </button>
                            <button
                              onClick={onDraftSubmit}
                              className={classes.saveDraftsBtn}
                            >
                              Save to Drafts
                            </button>
                            <button className={classes.submittingsBtn}>
                              Submit for Publishing
                            </button>
                          </Box>
                        )}
                      {REQUEST_STATUS === get('status', currentTemplate) && (
                        <Box align={'right'} mb={4}>
                          <button
                            onClick={onDraftSubmit}
                            className={classes.saveDraftsBtn}
                          >
                            Cancel Request
                          </button>
                        </Box>
                      )}
                    </div>
                  ) : (
                    <div className={classes.thankyouMsg}>
                      <Box align={'right'} mb={6}></Box>
                      <Grid container className={classes.marginBottom60}>
                        <Grid item xs={12} md={12}>
                          <p>Thank you for your template design.</p>
                        </Grid>
                      </Grid>
                      <Grid container className={classes.marginBottom60}>
                        <Grid item xs={12} md={12}>
                          <span>
                            Your design and description will be reviewed by our
                            customer service team, you will receive a message as
                            soon as it’s been published. If there is anything
                            that requires your attention, we’ll be in touch.
                          </span>
                        </Grid>
                      </Grid>
                      <Grid container item sm={2} justify="flex-end">
                        <Link onClick={goToDesignerPage}>
                          Go back to Dashboard
                        </Link>
                      </Grid>
                    </div>
                  )}
                </Box>
              </Box>
            </Box>
          </form>
        )}
      </Box>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {'Category Validation'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You need to choose at least one category from Personal Expression
              or Business or Theme or Occasion.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  )
}

TemplateSubmitForm.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
}

export default TemplateSubmitForm
