import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import { TextField, Button } from '@material-ui/core'
import {
  Dialog,
  DialogContent,
  DialogActions
} from '@material-ui/core'
import { Grid, Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

import HttpClient from '../../../../services/HttpClient'
import AuthContext from '../../../../contexts/AuthContext'

const useStyles = makeStyles(() => ({
  logo: {
    fontSize: '4.4rem',
    marginTop: '10%',
  },
  actions: {
    marginTop: '1%',
    marginBottom: '5%',
    justifyContent: 'flex-end',
  },
  format: {
    '&:not(.active)': {
      cursor: 'pointer',
    },
    '& .thumb': {
      height: 0,
      outline: 'none',
      backgroundColor: '#e1eaff',
      border: '2px solid #fff',
      borderRadius: '8%',
    },
    '& .title': {
      fontSize: 12,
    },
    '&.active': {
      '& .thumb': {
        border: '2px solid #005bff',
      },
      '& .title': {
        color: '#0a60fe',
      },
    }
  },
  format_1: {
    paddingBottom: '60%',
  },
  format_2: {
    paddingBottom: '125%',
  },
  format_3: {
    paddingBottom: '100%',
  },
}))

const formatsSettings = [
  {
    width: 4320,
    height: 2592,
  },
  {
    width: 4320,
    height: 5400,
  },
  {
    width: 4320,
    height: 4320,
  },
]

const New = props => {
  const classes = useStyles()
  const [authData] = useContext(AuthContext)
  const [template, setTemplate] = useState({ name: '', initialFormat: 2 })
  const [errors, setErrors] = useState({ name: '' })

  const handleSubmit = e => {
    e.preventDefault()
    const _errors = { name: '' }
    let isValid = true
    if(template.name === '') {
      isValid = false
      _errors.name = 'Required'
    }
    if(isValid) {
      const templateData = {
        name: template.name,
        initialFormat: template.initialFormat,
        colors: [],
        format: {
          type: template.initialFormat,
          cornerRadius: 8,
          sides: [
            {
              position: 0,
              svg: '',
              json: '',
              historyNextState: '',
              historyUndo: '[]',
              historyRedo: '[]',
              settings: formatsSettings[template.initialFormat-1],
            }
          ]
        }
      }
      HttpClient.post('/templates', templateData, { Authorization: `Bearer ${authData.authToken}` })
        .then(res => props.onCreate(res.id))
        .catch(error => console.log('Error creating template: ', error))
    } else {
      setErrors(_errors)
    }
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth="xs"
      open={true}
      onClose={props.onClose}
      aria-labelledby="new-template-title"
    >
      {/* <DialogTitle id="new-template-title">
        <LogoIcon className={classes.logo} />
      </DialogTitle> */}
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Typography variant="h6" component="h2" color="textPrimary" gutterBottom>New Template:</Typography>
          <TextField
            id="template-name"
            label="Template Name"
            value={template.name}
            onChange={e => setTemplate({ ...template, name: e.currentTarget.value })}
            margin="normal"
            variant="outlined"
            size="small"
            fullWidth
            autoFocus
            required
            error={errors.name !== ''}
            helperText={errors.name}
          />
          <Box>
            <Box paddingTop={2} paddingBottom={1}>Initial format:</Box>
            <Grid container spacing={1}>
              <Grid
                item
                sm={3}
                onClick={() => setTemplate({ ...template, initialFormat: 2 })}
                className={`${classes.format} ${template.initialFormat === 2 && 'active'}`}
              >
                <Box
                  className={`${classes.format_2} thumb`}  
                />
                <Box className="title" textAlign="center">Vertical</Box>
              </Grid>
              <Grid
                item
                sm={3}
                onClick={() => setTemplate({ ...template, initialFormat: 3 })}
                className={`${classes.format} ${template.initialFormat === 3 && 'active'}`}
              >
                <Box
                  className={`${classes.format_3} thumb`}  
                />
                <Box className="title" textAlign="center">Square</Box>
              </Grid>
              <Grid
                item
                sm={3}
                onClick={() => setTemplate({ ...template, initialFormat: 1 })}
                className={`${classes.format} ${template.initialFormat === 1 && 'active'}`}
              >
                <Box
                  className={`${classes.format_1} thumb`}  
                />
                <Box className="title" textAlign="center">Horizontal</Box>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button
            variant="contained"
            size="small"
            onClick={props.onClose}
          >
            Close
          </Button>
          <Button type="submit" variant="contained" color="primary" size="small">Create</Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

New.propTypes = {
  onClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
}

export default New
