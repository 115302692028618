import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'

import { DialogContent, DialogActions } from '@material-ui/core/'
import { Grid, Box } from '@material-ui/core'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'

import HttpClient from '../../../../../services/HttpClient'
import AuthContext from '../../../../../contexts/AuthContext'

const Final = props => {
  const [authData] = useContext(AuthContext)
  const [loading, setLoading] = useState(false)
  const [toDashboard, setToDashboard] = useState(false)
  
  const handleSubmit = () => {
    setLoading(true)
    HttpClient.post(`/templates/${props.template.id}/publish`, { categoryIds: props.categories.map(c => c.id) }, { Authorization: `Bearer ${authData.authToken}` })
      .then(() => {
        setToDashboard(true)
      })
      .catch(error => {
        console.error('Error publish template: ', error)
        setLoading(false)
      })
  }

  return (
    toDashboard ?
      <Redirect to='/designer' />
      :
      <React.Fragment>
        <DialogContent>
          <Box my={2}>
            <Grid container>
              <Grid item sm={10}>Your template name</Grid>
              <Grid container item sm={2} justify="flex-end">
                <Link href="#" onClick={props.onNameEdit}>edit</Link>
              </Grid>
            </Grid>
            <Typography variant="h6">{props.template.name}</Typography>
          </Box>
          <Box my={2}>
            <Divider />
          </Box>
          <Box my={2}>
            <Grid container>
              <Grid item sm={10}>Industries categories</Grid>
              <Grid container item sm={2} justify="flex-end">
                <Link href="#" onClick={props.onIndustriesEdit}>edit</Link>
              </Grid>
            </Grid>
            <Box ml={-0.5} mt={0.5}>
              {props.categories.filter(c => c.type === 1).map((category, index) => (
                <Box
                  key={index}
                  component="span"
                  display="inline-block"
                  bgcolor="#e9e3ff"
                  py={0.5}
                  px={1}
                  m={0.5}
                  borderRadius={4}
                >
                  {category.title}
                </Box>
              ))}
            </Box>
          </Box>
          <Box my={2}>
            <Divider />
          </Box>
          <Box my={2}>
            <Grid container>
              <Grid item sm={10}>Styles categories</Grid>
              <Grid container item sm={2} justify="flex-end">
                <Link href="#" onClick={props.onStylesEdit}>edit</Link>
              </Grid>
            </Grid>
            <Box ml={-0.5} mt={0.5}>
              {props.categories.filter(c => c.type === 2).map((category, index) => (
                <Box
                  key={index}
                  component="span"
                  display="inline-block"
                  bgcolor="#e9e3ff"
                  py={0.5}
                  px={1}
                  m={0.5}
                  borderRadius={4}
                >
                  {category.title}
                </Box>
              ))}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="small"
            onClick={handleSubmit}
          >
            Publish
          </Button>
        </DialogActions>
      </React.Fragment> 
  )
}

Final.propTypes = {
  template: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
  onNameEdit: PropTypes.func.isRequired,
  onIndustriesEdit: PropTypes.func.isRequired,
  onStylesEdit: PropTypes.func.isRequired,
}

export default Final
