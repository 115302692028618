import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles(() => ({
  icon: {
    fontSize: '2em'
  },
  caption: {
    marginTop: '-5px',
    fontSize: '0.6em',
    textAlign: 'center',
  }
}))

const MenuBox = ({ icon: Icon, label }) => {
  const classes = useStyles()
  
  return (
    <Box position="relative" width={1} paddingBottom="100%">
      <Box
        position="absolute"
        display="flex"
        alignItems="center"
        textAlign="center"
        bgcolor="#000"
        top="12%"
        left="12%"
        bottom="12%"
        right="12%"
        borderRadius={14}
      >
        <Box margin="0 auto" p={1} textAlign="center">
          <Icon fontSize="large" className={classes.icon} />
          <div className={classes.caption}>{label}</div>
        </Box>
      </Box>
    </Box>
  )
}

MenuBox.propTypes = {
  icon: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]).isRequired,
  label: PropTypes.string.isRequired,
}

export default MenuBox
