import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import './style.css'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  vCardDownloaded: {
    lineHeight: 'normal',
    width: '50%',
    height: '100%',
    position: 'absolute',
    top: '50%',
    left: '30%',
    right:'35%',
    margin: '-25px 0 0 -25px',
    "& p": {
      fontSize: '50px',
      fontWeight: '600'
    }
  },
  blueColor:{
    color:'#25ACE8',
  },
  }
))

const VcardDownloader = () => {
    const { token } = useParams()
    const classes = useStyles()
    const baseUrl = process.env['REACT_APP_API_BASE_URL']
    useEffect(() => {
      const fileDownload = require('js-file-download')
      axios.get(`${ baseUrl }/cards/${token}/vcard`, {
        responseType: 'blob',
      }).then((res) => {
        fileDownload(res.data, `be-vcard.vcf`)
      })
      }, [token])

      return (
        <div className={classes.vCardDownloaded}>
         <p>
          <span className={classes.blueColor}>Thank you</span> for downloading Vcard.
         </p>
      </div>
        )
            
}

export default VcardDownloader
