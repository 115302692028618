import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import { Box, Grid } from '@material-ui/core'
import Slider from '@material-ui/core/Slider'
import TextField from '@material-ui/core/TextField'

const useStyles = makeStyles(() => ({
  slider: {
    padding: 0
  },
  input: {
    '& .MuiOutlinedInput-root input': {
      paddingTop: '5.5px',
      paddingBottom: '5.5px',
    },
  },
}))

const FontSize = props => {
  const classes = useStyles()
  const [fontSize, setFontSize] = useState(props.fontSize)

  const handleFontSizeChange = size => {
    setFontSize(size)
    props.onAddAction({
      type: 'text', property: 'fontSize', value: size
    })
  }

  return (
    <Box px={2} paddingBottom={2}>
      <Box py={1}>
        <Grid container spacing={1}>
          <Grid item xs={3}>Font Size</Grid>
          <Grid item xs={7}>
            <Slider
              value={fontSize}
              max={150}
              onChange={(e, value) => setFontSize(value)}
              onChangeCommitted={(e, value) => handleFontSizeChange(value)}
              className={classes.slider}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              id="font-size"
              type="number"
              size="small"
              variant="outlined"
              value={fontSize}
              onChange={e => handleFontSizeChange(e.currentTarget.value)}
              className={classes.input}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

FontSize.propTypes =  {
  fontSize: PropTypes.number.isRequired,
  onAddAction: PropTypes.func.isRequired,
}

export default FontSize
