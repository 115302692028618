import React, {useContext, useState, useEffect} from 'react'
import HttpClient from '../../../../services/HttpClient'
import AuthContext from '../../../../contexts/AuthContext'
import {makeStyles} from '@material-ui/core/styles'
import {DialogContent} from '@material-ui/core'
import {Button, IconButton} from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import {Box, Grid} from '@material-ui/core'
import multiSided from '../../../../images/multiSided.png'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '20px',
    borderRadius: '20px',
  },
  title: {
    paddingTop: '11px',
    padding: '0px',
    paddingRight: '26px',
    fontSize: '19px',
    fontWeight: 'bold',
  },
  price: {
    color: 'black',
    padding: '0px',
    fontSize: '13px',
  },
  content: {
    padding: '0px',
    fontSize: '14px',
  },
  icon: {
    height: '47px',
  },
  bold: {
    fontWeight: 'bold',
  },
  flexBox: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '-14px',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    color: 'white',
    textAlign: 'center',
    justifyContent: 'space-evenly',
  },
  cancelButton: {
    backgroundColor: '#393c40',
    alignItems: 'center',
    borderRadius: '18px',
    paddingLeft: '27px',
    paddingRight: '27px',
    height: '32px',
  },
  purchaseButton: {
    backgroundColor: '#393c40',
    alignItems: 'center',
    borderRadius: '18px',
    paddingLeft: '27px',
    paddingRight: '27px',
    height: '32px',
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function MultiSidedDialog({
  open,
  close,
  card,
  saveSide,
  templateId,
}) {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [authData] = useContext(AuthContext)

  const handleBackClick = () => {
    close(false)
  }

  const handlePurchasedEvent = () => {
    console.log(`templateId:${templateId}`)
    if (window.Print !== undefined)
      window.Print.postMessage(`upgradePlan-${templateId}`)
  }

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <div className={classes.root}>
          <div className={classes.flexBox}>
            <DialogTitle className={classes.title}>
              Multi-Sided Design
            </DialogTitle>
            <img className={classes.icon} src={multiSided}></img>
          </div>
          <br />
          <DialogContent className={classes.content}>
            <DialogContentText className={classes.content}>
              A multi-page card design that you can fully edit both on the app
              and on the website.A one time purchase.
            </DialogContentText>
            <DialogContentText className={classes.price}>
            {/* CardPrices.multiSided */}
              Price: $1.50
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.buttons}>
            <Button
              classes={{
                root: classes.cancelButton,
              }}
              style={{
                backgroundColor: '#393c40',
              }}
              variant="contained"
              onClick={handleBackClick}
            >
              <Box
                display="inline"
                fontSize="14px"
                fontWeight="900"
                color="white"
              >
                Cancel
              </Box>
            </Button>
            <Button
              classes={{
                root: classes.purchaseButton,
              }}
              style={{
                backgroundColor: '#9933ff',
              }}
              variant="contained"
            >
              <Box
                display="inline"
                fontSize="14px"
                fontWeight="900"
                color="white"
                onClick={handlePurchasedEvent}
              >
                Purchase
              </Box>
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  )
}
