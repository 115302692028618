import React from 'react'
import PropTypes from 'prop-types'
import { Box, Grid } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '13px',
    fontWeight: 600,
    marginLeft: '15px',
    textDecoration: 'none !important' 
  },
  link: {
    color: '#6495ED',
    textDecoration: 'none',
    fontSize: '16px',
    marginRight: '16px',
  },
}))

const CategoryHeading = ({ title, to }) => {
  const classes = useStyles()

  return (
    <Box>
      <Grid container>
        <Grid item xs={8}>
          <Typography gutterBottom variant="h6" className={classes.title}>{title}</Typography>
        </Grid>
        <Grid container item xs={4} justifyContent="flex-end" alignItems="center">
          <Link
            to={to}
            className={classes.link}
          >see all</Link>
        </Grid>
      </Grid>    
    </Box> 
  )
}

CategoryHeading.propTypes = {
  to: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

export default CategoryHeading
