import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Box, Paper, AppBar, Button, IconButton } from '@material-ui/core'
import { Menu, MenuItem } from '@material-ui/core'
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap'

import { SideContextProvider } from '../../../../contexts/SideContext'
import { NavigationContextProvider } from '../../../../contexts/NavigationContext'
import { ActionContextProvider } from '../../../../contexts/ActionContext'
import HttpClient from '../../../../services/HttpClient'
import AuthContext from '../../../../contexts/AuthContext'
import Navigation from './Navigation'
import TitleBar from './TitleBar'
import Format from './Formats/Format'
import Previews from './Formats/Previews'
// import { TemplateContext } from '../../../../contexts/TemplateContext'

const initialTemplate = {
  id: 'temp-1',
  name: 'Name Your Template',
  initialFormat: 3,
  colors: [],
  // formats: [
  //   {
  //     id: 'temp-1-1',
  //     type: 1,
  //     cornerRadius: 0,
  //     sides: [
  //       {
  //         id: 'temp-1-1-1',
  //         position: 0,
  //         svg: null,
  //         json: null,
  //         undo: 0,
  //         redo: 0
  //       },
  //     ]
  //   }
  // ]
}
const formatsSettings = [
  {
    width: 4320,
    height: 2592,
  },
  {
    width: 4320,
    height: 5400,
  },
  {
    width: 4320,
    height: 4320,
  }
]
const zoomOptions = [300, 200, 125, 100, 75, 50, 25, 10]

const Template = ({ match }) => {
  const [authData] = useContext(AuthContext)
  const [template, setTemplate] = useState(initialTemplate)
  const [currentFormatType, setCurrentFormatType] = useState(template.initialFormat)  // 0: horizontal, 1: square, 2: vertical, 3: all
  const [zoomValue, setZoomValue] = useState(localStorage.getItem('be-designer-template-zoom') || 50)
  const [zoomEl, setZoomEl] = useState(null)

  useEffect(() => {
    if (match.params.id !== undefined) {
      HttpClient.get(`/templates/${match.params.id}`, { Authorization: `Bearer ${authData.authToken}` })
        .then(res => {
          if (res.colors ===  null)
            res.colors = []
          setCurrentFormatType(res.initialFormat)
          setTemplate({ ...initialTemplate, ...res })
        })
        .catch(error => console.error('Error fetch template: ', error))
    }
  }, [authData.authToken, match.params.id])

  const handleTemplateChange = tmplt => setTemplate({ ...template, ...tmplt })

  const handleColorsChange = colors => {
    setTemplate({ ...template, colors })
    // HttpClient.put(`/templates/${template.id}`, { colors }, { Authorization: `Bearer ${authData.authToken}` })
    //   .then(res => setTemplate({ ...template, colors }))
    //   .catch(error => console.log('Error template colors update: ', error))
  }

  const handleCurrentFormatTypeChange = formatType => {
    if (formatType !== 4) {
      const formatIndex = template.formats.findIndex(f => f.type === formatType)
      if (formatIndex === -1) {
        HttpClient.get(`/templates/${template.id}/formats/${template.initialFormat}`, { Authorization: `Bearer ${authData.authToken}` })
          .then(response => {
            let newFormat = { type: formatType, cornerRadius: 8 }
            newFormat.sides = response.sides.map(s => {
              let { id, formatId, settings, createdAt, updatedAt, ...newSide } = s
              newSide.settings = formatsSettings[formatType-1]
              if (newSide.colors === null)
                newSide.colors = []
              if (newSide.json !== ''){   
                const sideJSON = JSON.parse(newSide.json)
                if (sideJSON.backgroundImage) {
                  const sideAspect = newSide.settings.width / newSide.settings.height
                  const backgroundImageAspect = sideJSON.backgroundImage.width / sideJSON.backgroundImage.height
                  let left, top, scaleFactor
                  if (sideAspect >= backgroundImageAspect) {
                    scaleFactor = newSide.settings.width / sideJSON.backgroundImage.width
                    left = 0
                    top = -(((sideJSON.backgroundImage.height * scaleFactor) - newSide.settings.height) / 2)
                  } else {
                    scaleFactor = newSide.settings.height / sideJSON.backgroundImage.height
                    top = 0
                    left = -(((sideJSON.backgroundImage.width * scaleFactor) - newSide.settings.width) / 2)
                  }
                  // Set image dimensions and scaling according to new format dimensions
                  sideJSON.backgroundImage.left = left
                  sideJSON.backgroundImage.top = top
                  sideJSON.backgroundImage.scaleX = scaleFactor
                  sideJSON.backgroundImage.scaleY = scaleFactor
                }
                sideJSON.objects.map(obj => {
                  // if initial format is Vertical. and objects goes out of height for other formats
                  if (((obj.top*obj.scaleY)+obj.height) > newSide.settings.height) {
                    obj.top = newSide.settings.height - (obj.height * obj.scaleY)
                  }
                  return obj
                })
                newSide.json = JSON.stringify(sideJSON)
              }
              return newSide
            })
            HttpClient.post(
              `/templates/${template.id}/formats`,
              newFormat,
              { Authorization: `Bearer ${authData.authToken}` }
            )
              .then(format => {
                let formats = [...template.formats]
                formats.push(format)
                setTemplate({ ...template, formats })
                setCurrentFormatType(formatType)
              })
          })
      } else {
        HttpClient.get(
          `/templates/${template.id}/formats/${formatType}`,
          { Authorization: `Bearer ${authData.authToken}` }
        )
          .then(format => {
            let formats = [...template.formats]
            formats[formatIndex] = format
            setTemplate({ ...template, formats })
            setCurrentFormatType(formatType)
          })
      }
    } else {
      setCurrentFormatType(formatType)
    }
  }

  const handleFormatChange = (formatId, format) => {
    const formatIndex = template.formats.findIndex(f => f.id === formatId)
    if (formatIndex !== -1) {
      const newTemplate = { ...template }
      newTemplate.formats[formatIndex] = format
      setTemplate(newTemplate)
    }
  }

  const handleOpenZoomMenu = e => {
    setZoomEl(e.currentTarget)
  }

  const handleCloseZoomMenu = () => {
    setZoomEl(null)
  }

  const handleZoomChange = zoomVal => {
    localStorage.setItem('be-designer-template-zoom', zoomVal)
    setZoomValue(zoomVal)
    setZoomEl(null)
  }

  const getCurrentFormat = () => template.formats.find(f => f.type === currentFormatType)

  return (
    <SideContextProvider>
      <NavigationContextProvider>
        <ActionContextProvider>
          <Box display="flex" flex="0 1 auto" bgcolor="#000">
            <Navigation templateId={template.id} colors={template.colors} onColorsChange={handleColorsChange} />  
          </Box>
          {template.formats ?
            <Box flex="1 1 auto" width={1} overflow="hidden">
              <Box height={1}>
                <AppBar position="relative" elevation={0}>
                  <TitleBar
                    template={template}
                    onTemplateChange={handleTemplateChange}
                    currentFormat={currentFormatType}
                    onFormatChange={handleCurrentFormatTypeChange}
                    formatsCount={template.formats.length}
                  />
                </AppBar>
                {currentFormatType === 4 ?
                  <Previews formats={template.formats} />
                  :
                  <>
                    <Format
                      templateId={template.id}
                      format={getCurrentFormat()}
                      onFormatChange={handleFormatChange}
                      zoomValue={parseInt(zoomValue)}
                    />
                    <Box position="absolute" bottom="2%" right="2%">
                      <Paper variant="outlined">
                        <Box display="flex">
                          <Button
                            fullWidth
                            size="small"
                            aria-label="Zomm"
                            aria-controls="template-zoom"
                            aria-haspopup="true"
                            onClick={handleOpenZoomMenu}
                          >
                            {`${zoomValue}%`}
                          </Button>
                          <Menu
                            id="template-zoom"
                            anchorEl={zoomEl}
                            keepMounted
                            open={Boolean(zoomEl)}
                            onClose={handleCloseZoomMenu}
                            getContentAnchorEl={null}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                          >
                            {zoomOptions.map((zoomOption, index) => 
                              <MenuItem key={index} onClick={() => handleZoomChange(zoomOption)}>{zoomOption}%</MenuItem>  
                            )}
                          </Menu>
                          <IconButton size="small" aria-label="Full screen">
                            <ZoomOutMapIcon fontSize="small" />
                          </IconButton>
                        </Box>
                      </Paper>
                    </Box>
                  </> 
                }
              </Box>
            </Box>
            : null
          }
        </ActionContextProvider>
      </NavigationContextProvider>
    </SideContextProvider>
  )
}

Template.propTypes = {
  match: PropTypes.object.isRequired,
}

export default Template
