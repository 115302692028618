import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles'
import { Grid, Box, Toolbar } from '@material-ui/core'
import { DialogContent, DialogActions } from '@material-ui/core/'
import { TextField, Button, IconButton } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import AllFormatsIcon from '../../../../icons/AllFormatsIcon'
import Overlay from '../Overlay'

import HttpClient from '../../../../services/HttpClient'
import AuthContext from '../../../../contexts/AuthContext'
import FormatButton from './FormatButton'
import Publish from './Publish'
import SaveAs from './SaveAs'

const useStyles = makeStyles(() => ({
  toolbar: {
    color: '#FFF',
    backgroundColor: '#8BA8F0',
    minHeight: '42px'
  },
  button: {
    color: 'white'
  }
}))

const TitleBar = props => {
  const history = useHistory();
  const classes = useStyles()
  const [authData] = useContext(AuthContext)
  const [templateName, setTemplateName] = useState(props.template.name)
  const [editing, setEditing] = useState(false)
  const [savingAs, setSavingAs] = useState(false)
  const [publishing, setPublishing] = useState(false)

  useEffect(() => {
    setTemplateName(props.template.name)
  }, [props.template.name])

  const handleSubmit = e => {
    e.preventDefault()
    if (templateName !== '') {
      if ( Number.isInteger(props.template.id) ) {
        HttpClient.put(`/templates/${props.template.id}`, { name: templateName }, { Authorization: `Bearer ${authData.authToken}` })
          .then(res => {
            setEditing(false)
            props.onTemplateChange({ name: res.name })
          })
          .catch(error => console.error('Error template name update: ', error))
      } else {
        HttpClient.post('/templates', { name: templateName }, { Authorization: `Bearer ${authData.authToken}` })
          .then(res => {
            setEditing(false)
            props.onTemplateChange({ id: res.id, name: res.name })
          })
          .catch(error => console.error('Error new template name update: ', error))
      }
    }
  }

  const onPublishTemplate = () => {
    history.push(`/designer/templates/${props.template.id}/publish?timestamp=${new Date().toISOString()}`);
  };

  const secondFormat = (props.template.initialFormat + 1) > 3 ? (props.template.initialFormat - 2) : (props.template.initialFormat + 1)
  const thirdFormat = (props.template.initialFormat + 2) > 3 ? (props.template.initialFormat - 1) : (props.template.initialFormat + 2)

  return (
    <Toolbar variant="dense" className={classes.toolbar}>
      <Grid container>
        <Grid item sm={3}>
          {props.template.name}
          <IconButton
            color="inherit"
            aria-label="Edit Template Name"
            component="span"
            onClick={() => setEditing(true)}
          >
            <EditIcon fontSize="small" />
          </IconButton>
          <Overlay
            maxWidth="xs"
            open={editing}
            onClose={() => setEditing(false)}
          >
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <TextField
                  id="template-name"
                  label="Name"
                  value={templateName}
                  onChange={e => setTemplateName(e.currentTarget.value)}
                  margin="normal"
                  variant="outlined"
                  size="small"
                  fullWidth
                  autoFocus
                  required
                />
              </DialogContent>
              <DialogActions>
                <Button type="submit" variant="contained" color="primary" size="small">Save</Button>
              </DialogActions> 
            </form>
          </Overlay>
        </Grid>
        <Grid item sm={6}>
          <Box display="flex" justifyContent="center" color="#D8D8D8">
            <FormatButton
              type={props.template.initialFormat}
              active={props.currentFormat === props.template.initialFormat}
              onFormatChange={props.onFormatChange}
            />
            <FormatButton
              type={secondFormat}
              active={props.currentFormat === secondFormat}
              onFormatChange={props.onFormatChange}
            />
            <FormatButton
              type={thirdFormat}
              active={props.currentFormat === thirdFormat}
              onFormatChange={props.onFormatChange}
            />
            <Button
              color="inherit"
              size="large"
              aria-label="View All"
              startIcon={<AllFormatsIcon />}
              disabled={props.formatsCount !== 3}
              classes={props.currentFormat === 4 ? { root: classes.button } : {}}
              onClick={() => {
                if (props.currentFormat !== 4) {
                  props.onFormatChange(4)
                }
              }}
            >
              View All
            </Button>
          </Box>
        </Grid>
        <Grid item sm={3}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              color="inherit"
              size="large"
              aria-label="save template as"
              onClick={() => setSavingAs(true)}
            >
              Save As
            </Button>
            <Button
              color="inherit"
              size="large"
              aria-label="publish template"
              disabled={props.formatsCount !== 3}
              onClick={() => onPublishTemplate()}
            >
              Publish
            </Button>
            <Overlay
              maxWidth="sm"
              open={publishing}
              onClose={() => setPublishing(false)}
            >
              <Publish template={props.template} onTemplateChange={props.onTemplateChange} />
            </Overlay>
            {savingAs ?
              <SaveAs templateId={props.template.id} onClose={() => setSavingAs(false)} />
              : null
            }
          </Box>
        </Grid>
      </Grid>
    </Toolbar>
  )
}

TitleBar.propTypes = {
  template: PropTypes.object.isRequired,
  currentFormat: PropTypes.number.isRequired,
  onTemplateChange: PropTypes.func.isRequired,
  onFormatChange: PropTypes.func.isRequired,
  formatsCount: PropTypes.number.isRequired
}

export default TitleBar
