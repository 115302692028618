import React, { createContext, useState } from 'react'
import PropTypes from 'prop-types'

const SideContext = createContext([null,() => {}])

const SideContextProvider = props => {
  const [activeSide, setActiveSide] = useState(null)

  // const setActiveCanvasObject = canvas => {
  //   canvas.undo = canvas.fabricCanvas.historyUndo.length
  //   canvas.redo = canvas.fabricCanvas.historyRedo.length
  //   setActiveCanvas({
      
  //   })
  // }

  return (
    <SideContext.Provider value={[activeSide, setActiveSide]}>
      {props.children}
    </SideContext.Provider>
  )

}

SideContextProvider.propTypes = {
  children: PropTypes.node
}

export { SideContext, SideContextProvider }
