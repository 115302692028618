import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'

import { DialogContent, DialogActions } from '@material-ui/core/'
import { TextField, Button } from '@material-ui/core'

import HttpClient from '../../../../services/HttpClient'
import AuthContext from '../../../../contexts/AuthContext'

const Publish = ({ nameUpdate, textStyling, onTextStylingChange, onClose }) => {
  const [authData] = useContext(AuthContext)
  const [textStylingName, setTextStylingName] = useState(textStyling.name)
  const [toDashboard, setToDashboard] = useState(false)

  useEffect(() => {
    setTextStylingName(textStyling.name)
  }, [textStyling.name])

  const handleSubmit = e => {
    e.preventDefault()
    if (textStylingName !== '') {
      HttpClient.put(
        `/text_stylings/${textStyling.id}/publish`,
        { name: textStylingName },
        { Authorization: `Bearer ${authData.authToken}` }
      )
        .then(res => {
          onTextStylingChange({ name: res.name })
          nameUpdate(textStylingName)
          setToDashboard(true)
        })
        .catch(error => console.error('Error text styling name update: ', error))
    }
  }

  return (
    toDashboard ?
      <Redirect to='/designer' />
      :
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextField
            id="text-styling-name"
            label="Name"
            value={textStylingName}
            onChange={e => setTextStylingName(e.currentTarget.value)}
            margin="normal"
            variant="outlined"
            size="small"
            fullWidth
            autoFocus
            required
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" size="small" onClick={onClose}>Close</Button>
          <Button type="submit" variant="contained" color="primary" size="small">Publish</Button>
        </DialogActions>
      </form>
  )
}

Publish.propTypes = {
  textStyling: PropTypes.object.isRequired,
  onTextStylingChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default Publish
