import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { DialogContent, DialogActions } from '@material-ui/core/'
import { TextField, Button } from '@material-ui/core'

import HttpClient from '../../../../../services/HttpClient'
import AuthContext from '../../../../../contexts/AuthContext'

const Name = props => {
  const [authData] = useContext(AuthContext)
  const [templateName, setTemplateName] = useState(props.template.name)

  useEffect(() => {
    setTemplateName(props.template.name)
  }, [props.template.name])

  const handleSubmit = e => {
    e.preventDefault()
    if (templateName !== '') {
      HttpClient.put(`/templates/${props.template.id}`, { name: templateName }, { Authorization: `Bearer ${authData.authToken}` })
        .then(res => {
          props.onNameChange(res.name)
        })
        .catch(error => console.error('Error template name update: ', error))
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <DialogContent>
        <TextField
          id="template-name"
          label="Name"
          value={templateName}
          onChange={e => setTemplateName(e.currentTarget.value)}
          margin="normal"
          variant="outlined"
          size="small"
          fullWidth
          autoFocus
          required
        />
      </DialogContent>
      <DialogActions>
        <Button type="submit" variant="contained" color="primary" size="small">Next</Button>
      </DialogActions>
    </form>
  )
}

Name.propTypes = {
  template: PropTypes.object.isRequired,
  onNameChange: PropTypes.func.isRequired,
}

export default Name
