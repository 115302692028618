import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const UndoIcon = props => {
  return (
    <SvgIcon {...props}>
      <path d="M9.69 13.2c-.12 0-.24.05-.32.13-.08.08-.13.19-.13.31v2.37l-5.65-4.58 5.65-4.57v2.37c0 .24.2.44.45.44 6.48.06 8.89 3.93 9.6 6.65-2.79-3.12-6.97-3.12-9.6-3.12zm8.54-.84c-1.3-1.55-3.76-3.4-8.09-3.56V5.92c0-.17-.1-.33-.26-.4s-.34-.05-.47.06l-6.82 5.5c-.1.08-.17.21-.17.34 0 .13.06.26.16.34l6.82 5.53c.14.11.32.13.48.06.16-.07.26-.23.26-.4v-2.87c2.83.01 7.15.22 9.44 4.18.08.14.23.22.39.22.04 0 .08 0 .11-.01.2-.05.33-.23.33-.43.02-1.48-.56-3.75-2.18-5.68z" />
    </SvgIcon>
  )
}

export default UndoIcon
