import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const HorizontalIcon = props => {
  return (
    <SvgIcon {...props}>
      <rect x="0" y="4" rx="4" ry="4" width="24" height="16" />
    </SvgIcon>
  )
}

export default HorizontalIcon
