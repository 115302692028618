import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const MiddleAlignIcon = props => {
  return (
    <SvgIcon {...props}>
      <path d="M11.6 3v17.2h1V3h-1zM6.2 6.2v4h11.6v-4H6.2zm2.6 6.6v4h6.4v-4H8.8z"/>
    </SvgIcon>
  )
}

export default MiddleAlignIcon
