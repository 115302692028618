import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const UploadsIcon = props => {
  return (
    <SvgIcon {...props}>
      <path d="M19.1 7.1C18.316 3 14.52.34 10.61 1.16 7.75 1.76 5.518 4.1 4.946 7.1 2.171 7.43.187 10.06.511 12.97c.296 2.67 2.451 4.69 5.017 4.69h3.605c.4 0 .725-.34.725-.76s-.325-.76-.725-.76H5.528c-1.993-.01-3.596-1.72-3.586-3.8.01-2.09 1.64-3.77 3.634-3.76.372 0 .677-.29.715-.67.362-3.32 3.233-5.7 6.4-5.32 2.66.32 4.758 2.53 5.073 5.32.057.39.381.68.763.67 1.993 0 3.605 1.69 3.605 3.78s-1.612 3.78-3.605 3.78h-3.605c-.4 0-.725.34-.725.76s.324.76.725.76h3.605c2.785-.02 5.035-2.4 5.016-5.33-.028-2.67-1.926-4.91-4.444-5.23z" />
      <path d="m 14.78,12.79 a 0.758,0.758 0 0 0 1.09,-1.05 L 15.85,11.72 12.64,8.51 c -0.29,-0.3 -0.77,-0.3 -1.07,0 l -3.21,3.21 a 0.758,0.758 0 0 0 1.05,1.09 l 0.02,-0.02 1.92,-1.92 v 9.52 a 0.76,0.76 0 1 0 1.52,0 v -9.52 z" />
    </SvgIcon>
  )
}

export default UploadsIcon
