import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ChromePicker } from 'react-color'

import { makeStyles } from '@material-ui/core/styles'
import { Grid, Box, Tooltip } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Popover from '@material-ui/core/Popover'

import HttpClient from '../../../../services/HttpClient'
import AuthContext from '../../../../contexts/AuthContext'

import { ActionContext } from '../../../../contexts/ActionContext'
// import { TemplateContext } from '../../../../contexts/TemplateContext'
import { DEFAULT_COLORS } from '../../../../constants/DefaultColors'
import colorPickerImage from '../../../../images/color-pick.webp'

const useStyles = makeStyles(() => ({
  colorBox: {
    cursor: 'pointer',
    transition: 'box-shadow .2s ease-in-out',
    '&:hover': {
      boxShadow: '0 0 0 2px #00c4cc, inset 0 0 0 2px #fff',
    }
  },
  selected: {
    cursor: 'pointer',
    transition: 'box-shadow .2s ease-in-out',
    boxShadow: '0 0 0 2px #00c4cc, inset 0 0 0 2px #fff',
  },
  colorPickerBtn: {
    maxWidth: '100%',
    borderRadius: 4
  }
}))

const componentToHex = c => {
  const hex = c.toString(16)
  return hex.length == 1 ? `0${hex}` : hex
}

const rgbToHex = color => {
  if( color !== undefined ) {
    if( color.startsWith('rgb') ) {
      const hex = color.replace(/\brgb\b|\(|\)/g, '').split(',')
      return `#${componentToHex(hex[0])}${componentToHex(hex[1])}${componentToHex(hex[2])}`
    }
    return color
  }
}

const colorTitle = c => {
  return c.name !== undefined ? `${c.name} ${c.hex}` : c.hex
}

const Overlay = ({ templateId, bgColor }) => {
  const classes = useStyles()
  const [authData] = useContext(AuthContext)
  const [action, setAction] = useContext(ActionContext)
  const [templateColors, setTemplateColors] = useState([])
  const colour = rgbToHex(bgColor)
  const [color, setColor] = useState(colour)
  const [colorPickerEl, setColorPickerEl] = useState(null)
  const colorPickerOpen = Boolean(colorPickerEl)
  const colorPickerId = colorPickerOpen ? 'color-picker' : undefined

  useEffect(() => {
    HttpClient.get(`/templates/${templateId}`, { Authorization: `Bearer ${authData.authToken}` })
      .then(res => setTemplateColors(res.colors||[]))
      .catch(error => console.log(error))
  }, [templateId])

  useEffect(() => {
    setColor(colour)
  }, [colour])

  const handleColorUpdate = clr => {
    const _colors = [ ...templateColors.filter(c => c.type === undefined) ]
    let newColor  = { hex: clr.hex, type: 'BackgroundColor' }
    if( clr.name !== undefined ) {
      newColor.name = clr.name
    }
    setColor(clr.hex)
    _colors.push( newColor )
    setAction({
      type: 'background', property: 'color', value: newColor
    })
    setTemplateColors(_colors)
  }

  const hanldeColorPickerOpen = e => setColorPickerEl(e.currentTarget)
  const hanldeColorPickerClose = () => setColorPickerEl(null)

  const uniqueColors = () => templateColors.filter((obj, pos, arr) => arr.map(mapObj => mapObj.hex).indexOf(obj.hex) === pos)

  return (
    <Box width={1} p={1}>
      <Box>
        <Typography variant="caption" display="block" gutterBottom>
          New Color
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={2}>
            <Box
              position="relative"
              width={1}
              pb="100%"
              borderRadius={4}
              boxSizing="border-box"
            >
              <Box
                position="absolute"
                left={1}
                right={1}
                top={1}
                bottom={1}
                borderRadius={4}
                className={classes.colorBox}
                aria-describedby={colorPickerId}
                onClick={hanldeColorPickerOpen}
              >
                <img src={colorPickerImage} className={classes.colorPickerBtn} />
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Popover
          id={colorPickerId}
          open={colorPickerOpen}
          anchorEl={colorPickerEl}
          onClose={hanldeColorPickerClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <ChromePicker
            disableAlpha
            color={color}
            onChangeComplete={handleColorUpdate}
          />
        </Popover>
      </Box>
      <Box pt={2}>
        <Typography variant="caption" display="block" gutterBottom>
          Template Colors
        </Typography>
        <Grid container spacing={1}>
          {uniqueColors().map((color, index) => (
            <Grid item xs={2} key={index}>
              <Box
                position="relative"
                width={1}
                pb="100%"
                borderRadius={4}
                boxSizing="border-box"
              >
                <Tooltip title={colorTitle(color)} arrow>
                  <Box
                    position="absolute"
                    left={1}
                    right={1}
                    top={1}
                    bottom={1}
                    border={1}
                    borderColor="grey.500"
                    borderRadius={4}
                    className={classes.colorBox}
                    style={{ backgroundColor: color.hex }}
                    onClick={() => handleColorUpdate(color)} />
                </Tooltip>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box pt={2}>
        <Typography variant="caption" display="block" gutterBottom>
          Default Colors
        </Typography>
        <Grid container spacing={1}>
          {DEFAULT_COLORS.map((color, index) => (
            <Grid item xs={2} key={index}>
              <Box
                position="relative"
                width={1}
                pb="100%"
                borderRadius={4}
                boxSizing="border-box"
              >
                <Tooltip title={`${color.name} ${color.hex}`} arrow>
                  <Box
                    position="absolute"
                    left={1}
                    right={1}
                    top={1}
                    bottom={1}
                    border={1}
                    borderColor="grey.500"
                    borderRadius={4}
                    className={classes.colorBox}
                    style={{ backgroundColor: color.hex }}
                    onClick={() => handleColorUpdate(color)} />
                </Tooltip>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  )
}

Overlay.propTypes = {
  templateId: PropTypes.number.isRequired,
  bgColor: PropTypes.string,
}

export default Overlay
