export const CONSTANT_STRINGS = {
  vertical: 'vertical',
  horizontal: 'horizontal',
  square: 'square',
}

export const CardPrices = {
  multiSided: '$1.50',
  premium: '$2.00',
}

export const SocialProfileLinks = {
  facebook: 'https://www.facebook.com/',
  linkedin: 'https://www.linkedin.com/in/',
  instagram: 'instagram://user?username=',
  vimeo: 'https://www.vimeo.com/',
  whatsapp: 'https://wa.me/',
  skype: 'skype:-',
}

export const EDITABLE_STATUSES = [1, 3]
export const REQUEST_STATUS = 5
export const DRAFT_STATUS = 1
export const PUBLISHED_STATUS = 2
