import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import { Box, GridList, GridListTile } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'

import HttpClient from '../../../../services/HttpClient'
import AuthContext from '../../../../contexts/AuthContext'
import SearchBar from '../SearchBar'
import Colors from './Colors'
import Image from './Image'
import MyImage from './MyImage'

const useStyles = makeStyles(() => ({
  tabs: {
    cursor: 'pointer'
  },
  tabActive: {
    backgroundColor: '#293039'
  },
}))

const Backgrounds = ({ templateId }) => {
  const classes = useStyles()
  const [authData] = useContext(AuthContext)
  const [mode, setMode] = useState(0)
  const [images, setImages] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    pexels('sports')
  }, [])

  // const pixaybay = q => {
  //   fetch(`https://pixabay.com/api/?key=11095386-871fd43c33a92700d9bffb82d&q=${q}&image_type=photo&pretty=true&page=1&per_page=24&safesearch=true`)
  //     .then(res => res.json())
  //     .then(res => setImages(res.hits))
  //     .catch(err => setImages([]))
  // }

  const searchImages = q => {
    if (mode === 1) {
      myImages(q)
    } else {
      pexels(q)
    }
  }

  const pexels = q => {
    setMode(0)
    setLoading(true)
    fetch(`https://api.pexels.com/v1/search?query=${q}&per_page=40`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: process.env.REACT_APP_PEXELS_API_KEY
      }
    })
      .then(res => res.json())
      .then(res => {
        setImages(res.photos)
        setLoading(false)
      })
      .catch(error => {
        console.error('Error getting pexels images: ', error)
        setImages([])
        setLoading(false)
      })
  }

  const myImages = q => {
    setMode(1)
    setLoading(true)
    HttpClient.get(
      `/files?storageableType=Designer&fileName=${q}`,
      { Authorization: `Bearer ${authData.authToken}` }
    )
      .then(res => {
        setImages(res)
        setLoading(false)
      })
      .catch(error => {
        console.error('Error getting designer background images: ', error)
        setImages([])
        setLoading(false)
      })
  }

  // const unsplash = () => {
  //   fetch('https://api.unsplash.com/search/photos/?client_id=d180e1acc68141f91058bc9098f8bfcbfad8d2297a1568ee11e86b66ebc123ca&query=sports&per_page=24&page=1')
  //     .then(res => res.json())
  //     .then(res => setImages(res.results))
  //     .catch(err => {
  //       console.log('Error!', err)
  //       setImages([])
  //     })
  // }

  return (
    <>
      <SearchBar onSearch={searchImages} />
      <Box mx={2} color="white">
        <Box
          py={1}
          width={0.5}
          display="inline-block"
          borderRadius="4px 4px 0 0"
          className={mode === 0 ? classes.tabActive : classes.tabs}
          textAlign="center"
          onClick={() => { (mode !== 0) && pexels('sports') }}
        >Public</Box>
        <Box
          py={1}
          width={0.5}
          display="inline-block"
          borderRadius="4px 4px 0 0"
          className={mode === 1 ? classes.tabActive : classes.tabs}
          textAlign="center"
          onClick={() => { (mode !== 1) && myImages('') }}
        >My Images</Box>
      </Box>
      <Box position="relative" pb={1} bgcolor="#293039">
        <Colors templateId={templateId} />
      </Box>
      <Box
        flex="1 1 auto"
        p={1}
        overflow="scroll"
        bgcolor="#293039"
        className="scrollbar-hidden"
      >
        {loading ? 
          <GridList cellHeight={77} cols={2}>
            <GridListTile cols={1}>
              <Skeleton variant="rect" width="100%" height={118} />
            </GridListTile>
            <GridListTile cols={1}>
              <Skeleton variant="rect" width="100%" height={118} />
            </GridListTile>
          </GridList>
          :
          <GridList cellHeight={77} cols={2}>
            {images.map((image, index) => (
              <GridListTile key={index} cols={1}>
                {mode === 1 ? <MyImage image={image} /> : <Image image={image} />}
              </GridListTile>
            ))}
          </GridList>
        }
      </Box>
    </>
  )
}

Backgrounds.propTypes = {
  templateId: PropTypes.number.isRequired,
}

export default Backgrounds
