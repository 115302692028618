import React from 'react'
import PropTypes from 'prop-types'

import { Box, Grid } from '@material-ui/core'

import Preview from './Preview'

const Previews = ({ formats }) => {
  const horizontal = () => formats.find(f => f.type === 1)
  const square = () => formats.find(f => f.type === 3)
  const vertical = () => formats.find(f => f.type === 2)

  return (
    <Box width={1} height="calc(100% - 44px)" overflow="auto">
      <Box width={0.6} m="0 auto">
        <Box my="5%">
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <Preview format={vertical()} />
            </Grid>
            <Grid item sm={6}>
              <Grid container spacing={2}>
                <Grid item sm={12}>
                  <Preview format={horizontal()} />
                </Grid>
                <Grid item sm={12}>
                  <Preview format={square()} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>    
  )
}

Previews.propTypes = {
  formats: PropTypes.array.isRequired,
}

export default Previews
