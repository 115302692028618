import React, { useContext, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { fabric } from 'fabric'
import 'fabric-history'
import WebFont from 'webfontloader'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Paper } from '@material-ui/core'
import HttpClient from '../../../../services/HttpClient'
import AuthContext from '../../../../contexts/AuthContext'
import { dataURIToBlob } from '../../../../utils'

const useStyles = makeStyles(() => ({
  format_1: {
    position: 'relative',
    paddingTop: '60%',
    backgroundColor: 'unset',
  },
  format_2: {
    position: 'relative',
    paddingTop: '125%',
    backgroundColor: 'unset',
  },
  format_3: {
    position: 'relative',
    paddingTop: '100%',
    backgroundColor: 'unset',
  },
  canvas: {
    position: 'absolute',
    top: 0,
    boxShadow: '0 0 16px 0 #d3d4d5',
  },
  link: {
    color: '#007aff',
    cursor: 'pointer',
  },
  caption: {
    color: '#9b9b9b',
  },
}))

fabric.Object.prototype.set({
  transparentCorners: false,
  borderColor: '#01d9e1',
  borderScaleFactor: 2,
  cornerColor: '#ffffff',
  cornerStyle: 'circle',
  cornerStrokeColor: '#ece9e6',
})

fabric.Image.prototype.toObject = (function (toObject) {
  return function () {
    return fabric.util.object.extend(toObject.call(this), {
      lockMovementX: this.lockMovementX,
      lockMovementY: this.lockMovementY,
    })
  }
})(fabric.Image.prototype.toObject)

const Preview = props => {
  const classes = useStyles()
  const [authData] = useContext(AuthContext)
  const sideBoxRef = useRef(null)
  const canvasRef = useRef(null)

  useEffect(() => {
    const fabricCanvas = new fabric.Canvas(canvasRef.current, {
      preserveObjectStacking: true,
      renderOnAddRemove: false,
      width: sideBoxRef.current.offsetWidth,
      height: sideBoxRef.current.offsetHeight,
      allowTouchScrolling: true,
      borderRadius: props.cornerRadius
    })
    let settings = props.side.settings
    if (!settings)
      settings = {
        width: sideBoxRef.current.offsetWidth,
        height: sideBoxRef.current.offsetHeight,
      }
    const sideBoxWidth = sideBoxRef.current.offsetWidth
    fabricCanvas.setDimensions({
      width: settings.width,
      height: settings.height,
    })
    const ratio = fabricCanvas.getWidth() / fabricCanvas.getHeight()
    const scale = sideBoxWidth / fabricCanvas.getWidth()
    const zoom = fabricCanvas.getZoom() * scale
    let fontFamilies = []
    const sideJSON = JSON.parse(props.side.json)
    if (sideJSON.objects !== undefined) {
      const textObjects = sideJSON.objects.filter(
        o => o.type === 'textbox' && o.fontFamily !== 'Times New Roman'
      )
      fontFamilies = textObjects.map(o => o.fontFamily.replace(/\s/g, '+'))
    }
    fabricCanvas.sideId = props.side.id
    fabricCanvas.id = 'loading'
    if (fontFamilies.length) {
      WebFont.load({
        google: { families: fontFamilies },
        active: () => {
          loadFabricCanvasByJson(fabricCanvas)
        },
      })
    } else {
      loadFabricCanvasByJson(fabricCanvas)
    }
    fabricCanvas.setDimensions({
      width: sideBoxWidth,
      height: sideBoxWidth / ratio,
    })
    fabricCanvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0])
  }, [props.side.id, props.side.json, props.cornerRadius])

  const loadFabricCanvasByJson = fabricCanvas => {
    fabricCanvas.loadFromJSON(
      props.side.json,
      () => {
        fabricCanvas.id = 'loaded'
        fabricCanvas.renderAll.bind(fabricCanvas)
        saveSide(fabricCanvas)
      },
      (o, object) => {
        if (object.id !== undefined && object.id.startsWith('DataField-')) {
          const dataField = props.dataFields.find(
            df => `DataField-${df.id}` === object.id
          )
          if (dataField !== undefined) {
            object.set('id', undefined)
            object.set('text', dataField.fieldValue)
          }
        }
      }
    )
  }

  const saveSide = canvas => {
    if (Number.isInteger(canvas.sideId)) {
      const formData = new FormData()
      const jpegBlob = dataURIToBlob(
        canvas.toDataURL({ format: 'jpeg', enableRetinaScaling: true })
      )
      formData.append('file', jpegBlob, `${canvas.sideId}.jpeg`)
      let canvasSVG = canvas.toSVG({ suppressPreamble: true })
      if (canvas.backgroundColor !== '') {
        const vpt = canvas.viewportTransform
        const viewBoxWidth = fabric.util.toFixed(
          canvas.width / vpt[0],
          fabric.Object.NUM_FRACTION_DIGITS
        )
        const viewBoxHeight = fabric.util.toFixed(
          canvas.height / vpt[3],
          fabric.Object.NUM_FRACTION_DIGITS
        )
        canvasSVG = canvasSVG.replace(
          `<rect x="0" y="0" width="100%" height="100%" fill="${canvas.backgroundColor}"></rect>`,
          `<rect x="0" y="0" width="${viewBoxWidth}" height="${viewBoxHeight}" fill="${canvas.backgroundColor}"></rect>`
        )
      }
      let fontFamilies = []
      canvas.getObjects().forEach(o => {
        if (o.type === 'textbox' && o.fontFamily !== 'Times New Roman') {
          fontFamilies.push(o.fontFamily.replace(/\s/g, '+'))
        }
      })
      if (fontFamilies.length > 0) {
        canvasSVG = canvasSVG.replace(
          '<defs>',
          `<defs><style type="text/css">@import url('https://fonts.googleapis.com/css?family=${fontFamilies.join(
            '|'
          )}');</style>`
        )
      }

      const fileUploadUrl =
        props.cardStatus === 2
          ? `/upload?storageableType=CardSide&storageableId=${canvas.sideId}&cloudPrefix=publish`
          : `/upload?storageableType=CardSide&storageableId=${canvas.sideId}`
      HttpClient.post(fileUploadUrl, formData, {
        Authorization: `Bearer ${authData.authToken}`,
      })
        .then(res => {
          HttpClient.put(
            `/cards/${props.cardId}/sides/${canvas.sideId}`,
            {
              svg: canvasSVG,
              draftPreviewImagePath: res[0].path,
            },
            { Authorization: `Bearer ${authData.authToken}` }
          )
            .then(() => closeWebView(res[0].path))
            .catch(error => console.error('Error side update: ', error))
        })
        .catch(error => console.error('Error side image upload: ', error))
    }
  }

  const closeWebView = preview => {
    if (window.Print !== undefined) {
      window.Print.postMessage(`card-preview-${preview}`)
    }
  }

  return (
    <Box p={2} py="2.25%">
      <Box margin="0 auto" maxWidth="100%">
        <Paper
          square
          elevation={0}
          className={classes[`format_${props.formatType}`]}
        >
          <Box
            id={`side-${props.side.id}`}
            ref={sideBoxRef}
            position="absolute"
            top={0}
            bottom={0}
            width={1}
          >
            <canvas
              id={`canvas-${props.side.id}`}
              ref={canvasRef}
              className={classes.canvas}
              style={{ borderRadius: `${props.cornerRadius}px` }}
              tabIndex="1"
            />
          </Box>
        </Paper>
      </Box>
    </Box>
  )
}

Preview.propTypes = {
  cardId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  formatType: PropTypes.number.isRequired,
  cardStatus: PropTypes.number.isRequired,
  side: PropTypes.object.isRequired,
  dataFields: PropTypes.array.isRequired,
  cornerRadius: PropTypes.number.isRequired,
}

export default Preview
