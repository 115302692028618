import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const TemplatesIcon = props => {
  return (
    <SvgIcon {...props}>
      <path d="M1.09 4.25v15.34H23V4.25H1.09zm21.64 5.7h-.51v8.86H1.87V9.95h-.78v-.53h.77V5.03h9.78v-.76h.53v.76h10.05v4.39h.51v.53z" />
      <path d="M12.17 9.42V4.27h-.52V9.42H1.09v.53H22.73v-.53h-.51z" />
    </SvgIcon>
  )
}

export default TemplatesIcon
