import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLocation, useParams } from 'react-router-dom'

import HttpClient from '../../../../services/HttpClient'
import AuthContext from '../../../../contexts/AuthContext'
import SubCategory from './SubCategory'
import { getRandomInt } from '../../../../utils'
import { getCategoryTypeValue } from './Common'
import DummySkeleton from './DummySkeleton'
import Breadcrumb from './Breadcrumb'
import DashboardHeader from './DashboardHeader'
import SearchResult from './SearchResult'

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

const SubCategoryHome = () => {
  const query = useQuery()
  const [authData] = useContext(AuthContext)
  const [templates, setTemplates] = useState(true)
  const [loading, setLoading] = useState(true)
  const [categoryType, setCategoryType] = useState('Business')
  const { cardId } = useParams()
  const [search, setSearch] = useState('')


  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    setLoading(true)
    HttpClient.get(`/templates/published?categoryType=${params.get('type')}`, {
      Authorization: `Bearer ${authData.authToken}`,
    })
      .then(resTemplates => {
        setTemplates(resTemplates)
        setLoading(false)
      })
      .catch(error => {
        console.log('Error getting industries: ', error)
        setTemplates([])
        setLoading(false)
      })
  }, [authData.authToken])

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    setCategoryType(getCategoryTypeValue(params.get('type')))
  }, [])

  return (
    <>
      <DashboardHeader onChange={searchQuery => setSearch(searchQuery)} />
      {search !== '' ? (
        <SearchResult
          url={`/templates/published?search=${search}`}
          onClose={() => setSearch('')}
          cardId={cardId}
        />
      ) : (
        <>
          {loading ? (
            <DummySkeleton />
          ) : (
            <React.Fragment>
              <Breadcrumb categoryType={query.get('type')} cardId={cardId} />
              {templates.map(template => (
                <SubCategory
                  key={`${template.category_id}_${getRandomInt(3) + 1}`}
                  category={{
                    id: template.category_id,
                    name: template.category_title,
                    type: template.category_type,
                    format: query.get('format'),
                  }}
                  cardId={cardId}
                />
              ))}
            </React.Fragment>
          )}
        </>
      )}
    </>
  )
}

SubCategoryHome.propTypes = {
  match: PropTypes.object.isRequired,
}

export default SubCategoryHome
