import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import { Box, Tabs, Tab } from '@material-ui/core'
import PhoneIcon from '@material-ui/icons/Phone'
import CategoryOutlinedIcon from '@material-ui/icons/CategoryOutlined'
import FolderOpenIcon from '@material-ui/icons/FolderOpen'
import TemplatesIcon from '../../../../icons/TemplatesIcon'
import TextsIcon from '../../../../icons/TextsIcon'
import TextStylingsIcon from '../../../../icons/TextStylingsIcon'
import ImagesIcon from '../../../../icons/ImagesIcon'
import BackgroundIcon from '../../../../icons/BackgroundIcon'
import UploadsIcon from '../../../../icons/UploadsIcon'
import SocialMediaIcon from '../../../../icons/SocialMediaIcon'

import Icons from './Icons'

const verticalTabProps = index => (
  {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  }
)

const useStyles = makeStyles(() => ({
  tabs: {
    height: '100%',
    color: '#fff'
  },
  tabVertical: {
    position: 'absolute',
    zIndex: 1
  },
  tabIndicator: {
    width: '100%',
    backgroundColor: '#293039'
  },
  tab: {
    textTransform: 'none',
    minWidth: '80px',
    minHeight: '85px',
    padding: 0,
  },
  wrapper: {
    width: '90%'
  },
  icon: {
    fontSize: 30,
    marginBottom: '0 !important'
  }
}))

const Navigation = ({ navigation, onNavigationChange, icons, icon, onIconChange, onSelectIcon }) => {
  const classes = useStyles()
  
  const handleTabChange = (event, newTabIndex) => {
    onNavigationChange({
      activeTab: newTabIndex,
      activePanel: 0
    })
  }

  const handleSubPanelChange = index => {
    onNavigationChange({
      panel: index
    })
  }

  return (
    <React.Fragment>
      <Box position="absolute" width="85px" height="calc(100% - 55px)" bgcolor="#000">
        <Box height={1} overflow="scroll">
          <Tabs
            orientation="vertical"
            value={navigation.activeTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            variant="scrollable"
            scrollButtons="off"
            aria-label="Toolbar menu"
            classes={{
              root: classes.tabs,
              flexContainerVertical: classes.tabVertical,
              indicator: classes.tabIndicator
            }}
          >
            <Tab
              label="Templates"
              icon={<TemplatesIcon className={classes.icon} />}
              {...verticalTabProps(0)}
              className={classes.tab}
              disabled
            />
            <Tab
              label="Texts"
              {...verticalTabProps(1)}
              icon={<TextsIcon className={classes.icon} />}
              className={classes.tab}
              disabled
            />
            <Tab
              label="Texts Styling"
              {...verticalTabProps(2)}
              icon={<TextStylingsIcon fontSize="large" className={classes.icon} />}
              className={classes.tab}
              disabled
            />
            <Tab
              label="Images"
              icon={<ImagesIcon className={classes.icon} />}
              {...verticalTabProps(3)}
              className={classes.tab}
              disabled
            />
            <Tab
              label="Background"
              icon={<BackgroundIcon className={classes.icon} />}
              {...verticalTabProps(4)}
              className={classes.tab}
              disabled
            />
            <Tab
              label="Active Icons"
              icon={<PhoneIcon className={classes.icon} />}
              {...verticalTabProps(5)}
              className={classes.tab}
              disabled
            />
            <Tab
              label="Social Media Icons"
              icon={<SocialMediaIcon className={classes.icon} />}
              {...verticalTabProps(6)}
              className={classes.tab}
              disabled
            />
            <Tab
              label="Graphics"
              icon={<CategoryOutlinedIcon className={classes.icon} />}
              {...verticalTabProps(7)}
              className={classes.tab}
              disabled
            />
            {/* <Tab
              label="Folders"
              icon={<FolderOpenIcon className={classes.icon} />}
              {...verticalTabProps(9)}
              className={classes.tab}
              disabled
            /> */}
           <Tab
            label="Uploads"
            icon={<UploadsIcon className={classes.icon} />}
            {...verticalTabProps(9)}
            className={classes.tab}
            disabled
          />
          </Tabs>
        </Box>
      </Box>
      <Box position="absolute" width="260px" left="85px" height="100%" bgcolor="#000">
        { navigation.activeTab === 5 &&
          <Icons
            subPanel={navigation.panel}
            onSubpanelChange={handleSubPanelChange}
            icons={icons}
            icon={icon}
            onIconChange={onIconChange}
            onSelectIcon={onSelectIcon}
          />
        }
      </Box>
    </React.Fragment>
  )
}

Navigation.propTypes = {
  navigation: PropTypes.object.isRequired,
  onNavigationChange: PropTypes.func.isRequired,
  icons: PropTypes.array.isRequired,
  icon: PropTypes.object.isRequired,
  onIconChange: PropTypes.func.isRequired,
  onSelectIcon: PropTypes.func.isRequired,
}

export default Navigation
