import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Box } from '@material-ui/core'

import HttpClient from '../../../../services/HttpClient'
import AuthContext from '../../../../contexts/AuthContext'
import Preview from './Preview'


const initialCard = {
  name: 'Name Your Card',
  type: 1,
  status: 1,
  cornerRadius: 8,
  colors: [],
}

const DefaultPreview = ({ match }) => {
  const [authData] = useContext(AuthContext)
  const [dataFields, setDataFields] = useState([])
  initialCard.id = match.params.id
  const [card, setCard] = useState(initialCard)

  useEffect(() => {
    if (match.params.id !== undefined) {
      Promise.all([
        HttpClient.get(`/cards/${match.params.id}`, {
          Authorization: `Bearer ${authData.authToken}`,
        }),
        HttpClient.get(`/cards/${match.params.id}/data_fields`, {
          Authorization: `Bearer ${authData.authToken}`,
        }),
      ])
        .then(([resCard, resDataFields]) => {
          setDataFields(resDataFields)
          if (resCard.colors === null) resCard.colors = []
          if (resCard.cornerRadius === null) resCard.cornerRadius = 8
          if (resCard.sides && resCard.sides.length !== 0) {
            resCard.sides = resCard.sides.sort(
              (s1, s2) => s1.position - s2.position
            )
            setCard(resCard)
          } else {
            console.log('Card does not have any side.')
          }
        })
        .catch(error => {
          console.error('Error fetch card and data fields: ', error)
        })
    }
  }, [authData.authToken, match.params.id])

  return (
    <>
      <Box display="flex" height={1} flexDirection="column">
        {Number.isInteger(card.id) ? (
          <Box
            key={card.sides[0]?.id}
            flex="0 0 100%"
            id={card.sides[0]?.id}
          >
            <Preview
              cardId={card.id}
              formatType={card.type}
              cardStatus={card.status}
              cornerRadius={card.cornerRadius}
              side={card.sides[0]}
              dataFields={dataFields}
              card={card}
            />
          </Box>
              
        ) : null}
      </Box>
    </>
  )
}

DefaultPreview.propTypes = {
  match: PropTypes.object.isRequired,
}

export default DefaultPreview
