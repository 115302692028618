import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import  newCardTopIcon  from '../../images/weblink-images/new-card-top-icon.png'


const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  dialogContainer:{
    height: '401px',
    width: '409px',
    borderRadius:'23px',
    overflow: 'hidden',
  },
  contentWrapper:{
    display:'flex',
    justifyContent:'center',
    flexDirection:'column',
    alignItems:'center'
  },
  closeButton: {
    alignSelf:'flex-end',
    marginTop:'-10px',
  },
  buttonContainer:{
    backgroundColor:'#F4F4F4',
    width:'-webkit-fill-available',
    height: 56,
    padding: '10px',
    display: 'flex',
    alignItems:"center",
    justifyContent:'center',
    borderRadius:'10px',
    textColor:'#016BDF',
    textDecoration:'none',   
  },
  buttonStyling:{
    color: '#016BDF',
    fontSize:'17px',
    fontWeight:'700',  
  },
  textWrapper:{
    display: 'flex',
    alignItems:"center",
    justifyContent:'center',
  },
  textStyling:{
    color: '#0E1F4A',
    width: '230px',
    fontWeight:'900',
    textAlign:'center',
    lineHeight:'1.3',
    marginTop:'20px',
    marginBottom: '20px'
  },
  imageAlignment:{
    marginTop:'-10px',
    marginBottom: '10px',
    color:'#0E1F4A',
    fontWeight:'500',
  },
  iconSize:{
    fontSize:'28px',
    color: '#4A4A4A',
  }
});

const DownloadAlertDialog = (props) => {
  const classes = useStyles();
  const { onClose, open, downloadCard } = props;

  const handleClose = () => {
    onClose(false);
  };

  const downloadApp = () => {
    window.location.href = process.env['REACT_APP_DOWNLOAD_URL']
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} classes={{ paper: classes.dialogContainer }} >
    
      <DialogContent className={classes.contentWrapper}>
      <IconButton onClick={handleClose} className={classes.closeButton}><CloseIcon className={classes.iconSize}></CloseIcon></IconButton>
      <div className={classes.imageAlignment}><img src={newCardTopIcon} width='52px' height='52px'></img>
      </div>
      <div className={classes.textWrapper}><Typography className={classes.textStyling}>The new contact manager app for friends, family and business.</Typography></div>
      <Button onClick={downloadApp} className={classes.buttonContainer}><span  className={classes.buttonStyling}>Download LiveContact App</span></Button>
      <div className={classes.textWrapper}><Typography className={classes.textStyling}>Import content information to your contacts app</Typography></div>
      <Button onClick={downloadCard} className={classes.buttonContainer}><span  className={classes.buttonStyling}>Save to Contacts</span></Button>
      </DialogContent>
    </Dialog>
  );
}

DownloadAlertDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  downloadCard: PropTypes.func.isRequired,
}

export default DownloadAlertDialog