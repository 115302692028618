import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const AllFormatsIcon = props => {
  return (
    <SvgIcon {...props}>
      <rect x="0" y="0" rx="2" ry="2" width="13" height="8" />
      <rect x="15" y="0" rx="2" ry="2" width="9" height="14" />
      <rect x="2" y="10" rx="2" ry="2" width="11" height="11" />
    </SvgIcon>
  )
}

export default AllFormatsIcon
