import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import HttpClient from '../../services/HttpClient'

import { makeStyles } from '@material-ui/core/styles'
import { Grid, Box, Button } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Typography from '@material-ui/core/Typography'
import Alert from "@material-ui/lab/Alert";
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Loader from "./Loader";

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '60vh',
  },
  marginTop20: {
    marginTop:20
  },
  marginTop50: {
    marginTop:50
  },
  logo: {
    fontSize: '4.4rem',
    marginTop: '16%',
    marginBottom: '3%',
  },
  actions: {
    display: "flex",
    marginTop: '10%',
    marginBottom: '20%',
    justifyContent: 'flex-end',
  },
  verificationActions: {
    display: "flex",
    marginTop: "10%",
    marginBottom: "20%",
    justifyContent: "space-between",
  },
  pointer: {
    cursor: 'pointer'
  },
  brandBox: {
    fontSize: "4rem",
  },
  brandFirst: {
    color: "#000000",
  },
  brandSecond: {
    color: "#3A60AC",
  },
  brandFont: {
    fontWeight: "950",
    fontSize: "35px",
  },
  startButton:{
    width: 150,
    height: 45,
    marginTop: '100px',
    marginBottom: '30px',
    borderRadius: '30px',
    fontSize: '18px',
    fontWeight: 400
  },
  brandThird: {
    color: "#000000",
    fontWeight: "400",
    fontSize: "35px",
    paddingLeft: '5px'
  },
  passwordText: {
    fontWeight: 200, 
    textAlign: 'center',
    marginTop:50
  },
  enterButton: {
    width: 130,
    height: 45,
    marginTop: '100px',
    marginBottom: '30px',
    borderRadius: '30px',
    fontSize: '18px',
    fontWeight: 400
  },
}))

const initialErrorMessages = {
  password: "",
  newPassword: "",
};

const PasswordReset = ({ history, match }) => {
  const classes = useStyles()
  const [password, setPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [errors, setErrors] = useState(initialErrorMessages);
  const [generalError, setGeneralError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [resetInProgress, setResetInProgress] = useState(true)
  const [loading, setLoading] = useState(false);


  const handleClickShowPassword = type => {
    if (type === 'password')
      setShowPassword(!showPassword)
    else if (type === 'confirmPassword')
      setShowConfirmPassword(!showConfirmPassword)
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onChangePassword = (value, type) => {
    if (type === 'password')
      setPassword(value)
    else if (type === 'confirmPassword')
      setNewPassword(value) 
  }

  useEffect(() => {
    setGeneralError(null)
  }, [match.params.code])

  const handleResetPassword = () => {
    const _errors = { ...errors, ...initialErrorMessages };
    let isValid = true;
    if (password === "") {
      isValid = false;
      _errors.password = "Required";
    }
    if (newPassword === "") {
      isValid = false;
      _errors.newPassword = "Required";
    }

    if (password !== newPassword)
    {
      isValid = false;
      setGeneralError('Password and new password are not same')
    }

    if (isValid)
    {
      setLoading(true)
      setGeneralError(null)
      HttpClient.put('/account/password/reset', {
        code: match.params.code, 
        password: password,
        confirmPassword: newPassword
      })
        .then(() => {
          setLoading(false)
          setResetInProgress(false)
        })
        .catch(error => {
          setLoading(false)
          console.log('Error cofirmation: ', error)
          error.response.text().then(response => {
            setGeneralError(JSON.parse(response).error.message);
          });
        })
    }
    else{
      setErrors(_errors);
    }
  }

  const handleGetStartedButton = () => {
    if (localStorage.getItem('beEmailForgotPassword'))
      autoLogin()
    else
      history.push(`/designer?timestamp=${new Date().toISOString()}`)
  }
  

  const autoLogin = () => {
    setLoading(true)
    HttpClient.post('/login', {
      email: localStorage.getItem('beEmailForgotPassword'),
      password: password
    })
      .then(res => {
        setLoading(false)
        localStorage.setItem('jwtToken', res.token)
        localStorage.setItem('beDesignerName', res.designer.brandName)
        localStorage.removeItem('beEmailForgotPassword');
        handleGetStartedButton()
      })
      .catch(error => {
        setLoading(false)
        localStorage.removeItem('beEmailForgotPassword')
        handleGetStartedButton()
      })
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={true}
      onClose={() => history.push('/')}
      aria-labelledby="sign-in-dialog-title"
      classes={{ paper: classes.root }}
    >
      <Grid container>
      <Grid item xs={2}></Grid>
        <Grid item xs={8}>
          
          <DialogTitle id="registration-dialog-title" className={resetInProgress? classes.marginTop20: classes.marginTop50}>
            <Box component="span" display='flex' justifyContent='center' className={classes.brandBox}>
              <span className={classes.brandFirst + ' ' + classes.brandFont}>Live</span>
              <span className={classes.brandSecond + ' ' + classes.brandFont}>Contact</span>
              {/* {resetInProgress && (<span className={classes.brandThird}>Contributor</span>)} */}
            </Box>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Grid container>
                <Grid item xs={1}></Grid>
                <Grid item xs={10}>
                  {resetInProgress && (<>
                    <FormControl fullWidth mt={6} className={classes.marginTop20}>
                      <InputLabel htmlFor="standard-adornment-password">Password*</InputLabel>
                      <Input
                        id="standard-adornment-password"
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        fullWidth
                        autoFocus
                        required
                        onChange={(e) => onChangePassword(e.target.value, 'password')}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => handleClickShowPassword('password')}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        }
                        inputProps={
                          { minLength: 5 }
                        }
                        error={errors.password !== ''}
                        helperText='Enter your password. Minimum 5 characters required.'
                      />
                    </FormControl>

                    <FormControl fullWidth className={classes.marginTop20}>
                      <InputLabel htmlFor="standard-adornment-confirm-password">Confirm Password*</InputLabel>
                      <Input
                        id="standard-adornment-confirm-password"
                        type={showConfirmPassword ? 'text' : 'password'}
                        value={newPassword}
                        onChange={(e) => onChangePassword(e.target.value, 'confirmPassword')}
                        fullWidth
                        required
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={ () => handleClickShowPassword('confirmPassword')}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        }
                        inputProps={
                          { minLength: 5 }
                        }
                        error={errors.newPassword !== ''}
                        helperText='Enter your password again'
                      />
                    </FormControl>
                  </>)}

                  {!resetInProgress && (
                    <Typography className={classes.passwordText}
                    variant="h5" 
                    component="h5" 
                    color="textPrimary" 
                    gutterBottom>Password created successfully</Typography>          
                  )}
                  
                </Grid>
                <Grid item xs={1}></Grid>
              </Grid>

            </DialogContentText>

            {generalError && (
              <Box paddingTop={2}>
                <Alert severity="error">{generalError}</Alert>
              </Box>
            )}

            <Box display="flex" justifyContent="center">
              {resetInProgress ? (
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => handleResetPassword()}
                  className={classes.enterButton}
                >
                Enter
                {loading && <Loader />}
                
                </Button>
              ): (
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => handleGetStartedButton()}
                  className={classes.startButton}
                >
                Get Started
                {loading && <Loader />}
                </Button>
              )}
            </Box>
          </DialogContent>
        </Grid>
      </Grid>
      <Grid item xs={2}></Grid>
    </Dialog>
  )
}

PasswordReset.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
}

export default PasswordReset
