import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import { TextField, InputAdornment } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: '0.5em'
  },
  input: {
    paddingLeft: '10px',
    '& input': {
      paddingTop: '7px',
      paddingBottom: '7px'
    }
  },
}))

const SearchBar = ({ onSearch }) => {
  const classes = useStyles()
  const [query, setQuery] = useState('')

  const handleQueryUpdate = q => {
    setQuery(q)
    onSearch(q)
  }

  return (
    <Box position="relative" pb={4}>
      <Box position="absolute" width={1}>
        <TextField
          id="q"
          variant="outlined"
          fullWidth
          size="small"
          placeholder="Search"
          className={classes.root}
          value={query}
          onChange={e => handleQueryUpdate(e.currentTarget.value)}
          InputProps={{
            className: classes.input,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </Box>
  )
}

SearchBar.propTypes = {
  onSearch: PropTypes.func.isRequired,
}

export default SearchBar
