import React, {useContext, useState} from 'react'
import HttpClient from '../../../../services/HttpClient'
import AuthContext from '../../../../contexts/AuthContext'
import {makeStyles} from '@material-ui/core/styles'
import {DialogContent} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import {Box} from '@material-ui/core'
import crownIcon from '../../../../images/crown.png'

const useStyles = makeStyles(() => ({
  root: {
    padding: '20px',
    borderRadius: '20px',
  },
  title: {
    paddingTop: '3px',
    padding: '0px',
    paddingRight: '26px',
    fontSize: '19px',
    fontWeight: 'bold',
  },
  content: {
    padding: '0px',
    fontSize: '14px',
  },
  icon: {
    height: '40px',
  },
  bold: {
    fontWeight: 'bold',
  },
  flexBox: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '-14px',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'center',
    justifyContent: 'space-evenly',
  },
  cancelButton: {
    backgroundColor: '#393c40',
    alignItems: 'center',
    borderRadius: '18px',
    paddingLeft: '27px',
    paddingRight: '27px',
    height: '32px',
  },
  upgradeButton: {
    backgroundColor: '#393c40',
    alignItems: 'center',
    borderRadius: '18px',
    paddingLeft: '27px',
    paddingRight: '27px',
    height: '32px',
  },
  premium: {
    fontWeight: 'bold',
  },
  button: {
    color: '#6454FF',
    minWidth: '90px',
    marginRight: '10px',
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function SideAddDialog({open, close, templateId}) {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [authData] = useContext(AuthContext)

  const handleBackClick = () => {
    close(false)
  }

  const handlePurchasedEvent = () => {
    if (window.Print !== undefined)
      window.Print.postMessage(`upgradePlan${templateId}`)
  }

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <div className={classes.root}>
          <div className={classes.flexBox}>
            <DialogTitle className={classes.title}>
              Upgrade Subscription
            </DialogTitle>
            <img className={classes.icon} src={crownIcon}></img>
          </div>
          <br />
          <DialogContent className={classes.content}>
            <DialogContentText className={classes.content}>
              Sides limit exceed. Please upgrade your plan to{' '}
              <span className={classes.premium}>Premium</span> to add more
              sides.
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.buttons}>
            <Button
              classes={{
                root: classes.cancelButton,
              }}
              style={{
                backgroundColor: '#393c40',
              }}
              variant="contained"
              onClick={handleBackClick}
            >
              <Box
                display="inline"
                fontSize="14px"
                fontWeight="900"
                color="white"
              >
                Cancel
              </Box>
            </Button>
            <Button
              classes={{
                root: classes.upgradeButton,
              }}
              style={{
                backgroundColor: '#9933ff',
              }}
              variant="contained"
            >
              <Box
                display="inline"
                fontSize="14px"
                fontWeight="900"
                color="white"
                onClick={handlePurchasedEvent}
              >
                Upgrade
              </Box>
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  )
}
