import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Delete = props => {
  return (
    <SvgIcon {...props}>
      <path d="M11.5,7 C11.2238281,7 11,7.23560856 11,7.52631575 L11,17.4736842 C11,17.7641858 11.2238281,18 11.5,18 C11.7761719,18 12,17.7641858 12,17.4736842 L12,7.52631575 C12,7.23560856 11.7761719,7 11.5,7 Z" />
      <path d="M5.5,7 C5.2238281,7 5,7.23560856 5,7.52631575 L5,17.4736842 C5,17.7641858 5.2238281,18 5.5,18 C5.77617185,18 6,17.7641858 6,17.4736842 L6,7.52631575 C6,7.23560856 5.77617185,7 5.5,7 Z" />
      <path d="M1.39222374,6.25198919 L1.39222374,18.368876 C1.39222374,19.0850582 1.65397828,19.7576315 2.11123502,20.2402091 C2.56638544,20.7241316 3.19980478,20.9988473 3.86271212,21 L13.1372879,21 C13.8003867,20.9988473 14.4338061,20.7241316 14.888765,20.2402091 C15.3460217,19.7576315 15.6077763,19.0850582 15.6077763,18.368876 L15.6077763,6.25198919 C16.5167368,6.00993194 17.1057325,5.12892034 16.984142,4.1931577 C16.8623601,3.25758722 16.0679052,2.55773435 15.1273504,2.55754225 L12.6176083,2.55754225 L12.6176083,1.94279367 C12.6204805,1.42582851 12.4167447,0.929419022 12.0519733,0.564219948 C11.6872019,0.199212971 11.191649,-0.00403828159 10.6763735,6.08203574e-05 L6.32362648,6.08203574e-05 C5.80835098,-0.00403828159 5.31279804,0.199212971 4.94802667,0.564219948 C4.5832553,0.929419022 4.37951946,1.42582851 4.3823917,1.94279367 L4.3823917,2.55754225 L1.87264966,2.55754225 C0.93209478,2.55773435 0.137639948,3.25758722 0.0158579555,4.1931577 C-0.10573247,5.12892034 0.483263217,6.00993194 1.39222374,6.25198919 Z M13.1372879,20.0164023 L3.86271212,20.0164023 C3.02459961,20.0164023 2.37260668,19.2940727 2.37260668,18.368876 L2.37260668,6.29521369 L14.6273934,6.29521369 L14.6273934,18.368876 C14.6273934,19.2940727 13.9754004,20.0164023 13.1372879,20.0164023 Z M5.36277463,1.94279367 C5.35951942,1.68671247 5.45985548,1.44023669 5.64099657,1.2594622 C5.82194615,1.0786877 6.06819074,0.979367344 6.32362648,0.98378586 L10.6763735,0.98378586 C10.9318093,0.979367344 11.1780538,1.0786877 11.3590034,1.2594622 C11.5401445,1.44004459 11.6404806,1.68671247 11.6372254,1.94279367 L11.6372254,2.55754225 L5.36277463,2.55754225 L5.36277463,1.94279367 Z M1.87264966,3.54114 L15.1273504,3.54114 C15.6146696,3.54114 16.009695,3.93746075 16.009695,4.42637797 C16.009695,4.9152952 15.6146696,5.31161595 15.1273504,5.31161595 L1.87264966,5.31161595 C1.38533037,5.31161595 0.990305017,4.9152952 0.990305017,4.42637797 C0.990305017,3.93746075 1.38533037,3.54114 1.87264966,3.54114 Z" />
      <path d="M8.5,7 C8.2238281,7 8,7.23560856 8,7.52631575 L8,17.4736842 C8,17.7641858 8.2238281,18 8.5,18 C8.77617185,18 9,17.7641858 9,17.4736842 L9,7.52631575 C9,7.23560856 8.77617185,7 8.5,7 Z" />
    </SvgIcon>
  )
}

export default Delete
