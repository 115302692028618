import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import image1 from "../../../../images/p1.png";
import image2 from "../../../../images/p2.png";
import image3 from "../../../../images/p3.png";
import image4 from "../../../../images/p4.png";
import icon1 from "../../../../images/icon1.png";
import icon2 from "../../../../images/icon2.png";
import icon3 from "../../../../images/icon3.png";
import { Slide } from "@material-ui/core";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    height: "100%",
    marginTop: "115px",
    width: "100%",
  },
  scrollPaper: {
    marginTop: "50px",
  },
  paper: { borderRadius: 25, margin: 10 },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "red",
  },
  closeIcon: {
    float: "right",
    color: "#989898",
    fontWeight: "300px",
  },
  dialogStyle: {
    marginTop: "50px",
    height: "100%",
    borderRadius: "50px !important",
    width: "100%",
    marginRight: "0px",
    padding: 0,
    "&::-webkit-scrollbar": {
      display: "none",
    },
    msOverflowStyle: 'none',
    ScrollbarWidth: 'none',
  },
  title: {
    color: "#0E1F4A",
    font: "lato",
    fontSize: "35px",
    lineHeight:1.2,
    fontWeight: 900,
    alignSelf: "center",
    textAlign: "center",
    marginTop:20,
  },
  live: {
    color: "#49AEDD",
  },
  mainDiv: {
    display: "flex",
    flexDirection: "column",
    font: "lato",
    margin: "0px 18px",
  },
  box: {
    display: "flex",
    marginBottom: "5px",
    justifyContent: "sapce-between",
  },
  secondHeading: {
    display: "flex",
    color: "#007AFF",
    fontSize: "21px",
    fontWeight: 700,
    alignSelf: "center",
    textAlign: "center",
    marginBottom: "15px",
    marginTop: "20px",
  },
  thirdHeading: {
    display: "flex",
    fontSize: "12px",
    font:'lato',
    fontWeight: 'bold',
    margin: "0 10px",
    color: "#0E1F4A",
    marginBottom: "15px",
    padding: 12,
    justifyContent: "space-between",
  },
  fourthHeading: {
    color: "#007AFF",
    fontSize: "20px",
    fontWeight: 700,
    alignContent: "center",
    AlignItems: "center",
    justifyContent: "center",
    marginBottom: "15px",
  },
  heading: {
    fontWeight: "700",
    fontSize: "100%",
  },
  imageStyling: {
    display: "flex",
    width: "23%",
    height: "85px",
    borderRadius: "8px",
    justifyContent: "center",
    margin: "0 auto",
  },
  content: {
    color: "#0E1F4A",
    fontWeight: 300,
  },
  chooseDesignSubHeading: {
    font: "lato",
    fontSize: "15px",
    fontWeight: 400,
    color: "#0E1F4A",
  },
  boxStyle: {
    display: "flex",
    flexDirection: "row",
    fontWeight: 900,
  },
  verticalIcon: {
    marginLeft: 6,
    height: 22,
  },
  squareIcon: {
    marginLeft: 6,
    height: 19,
  },
  horizontalIcon: {
    marginLeft: 6,
    height: 16,
  },
  bold: {
    fontWeight: "bold",
  },
  contact:{
   color:'#29406B',
  },
}));

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function CustomizedDialogs(props) {
  const classes = useStyles();
  const { open, handleState } = props

  const handleCloseDialogue = () => {
    handleState(false)
  }

  return (
    <Dialog
      classes={{
        root: classes.dialogStyle,
        paper: classes.paper,
      }}
      TransitionComponent={Transition}
      timeout={200}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogContent dividers>
        <CloseIcon
          className={classes.closeIcon}
          onClick={handleCloseDialogue}
        ></CloseIcon>
        <div className={classes.mainDiv}>
          <Typography className={classes.title}>
            Creating Your
            <span className={classes.live}> Live</span><span className={classes.contact}>Contact</span> Card
          </Typography>
          <Typography className={classes.secondHeading}>
            Choose a Format
          </Typography>
          <Box className={classes.thirdHeading}>
            <Box className={classes.boxStyle}>
              <span className={classes.bold}>Vertical</span>
              <img className={classes.verticalIcon} src={icon1}></img>
            </Box>

            <Box className={classes.boxStyle}>
              <span className={classes.bold}>Square</span>
              <img className={classes.squareIcon} src={icon2}></img>
            </Box>

            <Box className={classes.boxStyle}>
              <span className={classes.bold}>Horizontal</span>
              <img className={classes.horizontalIcon} src={icon3}></img>
            </Box>
          </Box>

          <Typography className={classes.chooseDesignSubHeading}>
            <span className={classes.fourthHeading}>Choose a Design</span>
            <br />
            <span>
              Look out for these categories of card designs.
            </span>
          </Typography>

          <br />

          <Typography className={classes.content}>
            <span className={classes.heading}>Styles.</span>
            &nbsp;
            <span>
              Browse all the different card desin styles. (blank is first option)
            </span>
            <br />
            <br />
            <span className={classes.heading}>Personal Expression.</span>
            &nbsp;
            <span>Designs around the different concepts of expression.</span>
            <br />
            <br />
            <span className={classes.heading}>Bussiness.</span>
            &nbsp;
            <span>
              Designed specifically with different businesses in mind.
            </span>
            <br />
            <br />
            <span className={classes.heading}>Themes.</span>
            &nbsp;
            <span>
              Choose a card design based on design style.
            </span>
            <br />
            <br />
            <span className={classes.heading}>Occasions.</span>
            &nbsp;
            <span> Designs celebrating different life occasions.</span>
            <br />
            <br />
          </Typography>

          <div className={classes.box}>
            <img className={classes.imageStyling} src={image1}></img>
            <img className={classes.imageStyling} src={image2}></img>
            <img className={classes.imageStyling} src={image3}></img>
            <img className={classes.imageStyling} src={image4}></img>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
