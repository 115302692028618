import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import HttpClient from '../../services/HttpClient'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Box, TextField, Button } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Typography from '@material-ui/core/Typography'
import Alert from '@material-ui/lab/Alert'
import { useHistory } from 'react-router-dom'
import AuthenticationCode from './AuthenticationCode'
import Loader from './Loader'
import MuiPhoneInput from 'material-ui-phone-number'
import Link from '@material-ui/core/Link'

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '70vh',
  },
  rootPasscodeBox: {
    textDecoration: 'none'
  },
  passcodeBox: {
    borderWidth: '3px',
    borderStyle: 'solid',
    borderRadius: '10px',
    height: '65px',
    width: '50px',
    textAlign: 'center',
    fontSize: '18px',
    fontWeight: 500,
    textDecoration: 'none',
    '& :focus': {
      border: '3px solid #979797',
    }
  },
  logo: {
    fontSize: '4.4rem',
    marginTop: '16%',
    marginBottom: '3%',
  },
  actions: {
    display: 'flex',
    marginTop: '20%',
    marginBottom: '20%',
    justifyContent: 'center',
  },
  verificationActions: {
    display: 'flex',
    marginTop: '10%',
    marginBottom: '20%',
    justifyContent: 'space-between',
  },
  resend: {
    cursor: 'pointer',
    marginTop: '50px',
  },
  brandBox: {
    fontSize: '4rem',
  },
  brandFirst: {
    color: '#000000',
  },
  brandSecond: {
    color: '#3A60AC',
  },
  brandThird: {
    color: '#000000',
    paddingLeft: '5px'
  },
  brandFont: {
    fontWeight: '400',
    fontSize: '40px',
  },
  contentMargin: {
    marginLeft: '5%',
    marginRight: '5%'
  },
  signup: {
    borderRadius: 30,
    paddingLeft:30,
    paddingRight:30,
    fontSize:18
  },
  YesNo: {
    borderRadius: 30,
    paddingLeft:50,
    paddingRight:50,
    fontSize:18
  },
  scoreInput: {
    border: '1px solid #979797',
    borderRadius: '2px',
    height: '45px',
    width: '40px',
    textAlign: 'center',
    
  },
  btnAlignment:{
    display:'flex',
    justifyContent:'space-evenly',
  },
  description: {
    textAlign: 'center',
    fontSize: '20px',
    color: '#000000',
    paddingTop: '20px'
  },
  fontWeight600: {
    fontWeight: '600',
  },
  fontWeight300: {
    fontWeight: '300',
  },
  text: {
    fontWeight: '300',
    textAlign: 'center' 
  },
  signUpText:{
    textAlign: 'center',
    fontSize: '20px',
    color: '#000000',
    fontWeight: '300'
  },
  marginTop30:{
    marginTop:'30'
  }
}))

const initialDesigner = {
  email: '',
  mobileNumber: '',
  designer: true,
  firstName: '',
  lastName: '',
}

const initialErrorMessages = {
  nickName: '',
  email: '',
  mobileNumber: '',
  fullName:'',
  firstName: '',
  lastName: '',
  passcode1: '',
  passcode2: '',
  passcode3: '',
  passcode4: '',
  passcode5: ''
}


const Registration = props => {
  const classes = useStyles()
  const { onClose, open } = props
  const [designer, setDesigner] = useState(initialDesigner)
  const [errors, setErrors] = useState(initialErrorMessages)
  const [generalError, setGeneralError] = useState(null)
  const [registered, setRegistered] = useState(false)
  const [emailVerified, setEmailVerified] = useState(false)
  const [loading, setLoading] = useState(false)
  const [signup, setSignup] = useState({})
  const history = useHistory()
  const [isShow, setIsShow] = useState(false)
  const [autoFocusEmail, setAutoFocusEmail] = useState(false)
  const [emailRegisteredAsDesigner, setEmailRegisteredAsDesigner] = useState(false)
  const [mobileNumberPlain, setMobileNumberPlain] = useState(null)

  const verifyByPasscode = code => {
    setGeneralError(null)
    setLoading(true)
    HttpClient.post(
      '/account/passcode',
      { changeableType: 'email', changeableValue: designer.email, passCode: code },
    )
      .then(res => {
        setLoading(false)
        localStorage.setItem('jwtToken', res.token)
        res.designer.brandName && localStorage.setItem('beDesignerName', res.designer.brandName)
        history.push(`/registration/password/reset/${res.account.resetPasswordCode}?timestamp=${new Date().toISOString()}`)
        onClose()  
      })
      .catch(error => {
        setLoading(false)
        console.log('Error verifying with code: ', error)
        error.response.text().then(response => {
          setGeneralError(JSON.parse(response).error.message)
        })
      })
  }

  const resendPasscode = () => {
    setGeneralError(null)
    setLoading(true)
    HttpClient.get(
      `/account/passcode/new?email=${designer.email}&phone=${mobileNumberPlain}`,
    )
      .then(res => {
        console.log(res)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        console.log('Error resending password: ', error)
        error.response.text().then(response => {
          setGeneralError(JSON.parse(response).error.message)
        })
      })
  }

  useEffect(() => {
    setDesigner(initialDesigner)
    setRegistered(false)
    setGeneralError(null)
  }, [props.open])

  const handleSubmit = e => {
   
    e.preventDefault()
    setGeneralError(null)

    const _errors = { ...errors, ...initialErrorMessages }
    let isValid = true
    if (designer.firstName === '') {
      isValid = false
      _errors.firstName = 'Required'
    }
    else designer.nickName = designer.firstName
    if (designer.lastName === '') {
      isValid = false
      _errors.lastName = 'Required'
    }

    designer.displayName = `${designer.firstName} ${designer.lastName}`

    if (designer.email === '') {
      isValid = false
      _errors.email = 'Required'
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(designer.email)
    ) {
      isValid = false
      _errors.email = 'Invalid E-mail'
    }
    if (designer.mobileNumber === '') {
      isValid = false
      _errors.mobileNumber = 'Required'
    }

    if (isValid) {
      const {  ...apiData } = designer
      apiData.displayName = apiData.nickName
      apiData.mobileNumber = apiData.mobileNumber.replace(/[^0-9\+]/g, '')
      setMobileNumberPlain(apiData.mobileNumber)
      setLoading(true)
      setGeneralError(null)

      HttpClient.post('/signup', apiData)
        .then(res => {
          setLoading(false)
          setSignup(res)
          setRegistered(true)
          if (res.verifiedAt)
            setEmailVerified(true)
        })
        .catch(error => {
          setLoading(false)
          error.response.text().then(response => {
            setGeneralError(JSON.parse(response).error.message)
            if (JSON.parse(response).error.statusCode === 412) {
              setRegistered(true)
              setEmailVerified(false)
              setGeneralError(null)
            } 
            else if (JSON.parse(response).error.statusCode === 406) {
              setIsShow(true)
            }
            else if (JSON.parse(response).error.statusCode === 405) {
              setEmailRegisteredAsDesigner(true)
            }
          })
        })
     
    } else {
      setErrors(_errors)
    }
  }

  const moveToLogin = () => {
    resetDialogs()
    onClose(true, designer.email)
  }

  const handleClose = () => {
    resetDialogs()
    onClose(false)
  }

  const resetDialogs = () => {
    setEmailRegisteredAsDesigner(false)
    setIsShow(false)
  }

  const handleNo = () => {
    setIsShow(false)
    setDesigner({ ...designer, email: '' })
    setGeneralError(null)
    setAutoFocusEmail(true)
  }

  const handleYes = e => {
    e.preventDefault()
    
    setGeneralError(null)
    const _errors = { ...errors, ...initialErrorMessages }
    let isValid = true
    if (designer.email === '') {
      isValid = false
      _errors.email = 'Required'
    }
    if (isValid) {
      HttpClient.post('/accounts/designer', { email: designer.email })
        .then(() => {
          setLoading(false)
          setIsShow(false)
          moveToLogin()
        })
        .catch(error => {
          setLoading(false)
          setGeneralError(JSON.parse(error).error.message)
        })
    } else {
      setErrors(_errors)
    }
  }

  const handlePhoneNumberInput = value => {
    setDesigner({ ...designer, mobileNumber: value })
  }


  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="registration-dialog-title"
        classes={{ paper: classes.root }}
      >
        <Grid container>
          <Grid item xs={2}></Grid>
          <Grid item xs={8}>
            <DialogTitle id="registration-dialog-title">
              <Box mt={6} component="span" display='flex' justifyContent='center' className={classes.brandBox}>
                <span className={classes.brandFirst + ' ' + classes.brandFont}>Live</span>
                <span className={classes.brandSecond + ' ' + classes.brandFont}>Contact</span>
                {/* <span className={classes.brandThird + ' ' + classes.brandFont}>Contributor</span> */}
              </Box>
            </DialogTitle>
            {!registered && 
            <form onSubmit={handleSubmit}>
              <Typography
                variant="h5"
                component="h5"
                color="textPrimary"
                mt={6}
                className={classes.text}
              >
              Sign up as designer and help us build ...
              </Typography>
              <DialogContent> 
                <Grid container>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={10}>
                    <TextField
                      id="firstname"
                      label="First Name"
                      value={designer.firstName}
                      onChange={e =>
                        setDesigner({ ...designer, firstName: e.currentTarget.value })
                      }
                      fullWidth
                      autoFocus
                      required
                      error={errors.firstName !== ''}
                      helperText={errors.firstName}
                      mt={6}
                      className={classes.marginTop30}
                    />

                    <TextField
                      id="lastname"
                      label="Last Name"
                      value={designer.lastName}
                      onChange={e =>
                        setDesigner({ ...designer, lastName: e.currentTarget.value })
                      }
                      margin="normal"
                      fullWidth
                      required
                      mt={6}
                      error={errors.lastName !== ''}
                      helperText={errors.lastName}
                    />
                
                    <TextField
                      id="email"
                      label="E-mail"
                      type="email"
                      autoComplete="username"
                      value={designer.email}
                      onChange={e =>
                        setDesigner({ ...designer, email: e.currentTarget.value })
                      }
                      margin="normal"
                      autoFocus={autoFocusEmail}
                      fullWidth
                      required
                      mt={6}
                      error={errors.email !== ''}
                      helperText={errors.email}
                    />

                    <MuiPhoneInput
                      id="phone"
                      label="Mobile phone number"
                      type="phone"
                      onChange={value =>
                        handlePhoneNumberInput(value)
                      }
                      margin="normal"
                      fullWidth
                      required
                      mt={6}
                      error={errors.mobileNumber !== ''}
                      helperText={errors.mobileNumber}
                    />

                    {generalError ? (
                      <Box paddingTop={2}>
                        {generalError && (
                          <Box paddingTop={2}>
                            {emailRegisteredAsDesigner ?
                              <Alert severity="error">{generalError} Please <Link href='#' onClick={moveToLogin}>login</Link> to proceed</Alert>
                              : <Alert severity="error">{generalError}</Alert>
                            }
                          </Box>
                        )}
                      </Box>
                    ) : null}

                    {isShow ? 
                      (<>
                        <Box className={classes.btnAlignment}>
                          <Box mt={6} className={classes.actions}>
                            <Button
                              type="button"
                              variant="contained"
                              color="primary"
                              size="large"
                              className={classes.YesNo}
                              onClick={handleYes}
                            >
                              Yes
                              {loading && <Loader />}
                            </Button></Box>
                          <Box mt={6} className={classes.actions}>
                            <Button
                              type="button"
                              variant="contained"
                              color="primary"
                              size="large"
                              className={classes.YesNo}
                              onClick={()=>handleNo()}
                            >
                              No
                              {loading && <Loader />}
                            </Button></Box>
                        </Box>
                      </>)
                      :(<Box mt={6} className={classes.actions}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          size="large"
                          className={classes.signup}
                        >
                          Sign Up
                          {loading && <Loader />}
                        </Button></Box>)
                    }
                    
                  </Grid>
                  <Grid item xs={1}></Grid>
                </Grid>
              </DialogContent>
            </form>
            }
            {registered && !emailVerified &&  
            <form onSubmit={handleSubmit}>
              <DialogContent>
                
                <Typography
                  variant="body1"
                  component="h3"
                  color="textPrimary"
                  gutterBottom
                  className={classes.signUpText}
                >
                  Thank you for Signing Up.
                </Typography>
                <DialogContentText 
                  className={classes.description + ' ' + classes.fontWeight300}>
                  We have sent an authentication to</DialogContentText>

                <Typography
                  gutterBottom
                  className={classes.description + ' ' + classes.fontWeight600}
                >
                  {designer.email}
                </Typography>

                <Typography
                  gutterBottom 
                  className={classes.description + ' ' + classes.fontWeight300}
                >
                  Please enter code below:
                </Typography>

                <AuthenticationCode onCodeEntered={code => verifyByPasscode(code)}/>

                {generalError && (
                  <Box paddingTop={2}>
                    <Alert severity="error">{generalError}</Alert>
                  </Box>
                )}

                {loading && 
                <Box display='flex' justifyContent='center' paddingTop={2}>
                  <Loader color='blue'/>
                </Box>}

                <Box display="flex" justifyContent="center">
                  <Typography
                    variant="body1"
                    component="h4"
                    color="primary"
                    gutterBottom
                    className={ classes.resend }
                    onClick={() => resendPasscode()}
                  >
                    resend passcode
                  </Typography>
                </Box>
              </DialogContent> 
            </form>
            }
          </Grid>
          <Grid item xs={2}></Grid>
        </Grid>
      </Dialog>
    </>
  )
}

Registration.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default Registration
