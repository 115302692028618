export const DEFAULT_COLORS = [
  {
    name: 'Black',
    hex: '#000000',
    rgba: 'rgba(0, 0, 0, 1)',
  },
  {
    name: 'Dark gray',
    hex: '#545454',
    rgba: 'rgba(84, 84, 84, 1)',
  },
  {
    name: 'Gray',
    hex: '#737373',
    rgba: 'rgba(115, 115, 115, 1)',
  },
  {
    name: 'Gray',
    hex: '#a6a6a6',
    rgba: 'rgba(115, 115, 115, 1)',
  },
  {
    name: 'Light gray',
    hex: '#d9d9d9',
    rgba: 'rgba(217, 217, 217, 1)',
  },
  {
    name: 'White',
    hex: '#ffffff',
    rgba: 'rgba(255, 255, 255, 1)',
  },
  {
    name: 'Bright red',
    hex: '#ff1616',
    rgba: 'rgba(255, 22, 22, 1)',
  },
  {
    name: 'Coral red',
    hex: '#ff5757',
    rgba: 'rgba(255, 87, 87, 1)',
  },
  {
    name: 'Pink',
    hex: '#ff66c4',
    rgba: 'rgba(255, 102, 196, 1)',
  },
  {
    name: 'Magenta',
    hex: '#cb6ce6',
    rgba: 'rgba(203, 108, 230, 1)',
  },
  {
    name: 'Purple',
    hex: '#8c52ff',
    rgba: 'rgba(140, 82, 255, 1)',
  },
  {
    name: 'Violet',
    hex: '#5e17eb',
    rgba: 'rgba(94, 23, 235, 1)',
  },
  {
    name: 'Dark torquoise',
    hex: '#03989e',
    rgba: 'rgba(3, 152, 158, 1)',
  },
  {
    name: 'Aqua blue',
    hex: '#00c2cb',
    rgba: 'rgba(0, 194, 203, 1)',
  },
  {
    name: 'Torquoise blue',
    hex: '#5ce1e6',
    rgba: 'rgba(92, 225, 230, 1)',
  },
  {
    name: 'Light blue',
    hex: '#38b6ff',
    rgba: 'rgba(56, 182, 255, 1)',
  },
  {
    name: 'Royal blue',
    hex: '#5271ff',
    rgba: 'rgba(82, 113, 255, 1)',
  },
  {
    name: 'Cobalt blue',
    hex: '#004aad',
    rgba: 'rgba(0, 74, 173, 1)',
  },
  {
    name: 'Green',
    hex: '#008037',
    rgba: 'rgba(0, 128, 55, 1)',
  },
  {
    name: 'Grass green',
    hex: '#7ed957',
    rgba: 'rgba(126, 217, 87, 1)',
  },
  {
    name: 'Lime',
    hex: '#c9e265',
    rgba: 'rgba(201, 226, 101, 1)',
  },
  {
    name: 'Yellow',
    hex: '#ffde59',
    rgba: 'rgba(255, 222, 89, 1)',
  },
  {
    name: 'Peach',
    hex: '#ffbd59',
    rgba: 'rgba(255, 189, 89, 1)',
  },
  {
    name: 'Orange',
    hex: '#ff914d',
    rgba: 'rgba(255, 145, 77, 1)',
  },
]
