import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'

import { DEFAULT_FONTS } from '../../../../../../constants/DefaultFonts'
import SearchBar from '../SearchBar'
import Font from './Font'

const useStyles = makeStyles(() => ({
  root: {
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
  },
}))

const FontFamily = ({ family, onAddAction }) => {
  const classes = useStyles()
  const [fonts, setFonts] = useState(DEFAULT_FONTS)

  const handleSearch = q => {
    if(q.trim() === '') {
      setFonts(DEFAULT_FONTS)
    } else {
      setFonts(
        DEFAULT_FONTS.filter(f => f.replace(/\+/g, ' ').startsWith(q))
      )
    }
  }

  return (
    <Box px={2}>
      <SearchBar onSearch={handleSearch} />
      <Box position="relative" paddingBottom="60%">
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          className={classes.root}
        >
          {fonts.map((font, index) => (
            <Font key={index} font={font} onAddAction={onAddAction} />
          ))}
        </Box>
      </Box>
    </Box>
  )
}

FontFamily.propTypes =  {
  family: PropTypes.string.isRequired,
  onAddAction: PropTypes.func.isRequired,
}

export default FontFamily
