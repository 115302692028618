import React, { createContext, useState } from 'react'
import PropTypes from 'prop-types'

const NavigationContext = createContext([null,() => {}])

const NavigationContextProvider = ({ children }) => {
  const [navigation, setNavigation] = useState({
    activeTab: 0,
    panel: 0,
  })

  return (
    <NavigationContext.Provider value={[navigation, setNavigation]}>
      {children}
    </NavigationContext.Provider>
  )

}

NavigationContextProvider.propTypes = {
  children: PropTypes.node
}

export { NavigationContext, NavigationContextProvider }
