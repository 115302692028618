import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import { Container, Grid, Box, Typography } from '@material-ui/core'
import { TextField, InputAdornment } from '@material-ui/core'
import { Button, IconButton } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import Divider from '@material-ui/core/Divider'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import SearchIcon from '@material-ui/icons/Search'

import Templates from './Templates'

const useStyles = makeStyles(() => ({
  box: {
    position: 'relative',
    height: 0,
    paddingTop: '70%',
    border: '3px solid transparent',
    '&:hover': {
      border: '3px solid #007AFF',
    },
    '&:hover $icon': {
      fill: '#007AFF'
    }
  },
  link: {
    padding: 0,
    textDecoration: 'none',
  },
  icon: {
    position: 'absolute',
    left: '3%',
    top: '3%',
    fill: '#8BA8F0',
  },
  textField: {
    ['& fieldset']: {
      borderRadius: '5em',
    },
  },
  button: {
    padding: '6px 15px',
    borderRadius: '5em'
  },
  iconButton: {
    padding: '6px',
    border: '1px solid',
    borderRadius: '50%'
  },
  paper: {
    position: 'relative',
    height: 0,
    paddingTop: '80%',
    boxShadow: '0 0 7px 6px rgba(232,238,255,1)',
    cursor: 'pointer',
  },
  paperTextField: {
    position: 'absolute',
    width: '100%',
    bottom: '5%'
  }
}))

const Dashboard = ({ onOpenNewTemplateDialog, onCreateTextStyling }) => {
  const classes = useStyles()
  const urlParams = new URLSearchParams(window.location.search)
  const statusParam = urlParams.get('status')
  const [selectedFolder, setSelectedFolder] = useState(null)

  useEffect(() => {
    if (statusParam) {
      setSelectedFolder(parseInt(statusParam, 10))
    } else {
      setSelectedFolder(null)
    }
  }, [statusParam])  

  return (
    <Box px={3} width={1} overflow="auto">
      <Container>
        <Box mt={6} fontSize="h4.fontSize" fontWeight="fontWeightLight">Create New Project</Box>
        <Box mt={4}>
          <Box display="flex" width="100%" justifyContent='space-between'>
            <Box width="22%" height="100%" mr={2}>
              <Button
                fullWidth
                disableElevation
                className={classes.link}
                aria-label="New Template"
                onClick={onOpenNewTemplateDialog}
              >
                <Box width="100%" mb={1} bgcolor="#E1EAFF" borderRadius={16} className={classes.box}>
                  <AddCircleIcon className={classes.icon} />
                </Box>                
              </Button>
              <Typography align="center" color="textPrimary" variant="subtitle1">
                <Box fontWeight="fontWeightLight">Card Template</Box>
              </Typography>
            </Box>
            <Box width="22%" mr={2}>
              <Button
                fullWidth
                disableElevation
                className={classes.link}
                aria-label="New Text Styling"
                onClick={onCreateTextStyling}
              >
                <Box width="100%" mb={1} bgcolor="#E1EAFF" borderRadius={16} className={classes.box}>
                  <AddCircleIcon className={classes.icon} />
                </Box>                
              </Button>
              <Typography align="center" color="textPrimary" variant="subtitle1">
                <Box fontWeight="fontWeightLight">Text Styling</Box>
              </Typography>
            </Box>
            <Box width="22%" mr={2}>
              <Link to="/designer/active_icon" className={classes.link}>
                <Box width="100%" mb={1} bgcolor="#E1EAFF" borderRadius={16} className={classes.box}>
                  <AddCircleIcon className={classes.icon} />
                </Box>
                <Typography align="center" color="textPrimary" variant="subtitle1">
                  <Box fontWeight="fontWeightLight">Active Icons</Box>
                </Typography>
              </Link>
            </Box>
            <Box width="22%" mr={2}>
              <Link to="/designer/social_media" className={classes.link}>
                <Box width="100%" mb={1} bgcolor="#E1EAFF" borderRadius={16} className={classes.box}>
                  <AddCircleIcon className={classes.icon} />
                </Box>
                <Typography align="center" color="textPrimary" variant="subtitle1">
                  <Box fontWeight="fontWeightLight">Social Media</Box>
                </Typography>
              </Link>
            </Box>
            {/* <Box width="22%" mr={2}>
              <Link to="/designer/corporate" className={classes.link}>
                <Box width="100%" mb={1} bgcolor="#E1EAFF" borderRadius={16} className={classes.box}>
                  <AddCircleIcon className={classes.icon} />
                </Box>
                <Typography align="center" color="textPrimary" variant="subtitle1">
                  <Box fontWeight="fontWeightLight">Corporate Project</Box>
                </Typography>
              </Link>
            </Box> */}
          </Box>
        </Box>
        <Box mt={9}>
          <Divider />
        </Box>
        <Box mx={3}>
          <Box my={4}>
            <Grid container>
              <Grid item sm={2}>
                <Typography variant="h4">
                  <Box fontWeight="fontWeightLight">Projects</Box>
                </Typography>
              </Grid>
              <Grid item sm={4}>
                <TextField
                  id="q"
                  variant="outlined"
                  fullWidth
                  size="small"
                  placeholder="Search"
                  className={classes.textField}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon color="disabled" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item sm={2}></Grid>
              <Grid item sm={4}>
                <Grid container spacing={2}>
                  <Grid item sm={9}>
                    <Button fullWidth variant="outlined" color="primary" className={classes.button}>+ Create New Folder</Button>
                  </Grid>
                  <Grid item sm={3}>
                    <IconButton variant="outlined" color="primary" className={classes.iconButton}>
                      <DeleteOutlineIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box pt={3}>
            {selectedFolder === null ?
              <Grid container spacing={4}>
                <Grid item sm={3}>
                  <Paper
                    square
                    className={classes.paper}
                    onClick={() => setSelectedFolder(1)}
                  >
                    <Typography align="center" color="primary" variant="subtitle1" className={classes.paperTextField}>Drafts</Typography>
                  </Paper>
                </Grid>
                <Grid item sm={3}>
                  <Paper
                    square
                    className={classes.paper}
                    onClick={() => setSelectedFolder(2)}
                  >
                    <Typography align="center" color="primary" className={classes.paperTextField}>Published</Typography>
                  </Paper>
                </Grid>
                <Grid item sm={3}>
                  <Paper
                    square
                    className={classes.paper}
                    onClick={() => setSelectedFolder(5)}
                  >
                    <Typography align="center" color="primary" className={classes.paperTextField}>In Review</Typography>
                  </Paper>
                </Grid>
                <Grid item sm={3}>
                  <Paper
                    square
                    className={classes.paper}
                    onClick={() => setSelectedFolder(3)}
                  >
                    <Typography align="center" color="primary" className={classes.paperTextField}>Revision</Typography>
                  </Paper>
                </Grid>
              </Grid>
              :
              <Templates status={selectedFolder} onClose={() => setSelectedFolder(null)} />
            }
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

Dashboard.propTypes = {
  onOpenNewTemplateDialog: PropTypes.func.isRequired,
  onCreateTextStyling: PropTypes.func.isRequired,
}

export default Dashboard
