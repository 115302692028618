import React, { useContext, useState, useEffect } from "react";
import HttpClient from "../../../../services/HttpClient";
import AuthContext from "../../../../contexts/AuthContext";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { DialogContent } from "@material-ui/core";
import icon from "../../../../images/draftDialougIcon.png";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  button: {
    width: "100%",
    paddingTop: "10px",
    paddingBottom: "10px",
    marginTop: "10px",
  },
  content: {
    color: "black",
    display: "flex",
    margin: "20px 21px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "80px",
  },
  icon: {
    height: "70px",
  },
  textStyling: {
    textAlign: "center",
    fontSize: "bold",
    fontSize: "20px",
  },
  bold: {
    fontWeight: "bold",
  },
  flexBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    padding: "20px",
  },
  backArrow: {
    fontSize: "17px",
  },
  back: {
    alignItems: "center",
    marginTop: "-5px",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DraftDialoug({
  open,
  close,
  card,
  saveSide,
  savingInProgress
}) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [authData] = useContext(AuthContext);

  const handleBackClick = () => {
    close(false);
  };

  const handleSave = () => {
    setLoading(true);
    console.log(authData.authToken);
    HttpClient.put(
      `/cards/${card.id}`,
      {},
      {
        Authorization: `Bearer ${authData.authToken}`,
      }
    )
      .then(() => {
        saveSide(true);
      })
      .catch((error) => {
        console.log("Error getting industries: ", error);
        saveSide(true);
      });
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={() => close(false)}
        TransitionComponent={Transition}
      >
        <div className={classes.flexBox}>
          <ArrowBackIosIcon
            className={classes.backArrow}
            onClick={handleBackClick}
          />
          <Typography className={classes.back}>Back</Typography>
        </div>
        <DialogContent>
          <div className={classes.content}>
            <Typography className={classes.textStyling}>
              <span className={classes.bold}>Draft Saved</span>
            </Typography>
            <br />
            <br />
            <div>
              <img className={classes.icon} src={icon}></img>
            </div>
            <br />
            <Typography className={classes.textStyling}>
              See in <span className={classes.bold}>My Profiles.</span>
            </Typography>
            <br />
            <Typography className={classes.textStyling}>
              Complete your design anytime, it will update automatically in your
              profile.
            </Typography>
            <br />
            <br />
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={() => handleSave()}
            >
              Got it{" "}
              {loading && (
                <CircularProgress
                  style={{
                    color: "inherit",
                    marginLeft: "5px",
                    width: "15px",
                    height: "15px",
                  }}
                />
              )}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
