import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const ForwardIcon = props => {
  return (
    <SvgIcon {...props}>
      <path d="M0 8.3l12-6.9 12 6.9-12 6.5z"/>
      <path d="M12 22.3L0 15.5l1.3-.7L12 9l12 6.5zm0-1.9l8.5-4.9-8.5-4.6-8.5 4.6z"/>
    </SvgIcon>
  )
}

export default ForwardIcon
