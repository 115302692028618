import React, { createContext, useState } from 'react'
import PropTypes from 'prop-types'

const ActionContext = createContext([null,() => {}])

const ActionContextProvider = ({ children }) => {
  const [action, setAction] = useState({})

  return (
    <ActionContext.Provider value={[action, setAction]}>
      {children}
    </ActionContext.Provider>
  )

}

ActionContextProvider.propTypes = {
  children: PropTypes.node
}

export { ActionContext, ActionContextProvider }
