import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const TextsIcon = props => {
  return (
    <SvgIcon {...props}>
      <path d="M20.08 20.03c.15.37-.13.76-.53.76h-1.68c-.26 0-.47-.06-.64-.19-.16-.13-.29-.29-.37-.48l-1.26-2.86a.576.576 0 00-.54-.37H9.55c-.24 0-.45.15-.54.37l-1.26 2.86c-.06.17-.18.32-.35.46-.17.14-.38.21-.64.21h-1.7c-.4 0-.68-.4-.53-.76L10.45 5.3c.09-.21.3-.92.53-.92h2.67c.23 0 .44.71.53.92l5.9 14.73zM14 14.64c.4 0 .68-.4.54-.77L12.83 9.6c-.1-.24-.2-.53-.31-.86-.09-.34-.31-.34-.42 0-.1.33-.2.62-.3.87l-1.69 4.25c-.14.38-.05.77.35.77H14z" fill="none" stroke="currentColor" strokeWidth="1.287" strokeMiterlimit="10" />
    </SvgIcon>
  )
}

export default TextsIcon
