import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'

import SearchBar from '../SearchBar'
import DataFields from './DataFields'
import Font from './Font'
import { DEFAULT_FONTS } from '../../../../constants/DefaultFonts'

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#444',
    borderRadius: '0.5em'
  },
  input: {
    color: 'white',
    paddingLeft: '10px',
    '& input': {
      paddingTop: '7px',
      paddingBottom: '7px'
    }
  },
  tabs: {
    cursor: 'pointer'
  },
  tabActive: {
    backgroundColor: '#293039'
  }
}))

const Texts = props => {
  const classes = useStyles()
  const [fonts, setFonts] = useState(DEFAULT_FONTS)

  const handleSearch = q => {
    if (q.trim() === '') {
      setFonts(DEFAULT_FONTS)
    } else {
      setFonts(
        DEFAULT_FONTS.filter(f => f.replace(/\+/g, ' ').startsWith(q))
      )
    }
  }

  return (
    <>
      <SearchBar onSearch={handleSearch} />
      <Box mx={1} color="white">
        <Box
          py={1}
          width={0.5}
          display="inline-block"
          borderRadius="4px 4px 0 0"
          className={props.subPanel === 0 ? classes.tabActive : classes.tabs}
          textAlign="center"
          onClick={() => props.onSubpanelChange(0)}
        >Data Fields</Box>
        <Box
          py={1}
          width={0.5}
          display="inline-block"
          borderRadius="4px 4px 0 0"
          className={props.subPanel === 1 ? classes.tabActive : null}
          textAlign="center"
        >Fonts</Box>
      </Box>
      <Box
        flex="1 1 auto"
        px={1}
        overflow="scroll"
        bgcolor="#293039"
        color="white"
        className="scrollbar-hidden"
      >
        {props.subPanel === 1 ?
          fonts.map((font, index) => (
            <Font key={index} font={font} />
          ))
          :
          <DataFields />
        }
      </Box>
    </>
  )
}

Texts.propTypes = {
  subPanel: PropTypes.number.isRequired,
  onSubpanelChange: PropTypes.func.isRequired
}

export default Texts
