import React, { useContext, useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { fabric } from 'fabric'
import 'fabric-history'
import ReactResizeDetector from 'react-resize-detector'
import WebFont from 'webfontloader'

import { makeStyles } from '@material-ui/core/styles'
import { Grid, Box, Paper, IconButton, Tooltip } from '@material-ui/core'
import UndoIcon from '../../../../icons/UndoIcon'
import RedoIcon from '../../../../icons/RedoIcon'

import HttpClient from '../../../../services/HttpClient'
import AuthContext from '../../../../contexts/AuthContext'
import { ActionContext } from '../../../../contexts/ActionContext'

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    height: 0,
    paddingTop: '100%',
    outline: 'none'
  },
  canvas: {
    position: 'absolute',
    top: 0,
    boxShadow: '0 0 16px 0 #d3d4d5'
  }
}))

const reviver = svg => svg.replace('rgb(0,0,0)', 'currentColor')

const Side = props => {
  const classes = useStyles()
  const [authData] = useContext(AuthContext)
  const [action] = useContext(ActionContext)
  const [side, setSide] = useState({ canvas: null, undo: 0, redo: 0 })

  const sideBoxRef = useRef(null)
  const canvasRef = useRef(null)

  useEffect(() => {
    if( side.canvas === null ) {
      // Initialize Fabric canvas once only, due to it's own internal state
      initCanvas()
    }
  }, [props.side.id, side.canvas])

  useEffect(() => {
    if( side.canvas !== null) {
      applyAction()
    }
  }, [props.side.id, action])
  // useEffect(() => {
  //   if( side.canvas ) {
  //     side.canvas.setDimensions({ borderRadius: props.formatRadius })
  //   }
  // }, [props.formatRadius])

  const initCanvas = () => {
    const fabricCanvas = new fabric.Canvas(canvasRef.current, {
      preserveObjectStacking: true,
      renderOnAddRemove: false,
      backgroundColor: '#ffffff',
      width: sideBoxRef.current.offsetWidth,
      height: sideBoxRef.current.offsetHeight,
    })
    if( props.side.json !== null ) {
      let fontFamilies = []
      const sideJSON = JSON.parse(props.side.json)
      if(sideJSON.objects !== undefined) {
        const textObjects = sideJSON.objects.filter(o => o.type === 'textbox' && o.fontFamily !== 'Times New Roman')
        fontFamilies = textObjects.map(o => o.fontFamily.replace(/\s/g, '+'))
      }
      fabricCanvas.setDimensions({
        width: sideBoxRef.current.offsetWidth,
        height: sideBoxRef.current.offsetHeight
      })
      if(fontFamilies.length) {
        WebFont.load({
          google: { families: fontFamilies },
          active: () => fabricCanvas.loadFromJSON(props.side.json)
        })
      } else {
        fabricCanvas.loadFromJSON(props.side.json)
      }
      // fabric.loadSVGFromString(props.side.svg, objects => {
      //   // const group = fabric.util.groupSVGElements(objects, options)
      //   // fabricCanvas.add(group)
      //   fabricCanvas.add.apply(fabricCanvas, objects)
      //   fabricCanvas.requestRenderAll()
      //   // obj.setCoords()
      //   attachHandlers(fabricCanvas)
      // })
    }
    attachHandlers(fabricCanvas)
    side.canvas = fabricCanvas
    // side.canvas.onHistory()
    setSide({
      ...side,
      undo: side.canvas.historyUndo.length,
      redo: side.canvas.historyRedo.length,
    })
  }

  const applyAction = () => {
    const activeObj = side.canvas.getActiveObject()
    if( action ) {
      side.canvas.set('navigation', props.navigation)
      switch(action.type) {
        case 'addObject':
          side.canvas.centerObject(action.value)
          side.canvas.add(action.value)
            .setActiveObject(action.value)
          // props.onToolbarChange( getObjectData(action.value) )
          break
        case 'addTextStyling':
          fabric.util.enlivenObjects(JSON.parse(action.value).objects, objects => {
            const group = new fabric.Group(objects, {
              originX: 'center',
              originY: 'center',
              subTargetCheck: true,
              objectCaching: false
            })
            side.canvas.add(group)
            group.center().setCoords()
            side.canvas.setActiveObject(group)
            objects.forEach((editObject, index) => {
              editObject.on('mousedblclick', event => {
                side.canvas.setActiveObject(editObject)
                editObject.enterEditing()
                editObject.selectAll()
              })
            })
          })
          break
        case 'addGraphic':
          // side.canvas.centerObject(action.value)
          side.canvas.add(action.value)
            .setActiveObject(action.value)
          // props.onToolbarChange( getObjectData(action.value) )
          break
        case 'group':
          activeObj.toGroup()
          activeObj.type = 'group'
          props.onToolbarChange( getObjectData(activeObj) )
          break
        case 'ungroup':
          const objectsInGroup = activeObj.getObjects()
          activeObj.destroy()
          side.canvas.remove(activeObj)
          objectsInGroup.forEach(obj => {
            side.canvas.add(obj)
          })
          break
        case 'text':
          if( activeObj ) {
            activeObj.set({
              [action.property]: action.value
            })
            props.onToolbarChange( getObjectData(activeObj) )
          }
          break
        case 'background':
          const canvasAspect = side.canvas.width / side.canvas.height
          const imgAspect = action.value.width / action.value.height
          let left, top, scaleFactor
          if (canvasAspect >= imgAspect) {
            scaleFactor = side.canvas.width / action.value.width
            left = 0
            top = -((action.value.height * scaleFactor) - side.canvas.height) / 2
          } else {
            scaleFactor = side.canvas.height / action.value.height
            top = 0
            left = -((action.value.width * scaleFactor) - side.canvas.width) / 2
          }
          side.canvas.setBackgroundImage(action.value, side.canvas.renderAll.bind(side.canvas), {
            top: top,
            left: left,
            originX: 'left',
            originY: 'top',
            scaleX: scaleFactor,
            scaleY: scaleFactor
          })
          break
        case 'alignment':
          if( activeObj ) {
            const bb = activeObj.getBoundingRect()
            switch (action.value) {
              case 'forward':
                side.canvas.bringToFront(activeObj)
                break
              case 'backward':
                side.canvas.sendBackwards(activeObj)
                break
              case 'top':
                activeObj.set({
                  originY: 'top',
                  top: 0
                })
                break
              case 'middle':
                activeObj.centerV()
                break
              case 'bottom':
                activeObj.setPositionByOrigin({
                  x: activeObj.left,
                  y: (side.canvas.height - (bb.height/2))
                }, activeObj.originX, 'center')
                break
              case 'left':
                activeObj.set({
                  originX: 'left',
                  left: 0
                })
                break
              case 'center':
                activeObj.centerH()
                break
              case 'right':
                activeObj.setPositionByOrigin({
                  x: (side.canvas.width - (bb.width/2)),
                  y: activeObj.top
                }, 'center', activeObj.originY)
                break
            }
          }
          props.onToolbarChange( getObjectData(activeObj) )
          break
        case 'flip':
          activeObj.set({
            [action.property]: action.value
          })
          props.onToolbarChange( getObjectData(activeObj) )
          break
        case 'duplicate':
          activeObj.clone(clonedObj => {
            side.canvas.discardActiveObject()
            clonedObj.set({
              left: clonedObj.left + 10,
              top: clonedObj.top + 10,
              evented: true,
            })
            if (clonedObj.type === 'activeSelection') {
              // active selection needs a reference to the canvas.
              clonedObj.canvas = side.canvas
              clonedObj.forEachObject(obj => side.canvas.add(obj))
              // this should solve the unselectability
              clonedObj.setCoords()
            } else {
              side.canvas.add(clonedObj)
            }
            side.canvas.setActiveObject(clonedObj)
          })
          break
        case 'remove':
          if (activeObj) {
            if (activeObj.type === 'activeSelection') { // if group selected
              activeObj.forEachObject(element => side.canvas.remove(element))
            } else{
              side.canvas.remove(activeObj)
            }
            side.canvas.discardActiveObject()
          }
          break
      }
      side.canvas.requestRenderAll()
      setSide({
        ...side,
        undo: side.canvas.historyUndo.length,
        redo: side.canvas.historyRedo.length,
      })
      saveSide( side.canvas )
    }
  }

  const attachHandlers = fabricCanvas => {
    // fabricCanvas.on('object:selected', handleSelectObject)
    // fabricCanvas.on('selection:created', handleSelectionCreated)
    // fabricCanvas.on('selection:cleared', handleSelectionClear)
    // // fabricCanvas.on('after:render', saveCanvas)
    // fabricCanvas.on('text:editing:entered', options => {
    //   const selectedObject = options.target
    //   props.onToolbarChange( getObjectData(selectedObject) )
    // })
    fabricCanvas.on('selection:created', handleSelectionCreated)
    fabricCanvas.on('selection:updated', handleSelectionCreated)
    fabricCanvas.on('selection:cleared', handleSelectionClear)
    fabricCanvas.on({
      'object:added': handleCanvasUpdate,
      'object:removed': handleCanvasUpdate,
      'object:modified': handleCanvasUpdate
    })
    sideBoxRef.current.tabIndex = 1000  // To focus canvas container for cathing keydown event
    sideBoxRef.current.addEventListener('keydown', e => {
      if (e.key === 'Backspace' || e.key === 'Delete') {
        const selection = fabricCanvas.getActiveObject()
        if (selection) {
          if (selection.type === 'activeSelection') { // if group selected
            selection.forEachObject(element => fabricCanvas.remove(element))
          } else{
            fabricCanvas.remove(selection)
          }
          fabricCanvas.discardActiveObject()
          fabricCanvas.requestRenderAll()
        }
      }
    })
    // props.onSideUpdate({
    //   ...props.side,
    //   fabricCanvas,
    //   undo: fabricCanvas.historyUndo.length,
    //   redo: fabricCanvas.historyRedo.length,
    //   svg: fabricCanvas.toSVG({ suppressPreamble: true }, reviver),
    //   json: JSON.stringify(side.canvas)
    // })
  }


  // const handleSelectObject = e => {
  //   const selectedObject = e.target
  //   props.onToolbarChange( getObjectData(selectedObject) )
  // }

  const handleSelectionCreated = e => {
    const selectedObject = e.target
    props.onToolbarChange( getObjectData(selectedObject) )
  }

  const handleSelectionClear = e => {
    props.onNavigationChange({
      activePanel: 0
    })
    props.onToolbarChange({
      type: 'default'
    })
  }

  const handleCanvasUpdate = e => {
    const canvas = e.target.canvas
    setSide({
      ...side,
      undo: canvas.historyUndo.length,
      redo: canvas.historyRedo.length
    })
    saveSide( canvas )
  }

  const saveSide = canvas => {
    if( Number.isInteger(props.side.id) ) {
      let svg = canvas.toSVG({ suppressPreamble: true }, reviver);
      let fontFamilies = []
      canvas.getObjects().forEach((o) => {
        if (o.type === 'textbox' && o.fontFamily !== 'Times New Roman') {
          fontFamilies.push(o.fontFamily.replace(/\s/g, '+'))
        }
      })
      if (fontFamilies.length > 0) {
        svg = svg.replace(
          '<defs>',
          `<defs><style type="text/css">@import url('https://fonts.googleapis.com/css?family=${fontFamilies.join(
            '|'
          )}');</style>`
        )
      }
      const textStyling = {
          svg: svg,
          json: JSON.stringify(canvas.toDatalessJSON()),
          historyUndo: JSON.stringify(canvas.historyUndo),
          historyRedo: JSON.stringify(canvas.historyRedo),
          historyNextState: canvas.historyNextState,
      }
      HttpClient.put(
        `/text_stylings/${props.side.id}`,
        textStyling,
        { Authorization: `Bearer ${authData.authToken}` }
      )
        .then(res => {
          // props.onSideUpdate({ ...props.side, ...res })
        })
        .catch(error => {
          console.log('Error side update: ', error.response.status)
        })
    }
  }

  const getObjectData = selectedObj => {
    switch ( selectedObj.type ) {
      case 'textbox':
        return {
          type: 'text',
          fontFamily: selectedObj.get('fontFamily'),
          fontSize: selectedObj.get('fontSize'),
          fontWeight: selectedObj.get('fontWeight'),
          textColor: selectedObj.get('fill'),
          fontStyle: selectedObj.get('fontStyle'),
          underline: selectedObj.get('underline'),
          textAlign: selectedObj.get('textAlign'),
        }
      case 'image':
      case 'group':
      case 'path':
      case 'circle':
      case 'polygon':
      case 'rect':
      case 'activeSelection':
        return {
          type: selectedObj.type,
          color: selectedObj.get('fill'),
          flipX: selectedObj.get('flipX'),
          flipY: selectedObj.get('flipY'),
        }
      default:
        return { type: 'default' }
    }  
  }

  const undo = () => {
    side.canvas.undo()
    setSide({
      ...side,
      undo: side.canvas.historyUndo.length,
      redo: side.canvas.historyRedo.length
    })
  }

  const redo = () => {
    side.canvas.redo()
    setSide({
      ...side,
      undo: side.canvas.historyUndo.length,
      redo: side.canvas.historyRedo.length
    })
  }

  const handleResize = width => {
    const ratio = side.canvas.getWidth() / side.canvas.getHeight()
    const scale = width / side.canvas.getWidth()
    const zoom  = side.canvas.getZoom() * scale
    side.canvas.setDimensions({ width, height: (width / ratio) })
    side.canvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0])
  }

  return (
    <Box my="2.25%">
      <Box my="1%">
        <Grid container>
          <Grid item sm={6}>
            <Tooltip title="Undo" arrow>
              <span>
                <IconButton
                  size="small"
                  aria-label="Undo"
                  disabled={!(side.undo)}
                  onClick={undo}
                >
                  <UndoIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Redo" arrow>
              <span>
                <IconButton
                  size="small"
                  aria-label="Redo"
                  disabled={!(side.redo)}
                  onClick={redo}
                >
                  <RedoIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
      <Paper square elevation={0} className={classes.root}>
        <Box
          id={props.side.id}
          ref={sideBoxRef}
          position="absolute"
          top={0}
          bottom={0}
          width={1}
        >
          <ReactResizeDetector handleWidth skipOnMount onResize={handleResize}>
            <canvas
              id={`canvas-${props.side.id}`}
              ref={canvasRef}
              className={classes.canvas}
              style={{ borderRadius: '8px' }}
            />
          </ReactResizeDetector>
        </Box>
      </Paper>
    </Box>
  )
}

Side.propTypes = {
  side: PropTypes.object.isRequired,
  onSideUpdate: PropTypes.func.isRequired,
  navigation: PropTypes.object.isRequired,
  onNavigationChange: PropTypes.func.isRequired,
  onToolbarChange: PropTypes.func,
}

export default Side
