import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ChromePicker } from 'react-color'

import { makeStyles } from '@material-ui/core/styles'
import { Grid, Box, Tooltip } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

import { ActionContext } from '../../../../contexts/ActionContext'
import SearchBar from '../SearchBar'
import { DEFAULT_COLORS } from '../../../../constants/DefaultColors'
import Popover from '@material-ui/core/Popover'
import colorPickerImage from '../../../../images/color-pick.webp'

const useStyles = makeStyles(theme => ({
  colorBox: {
    cursor: 'pointer',
    transition: 'box-shadow .2s ease-in-out',
    '&:hover': {
      boxShadow: '0 0 0 2px #00c4cc, inset 0 0 0 2px #fff',
    }
  },
  colorPickerBtn: {
    maxWidth: '100%',
    borderRadius: 4
  }
}))

const componentToHex = c => {
  const hex = c.toString(16)
  return hex.length == 1 ? `0${hex}` : hex
}

const rgbToHex = color => {
  if( color !== undefined ) {
    if( color.startsWith('rgb') ) {
      const hex = color.replace(/\brgb\b|\(|\)/g, '').split(',')
      return `#${componentToHex(hex[0])}${componentToHex(hex[1])}${componentToHex(hex[2])}`
    }
    return color
  }
}

const Colors = props => {
  const classes = useStyles()
  const [action, setAction] = useContext(ActionContext)
  const [query, setQuery] = useState('')
  const colour = rgbToHex(props.color)
  const [color, setColor] = useState(colour)
  const [colorPickerEl, setColorPickerEl] = useState(null)
  const colorPickerOpen = Boolean(colorPickerEl)
  const colorPickerId = colorPickerOpen ? 'color-picker' : undefined

  useEffect(() => {
    setColor(colour)
  }, [colour])

  const handleSearch = q => {}

  // const handleColorUpdate = hex => {
  //   setActiveSide({
  //     ...activeSide,
  //     action: { type: 'text', property: 'fill', value: hex }
  //   })
  // }

  const handleColorPicked = clr => {
    setColor(clr.hex)
    setAction({ type: 'text', property: 'fill', value: clr.hex })
  }

  // const handleColorUpdate = clr => {
  //   const colors = [ ...props.colors ]
  //   let newColor  = { hex: clr.hex }
  //   if( clr.name !== undefined )
  //     newColor.name = clr.name
  //   if( colorIndex !== -1 ) {
  //     colors[colorIndex] = newColor
  //   } else {
  //     colors.push( newColor )
  //     colorIndex = colors.findIndex(c => c.hex === newColor.hex)
  //   }
  //   setAction({ type: 'text', property: 'fill', value: newColor.hex })
  //   props.onColorsChange(colors)
  // }

  const handleDefaultColorSelection = clr => {
    const colorIndex = props.colors.findIndex(c => c.hex === color)
    const colors = [ ...props.colors ]
    let newColor  = { hex: clr.hex }
    if( clr.name !== undefined )
      newColor.name = clr.name
    if( colorIndex !== -1 ) {
      colors[colorIndex] = newColor
    } else {
      colors.push( newColor )
    }
    setColor(newColor.hex)
    setAction({ type: 'text', property: 'fill', value: newColor.hex })
    props.onColorsChange(colors)
  }

  const colorTitle = c => {
    return c.name !== undefined ? `${c.name} ${c.hex}` : c.hex
  }

  const hanldeColorPickerOpen = e => setColorPickerEl(e.currentTarget)
  const hanldeColorPickerClose = () => setColorPickerEl(null)

  const getColors = () => {
    if( query === '' )
      return props.colors
    return props.colors.filter(c => c.indexOf(query) !== -1)
  }

  return (
    <Box position="absolute" width="260px" left="85px" height="100%" bgcolor="#000">
      <Box position="absolute" width={1} height={1} top="0" bgcolor="#293039" color="#fff" zIndex="2">
        <SearchBar onSearch={handleSearch} />
        <Box mx={1} mt={2}>
          <Typography variant="caption" display="block" gutterBottom>
          New Color
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={2}>
              <Box
                position="relative"
                width={1}
                pb="100%"
                borderRadius={4}
                boxSizing="border-box"
              >
                <Box
                  position="absolute"
                  left={1}
                  right={1}
                  top={1}
                  bottom={1}
                  borderRadius={4}
                  className={classes.colorBox}
                  aria-describedby={colorPickerId}
                  onClick={hanldeColorPickerOpen}
                >
                  <img src={colorPickerImage} className={classes.colorPickerBtn} />
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Popover
            id={colorPickerId}
            open={colorPickerOpen}
            anchorEl={colorPickerEl}
            onClose={hanldeColorPickerClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <ChromePicker
              disableAlpha
              color={color}
              onChange={handleColorPicked}
              // onChangeComplete={handleColorUpdate}
            />
          </Popover>
        </Box>
        <Box mx={1} mt={2}>
          <Typography variant="caption" display="block" gutterBottom>
          Template Colors
          </Typography>
          <Grid container spacing={1}>
            {props.colors.map((color, index) => (
              <Grid item xs={2} key={index}>
                <Box
                  position="relative"
                  width={1}
                  pb="100%"
                  borderRadius={4}
                  boxSizing="border-box"
                >
                  <Tooltip title={colorTitle(color)} arrow>
                    <Box
                      position="absolute"
                      left={1}
                      right={1}
                      top={1}
                      bottom={1}
                      borderRadius={4}
                      className={classes.colorBox}
                      style={{ backgroundColor: color.hex }}
                      onClick={() => handleDefaultColorSelection(color)} />
                  </Tooltip>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box mx={1} mt={2}>
          <Typography variant="caption" display="block" gutterBottom>
          Default Colors
          </Typography>
          <Grid container spacing={1}>
            {DEFAULT_COLORS.map((color, index) => (
              <Grid item xs={2} key={index}>
                <Box
                  position="relative"
                  width={1}
                  pb="100%"
                  borderRadius={4}
                  boxSizing="border-box"
                >
                  <Tooltip title={`${color.name} ${color.hex}`} arrow>
                    <Box
                      position="absolute"
                      left={1}
                      right={1}
                      top={1}
                      bottom={1}
                      borderRadius={4}
                      className={classes.colorBox}
                      style={{ backgroundColor: color.hex }}
                      onClick={() => handleDefaultColorSelection(color)} />
                  </Tooltip>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Box>
  )
}

Colors.propTypes = {
  colors: PropTypes.array.isRequired,
  color: PropTypes.string,
  onColorsChange: PropTypes.func.isRequired
}

export default Colors
