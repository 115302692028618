export const DEFAULT_FONTS = [
  'Roboto',
  'Open+Sans',
  'Lato',
  'Oswald',
  'Slabo+27px',
  'Roboto+Condensed',
  'Montserrat',
  'Source+Sans+Pro',
  'Raleway',
  'PT+Sans',
  'Playfair+Display',
  'Bitter',
  'Libre+Baskerville',
  'Archivo+Narrow',
  'Alegreya+Sans',
  'Crimson+Text',
  'Heebo',
  'Lobster',
  'Pacifico',
  'Abril+Fatface',
  'Barlow',
  'Bree+Serif',
  'Bonbon',
  'Ropa+Sans',
  'Amiri',
  'Orbitron',
  'Zilla+Slab',
  'Great+Vibes',
  'Cantata+One',
  'Cardo',
  'Poppins',
  'Merriweather',
  'Lora',
  'Domine',
  'Karla',
  'EB Garamond',
  'Neuton',
  'Josefin+Slab',
  'Unna',
  'Abhaya+Libre',
  'Ubuntu',
  'Rubik',
  'Cabin',
  'Notable',
  'Asap',
  'Indie+Flower',
  'Shadows+Into+Light',
  'Amatic+SC',
  'Architects+Daughter',
  'Nothing+You+Could+Do',
  'Reenie+Beanie',
  'Sue+Ellen+Francisco',
  'Squada+One',
  'Barriecito',
  'Mountains+of+Christmas',
  'Righteous',
  'Comfortaa',
  'Geostar',
  'Patua+One',
  'Roboto+Mono',
  'Inconsolata',
  'Source+Code+Pro',
  'Cousine',
  'PT+Mono',
  'Nanum+Gothic+Coding',
  'Space+Mono',
  'Anonymous+Pro',
  'Cutive+Mono',
  'Oxygen+Mono',
  'Literata',
  'Roboto+Slab',
  'Work+Sans',
  'Chivo',
]
