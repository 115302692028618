import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { DialogContent } from '@material-ui/core'
import { Button } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import { Box } from '@material-ui/core'

const useStyles = makeStyles(() => ({
    root: {
        padding: '20px',
        borderRadius: '20px',
    },
    title: {
        paddingTop: '3px',
        padding: '0px',
        paddingRight: '26px',
        fontSize: '19px',
        fontWeight: '900px',
    },
    content: {
        padding: '0px',
        fontSize: '14px',
    },
    textStyling: {
        textAlign: 'center',
        fontSize: 'bold',
        fontSize: '20px',
    },
    bold: {
        fontWeight: 'bold',
    },
    flexBox: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '-14px',
    },
    buttons: {
        display: 'flex',
        flexDirection: 'row',
        color: 'white',
        textAlign: 'center',
        justifyContent: 'space-evenly',
    },
    cancelButton: {
        backgroundColor: '#393c40',
        alignItems: 'center',
        borderRadius: '18px',
        paddingLeft: '27px',
        paddingRight: '27px',
        height: '32px',
        display: 'inline',
        fontSize: '14px',
        fontWeight: '900',
        color: 'white',
    },
    renewButton: {
        backgroundColor: '#393c40',
        alignItems: 'center',
        borderRadius: '18px',
        paddingLeft: '27px',
        paddingRight: '27px',
        height: '32px',
        display: "inline",
        fontSize: "14px",
        fontWeight: "900",
        color: "white"
    },
    marginTop: {
        marginTop: '-2px'
    }
}))

export const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

export default function SubscriptionDialouge({ open, close }) {
    const classes = useStyles()

    const handleBackClick = () => {
        close(false)
    }

    const handlePurchasedEvent = () => {
        if (window.Print !== undefined)
            window.Print.postMessage(`upgradePlan`)
    }

    return (
        <Box>
            <Dialog
                open={open}
                aria-describedby="alert-dialog-slide-description"
            >
                < Box className={classes.root}>
                    <Box className={classes.flexBox}>
                        <DialogTitle className={classes.title}>Renew Subscription</DialogTitle>
                    </Box>
                    <br />
                    <DialogContent className={classes.content}>
                        <DialogContentText className={classes.content}>
                            Your subscription is expired.
                            Please renew your subscription to enjoy the further functionality.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className={classes.buttons}>
                        <Button
                            classes={{
                                root: classes.cancelButton,
                            }}
                            style={{
                                backgroundColor: '#393c40',
                            }}
                            variant="contained"
                            onClick={handleBackClick}
                        >
                            <Box className={classes.marginTop} >Cancel</Box>
                        </Button>
                        <Button
                            classes={{
                                root: classes.renewButton,
                            }}
                            style={{
                                backgroundColor: '#9933ff',
                            }}
                            variant="contained"
                        >
                            <Box
                                className={classes.marginTop}
                                onClick={handlePurchasedEvent}
                            >
                                Renew
                            </Box>
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </Box>
    )
}
