import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const VerticalIcon = props => {
  return (
    <SvgIcon {...props}>
      <rect x="3" y="0" rx="4" ry="4" width="18" height="24" />
    </SvgIcon>
  )
}

export default VerticalIcon
