import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import HttpClient from '../../services/HttpClient'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Skeleton from '@material-ui/lab/Skeleton'

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '60vh',
  },
  logo: {
    fontSize: '4.4rem',
    marginTop: '16%',
    marginBottom: '3%',
  },
  actions: {
    marginTop: '20%',
    marginBottom: '20%',
    justifyContent: 'center',
  },
  brandBox: {
    marginTop:20,
    fontSize: "4rem",
  },
  brandFirst: {
    color: "#000000",
    fontWeight: "950",
    fontSize: "55px",
  },
  brandSecond: {
    color: "#3A60AC",
    fontWeight: "950",
    fontSize: "55px",
  },

  brandThird: {
    color: "#000000",
    fontWeight: "400",
    fontSize: "35px",
    paddingLeft: '5px'
  },
  marginTop20: {
    marginTop: '20px'
  },
  fontSize:{
    fontSize: 20,
  },
  button:{
    width:100,
    height:40,
    fontSize: 20,
    borderRadius: 30
  }
}))

const Confirmation = ({ history, match }) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(true)
  const [verified, setVerified] = useState(false)

  useEffect(() => {
    let didCancel = false
    HttpClient.get(`/account/confirmation?verificationCode=${match.params.id}`)
      .then(() => {
        if(!didCancel) {
          setVerified(true)
          setLoading(false)
        }
      })
      .catch(error => {
        console.log('Error cofirmation: ', error)
        setLoading(false)
      })
    return () => { didCancel = true }
  },[match.params.id])

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={true}
      onClose={() => history.push('/')}
      aria-labelledby="sign-in-dialog-title"
      classes={{ paper: classes.root }}
    >
      <Grid container>
        <Grid item xs={3}></Grid>
        <Grid item xs={6}>
          <DialogTitle id="registration-dialog-title">
          <Box display='flex' justifyContent='center' component="span" className={classes.brandBox}>
                <span className={classes.brandFirst}>Live</span>
                <span className={classes.brandSecond}>Contact</span>
              </Box>
          </DialogTitle>
          <DialogContent>
            <Typography variant="h6"  component="h6" color="textPrimary" gutterBottom>Account Activation</Typography>
            <DialogContentText>
              {loading &&
                <Typography variant="h1">
                  <Skeleton />
                </Typography>
              }
              {!loading ? (
                verified ?
                  <div className={classes.fontSize}>Your account is being activated successfully now. You can now log in to your account.</div>
                  :
                  <div className={classes.fontSize}>The activation link is invalid or already being used.</div>
              ) 
                : null
              }
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.actions}>
            <Button
              variant="contained"
              size="large"
              color="primary"
              className={classes.button}
              onClick={() => history.push('/')}
            >
              Close
            </Button>
          </DialogActions>
        </Grid>
      </Grid>
    </Dialog>
  )
}

Confirmation.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
}

export default Confirmation
