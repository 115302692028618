import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import HttpClient from '../../../../../../services/HttpClient'
import AuthContext from '../../../../../../contexts/AuthContext'
import { ActionContext } from '../../../../../../contexts/ActionContext'

import { makeStyles } from '@material-ui/core/styles'
import { Box, Paper } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  format_1: {
    position: 'relative',
    width: '100%',
    height: 0,
    paddingTop: '60%',
    outline: 'none',
    '& svg': {
      maxWidth: '100%',
      maxHeight: '100%',
      borderRadius: 'inherit',
    },
  },
  format_2: {
    position: 'relative',
    width: '100%',
    height: 0,
    paddingTop: '125%',
    outline: 'none',
    '& svg': {
      maxWidth: '100%',
      maxHeight: '100%',
      borderRadius: 'inherit',
    },
  },
  format_3: {
    position: 'relative',
    width: '100%',
    height: 0,
    paddingTop: '100%',
    outline: 'none',
    '& svg': {
      maxWidth: '100%',
      maxHeight: '100%',
      borderRadius: 'inherit',
    },
  },
}))

const Preview = ({ format }) => {
  const classes = useStyles()
  const [authData] = useContext(AuthContext)
  const [action, setAction] = useContext(ActionContext)
  const [svg, setSvg] = useState('')

  useEffect(() => {
    setAction({})
    HttpClient.get(`/formats/${format.id}/sides`, { Authorization: `Bearer ${authData.authToken}` })
      .then(res => setSvg(res[0].svg))
  }, [authData.authToken, format])

  return (
    <Paper
      variant="outlined"
      square
      className={classes[`format_${format.type}`]}
      style={{ borderRadius: `${format.cornerRadius}px` }}
    >
      <Box
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        style={{ borderRadius: `${format.cornerRadius}px` }}
        dangerouslySetInnerHTML={{ __html: svg }} />
    </Paper>
  )
}

Preview.propTypes = {
  format: PropTypes.object.isRequired,
}

export default Preview
