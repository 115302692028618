import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import HeaderBgTop from '../../../../images/template-selection-top.png'
import HeaderBgBottom from '../../../../images/template-selection-bottom.jpg'
import Info from '../../../../images/Info.png'
import Home from './Home'
import SubCategoryGrid from './SubCategoryGrid'
import SubCategoryHome from './SubCategoryHome'
import InfoDialogue from './InfoDialoug'
import Dashboard from './Dashboard'
import KeepItSimple from './KeepItSimple'
import FeaturedBackgrounds from './FeaturedBackgrounds'

const useStyles = makeStyles(() => ({
  root: {
    overflowY: 'auto',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    backgroundColor: '#fff',
  },
  appBarTop: {
    paddingBottom: '41%',
    // background: 'transparent',
    backgroundImage: `url(${HeaderBgTop})`,
    backgroundSize: 'contain',
  },
  appBarBottom: {
    paddingBottom: '20.6%',
    marginTop: '-3px',
    // background: 'transparent',
    backgroundImage: `url(${HeaderBgBottom})`,
    backgroundSize: 'contain',
  },
  searchBar: {
    backgroundColor: '#fff',
    borderRadius: '0.3em',
  },
  searchBarInput: {
    color: '#989c9c',
    paddingLeft: '10px',
    borderRadius: '0.3em',
    '& input': {
      paddingTop: '9px',
      paddingBottom: '9px',
    },
    '& fieldset': {
      borderColor: '#ededef',
    },
  },
  navigation: {
    height: 'auto',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    background: '#fff',
  },
  navigationActionWrapper: {
    '& svg': {
      fontSize: '2.5em',
    },
  },
  navigationActionLabel: {
    paddingTop: '0.7em',
    fontSize: '0.7em',
  },
  paddingLeft5: {
    paddingLeft: '5px',
  },
  paddingRight5: {
    paddingRight: '5px',
  },
  marginTop10: {
    marginTop: '10px',
  },
  headerText: {
    display: 'flex',
    flexDirection: 'column',
    color: '#fff',
    width: '90%',
    marginLeft: '4%',
    marginTop: '5%',
  },
  heading1: {
    fontSize: '1.763rem',
    fontWeight: 'bold',
    marginTop: '15px',
  },
  heading2: {
    fontSize: '0.9rem',
    fontWeight: 'bold',
    color: '#F8F0E3',
  },
  heading3: {
    fontSize: '0.9rem',
    fontWeight: 'bold',
    marginLeft: '9%',
    marginTop: '0px',
    color: '#fff',
  },
  opacity0: {
    opacity: 0,
    color: 'red',
  },
  opacity1: {
    opacity: 1,
  },
  infoDialog: {
    margin: '10% 3% 0 0',
    float: 'right',
    backgroundImage: `url(${Info})`,
    backgroundSize: 'contain',
    width: '20px',
    height: '20px',
  },
  padding20: {
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '20px',
    paddingBottom: '20px',
  },
}))

export const ScrollDirection = {
  up: 'UP',
  down: 'DOWN',
}

const New = ({ match, location }) => {
  const classes = useStyles()
  const [search, setSearch] = useState('')
  const [openDialougue, setOpenDialougue] = useState(false)

  let scrollY = 0
  let scrollDirection = null

  useEffect(() => {
    console.log('match.path' + match.path)
    if (/\/template\/\d+$/.test(location.pathname)) {
      setSearch('')
    }
  }, [location.pathname])

  useEffect(() => {
    if (window.Print !== undefined) window.Print.postMessage('scroll-up')
  })

  const paneDidMount = node => {
    if (node) {
      node.addEventListener('scroll', handleNavigation)
    }
  }

  const handleNavigation = e => {
    const window = e.target
    // const bottom = window.scrollHeight - window.scrollTop === window.clientHeight;

    if (scrollY > window.scrollTop) {
      if (scrollDirection !== ScrollDirection.up) {
        if (window.Print !== undefined) window.Print.postMessage('scroll-up')
        scrollDirection = ScrollDirection.up
      }
    } else if (scrollY < window.scrollTop) {
      if (scrollDirection !== ScrollDirection.down) {
        if (window.Print !== undefined) window.Print.postMessage('scroll-down')
        scrollDirection = ScrollDirection.down
      }
    }
    scrollY = window.scrollTop
  }

  const handleInfoDialog = state => {
    setOpenDialougue(state)
  }

  return (
    <Box
      ref={paneDidMount}
      height={1}
      paddingBottom={10}
      className={classes.root}
    >
      <Box display={search === '' ? 'block' : 'none'}>
        <Route exact path={`${match.path}`} component={Dashboard} />
        <Route exact path={`${match.path}/designs`} component={Home} />
        <Route path={`${match.path}/category`} component={SubCategoryHome} />
        <Route path={`${match.path}/subcategory`} component={SubCategoryGrid} />
        <Route path={`${match.path}/keepitsimple`} component={KeepItSimple} />
        <Route path={`${match.path}/featured`} component={FeaturedBackgrounds} />
      </Box>

      <InfoDialogue
        open={openDialougue}
        handleState={handleInfoDialog}
      />
    </Box>
  )
}

New.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}

export default New
