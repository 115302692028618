import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { fabric } from 'fabric'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

import { ActionContext } from '../../../../contexts/ActionContext'

const useStyles = makeStyles(() => ({
  draggable: {
    '-moz-user-select': 'none',
    '-khtml-user-select': 'none',
    '-webkit-user-select': 'none',
    userSelect: 'none',
    /* Required to make elements draggable in old WebKit */
    '-khtml-user-drag': 'element',
    '-webkit-user-drag': 'element',
    cursor: 'pointer'
  },
  button: {
    backgroundColor: '#333840',
    justifyContent: 'left',
    borderRadius: 4,
    '&:hover': {
      backgroundColor: '#7c7f84',
    },
  },
}))

const DataField = ({ dataField }) => {
  const classes = useStyles()
  const [action, setAction] = useContext(ActionContext)
  const [dragging, setDragging] = useState(false)

  const handleDragStart = () => {
    setDragging(true)
  }

  // const handleDragEnd = e => {
  //   setDragging(true)
  // }

  const add = () => {
    const field = new fabric.Textbox(dataField.value, {
      fontSize: 42,
      textAlign: 'center',
      originX: 'center',
      originY: 'center',
      splitByGrapheme: false,
    })
    if (dataField.id) {
      field.id = `DataField-${dataField.id}`
    }
      
    // field.toObject = (function(toObject) {
    //   return function() {
    //     return fabric.util.object.extend(toObject.call(this), {
    //       be: this.be
    //     })
    //   }
    // })(field.toObject)
    setAction({ type: 'addObject', value: field })
  }

  return (
    <div
      className={`${classes.draggable} ${dragging ? 'dragging' : ''}`}
      onDragStart={handleDragStart}
    >
      <Button
        color="inherit"
        variant="contained"
        fullWidth
        disableElevation
        className={classes.button}
        onClick={add}
      >{dataField.name}</Button>
    </div>
  )
}

DataField.propTypes = {
  dataField: PropTypes.object.isRequired
}

export default DataField
