import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ChromePicker } from 'react-color'

import { makeStyles } from '@material-ui/core/styles'
import { Grid, Box, Tooltip } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Popover from '@material-ui/core/Popover'

import { ActionContext } from '../../../../contexts/ActionContext'
// import { TemplateContext } from '../../../../contexts/TemplateContext'
import SearchBar from '../SearchBar'
import { DEFAULT_COLORS } from '../../../../constants/DefaultColors'
import colorPickerImage from '../../../../images/color-pick.webp'

const useStyles = makeStyles(() => ({
  colorBox: {
    cursor: 'pointer',
    transition: 'box-shadow .2s ease-in-out',
    '&:hover': {
      boxShadow: '0 0 0 2px #00c4cc, inset 0 0 0 2px #fff',
    }
  },
  selected: {
    cursor: 'pointer',
    transition: 'box-shadow .2s ease-in-out',
    boxShadow: '0 0 0 2px #00c4cc, inset 0 0 0 2px #fff',
  },
  colorPickerBtn: {
    maxWidth: '100%',
    borderRadius: 4
  }
}))

// const componentToHex = c => {
//   const hex = c.toString(16)
//   return hex.length === 1 ? `0${hex}` : hex
// }

// const rgbToObj = color => {
//   if( color !== undefined ) {
//     if( color.startsWith('rgb') ) {
//       const rgb = color.replace(/^rgba?\(|\s+|\)$/g,'').split(',')
//       return { r: rgb[0], g: rgb[1], b: rgb[2], a: rgb[3] }
//     }
//     return color
//   }
// }

const colorTitle = c => {
  return c.name !== undefined ? `${c.name} ${c.hex}` : c.hex
}

const Colors = ({ textColor, colors, onColorsChange }) => {
  const classes = useStyles()
  const [action, setAction] = useContext(ActionContext)
  // const [query, setQuery] = useState('')
  // const colour = rgbToObj(textColor)
  const [color, setColor] = useState(textColor)
  const [colorPickerEl, setColorPickerEl] = useState(null)
  const colorPickerOpen = Boolean(colorPickerEl)
  const colorPickerId = colorPickerOpen ? 'color-picker' : undefined

  useEffect(() => {
    setColor(textColor)
  }, [textColor])

  // useEffect(() => {
  //   const colorIndex = colors.findIndex(c => c.hex === color)
  //   if( colorIndex === -1 ) {
  //     const _colors = [ ...colors ]
  //     const defaultColor = DEFAULT_COLORS.find(c => c.hex === color)
  //     if( defaultColor ) {
  //       _colors.push(defaultColor)
  //     } else {
  //       _colors.push({ hex: color})
  //     }
  //     onColorsChange(_colors)
  //   }
  // }, [colors, color])

  const handleSearch = q => {}

  // const handleColorUpdate = hex => {
  //   setActiveSide({
  //     ...activeSide,
  //     action: { type: 'text', property: 'fill', value: hex }
  //   })
  // }

  const handleColorUpdate = clr => {
    const colorIndex = colors.findIndex(c => c.hex === color)
    const _colors = [ ...colors ]
    let newColor  = {
      hex: clr.hex,
    }
    if(clr.hsl) {
      newColor['rgba'] = `rgba(${clr.rgb.r}, ${clr.rgb.g}, ${clr.rgb.b}, ${clr.rgb.a})`
    } else {
      newColor['rgba'] = clr.rgba
    }
    if(clr.name !== undefined)  {
      newColor.name = clr.name
    }
    setColor(newColor.rgba)
    if(colorIndex !== -1) {
      _colors[colorIndex] = newColor
    } else {
      _colors.push( newColor )
    }
    setAction({
      type: 'text', property: 'fill', value: newColor
    })
    onColorsChange(_colors)
  }

  const hanldeColorPickerOpen = e => setColorPickerEl(e.currentTarget)
  const hanldeColorPickerClose = () => setColorPickerEl(null)

  const uniqueColors = () => colors.filter((obj, pos, arr) => arr.map(mapObj => mapObj.rgba).indexOf(obj.rgba) === pos)

  return (
    <Box position="absolute" width={1} height={1} top="0" bgcolor="#293039" color="#fff" zIndex="2">
      <SearchBar onSearch={handleSearch} />
      <Box mx={1} mt={2}>
        <Typography variant="caption" display="block" gutterBottom>
          New Color
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={2}>
            <Box
              position="relative"
              width={1}
              pb="100%"
              borderRadius={4}
              boxSizing="border-box"
            >
              <Box
                position="absolute"
                left={1}
                right={1}
                top={1}
                bottom={1}
                borderRadius={4}
                className={classes.colorBox}
                aria-describedby={colorPickerId}
                onClick={hanldeColorPickerOpen}
              >
                <img src={colorPickerImage} alt="pick color" className={classes.colorPickerBtn} />
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Popover
          id={colorPickerId}
          open={colorPickerOpen}
          anchorEl={colorPickerEl}
          onClose={hanldeColorPickerClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <ChromePicker
            color={color}
            onChangeComplete={handleColorUpdate}
          />
        </Popover>
      </Box>
      <Box mx={1} mt={2}>
        <Typography variant="caption" display="block" gutterBottom>
          Template Colors
        </Typography>
        <Grid container spacing={1}>
          {uniqueColors().map((color, index) => (
            <Grid item xs={2} key={index}>
              <Box
                position="relative"
                width={1}
                pb="100%"
                borderRadius={4}
                boxSizing="border-box"
              >
                <Tooltip title={colorTitle(color)} arrow>
                  <Box
                    position="absolute"
                    left={1}
                    right={1}
                    top={1}
                    bottom={1}
                    borderRadius={4}
                    className={classes.colorBox}
                    style={{ backgroundColor: color.rgba }}
                    onClick={() => handleColorUpdate(color)} />
                </Tooltip>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box mx={1} mt={2}>
        <Typography variant="caption" display="block" gutterBottom>
          Default Colors
        </Typography>
        <Grid container spacing={1}>
          {DEFAULT_COLORS.map((color, index) => (
            <Grid item xs={2} key={index}>
              <Box
                position="relative"
                width={1}
                pb="100%"
                borderRadius={4}
                boxSizing="border-box"
              >
                <Tooltip title={`${color.name} ${color.hex}`} arrow>
                  <Box
                    position="absolute"
                    left={1}
                    right={1}
                    top={1}
                    bottom={1}
                    borderRadius={4}
                    className={classes.colorBox}
                    style={{ backgroundColor: color.rgba }}
                    onClick={() => handleColorUpdate(color)} />
                </Tooltip>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  )
}

Colors.propTypes = {
  textColor: PropTypes.string,
  colors: PropTypes.array.isRequired,
  onColorsChange: PropTypes.func.isRequired
}

export default Colors
