import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const Sides = props => {
  return (
    <SvgIcon {...props}>
      <rect x="1" y="1" width="16" height="16" rx="2" fill="none" strokeWidth="2" stroke="currentColor" />
      <rect x="6" y="5" width="16" height="16" rx="2" fill="#ecf0f3" strokeWidth="2" stroke="currentColor" />
    </SvgIcon>
  )
}

export default Sides
