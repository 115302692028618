import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import { Grid, Box, Toolbar } from '@material-ui/core'
import { DialogContent, DialogActions } from '@material-ui/core/'
import { TextField, Button, IconButton } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import Overlay from '../Overlay'
import Publish from './Publish'

const useStyles = makeStyles(() => ({
  toolbar: {
    color: '#FFF',
    backgroundColor: '#8BA8F0',
    minHeight: '42px'
  },
  button: {
    color: 'white'
  }
}))

const TitleBar = props => {
  const classes = useStyles()
  const [iconName, setIconName] = useState(props.icon.name)
  const [editing, setEditing] = useState(false)
  const [publishing, setPublishing] = useState(false)

  useEffect(() => {
    setIconName(props.icon.name)
  }, [props.icon.name])

  const handleSubmit = e => {
    e.preventDefault()
    if(iconName !== '') {
      if( Number.isInteger(props.icon.id) ) {
        setEditing(false)
        props.onIconChange({ name: iconName })
      } else {
        setEditing(false)
        props.onIconChange({ id: 22, name: iconName })
      }
    }
  }

  return (
    <Toolbar variant="dense" className={classes.toolbar}>
      <Grid container>
        <Grid item sm={4}>
          {props.icon.name !== '' ? props.icon.name : 'Name Your Active Icon'}
          <IconButton
            color="inherit"
            aria-label="Edit Social Media Icon Name"
            component="span"
            onClick={() => setEditing(true)}
          >
            <EditIcon fontSize="small" />
          </IconButton>
          <Overlay
            maxWidth="xs"
            open={editing}
            onClose={() => setEditing(false)}
          >
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <TextField
                  id="text-styling-name"
                  label="Name"
                  value={iconName||''}
                  onChange={e => setIconName(e.currentTarget.value)}
                  margin="normal"
                  variant="outlined"
                  size="small"
                  fullWidth
                  autoFocus
                  required
                />
              </DialogContent>
              <DialogActions>
                <Button type="submit" variant="contained" color="primary" size="small">Save</Button>
              </DialogActions> 
            </form>
          </Overlay>
        </Grid>
        <Grid item sm={5} />
        <Grid item sm={3}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              color="inherit"
              size="large"
              aria-label="save text styling as"
              disabled={!Number.isInteger(props.icon.id)}
            >
              Save As
            </Button>
            <Button
              color="inherit"
              size="large"
              aria-label="publish text styling"
              disabled={!Number.isInteger(props.icon.id)}
              onClick={() => setPublishing(true)}
            >
              Publish
            </Button>
            <Publish
              open={publishing}
              onClose={() => setPublishing(false)}
              icon={props.icon}
              onIconChange={props.onIconChange}
            />
          </Box>
        </Grid>
      </Grid>
    </Toolbar>
  )
}

TitleBar.propTypes = {
  icon: PropTypes.object.isRequired,
  onIconChange: PropTypes.func.isRequired,
}

export default TitleBar
