import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const ImagesIcon = props => {
  return (
    <SvgIcon {...props}>
      <defs>
        <path id="ig" d="M3.48 5.09h17.43c.82 0 1.48.66 1.48 1.48v9.73c0 .82-.66 1.48-1.48 1.48H3.48c-.82 0-1.48-.66-1.48-1.48V6.57c0-.82.66-1.48 1.48-1.48z" />
      </defs>
      <clipPath id="ih">
        <use xlinkHref="#ig" overflow="visible" />
      </clipPath>
      <g clipPath="url(#ih)">
        <defs>
          <path id="ii" d="M-8-4.91h40.67v32.67H-8z" />
        </defs>
        <clipPath id="ij">
          <use xlinkHref="#ii" overflow="visible" />
        </clipPath>
        <g clipPath="url(#ij)">
          <path d="M3.48 5.09h17.43c.82 0 1.48.66 1.48 1.48v9.73c0 .82-.66 1.48-1.48 1.48H3.48c-.82 0-1.48-.66-1.48-1.48V6.57c0-.82.66-1.48 1.48-1.48z" clipPath="url(#l)" fill="none" stroke="currentColor" strokeWidth="2.958" strokeMiterlimit={10} />
        </g>
      </g>
      <defs>
        <path id="im" d="M4.38 14.85l2.84-2.44 2.74 2.2 4.06-3.91 1.9 2.07 3.48-4.02v6.1z" />
      </defs>
      <clipPath id="in">
        <use xlinkHref="#im" overflow="visible" />
      </clipPath>
      <ellipse id="io" cx="8.22" cy="9.72" rx="1.24" ry="1.22"></ellipse>
      <path clipPath="url(#in)" d="M1.05 5.41h21.69v12.77H1.05z" />
    </SvgIcon>
  )
}

export default ImagesIcon
