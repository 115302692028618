import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'

import HttpClient from '../../../../services/HttpClient'
import AuthContext from '../../../../contexts/AuthContext'

import { makeStyles } from '@material-ui/core/styles'
import { TextField, Button } from '@material-ui/core'
import {
  Dialog,
  DialogContent,
  DialogActions
} from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(() => ({
  logo: {
    fontSize: '4.4rem',
    marginTop: '10%',
  },
  actions: {
    marginTop: '1%',
    marginBottom: '5%',
    justifyContent: 'flex-end',
  },
}))

const SaveAs = ({nameUpdate, textStylingId, onClose }) => {
  const classes = useStyles()
  const [authData] = useContext(AuthContext)
  const [textStyling, setTextStyling] = useState({ name: '' })
  const [errors, setErrors] = useState({ name: '' })

  const handleSubmit = e => {
    e.preventDefault()
    const _errors = { name: '' }
    let isValid = true
    if(textStyling.name === '') {
      isValid = false
      _errors.name = 'Required'
    }
    if(isValid) {
      HttpClient.get(
        `/text_stylings/${textStylingId}`,
        { Authorization: `Bearer ${authData.authToken}` }
      )
        .then(res => {
          const {id, previewImage, settings, createdAt, updatedAt, status, parentTextStylingId,...newTextStyling } = res
          newTextStyling.name = textStyling.name
          nameUpdate(newTextStyling.name)

          e.preventDefault()
          if(newTextStyling.colors === null)
            newTextStyling.colors = []
          HttpClient.put(
            `/text_stylings/${res.id}`,
            newTextStyling,
            { Authorization: `Bearer ${authData.authToken}` }
          )
            .then(res => {setTextStyling(res)
              nameUpdate(newTextStyling.name)
            })
            .catch(error => console.error('Error cloning text styling: ', error))
        })
        .catch(error => console.error('Error getting text styling: ', error))
    } else {
      setErrors(_errors)
    }
  }

  return (
    textStyling.id ?
      <Redirect to={`/designer/text_styling/${textStyling.id}`} />
      :
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open
        onClose={onClose}
        aria-labelledby="new-text-styling-title"
      >
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Typography variant="h6" component="h2" color="textPrimary" gutterBottom>Save TextStyling as:</Typography>
            <TextField
              id="text-styling-name"
              label="New Name"
              value={textStyling.name}
              onChange={e => setTextStyling({ ...textStyling, name: e.currentTarget.value })}
              margin="normal"
              variant="outlined"
              size="small"
              fullWidth
              autoFocus
              required
              error={errors.name !== ''}
              helperText={errors.name}
            />
          </DialogContent>
          <DialogActions className={classes.actions}>
            <Button
              variant="contained"
              size="small"
              onClick={onClose}
            >
              Close
            </Button>
            <Button type="submit" variant="contained" color="primary" size="small">Save</Button>
          </DialogActions>
        </form>
      </Dialog>
  )
}

SaveAs.propTypes = {
  textStylingId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default SaveAs
