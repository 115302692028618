import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import { Box, Grid } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Skeleton from '@material-ui/lab/Skeleton'
import IconButton from '@material-ui/core/IconButton'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'

import HttpClient from '../../../services/HttpClient'
import AuthContext from '../../../contexts/AuthContext'

const useStyles = makeStyles(() => ({
  template: {
    '& svg': {
      maxWidth: '100%',
      maxHeight: '100%',
      borderRadius: 'inherit',
    },
  },
}))

const Templates = ({ status, onClose }) => {
  const classes = useStyles()
  const [authData] = useContext(AuthContext)
  const [templates, setTemplates] = useState([])
  const [loading, setLoading] = useState(true)
  const defaultPreviewImage = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" xml:space="preserve"><rect x="0" y="0" width="100%" height="100%" fill="#ffffff" /></svg>'
  const folderLabels = [
    { key:1,  label:'Drafts' },
    { key: 2, label: 'Published' },
    { key: 3, label: 'Revision'},
    { key: 5, label: 'Submitted for Publishing' }

];
  useEffect(() => {
    setLoading(true)
    HttpClient.get(`/mytemplates?status=${status}`, { Authorization: `Bearer ${authData.authToken}` })
      .then(res => {
        setTemplates(res)
        setLoading(false)
      })
      .catch(error => {
        console.log('Error mytemplates: ', error)
        setTemplates([])
        setLoading(false)
      })
  }, [authData.authToken, status])

  return (
    <>
      <Grid container>
        <Grid item sm={10}>
          <Typography variant="h5">
            {folderLabels.map(folder => folder.key===status && folder.label)}
          </Typography>
        </Grid>
        <Grid container item sm={2} justify="flex-end">
          <IconButton size="small" onClick={onClose}>
            <ChevronLeftIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Divider  />
      <Box py={1} display="flex" flexWrap="wrap">
        {loading ? 
          [...Array(10)].map((_, index) => (
            <Box key={index} width="10%" p={0.5}>
              <Box
                position="relative"
                paddingBottom="125%"
              >
                <Box
                  position="absolute"
                  top="0"
                  left="0"
                  bottom="0"
                  right="0"
                >
                  <Skeleton variant="rect" width="100%" height="100%" />
                </Box>
              </Box>
            </Box>
          ))
          :
          templates.map((template, index) => (
            <Box key={index} width="10%" p={0.5}>
              <Box
                component={Link}
                to={`/designer/template/${template.id}`}
                position="relative"
                display="block"
                paddingBottom="125%"
              >
                <Box
                  position="absolute"
                  top={0}
                  bottom={0}
                  width={1}
                  dangerouslySetInnerHTML={{ __html: (template.square && template.square.previewImage) || defaultPreviewImage }}
                  className={classes.template}
                  style={{
                    borderRadius: `${template.square ? template.square.cornerRadius : 8}px`
                  }}
                />
              </Box>
            </Box>
          ))
        }
      </Box>
    </>
  )
}

Templates.propTypes = {
  status: PropTypes.number.isRequired,
  onClose: PropTypes.func
}

export default Templates

