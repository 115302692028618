import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import HttpClient from '../../services/HttpClient'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Skeleton from '@material-ui/lab/Skeleton'
import LogoIcon from '../../icons/LogoIcon'

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '60vh',
  },
  logo: {
    fontSize: '4.4rem',
    marginTop: '16%',
    marginBottom: '3%',
  },
  actions: {
    marginTop: '20%',
    marginBottom: '20%',
    justifyContent: 'center',
  },
  brandBox: {
    marginTop:20,
    fontSize: "4rem",
  },
  brandFirst: {
    color: "#000000",
    fontWeight: "950",
    fontSize: "30px",
  },
  brandSecond: {
    color: "#2C2A84",
    fontWeight: "950",
    fontSize: "30px",
  },

  brandThird: {
    color: "#000000",
    fontWeight: "400",
    fontSize: "30px",
    paddingLeft: '5px'
  },
  marginTop20: {
    marginTop: '20px'
  },
  marginTop40: {
    marginTop: '40px'
  },
  getStartedButton: {
    width:150, 
    height:50, 
    fontSize: 20,
    borderRadius: 30
  },
  fontWeight200:{
    fontWeight: 200
  }
}))

const Welcome = ({ history, match }) => {
  const classes = useStyles()

  
  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={true}
      onClose={() => history.push('/')}
      aria-labelledby="sign-in-dialog-title"
      classes={{ paper: classes.root }}
    >
      <Box  display='flex' justifyContent='center' component="span" className={classes.brandBox + ' ' + classes.marginTop40}>
        <span className={classes.brandFirst}>Live</span>
        <span className={classes.brandSecond}>Contact</span>
        {/* <span className={classes.brandThird}>Contributor</span> */}
      </Box>
      <Grid container>
        <Grid item xs={2}></Grid>
        <Grid item xs={8}>
          <DialogContent className={classes.marginTop40}>
            <Typography  mt={3} variant="h4"  component="h4" color="textPrimary" gutterBottom>Welcome!</Typography>
            <DialogContentText>
            <Typography className={classes.fontWeight200} variant="h5"  component="h5" color="textPrimary" gutterBottom>Looking forward to working with you.</Typography>
            
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.actions}>
            <Button
              variant="contained"
              size="large"
              color="primary"
              className={classes.getStartedButton}
              onClick={() => history.push('/')}
            >
              Get Started
            </Button>
          </DialogActions>
        </Grid>

      <Grid item xs={2}></Grid>
      </Grid>

    </Dialog>
  )
}

Welcome.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
}

export default Welcome
