import React, { useContext, useState, useEffect } from 'react'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'

import HttpClient from '../../../../services/HttpClient'
import AuthContext from '../../../../contexts/AuthContext'
import SearchBar from '../SearchBar'
import Image from './Image'

const Images = () => {
  const [authData] = useContext(AuthContext)
  const [allImages, setAllImages] = useState([])
  const [images, setImages] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    HttpClient.get('/files?storageableType=Designer', { Authorization: `Bearer ${authData.authToken}` })
      .then(res => {
        setAllImages(res)
        setImages(res)
        setLoading(false)
      })
      .catch(error => {
        console.log('Error getting files: ', error)
        setAllImages([])
        setImages([])
        setLoading(false)
      })
  }, [authData.authToken])

  const handleSearch = q => {
    if (q.trim() === '') {
      setImages(allImages)
    } else {
      setImages(allImages.filter(img => img.fileName.startsWith(q)))
    }
  }

  const handleFileUpload = files => {
    const formData = new FormData()
    Array.from(files).forEach((file, index) => formData.append(`files[${index}]`, file, file.name))
    HttpClient.post(
      '/upload-bulk',
      formData,
      { Authorization: `Bearer ${authData.authToken}` }
    )
      .then(res => setImages([...images, ...res]))
      .catch(error => console.log('Error image upload: ', error))
  }

  return (
    <>
      <SearchBar onSearch={handleSearch} />
      <Box
        flex="1 1 auto"
        p={1}
        overflow="scroll"
        bgcolor="#293039"
        className="scrollbar-hidden"
      >
        <Box width={1} marginBottom={1}>
          <Button
            component="label"
            variant="contained"
            size="small"
            fullWidth
          >
            Upload Images
            <input
              type="file"
              multiple
              accept="image/*"
              hidden
              onChange={event => handleFileUpload(event.target.files)}
            />
          </Button>
        </Box>
        <Box style={{ columns: 2, columnGap: '4px' }}>
          {images.map((image, index) => (
            <Box key={index} paddingBottom={1/2}>
              <Image image={image} />
            </Box>
          ))}
        </Box>
      </Box>
    </>
  )
}

export default Images
