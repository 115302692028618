import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const BackgroundIcon = props => {
  return (
    <SvgIcon {...props}>
      <defs>
        <path id="bga" d="M105.12-692.21h1440v1024h-1440z" />
      </defs>
      <clipPath id="bgb">
        <use xlinkHref="#bga" />
      </clipPath>
      <g opacity=".288" clipPath="url(#bgb)">
        <defs>
          <path id="bgc" d="M1040.12-578.21h29v29h-29z" />
        </defs>
        <clipPath id="bgd">
          <use xlinkHref="#bgc" />
        </clipPath>
        <g clipPath="url(#bgd)">
          <defs>
            <path id="bge" d="M1045.12-573.21h19v19h-19z" />
          </defs>
          <clipPath id="bgf">
            <use xlinkHref="#bge" />
          </clipPath>
          <path clipPath="url(#bgf)" fill="none" stroke="#bg625e5e" strokeWidth=".978" strokeMiterlimit={10} d="M1050.01-573.21v13.68h13.68" />
          <path clipPath="url(#bgf)" fill="none" stroke="#bg625e5e" strokeWidth=".978" strokeMiterlimit={10} d="M1058.8-554.64v-13.68h-13.68" />
        </g>
      </g>
      <path d="M3.47 4.59h17.54c.81 0 1.47.6 1.47 1.35v10.71c0 .75-.65 1.35-1.47 1.35H3.47C2.65 18 2 17.4 2 16.65V5.94c0-.74.65-1.35 1.47-1.35z" fill="none" stroke="#bgfff" strokeWidth="1.5" strokeMiterlimit={10} />
      <defs>
        <path id="bgg" d="M3.47 4.59h17.54c.81 0 1.47.6 1.47 1.35v10.71c0 .75-.65 1.35-1.47 1.35H3.47C2.65 18 2 17.4 2 16.65V5.94c0-.74.65-1.35 1.47-1.35z" />
      </defs>
      <clipPath id="bgh">
        <use xlinkHref="#bgg" />
      </clipPath>
      <g clipPath="url(#bgh)">
        <defs>
          <path id="bgi" d="M-3.65-1.06h32.48v24.71H-3.65z" />
        </defs>
        <clipPath id="bgj">
          <use xlinkHref="#bgi" />
        </clipPath>
        <g clipPath="url(#bgj)">
          <defs>
            <path id="bgk" d="M2 4.59h20.47V18H2z" />
          </defs>
          <clipPath id="bgl">
            <use xlinkHref="#bgk" />
          </clipPath>
          <path d="M3.47 4.59h17.54c.81 0 1.47.6 1.47 1.35v10.71c0 .75-.65 1.35-1.47 1.35H3.47C2.65 18 2 17.4 2 16.65V5.94c0-.74.65-1.35 1.47-1.35z" clipPath="url(#bgl)" fill="none" stroke="#bgfff" strokeWidth="1.5" strokeMiterlimit={10} />
        </g>
        <g clipPath="url(#bgj)">
          <defs>
            <path id="bgm" transform="rotate(-45.997 3.765 5.646)" d="M-2.94 4.59h13.41v2.12H-2.94z" />
          </defs>
          <clipPath id="bgn">
            <use xlinkHref="#bgm" />
          </clipPath>
          <g clipPath="url(#bgn)">
            <defs>
              <path id="bgo" d="M2 4.59h20.47V18H2z" />
            </defs>
            <clipPath id="bgp">
              <use xlinkHref="#bgo" />
            </clipPath>
            <path transform="rotate(-45.997 3.765 5.646)" clipPath="url(#bgp)" fill="#bgababab" d="M-6.47 1.06H14v9.18H-6.47z" />
          </g>
        </g>
        <g clipPath="url(#bgj)">
          <defs>
            <path id="bgq" transform="rotate(-45.997 5.884 8.47)" d="M-.82 7.41h13.41v2.12H-.82z" />
          </defs>
          <clipPath id="bgr">
            <use xlinkHref="#bgq" />
          </clipPath>
          <g clipPath="url(#bgr)">
            <defs>
              <path id="bgs" d="M2 4.59h20.47V18H2z" />
            </defs>
            <clipPath id="bgt">
              <use xlinkHref="#bgs" />
            </clipPath>
            <path transform="rotate(-45.997 5.884 8.47)" clipPath="url(#bgt)" fill="#bgababab" d="M-4.35 3.88h20.47v9.18H-4.35z" />
          </g>
        </g>
        <g clipPath="url(#bgj)">
          <defs>
            <path id="bgu" transform="rotate(-45.997 8.492 10.786)" d="M-2.45 9.73h21.89v2.12H-2.45z" />
          </defs>
          <clipPath id="bgv">
            <use xlinkHref="#bgu" />
          </clipPath>
          <g clipPath="url(#bgv)">
            <defs>
              <path id="bgw" d="M2 4.59h20.47V18H2z" />
            </defs>
            <clipPath id="bgx">
              <use xlinkHref="#bgw" />
            </clipPath>
            <path transform="rotate(-45.997 8.492 10.786)" clipPath="url(#bgx)" fill="#bgababab" d="M-5.98 6.2h28.95v9.18H-5.98z" />
          </g>
        </g>
        <g clipPath="url(#bgj)">
          <defs>
            <path id="bgy" transform="rotate(-45.997 12.974 11.238)" d="M1.68 10.18h22.59v2.12H1.68z" />
          </defs>
          <clipPath id="bgz">
            <use xlinkHref="#bgy" />
          </clipPath>
          <g clipPath="url(#bgz)">
            <defs>
              <path id="bgA" d="M2 4.59h20.47V18H2z" />
            </defs>
            <clipPath id="bgB">
              <use xlinkHref="#bgA" />
            </clipPath>
            <path transform="rotate(-45.997 12.974 11.238)" clipPath="url(#bgB)" fill="#bgababab" d="M-1.85 6.65H27.8v9.18H-1.85z" />
          </g>
        </g>
        <g clipPath="url(#bgj)">
          <defs>
            <path id="bgC" transform="rotate(-45.997 16.415 12.817)" d="M7.59 11.76h17.65v2.12H7.59z" />
          </defs>
          <clipPath id="bgD">
            <use xlinkHref="#bgC" />
          </clipPath>
          <g clipPath="url(#bgD)">
            <defs>
              <path id="bgE" d="M2 4.59h20.47V18H2z" />
            </defs>
            <clipPath id="bgF">
              <use xlinkHref="#bgE" />
            </clipPath>
            <path transform="rotate(-45.997 16.415 12.817)" clipPath="url(#bgF)" fill="#bgababab" d="M4.06 8.23h24.71v9.18H4.06z" />
          </g>
        </g>
        <g clipPath="url(#bgj)">
          <defs>
            <path id="bgG" transform="rotate(-45.997 20.004 14.118)" d="M13.3 13.06h13.41v2.12H13.3z" />
          </defs>
          <clipPath id="bgH">
            <use xlinkHref="#bgG" />
          </clipPath>
          <g clipPath="url(#bgH)">
            <defs>
              <path id="bgI" d="M2 4.59h20.47V18H2z" />
            </defs>
            <clipPath id="bgJ">
              <use xlinkHref="#bgI" />
            </clipPath>
            <path transform="rotate(-45.997 20.004 14.118)" clipPath="url(#bgJ)" fill="#bgababab" d="M9.77 9.53h20.47v9.18H9.77z" />
          </g>
        </g>
        <g clipPath="url(#bgj)">
          <defs>
            <path id="bgK" transform="rotate(-45.997 22.829 16.235)" d="M16.12 15.18h13.41v2.12H16.12z" />
          </defs>
          <clipPath id="bgL">
            <use xlinkHref="#bgK" />
          </clipPath>
          <g clipPath="url(#bgL)">
            <defs>
              <path id="bgM" d="M2 4.59h20.47V18H2z" />
            </defs>
            <clipPath id="bgN">
              <use xlinkHref="#bgM" />
            </clipPath>
            <path transform="rotate(-45.997 22.829 16.235)" clipPath="url(#bgN)" fill="#bgababab" d="M12.59 11.65h20.47v9.18H12.59z" />
          </g>
        </g>
        <g clipPath="url(#bgj)">
          <defs>
            <path id="bgO" d="M2 4.59h20.47V18H2z" />
          </defs>
          <clipPath id="bgP">
            <use xlinkHref="#bgO" />
          </clipPath>
          <path d="M3.47 4.59h17.54c.81 0 1.47.6 1.47 1.35v10.71c0 .75-.65 1.35-1.47 1.35H3.47C2.65 18 2 17.4 2 16.65V5.94c0-.74.65-1.35 1.47-1.35z" clipPath="url(#bgP)" fill="none" stroke="#bgfff" strokeWidth="1.5" strokeMiterlimit={10} />
        </g>
      </g>
    </SvgIcon>
  )
}

export default BackgroundIcon
