import React from 'react'

const DummySkeleton = () => {
  return (
    <React.Fragment>
    </React.Fragment>       
  )
}

export default DummySkeleton
