import React, { useState, useEffect } from 'react'
import { Route } from 'react-router-dom'
import PropTypes from 'prop-types'

import Box from '@material-ui/core/Box'

import TopMenu from './TopMenu'
import Confirmation from './Confirmation'
import PasswordReset from './PasswordReset'
import ForgotPassword from './ForgotPassword'
import Welcome from './Welcome'
import {isNull} from 'lodash/fp'

const Home = ({ history, match }) => {
  const handleAuthentication = () => {
    history.push(`/designer?timestamp=${new Date().toISOString()}`)
  }

  const ifLoggedIn = () => {
    if (!isNull(localStorage.getItem('jwtToken')))
      handleAuthentication()
  }

  useEffect(() => {
    history.push(`/?timestamp=${new Date().toISOString()}`)
  }, [])

  return (
    <Box px={3} overflow="auto" height={1}>
      <TopMenu onAuthenticate={handleAuthentication} />
      <Route path={`${match.path}registration/confirmation/:id`} component={Confirmation} />
      <Route path={`${match.path}registration/password/reset/:code`} component={PasswordReset} />
      <Route path={`${match.path}registration/password/forgot`} component={ForgotPassword} />
      <Route path={`${match.path}welcome`} component={Welcome} />
    </Box>
  )
}

Home.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
}

export default Home
