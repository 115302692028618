import React from 'react'
import PropTypes from 'prop-types'

import { Box, Grid } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import ForwardIcon from '../../../../../../icons/ForwardIcon'
import BackwardIcon from '../../../../../../icons/BackwardIcon'
import TopAlignIcon from '../../../../../../icons/TopAlignIcon'
import LeftAlignIcon from '../../../../../../icons/LeftAlignIcon'
import CenterAlignIcon from '../../../../../../icons/CenterAlignIcon'
import MiddleAlignIcon from '../../../../../../icons/MiddleAlignIcon'
import BottomAlignIcon from '../../../../../../icons/BottomAlignIcon'
import RightAlignIcon from '../../../../../../icons/RightAlignIcon'

const Position = ({ action, onAddAction }) => {
  const handleLayering = direction => {
    onAddAction({
      type: 'alignment', value: direction
    })
  }

  const handleVerticalAlignmentChange = position => {
    onAddAction({
      type: 'alignment', value: position
    })
  }

  const handleHorizontalAlignmentChange = position => {
    onAddAction({
      type: 'alignment', value: position
    })
  }

  const currentAlignment = () => action && action.value

  return (
    <>
      <Box my={1} mr={3}>
        <Grid container spacing={3}>
          <Grid item sm={6}>
            <Button
              size="small"
              aria-label="Forward"
              onClick={() => handleLayering('forward')}
              startIcon={
                <ForwardIcon fontSize="small" />
              }
            >
              Forward
            </Button>
          </Grid>
          <Grid item sm={6}>
            <Button
              size="small"
              aria-label="Backward"
              onClick={() => handleLayering('backward')}
              startIcon={
                <BackwardIcon fontSize="small" />
              }
            >
              Backward
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box mx={0.5} mt={1} mb={0.6}>
        <Typography variant="caption" display="block" gutterBottom>Align to page</Typography>
      </Box>
      <Grid container spacing={1}>
        <Grid item sm={6}>
          <Button
            size="small"
            aria-label="Top"
            onClick={() => handleVerticalAlignmentChange('top')}
            disabled={currentAlignment() === 'top'}
            startIcon={
              <TopAlignIcon fontSize="small" />
            }
          >
            Top
          </Button>
        </Grid>
        <Grid item sm={6}>
          <Button
            size="small"
            aria-label="Left"
            onClick={() => handleHorizontalAlignmentChange('left')}
            disabled={currentAlignment() === 'left'}
            startIcon={
              <LeftAlignIcon fontSize="small" />
            }
          >
            Left
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item sm={6}>
          <Button
            size="small"
            aria-label="Middle"
            onClick={() => handleVerticalAlignmentChange('middle')}
            disabled={currentAlignment() === 'middle'}
            startIcon={
              <CenterAlignIcon fontSize="small" />
            }
          >
            Middle
          </Button>
        </Grid>
        <Grid item sm={6}>
          <Button
            size="small"
            aria-label="Center"
            onClick={() => handleHorizontalAlignmentChange('center')}
            disabled={currentAlignment() === 'center'}
            startIcon={
              <MiddleAlignIcon fontSize="small" />
            }
          >
            Center
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item sm={6}>
          <Button
            size="small"
            aria-label="Bottom"
            onClick={() => handleVerticalAlignmentChange('bottom')}
            disabled={currentAlignment() === 'bottom'}
            startIcon={
              <BottomAlignIcon fontSize="small" />
            }
          >
            Bottom
          </Button>
        </Grid>
        <Grid item sm={6}>
          <Button
            size="small"
            aria-label="Right"
            onClick={() => handleHorizontalAlignmentChange('right')}
            disabled={currentAlignment() === 'right'}
            startIcon={
              <RightAlignIcon fontSize="small" />
            }
          >
            Right
          </Button>
        </Grid>
      </Grid>
    </>
  )
}
            
Position.propTypes =  {
  action: PropTypes.object.isRequired,
  onAddAction: PropTypes.func.isRequired,
}
            
export default Position
            