import React, {useContext, useState, useEffect} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {ToastContainer, toast} from 'react-toastify'

import {makeStyles, Typography} from '@material-ui/core'
import {Box} from '@material-ui/core'

import DashboardTemplates from './DashBoardTemplates'
import DashboardHeader from './DashboardHeader'
import {getCategoryTypeKey, CustomCategoryTitle} from './Common'
import HttpClient from '../../../../services/HttpClient'
import AuthContext from '../../../../contexts/AuthContext'
import 'react-toastify/dist/ReactToastify.css'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    columnGap: theme.spacing(2),
    rowGap: theme.spacing(2),
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  headings: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#4A4A4A ',
    marginLeft: '30px',
    marginBottom: '10px',
  },
}))

const FeaturedBackgrounds = () => {
  const classes = useStyles()
  const [templates, setTemplates] = useState([])
  const [authData] = useContext(AuthContext)
  const [loading, setLoading] = useState(true)
  const history = useHistory()
  const {cardId} = useParams()

  const changeTemplate = (templateId, formatType) => {
    setLoading(true)
    HttpClient.put(
      `/cards/${cardId}/design`,
      {templateId: templateId, formatType: formatType, onlyBackground: true},
      {
        Authorization: `Bearer ${authData.authToken}`,
      }
    )
      .then((res) => {
        history.push(
          `/user/cards/${
            res.id
          }/design?featured=true&dashboard=true&timestamp=${new Date().toISOString()}`
        )
      })
      .catch((error) => {
        if (error.response.status === 424) {
          error.response.text().then((response) => {
            notify(JSON.parse(response).error.message)
          })
        } else notify('No Side with the selected template format.')
        console.log('Error changing template: ', error)
      })
  }

  useEffect(() => {
    setLoading(true)
    HttpClient.get(
      `/templates/published?categoryType=${getCategoryTypeKey(
        'Styles'
      )}&categoryTitle=${CustomCategoryTitle.featured}`,
      {
        Authorization: `Bearer ${authData.authToken}`,
      }
    )
      .then((resCard) => {
        setTemplates(resCard)
        setLoading(false)
      })
      .catch((error) => {
        console.log('Error getting templates: ', error)
        setTemplates([])
        setLoading(false)
      })
  }, [authData.authToken])

  const search = (searchQuery) => {
    HttpClient.get(
      `/templates/published?categoryType=${getCategoryTypeKey(
        'Styles'
      )}&categoryTitle=${CustomCategoryTitle.featured}&search=${searchQuery}`,
      {
        Authorization: `Bearer ${authData.authToken}`,
      }
    )
      .then((res) => {
        setTemplates(res)
        setLoading(false)
      })
      .catch((error) => {
        console.log('Error getting templates: ', error)
        setTemplates([])
        setLoading(false)
      })
  }

  const notify = (message) => toast(message)

  return (
    <React.Fragment>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <DashboardHeader onChange={(searchQuery) => search(searchQuery)} />
      <Typography className={classes.headings}>Feature your photo</Typography>
      <Box p={2} className={classes.root}>
        <DashboardTemplates
          onClick={(templateId, formatType) =>
            changeTemplate(templateId, formatType)
          }
          templates={templates}
        />
      </Box>
    </React.Fragment>
  )
}

export default FeaturedBackgrounds
