import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import { Box, Tooltip } from '@material-ui/core'
import Popover from '@material-ui/core/Popover'
import PaletteOutlinedIcon from '@material-ui/icons/PaletteOutlined'

import { DEFAULT_COLORS } from '../../../../../../constants/DefaultColors'
import Overlay from './Overlay'

const useStyles = makeStyles(() => ({
  slider: {
    overflowX: 'auto',
    overflowY: 'hidden',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
  },
  colorBox: {
    flex: 'none',
    cursor: 'pointer',
    border: '1px solid #ccc',
    transition: 'box-shadow .2s ease-in-out',
    '&:hover': {
      boxShadow: '0 0 0 2px #00c4cc, inset 0 0 0 2px #fff',
    }
  },
  selected: {
    cursor: 'pointer',
    transition: 'box-shadow .2s ease-in-out',
    boxShadow: '0 0 0 2px #00c4cc, inset 0 0 0 2px #fff',
  },
  colorPicker: {
    display: 'flex',
    flex: 'none',
    cursor: 'pointer',
    border: '1px solid #ccc',
    borderRadius: 4,
  },
  colorPickerBtn: {
    flex: 'auto'
  }
}))

const colorTitle = c => {
  return c.name !== undefined ? `${c.name} ${c.hex}` : c.hex
}

const Colors = ({ cardId, onAddAction }) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)

  const handleColorUpdate = clr => {
    clr.type = 'BackgroundColor'
    onAddAction({
      type: 'background', property: 'color', value: clr
    })
  }

  const handleOpenOverlay = event => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  const open = Boolean(anchorEl)
  const id = open ? 'background-colors-popover' : undefined

  return (
    <Box display="flex" width={1} paddingTop={1} className={classes.slider}>
      <Box px="8px">
        <Box
          display="flex"
          alignItems="center"
          width="32px"
          height="32px"
          className={classes.colorPicker}
          onClick={handleOpenOverlay}
        >
          <PaletteOutlinedIcon className={classes.colorPickerBtn} />
        </Box>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          aria-labelledby="background-colors"
        >
          <Overlay cardId={cardId} onAddAction={onAddAction} />
        </Popover>
      </Box>
      {DEFAULT_COLORS.map((color, index) => (
        <Box
          key={index}
          paddingRight="8px">
          <Tooltip title={colorTitle(color)} arrow>
            <Box
              width="32px"
              height="32px"
              borderRadius={4}
              className={classes.colorBox}
              style={{ backgroundColor: color.hex }}
              onClick={() => handleColorUpdate(color)} />
          </Tooltip>
        </Box>
      ))}
    </Box>
  )
}

Colors.propTypes = {
  cardId: PropTypes.number.isRequired,
  onAddAction: PropTypes.func.isRequired,
}

export default Colors
