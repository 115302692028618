import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const GroupIcon = props => {
  return (
    <SvgIcon {...props}>
      <path d="M11.3 1.6c-1 0-1.8.9-1.8 1.9l.1 9.2c0 1.1.8 1.9 1.8 1.9h8.8c1 0 1.8-.8 1.8-1.9V3.5c0-1-.8-1.9-1.8-1.9h-8.9zm0 1h8.9c.4 0 .8.4.8.9v9.2c0 .6-.4.9-.8.9h-8.8c-.4 0-.8-.3-.8-.9l-.1-9.2c0-.5.4-.9.8-.9zM4.5 8.7c-1.1 0-1.9.9-1.9 1.9v9.2c0 1.1.8 1.9 1.9 1.9h8.9c.5 0 .9-.2 1.2-.5.6-.6.6-1.3.6-1.4V16h-1v3.8c0 .1 0 .4-.3.7 0 .1-.4.2-.5.2H4.5c-.6 0-.9-.3-.9-.9v-9.2c0-.5.3-.9.9-.9h3.6v-1H4.5z" />
    </SvgIcon>
  )
}

export default GroupIcon
