import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const TrashIcon = props => {
  return (
    <SvgIcon {...props}>
      <path d="M9.43 7.3c-.25 0-.45.2-.45.45v9.85c0 .25.2.45.45.45s.45-.2.45-.45V7.75c0-.24-.2-.45-.45-.45z" />
      <path d="M12.57 7.3c-.25 0-.45.2-.45.45v9.85c0 .25.2.45.45.45s.45-.2.45-.45V7.75c-.01-.24-.21-.45-.45-.45z" />
      <path d="M15.25 18.05c.25 0 .45-.2.45-.45V7.75c0-.25-.2-.45-.45-.45s-.45.2-.45.45v9.85c0 .25.21.45.45.45z" />
      <path d="M10.77 4.17h2.69v.9h-2.69v-.9zm7.62 1.79h.9c.25 0 .45-.2.45-.45s-.2-.45-.45-.45h-4.92V3.72c0-.25-.2-.45-.45-.45h-3.58c-.25 0-.45.2-.45.45v1.34H4.95c-.25 0-.45.2-.45.45s.2.45.45.45h.9" />
      <path d="M17.71 19.41H6.96V6.87H17.7v12.54zM5.17 6.87h.9v12.99c0 .25.2.45.45.45h11.64c.25 0 .45-.2.45-.45V6.87h.9" />
    </SvgIcon>
  )
}

export default TrashIcon
