import React, { useContext, useState } from 'react'
import AuthContext from '../../../../contexts/AuthContext'
import { makeStyles } from '@material-ui/core'
import { TextField, InputAdornment } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { useHistory } from 'react-router'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingRight: '15px',
    paddingLeft: '15px',
  },
  cardDesign: {
    fontSize: '34px',
    fontWeight: '500',
    color: '#050505',
    marginBottom: '5px',
    marginLeft: '10px',
  },
  searchBar: {
    backgroundColor: '#fff',
    borderRadius: '0.3em',
    color: '#9B9B9B',
  },
  headings: {
    fontSize: '20px',
    marginTop: '10px',
    fontWeight: '900',
    marginLeft: '10px',
  },
  searchIcon: {
    color: '#9B9B9B',
  },
  button: {
    color: '#A9A9A9',
    height: '25px',
    width: '25px'
  },
  flexBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: '20px',
    paddingBottom: '20px'
  },
  link:{
    textDecoration: 'none'
  }
}))

const DashboardHeader = ({ onChange }) => {
  const [authData] = useContext(AuthContext)
  const [search, setSearch] = useState('')
  const [home, setHome] = useState()
  const classes = useStyles()
  const { cardId } = useParams()
  const history = useHistory()

  const handleBackClick = () => {
    history.goBack()
  };
  return (
    <React.Fragment>
      <div className={classes.root}>
        <div className={classes.flexBox}>
          <ArrowBackIosIcon
            className={classes.button}
            onClick={handleBackClick}
          />
        </div>
        <a href={`/user/cards/new/${cardId}?timestamp=${new Date().toISOString()}`} className={classes.link}>
          {' '}
          <p className={classes.cardDesign}>Card Design</p>
        </a>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          value={search}
          placeholder="Search for industry, style, theme..."
          className={classes.searchBar}
          onChange={(e) => {
            setSearch(e.currentTarget.value)
            onChange(e.currentTarget.value)
          }}
          InputProps={{
            className: classes.searchBarInput,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon className={classes.searchIcon} />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <br />
    </React.Fragment>
  )
};

DashboardHeader.prototype = {
  onChange: PropTypes.func.isRequired,
}

export default DashboardHeader
