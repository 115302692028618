import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { fabric } from 'fabric'

import { makeStyles } from '@material-ui/core/styles'
import { Grid, Box, Tooltip } from '@material-ui/core'
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import HttpClient from '../../../../../services/HttpClient'
import AuthContext from '../../../../../contexts/AuthContext'
import { ActionContext } from '../../../../../contexts/ActionContext'
import SearchBar from '../../SearchBar'

const useStyles = makeStyles(theme => ({
  tabs: {
    cursor: 'pointer'
  },
  tabActive: {
    backgroundColor: '#293039'
  },
  iconBox: {
    fill: 'white',
    cursor: 'pointer',
    backgroundColor: 'hsla(0,0%,100%,.07)',
    '& svg': {
      maxWidth: '100%',
      maxHeight: '100%'
    },
    '&:before': {
      display: 'none',
    },
  },
  accordion: {
    color: '#fff',
    backgroundColor: '#191d23',
    borderTop: '2px solid #293039',
    '&$accordionExpanded': {
      margin: 'auto',
    },
  },
  accordionExpanded: {},
  accordionSummary: {
    minHeight: 20,
    '&$expanded': {
      minHeight: 20,
    },
  },
  expanded: {},
  accordionSummaryContent: {
    margin: `${theme.spacing(1)/2}px 0`,
    '&$expanded': {
      margin: `${theme.spacing(1)/2}px 0`,
    },
  },
  accordionExpandIcon: {
    padding: `${theme.spacing(1)/2}px`
  },
  accordionIcon: {
    color: '#8ba8f0',
    fontSize: theme.typography.body1.fontSize,
  },
  accordionDetails: {
    backgroundColor: 'hsla(0,0%,100%,.07)',
  }
}))

const groupByCategory = icons => {
  return icons.reduce((r, ico) => {
    r[ico.category] = r[ico.category] || []
    r[ico.category].push(ico)
    return r
  }, Object.create(null))
}

const SocialMediaIcons = props => {
  const classes = useStyles()
  const [authData] = useContext(AuthContext)
  const [action, setAction] = useContext(ActionContext)
  const [icons, setIcons] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    HttpClient.get('/myicons?type=1&state=3', { Authorization: `Bearer ${authData.authToken}` })
      .then(res => {
        setIcons(res)
        setLoading(false)
      })
      .catch(error => {
        console.log('Error active icons loading: ', error)
        setIcons([])
        setLoading(false)
      })
  }, [])
  
  const handleSearch = () => {}

  const handleAdd = icon => {
    fabric.loadSVGFromString(icon.svg, (objects, options) => {
      const obj = fabric.util.groupSVGElements(objects, options)
      obj.id = `Icon-${icon.id}`
      // obj.be = { id: icon.id, type: 'SocialMediaIcon' }
      setAction({ type: 'addObject', value: obj })
    })
  }

  const categorized = groupByCategory(icons)
  return (
    <React.Fragment>
      <SearchBar onSearch={handleSearch} />
      <Box mt={1} mx={2} color="white">
        <Box
          py={1}
          width={0.5}
          display="inline-block"
          borderRadius="4px 4px 0 0"
          className={props.subPanel === 0 ? classes.tabActive : classes.tabs}
          textAlign="center"
          onClick={() => props.onSubpanelChange(0)}
        >Published</Box>
        <Box
          py={1}
          width={0.5}
          display="inline-block"
          borderRadius="4px 4px 0 0"
          className={props.subPanel === 1 ? classes.tabActive : classes.tabs}
          textAlign="center"
        >Standard</Box>
      </Box>
      <Box flex="1 1 auto" p={1} overflow="scroll" bgcolor="#293039" color="white">
        <Grid container spacing={1}>
          {Object.keys(categorized).map((category, i) => (
            <Box width={1} key={i}>
              <Accordion square classes={{
                root: classes.accordion,
                expanded: classes.accordionExpanded
              }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className={classes.accordionIcon} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  classes={{
                    root: classes.accordionSummary,
                    expanded: classes.expanded,
                    content: classes.accordionSummaryContent,
                    expandIcon: classes.accordionExpandIcon
                  }}
                >
                  <Typography variant="caption">{category}</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                  <Grid container spacing={1}>
                    {categorized[category].map(icon => (
                      <Grid item xs={4} key={icon.id}>
                        <Box
                          position="relative"
                          width={1}
                          pb="100%"
                          borderRadius={4}
                          boxSizing="border-box"
                        >
                          <Tooltip title={icon.name} arrow>
                            <Box
                              position="absolute"
                              left={1}
                              right={1}
                              top={1}
                              bottom={1}
                              borderRadius={4}
                              className={classes.iconBox}
                              dangerouslySetInnerHTML={{ __html: icon.svg }}
                              onClick={() => handleAdd(icon)}
                            />
                          </Tooltip>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Box>
          ))}
          <Box width={1}>
            <Accordion square classes={{
              root: classes.accordion,
              expanded: classes.accordionExpanded
            }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.accordionIcon} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                classes={{
                  root: classes.accordionSummary,
                  expanded: classes.expanded,
                  content: classes.accordionSummaryContent,
                  expandIcon: classes.accordionExpandIcon
                }}
              >
                <Typography variant="caption">All</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                <Grid container spacing={1}>
                  {icons.map(icon => (
                    <Grid item xs={4} key={icon.id}>
                      <Box
                        position="relative"
                        width={1}
                        pb="100%"
                        borderRadius={4}
                        boxSizing="border-box"
                      >
                        <Tooltip title={icon.name} arrow>
                          <Box
                            position="absolute"
                            left={1}
                            right={1}
                            top={1}
                            bottom={1}
                            borderRadius={4}
                            className={classes.iconBox}
                            dangerouslySetInnerHTML={{ __html: icon.svg }}
                            onClick={() => handleAdd(icon)}
                          />
                        </Tooltip>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Grid>
      </Box>
    </React.Fragment>
  )
}

SocialMediaIcons.propTypes = {
  subPanel: PropTypes.number.isRequired,
  onSubpanelChange: PropTypes.func.isRequired
}

export default SocialMediaIcons








{/* <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="720" height="467.6495132127955" viewBox="0 0 720 467.65" xml:space="preserve">
  <desc>Created with Fabric.js 3.6.3</desc>
  <defs></defs>
  <g transform="matrix(1 0 0 1 610 389.78)" id="Icon-12">
    <g style=""   >
      <g transform="matrix(1 0 0 1 0 0)"  >
        <path style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(63,81,181); fill-rule: nonzero; opacity: 1;"  transform=" translate(-24, -24)" d="M 42 37 c 0 2.762 -2.238 5 -5 5 H 11 c -2.761 0 -5 -2.238 -5 -5 V 11 c 0 -2.762 2.239 -5 5 -5 h 26 c 2.762 0 5 2.238 5 5 V 37 z" stroke-linecap="round" />
      </g>
      <g transform="matrix(1 0 0 1 5 1.5)"  >
        <path style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;"  transform=" translate(-29, -25.5)" d="M 34.368 25 H 31 v 13 h -5 V 25 h -3 v -4 h 3 v -2.41 c 0.002 -3.508 1.459 -5.59 5.592 -5.59 H 35 v 4 h -2.287 C 31.104 17 31 17.6 31 18.723 V 21 h 4 L 34.368 25 z" stroke-linecap="round" />
      </g>
    </g>
  </g>
</svg> */}
