import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import { TextField, InputAdornment } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#fff',
    borderRadius: '0.3em',
    '& fieldset': {
      borderRadius: '0.3em',
    },
  },
  input: {
    color: '#989c9c',
    paddingLeft: '10px',
    '& input': {
      paddingTop: '8px',
      paddingBottom: '8px',
    }
  },
  tabs: {
    cursor: 'pointer',
  },
  tabActive: {
    backgroundColor: '#293039',
  }
}))

const SearchBar = ({ query, onSearch, placeholder }) => {
  const classes = useStyles()
  const [queryString, setQueryString] = useState(query)

  useEffect(() => setQueryString(query), [query])

  const handleQueryUpdate = q => {
    setQueryString(q)
    onSearch(q)
  }

  return (
    <TextField
      id="q"
      variant="outlined"
      size="small"
      fullWidth
      placeholder={placeholder}
      className={classes.root}
      value={queryString}
      onChange={e => handleQueryUpdate(e.currentTarget.value)}
      InputProps={{
        className: classes.input,
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  )
}

SearchBar.propTypes = {
  query: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired,
  placeholder: PropTypes.string
}

export default SearchBar
