import React, { useContext, useState, useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import { first } from 'lodash/fp'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import HttpClient from '../../../../services/HttpClient'
import AuthContext from '../../../../contexts/AuthContext'
import DummySkeleton from './DummySkeleton'
import TemplatesSection from './TemplatesSection'
import Breadcrumb from './Breadcrumb'
import DashboardHeader from './DashboardHeader'
import SearchResult from './SearchResult'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'grid',
    columnGap: theme.spacing(2),
    rowGap: theme.spacing(2),
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  breadcrumb: {
    fontSize: '12px',
    textDecoration: 'none !important',
    fontWeight: '500',
    paddingLeft: '1rem',
  },
}))

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

const SubCategoryGrid = () => {
  const classes = useStyles()
  const [authData] = useContext(AuthContext)
  const [loading, setLoading] = useState(true)
  const [templates, setTemplates] = useState(true)
  const history = useHistory()
  const query = useQuery()
  const { cardId } = useParams()
  const [search, setSearch] = useState('')
  const notify = message => toast(message)

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    setLoading(true)
    HttpClient.get(
      `/templates/published?categoryType=${params.get(
        'type'
      )}&categoryId=${params.get('id')}`,
      { Authorization: `Bearer ${authData.authToken}` }
    )
      .then(resTemplates => {
        setTemplates(resTemplates)
        setLoading(false)
      })
      .catch(error => {
        console.log('Error getting industries: ', error)
        setTemplates([])
        setLoading(false)
      })
  }, [authData.authToken])

  const handleChangeTemplate = (templateId, formatType) => {
    HttpClient.put(
      `/cards/${cardId}/design`,
      { templateId: templateId, formatType: parseInt(formatType) },
      {
        Authorization: `Bearer ${authData.authToken}`,
      }
    )
      .then(res => {
        history.push(`/user/cards/${res.id}/design?dashboard=true&timestamp=${new Date().toISOString()}`)
      })
      .catch(error => {
        if (error.response.status === 424) {
          error.response.text().then(response => {
            notify(JSON.parse(response).error.message)
          })
        } else notify('No Side with the selected template format.')
        console.log('Error applying template: ', error)
      })
  }

  return (
    <>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <DashboardHeader onChange={searchQuery => setSearch(searchQuery)} />
      {search !== '' ? (
        <SearchResult
          url={`/templates/published?search=${search}`}
          onClose={() => setSearch('')}
          cardId={cardId}
        />
      ) : (
        <>
          {loading ? (
            <DummySkeleton />
          ) : (
            <>
              <Breadcrumb
                categoryType={query.get('type')}
                categoryTitle={first(templates)?.category_title}
                cardId={cardId}
              />
              <Box p={2} className={classes.root}>
                <TemplatesSection
                  templates={templates}
                  showTitles={false}
                  type='grid'
                  format={query.get('format')}
                  onClick={(templateId, format, categoryId, categoryType) =>
                    handleChangeTemplate(
                      templateId,
                      format,
                      categoryId,
                      categoryType
                    )
                  }
                />
              </Box>
            </>
          )}
        </>
      )}
    </>
  )
}

export default SubCategoryGrid
