import React, { useEffect, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import freeIcon from '../../../../images/free.png'
import multiSidedIcon from '../../../../images/multiSidedIcon.png'
import premiumIcon from '../../../../images/pro.png'
import AuthContext from '../../../../contexts/AuthContext'
import { Box, makeStyles } from '@material-ui/core'
import { getTemplatePreviewPath } from './Common'
import PremiumDialog from '../Editor/PremiumDialog'
import MultiSidedDialog from '../Editor/MultiSidedDialog'
import HttpClient from '../../../../services/HttpClient'
import { ToastContainer, toast } from 'react-toastify'
import SubscriptionDialouge from '../Editor/SubscriptionDialouge'

const useStyles = makeStyles(() => ({
  card: {
    position: 'relative',
    display: 'block',
    fontSize: '7px',
    color: 'black',
  },
  templateIcon: {
    color: 'black',
    position: 'absolute',
    top: '97%',
    float: 'right',
    paddingRight: '3%',
    transform: 'translate(-100%, -100%)'
  },
  image: {
    height: 'auto',
    width: window.innerWidth / 2.3 + 'px',
    verticalAlign: 'middle',
    borderRadius: '10px',
    boxShadow: '0 0 10px 5px #F9F9F9',
  },
}))

const DashBoardTemplates = ({ cssClass, type, onClick, templates }) => {
  const [format, setFormat] = useState('2')
  const [authData] = useContext(AuthContext)
  const [loading, setLoading] = useState(true)
  const classes = useStyles()
  const [subscription, setSubscription] = useState({})
  const [subscribedTemplates, setSubscribeTemplates] = useState([])
  const [openPremiumDialoug, setOpenPremiumDialoug] = useState(false)
  const [openMultiSidedDialoug, setOpenMultiSidedDialoug] = useState(false)
  const [loadingsubscribedTemplate, setSubscribeTemplatesLoading] =
    useState(true)
  const [loadingSubscription, setLoadingSubscription] = useState(true)
  const [openSubscriptionDialoug, setOpenSubscriptionDialoug] = useState(false)
  const [templateId, setTemplateId] = useState()

  const filterTemplates = (templates) => {
    return templates.filter((template) => {
      if (getTemplatePreviewPath(format, template)) return template
    })
  }

  useEffect(() => {
    setLoadingSubscription(true)
    HttpClient.get('/subscription', {
      Authorization: `Bearer ${authData.authToken}`,
    })
      .then((res) => {
        // setSubscription(res)
        setLoadingSubscription(false)
      })
      .catch((error) => {
        console.log('subscription error: ', error)
        // setSubscription()
        setLoadingSubscription(false)
      })
    setLoading(true)
  }, [authData.authToken])

  useEffect(() => {
    setSubscribeTemplatesLoading(true)
    HttpClient.get('/templates/purchased', {
      Authorization: `Bearer ${authData.authToken}`,
    })
      .then((res) => {
        setSubscribeTemplates(res)
        setSubscribeTemplatesLoading(false)
      })
      .catch((error) => {
        console.log('subscription error: ', error)
        setSubscribeTemplates([])
        setSubscribeTemplatesLoading(false)
      })
    setLoading(true)
  }, [authData.authToken])

  const handleTemplateClick = (subscription, template, index) => {
    // if (!subscription) {
    //   setOpenSubscriptionDialoug(!openSubscriptionDialoug)
    //   return
    // }
    setTemplateId(template.template_id)
    if (subscribedTemplate(template.template_id)) {
      return onClick(template.template_id, 2, index)
    } else {
      subscription.planType === 'basic' && template.template_type !== 1
        ? template.template_type === 3
          ? setOpenPremiumDialoug(!openPremiumDialoug)
          : setOpenMultiSidedDialoug(!openMultiSidedDialoug)
        : onClick(template.template_id, 2, index)
    }
  }

  const getTemplateBadge = (template) => {
    if (template.template_type === 1) return freeIcon
    else if (template.template_type === 2) return multiSidedIcon
    else if (template.template_type === 3) return premiumIcon
  }

  const subscribedTemplate = (templateId) => {
    if (subscribedTemplates.every((value) => value.templateId !== templateId))
      return false
    else return true
  }

  return (
    <React.Fragment>
      <>
        <PremiumDialog
          open={openPremiumDialoug}
          close={setOpenPremiumDialoug}
          templateId={templateId}
        ></PremiumDialog>
        <MultiSidedDialog
          open={openMultiSidedDialoug}
          close={setOpenMultiSidedDialoug}
          templateId={templateId}
        ></MultiSidedDialog>

        {templates &&
          filterTemplates(templates).map((template, index) => (
            <Box key={`${template.template_id}_${index}`} className={classes.card}>
              <img
                src={getTemplatePreviewPath(format, template)}
                alt={template.title}
                className={classes.image}
                onClick={() =>
                  handleTemplateClick(subscription, template, index)
                }
              />
              {subscribedTemplates.every(
                (value) => value.templateId !== template.template_id
              ) && (
                  <img
                    src={getTemplateBadge(template)}
                    className={classes.templateIcon}
                  />
                )}
            </Box>
          ))}
      </>
      <SubscriptionDialouge
        open={openSubscriptionDialoug}
        close={setOpenSubscriptionDialoug}
      ></SubscriptionDialouge>
    </React.Fragment>
  )
}

DashBoardTemplates.propTypes = {
  onClick: PropTypes.func.isRequired,
  templates: PropTypes.array.isRequired,
}

export default DashBoardTemplates
