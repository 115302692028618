import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const DeleteSideIcon = props => {
  return (
    <SvgIcon {...props}>
      <path d="M18.5 4h-13C4.7 4 4 4.7 4 5.5v13c0 .8.7 1.5 1.5 1.5h13c.8 0 1.5-.7 1.5-1.5v-13c0-.8-.7-1.5-1.5-1.5zm.7 14.5c0 .5-.3.7-.7.7h-13c-.5 0-.7-.3-.7-.7v-13c0-.5.3-.7.7-.7h13c.5 0 .7.3.7.7zm-3.7-10c-.1-.1-.3-.1-.6 0L12 11.4 9 8.5c-.1-.1-.3-.1-.6 0-.1.1-.1.3 0 .6l3 2.9-2.9 3c-.1.1-.1.3 0 .6.1.1.1.1.2.1s.2 0 .2-.1l3-3 3 3c.1.1.1.1.2.1s.2 0 .2-.1c.1-.1.1-.3 0-.6l-2.8-3 3-3c.1-.1.1-.3 0-.6z"/>
    </SvgIcon>
  )
}

export default DeleteSideIcon
