import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const TextColorIcon = props => {
  return (
    <SvgIcon {...props}>
      <defs>
        <path id="tc-alphabet" d="M13.71 11.15L12.49 7.7c-.07-.18-.15-.39-.23-.64-.08-.24-.16-.51-.23-.79-.07.29-.15.56-.23.8-.08.25-.16.46-.23.64l-1.21 3.44h3.35zm3.92 4.28h-1.81c-.2 0-.37-.05-.49-.14a.797.797 0 01-.28-.37l-.76-2.15h-4.5l-.76 2.15c-.05.13-.14.25-.27.35-.13.11-.3.16-.49.16H6.43l4.41-11.28h2.39l4.4 11.28z" />
      </defs>
      <clipPath id="tc-h">
        <use xlinkHref="#tc-alphabet" overflow="visible" />
      </clipPath>
      <path clipPath="url(#tc-h)" d="M1.43-.85h21.2v21.28H1.43z" />
      <defs>
        <path id="tc-i" d="M3.85 16.54H6.7v2.89H3.85z" />
      </defs>
      <clipPath id="tc-j">
        <use xlinkHref="#tc-i" overflow="visible" />
      </clipPath>
      <path clipPath="url(#tc-j)" fill="#ff5945" d="M-1.15 11.54H11.7v12.89H-1.15z" />
      <defs>
        <path id="tc-k" d="M6.49 16.54h2.85v2.89H6.49z" />
      </defs>
      <clipPath id="tc-l">
        <use xlinkHref="#tc-k" overflow="visible" />
      </clipPath>
      <path clipPath="url(#tc-l)" fill="#e6c71f" d="M1.49 11.54h12.85v12.89H1.49z" />
      <defs>
        <path id="tc-m" d="M9.25 16.54h2.85v2.89H9.25z" />
      </defs>
      <clipPath id="tc-n">
        <use xlinkHref="#tc-m" overflow="visible" />
      </clipPath>
      <path clipPath="url(#tc-n)" fill="#47b649" d="M4.25 11.54H17.1v12.89H4.25z" />
      <defs>
        <path id="tc-o" d="M12.1 16.54h2.85v2.89H12.1z" />
      </defs>
      <clipPath id="tc-p">
        <use xlinkHref="#tc-o" overflow="visible" />
      </clipPath>
      <path clipPath="url(#tc-p)" fill="#44a9df" d="M7.1 11.54h12.85v12.89H7.1z" />
      <defs>
        <path id="tc-q" d="M14.66 16.54h2.85v2.89h-2.85z" />
      </defs>
      <clipPath id="tc-r">
        <use xlinkHref="#tc-q" overflow="visible" />
      </clipPath>
      <path clipPath="url(#tc-r)" fill="#5e3e98" d="M9.66 11.54h12.85v12.89H9.66z" />
      <defs>
        <path id="tc-s" d="M17.53 16.54h2.85v2.89h-2.85z" />
      </defs>
      <clipPath id="tc-t">
        <use xlinkHref="#tc-s" overflow="visible" />
      </clipPath>
      <path clipPath="url(#tc-t)" fill="#ee1d43" d="M12.53 11.54h12.85v12.89H12.53z" />
    </SvgIcon>
  )
}

export default TextColorIcon
