import React, { useState } from 'react'
import PropTypes from 'prop-types'

import HttpClient from '../../services/HttpClient'

import { makeStyles } from '@material-ui/core/styles'

import { Grid, Box, TextField, Button } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Typography from '@material-ui/core/Typography'
import Alert from '@material-ui/lab/Alert'
import AuthenticationCode from './AuthenticationCode'
import Loader from './Loader'

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '70vh',
  },
  rootPasscodeBox: {
    textDecoration: 'none'
  },
  passcodeBox: {
    borderWidth: '3px',
    borderStyle: 'solid',
    borderRadius: '10px',
    height: '65px',
    width: '50px',
    textAlign: 'center',
    fontSize: '18px',
    fontWeight: 500,
    textDecoration: 'none',
    '& :focus': {
      border: '3px solid #979797',
    }
  },
  logo: {
    fontSize: '4.4rem',
    marginTop: '16%',
    marginBottom: '3%',
  },
  actions: {
    display: 'flex',
    marginTop: '30%',
    marginBottom: '20%',
    justifyContent: 'flex-end',
  },
  verificationActions: {
    display: 'flex',
    marginTop: '10%',
    marginBottom: '20%',
    justifyContent: 'space-between',
  },
  pointer: {
    cursor: 'pointer',
    marginTop: '50px',
  },
  brandBox: {
    fontSize: '4rem',
  },
  brandFirst: {
    color: '#000000',
  },
  brandSecond: {
    color: '#3A60AC',
  },
  brandFont: {
    fontWeight: '950',
    fontSize: '55px',
  },
  fontSize:{
    fontSize:15,
  },
  margin:{
    marginTop:25, 
  },
  actionButton:{
    width:100,
    height:40, 
    fontSize: 20
  },
  text: {
    textAlign: 'center',
    fontSize: '20px',
    color: '#000000',
    fontWeight: '300'
  },
  dialougText: {
    textAlign: 'center',
    fontSize: '20px',
    color: '#000000',
    fontWeight: '300',
    paddingTop: '10px'
  },
  bold: {
    fontWeight: '600',
  },
  codeText:{
    textAlign: 'center',
    fontSize: '20px',
    color: '#000000',
    fontWeight: '500',
    paddingTop: '25px'
  }
}))

const initialErrorMessages = {
  email: '',
  passcode: '',
}

const ForgotPassword = ({ history }) => {
  const classes = useStyles()
  const [email, setEmail] = useState('')
  const [errors, setErrors] = useState(initialErrorMessages)
  const [generalError, setGeneralError] = useState(null)
  const [emailSent, setEmailSent] = useState(false)
  const [loading, setLoading] = useState(false)

  const forgotPasswordEmail = () => {
    setGeneralError(null)
    const _errors = { ...errors, ...initialErrorMessages }
    let isValid = true
    if (email === '') {
      isValid = false
      _errors.email = 'Required'
    }

    if (isValid)
    {
      setLoading(true)
      HttpClient.put(
        '/account/password/forgot',
        { type: 'email', value: email }
      )
        .then(res => {
          console.log(res)
          setLoading(false)
          setEmailSent(true)
          localStorage.setItem('beEmailForgotPassword', email)
        })
        .catch(error => {
          setLoading(false)
          console.log('Error sending forgot password: ', error)
          error.response.text().then(response => {
            setGeneralError(JSON.parse(response).error.message)
          })
        }) 
    } else {
      setErrors(_errors)
    }
  }


  const resendEmail = () => {
    setEmailSent(false)
  }

  const handleResetPassword = resetPasswordCode => 
  {
    setGeneralError(null)
    if (resetPasswordCode !== '') {
    
      HttpClient.get(
        `/account/password/code?code=${resetPasswordCode}`,
      )
        .then(res => {
          console.log(res)
          history.push(`/registration/password/reset/${resetPasswordCode}?timestamp=${new Date().toISOString()}`)
        })
        .catch(error => {
          console.log('Error resetting password: ', error)
          error.response.text().then(response => {
            setGeneralError(JSON.parse(response).error.message)
          })
        })
    }
  } 

  return (
    <Dialog
      fullWidth={true}
      open={true}
      onClose={() => history.push('/')}
      aria-labelledby="sign-in-dialog-title"
      classes={{ paper: classes.root }}
    >
      <Grid container>
        <Grid item xs={2}></Grid>
        {!emailSent? (
          <Grid item xs={8}>
            <DialogTitle id="registration-dialog-title">
              <Box mt={6} display='flex' justifyContent='center' component="span" className={classes.brandBox}>
                <span className={classes.brandFirst + ' ' + classes.brandFont}>Live</span>
                <span className={classes.brandSecond + ' ' + classes.brandFont}>Contact</span>
              </Box>
            </DialogTitle>
            <DialogContent>
              <Grid container>
                <Grid item xs={1}></Grid>
                <Grid item xs={10}>
                  <Typography variant="h5" component="h5" color="textPrimary" gutterBottom className={classes.margin}>Forgot Password</Typography>
                  <DialogContentText>
                    <Typography className={classes.fontSize} color="textSecondary" gutterBottom>Enter your account email.
                    You will recieve a authentication code to reset your password. </Typography>
                    <TextField
                      id="email"
                      label="Enter Email"
                      type="email"
                      autoComplete="email"
                      value={email}
                      onChange={e => setEmail(e.currentTarget.value)}
                      margin="normal"
                      fullWidth
                      required
                      error={errors.email !== ''} />

                  </DialogContentText>

                  {generalError ? (
                    <Box paddingTop={2}>
                      <Alert severity="error">{generalError}</Alert>
                    </Box>
                  ) : null}

                  <Box className={classes.actions}>
                    <Button
                      variant="contained"
                      size="large"
                      color="primary"
                      className={classes.actionButton}
                      onClick={() => forgotPasswordEmail()}
                    >
                      Send
                      {loading && <Loader />}
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={1}></Grid>
              </Grid>
            </DialogContent>
          </Grid>
        ): (
          <Grid item xs={8}>
            <DialogTitle id="registration-dialog-title">
              <Box mt={6} component="span" display='flex' justifyContent='center' className={classes.brandBox}>
                <span className={classes.brandFirst + ' ' + classes.brandFont}>Live</span>
                <span className={classes.brandSecond + ' ' + classes.brandFont}>Contact</span>
              </Box>
            </DialogTitle>
            <DialogContent>
              <Typography
                variant="body1"
                component="h3"
                color="textPrimary"
                gutterBottom
                className={classes.text}
              >
                Thank you for Signing Up.
              </Typography>
              <DialogContentText
                className={classes.dialougText}>
                We have sent an authentication to <span className={classes.bold}>{email}</span>.
              </DialogContentText>

              <Typography
                gutterBottom
                className={classes.codeText}
              >
                Please enter code below:
              </Typography>

              <AuthenticationCode onCodeEntered={code => handleResetPassword(code)}/>

              {generalError ? (
                <Box paddingTop={2}>
                  <Alert severity="error">{generalError}</Alert>
                </Box>
              ) : null}

              {loading && 
                <Box display='flex' justifyContent='center' paddingTop={2}>
                  <Loader color='blue' />
                </Box>}

              <Box display="flex" justifyContent="center">
                <Typography
                  variant="body1"
                  component="h4"
                  color="primary"
                  gutterBottom
                  className={ classes.pointer }
                  onClick={() => resendEmail()}
                >
                  resend passcode
                </Typography>
              </Box>
            </DialogContent>
          </Grid>
        )}

        <Grid item xs={2}></Grid>
      </Grid>
    </Dialog>
  )
}

ForgotPassword.propTypes = {
  history: PropTypes.object.isRequired,
}

export default ForgotPassword
