import React, { useContext, useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import VisibilitySensor from 'react-visibility-sensor'

import { makeStyles } from '@material-ui/core/styles'
import { Box, Button } from '@material-ui/core'

import HttpClient from '../../../../../services/HttpClient'
import AuthContext from '../../../../../contexts/AuthContext'
import { SideContext } from '../../../../../contexts/SideContext'
import { NavigationContext } from '../../../../../contexts/NavigationContext'
import { ActionContext } from '../../../../../contexts/ActionContext'
import Side from '../Sides/Side'
import Toolbar from '../Toolbar'
// import { generateUid } from '../../../../../utils'

const useStyles = makeStyles(() => ({
  button: {
    borderStyle: 'dotted',
    cornerRadius: 0
  },
}))

const formatsSettings = [
  {
    width: 4320,
    height: 2592,
  },
  {
    width: 4320,
    height: 5400,
  },
  {
    width: 4320,
    height: 4320,
  }
]

const Format = props => {
  const classes = useStyles()
  const [authData] = useContext(AuthContext)
  const [activeSide, setActiveSide] = useContext(SideContext)
  const [navigation, setNavigation] = useContext(NavigationContext)
  const [action, setAction] = useContext(ActionContext)
  const [format, setFormat] = useState(props.format)
  const [toolbar, setToolbar] = useState({ type: 'default' })

  const formatBoxRef = useRef(null)

  useEffect(() => {
    setFormat(props.format)
  }, [props.format.id])

  // useEffect(() => {
  //   if( activeSide === null ) {
  //     setActiveSide(format.sides[0].id)
  //   }
  //   setAction({})
  // }, [format.id, activeSide])

  const handleSideAddition = (sideId, sidePosition) => {
    const sourceSideIndex = format.sides.findIndex(s => s.id === sideId)
    if (sourceSideIndex !== -1) {
      let newSide = {
        position: (sidePosition + 1),
        svg: '',
        json: '',
      }
      // newSide.id = generateUid('side')
      newSide.settings = formatsSettings[format.type-1]
      HttpClient.post(
        `/formats/${format.id}/sides`,
        newSide,
        { Authorization: `Bearer ${authData.authToken}` }
      )
        .then(res => {
          const newSides = [...format.sides]
          newSides.splice(sourceSideIndex + 1, 0, res)
          setFormat({ ...format, sides: newSides })
        })
        .catch(error => console.error('Error side create: ', error.response.status))
    }
  }

  const handleSideRemoval = side => {
    const sourceSideIndex = format.sides.findIndex(s => s.id === side.id)
    if (sourceSideIndex !== -1) {
      if (Number.isInteger(side.id)) {
        HttpClient.delete(
          `/formats/${format.id}/sides/${side.id}`,
          { Authorization: `Bearer ${authData.authToken}` }
        )
      }
      const newSides = format.sides.filter(s => s.id !== side.id)
      setFormat({ ...format, sides: newSides })
    }
  }

  const addSideAtEnd = () => {
    let newSide = {
      svg: '',
      json: ''
    }
    // newSide.id = generateUid('side')
    newSide.position = format.sides !== undefined ? (Math.max(...format.sides.map(s => s.position), 0) + 1) : 0
    newSide.settings = formatsSettings[format.type-1]
    HttpClient.post(
      `/formats/${format.id}/sides`,
      newSide,
      { Authorization: `Bearer ${authData.authToken}` }
    )
      .then(res => {
        const newSides = format.sides !== undefined ? [...format.sides, res] : [res]
        setFormat({ ...format, sides: newSides })
      })
      .catch(error => console.error('Error side create: ', error.response.status))
    
  }

  const handleSideUpdate = (sideId, side) => {
    const sideIndex = format.sides.findIndex(s => s.id === sideId)
    if (sideIndex !== -1) {
      const newFormat = { ...format }
      newFormat.sides[sideIndex] = side
      setFormat(newFormat)
    }
  }

  const handleNavigationChange = nav => setNavigation({ ...navigation, ...nav })

  // const handleRadiusUpdate = radius => {
  //   if( Number.isInteger(format.id) ) {
  //     HttpClient.put(`/templates/${props.templateId}/formats/${format.id}`, { cornerRadius: radius }, { Authorization: `Bearer ${authData.authToken}` })
  //   }
  //   setFormat({ ...format, cornerRadius: radius })
  // }

  const getWidthByZoom = zoomVal => {
    const width = window.innerWidth
    return (width * zoomVal/100)
  }

  const handleSideScrolledIntoView = (sideId, isVisible) => {
    if (isVisible) {
      // setToolbar({ type: 'default' })
      setAction({})
      setActiveSide(sideId)
    }
  }

  return (
    <Box display="flex" flexDirection="column" height={1}>
      <Toolbar
        toolbar={toolbar}
        // cornerRadius={format.cornerRadius}
        // onRadiusUpdate={handleRadiusUpdate}
      />
      <Box ref={formatBoxRef} flex="1 1 auto" overflow="auto">
        <Box
          m="0 auto"
          width={props.zoomValue ? `${getWidthByZoom(props.zoomValue)}px` : (format.type === 1 ? 1 : 0.7)}
        >
          {format.sides ?
            format.sides.sort((s1, s2) => s1.position - s2.position).map((side, index) => (
              <VisibilitySensor
                key={side.id}
                containment={formatBoxRef.current}
                // partialVisibility
                // offset={{ top: 150, bottom: 150 }}
                onChange={isVisible => handleSideScrolledIntoView(side.id, isVisible)}
              >
                <Side
                  removeAble={index !== 0}
                  side={side}
                  formatId={format.id}
                  formatType={format.type}
                  formatRadius={format.cornerRadius}
                  onAdd={handleSideAddition}
                  onRemove={handleSideRemoval}
                  onSideUpdate={handleSideUpdate}
                  onNavigationChange={handleNavigationChange}
                  onToolbarChange={setToolbar} />
              </VisibilitySensor>
            ))
            : null
          }
          <Box mt={3} mb={10}>
            <Button
              color="inherit"
              variant="outlined"
              fullWidth
              className={classes.button}
              onClick={addSideAtEnd}
            >Add side +</Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

Format.propTypes = {
  templateId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  format: PropTypes.object.isRequired,
  onFormatChange: PropTypes.func.isRequired,
  zoomValue: PropTypes.number
}

export default Format
