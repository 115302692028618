import React from 'react'
import PropTypes from 'prop-types'

import PanelTemplate from './PanelTemplate'
import TextStylings from './TextStylings'
import Images from './Images'
import Backgrounds from './Backgrounds'
import ActiveIcons from './ActiveIcons'
import SocialMediaIcons from './SocialMediaIcons'
import Graphics from './Graphics'

const Panel = ({ panel, cardId, onAddAction, onClose }) => {
  const handleAddAction = action => {
    onAddAction(action)
    onClose()
  }
  return (
    <PanelTemplate onClose={onClose}>
      {panel === 2 ?
        <TextStylings onAddAction={handleAddAction} />
        : null
      }
      {panel === 3 ?
        <Images onAddAction={handleAddAction} />
        : null
      }
      {panel === 4 ?
        <Backgrounds cardId={cardId} onAddAction={handleAddAction} />
        : null
      }
      {panel === 5 ?
        <ActiveIcons onAddAction={onAddAction} />
        : null
      }
      {panel === 6 ?
        <SocialMediaIcons onAddAction={onAddAction} />
        : null
      }
      {panel === 7 ?
        <Graphics onAddAction={onAddAction} />
        : null
      }
    </PanelTemplate>
  )
}

Panel.propTypes = {
  panel: PropTypes.number.isRequired,
  cardId: PropTypes.number.isRequired,
  onAddAction: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default Panel
