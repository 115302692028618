import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const TextStylingsIcon = props => {
  return (
    <SvgIcon {...props}>
      <path d="M11.94 8.76c.99-1.6 1.85-2.47 2.6-2.9v-.02c-.37.04-.97.32-1.53.7-.76.52-1.53 1.36-2.16 2.06-.39.44-.69.8-1.01 1.18.08.28.18.68.21 1.3A7 7 0 0110 12.2c.36-.71 1.38-2.53 1.94-3.44zm-3.71 4.41c.26.14.76.34 1.05.38.13-.28.42-.82.48-.96.11-.28.13-.92.1-1.48-.03-.48-.08-.86-.18-1.16-.39.44-.77.88-1.13 1.28-.43.48-.81.88-1.16 1.24.18.26.53.52.84.7zm-.16-4.7c-.84 0-1.29.9-1.32 1.84-.03.84.32 1.66.53 1.99.34-.34.74-.76 1.18-1.24.39-.42.77-.86 1.14-1.28-.32-.74-.85-1.3-1.52-1.3l-.01-.01zm-6.74 4.58c0-.7.42-1.2.84-1.22.37-.04.6.22.6.6 0 .3-.14.48-.37.68-.16.14-.29.28-.29.5 0 .42.53.76 1.58.66 1.26-.14 2.47-.98 3.36-1.74-.3-.34-.89-1.24-.89-2.26.01-1.2.76-2.12 1.92-2.1.87.04 1.48.9 1.68 1.44.21-.26.56-.66.76-.88.85-.96 1.61-1.76 2.42-2.3.61-.42 1.3-.76 1.94-.76.3 0 .48.12.57.2.1.1.16.24.11.28-.05.01-.09-.05-.2-.15a.73.73 0 00-.36-.12c-.18 0-.35.06-.52.22-.35.36-.94 1.22-1.31 1.88-1 1.72-2.1 3.78-3.01 5.65.26-.02.58-.14.9-.34.34-.22.76-.62 1.01-.92.08-.1.13-.1.16-.06.03.04-.01.12-.13.26-.23.26-.6.64-1 .9-.34.22-.72.34-1.03.36-.23.3-.39.44-.63.5-.45.13-.44-.14-.37-.27.05-.12.1-.22.13-.3-.29-.04-.77-.22-1.05-.36-.35-.18-.74-.46-.95-.7-.61.54-2.05 1.7-3.56 1.84-1.26.12-2.31-.36-2.31-1.46v-.03z" />
      <clipPath id="tsh">
        <use xlinkHref="#tsg" overflow="visible" />
      </clipPath>
      <path clipPath="url(#tsh)" d="M-1.29 3.05h19.46v14.13H-1.29z" />
      <path id="tsi" d="M13.78 10.11h9.78v1.33h-9.78z" />
      <clipPath id="tsj">
        <use xlinkHref="#tsi" overflow="visible" />
      </clipPath>
      <path clipPath="url(#tsj)" d="M11.16 7.49h15.02v6.57H11.16z" />
      <path id="tsk" d="M13.78 13.67h9.78V15h-9.78z" />
      <clipPath id="tsl">
        <use xlinkHref="#tsk" overflow="visible" />
      </clipPath>
      <path clipPath="url(#tsl)" d="M11.16 11.05h15.02v6.57H11.16z" />
      <path d="M16 6.55h7.12v1.33H16z" />
      <clipPath id="tsn">
        <use xlinkHref="#tsm" overflow="visible" />
      </clipPath>
      <path clipPath="url(#tsn)" d="M13.38 3.93h12.35v6.57H13.38z" />
    </SvgIcon>
  )
}

export default TextStylingsIcon
