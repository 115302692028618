import React, {useContext, useState} from 'react'
import HttpClient from '../../../../services/HttpClient'
import AuthContext from '../../../../contexts/AuthContext'
import {makeStyles} from '@material-ui/core/styles'
import {DialogContent} from '@material-ui/core'
import {Button} from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import {Box, Grid} from '@material-ui/core'
import multiSided from '../../../../images/crown.png'

const useStyles = makeStyles(() => ({
  root: {
    padding: '20px',
    borderRadius: '20px',
  },
  title: {
    paddingTop: '3px',
    padding: '0px',
    paddingRight: '26px',
    fontSize: '19px',
    fontWeight: 'bold',
  },
  price: {
    color: 'black',
    padding: '0px',
    fontSize: '13px',
  },
  content: {
    padding: '0px',
    fontSize: '14px',
  },
  icon: {
    height: '40px',
  },
  textStyling: {
    textAlign: 'center',
    fontSize: 'bold',
    fontSize: '20px',
  },
  bold: {
    fontWeight: 'bold',
  },
  flexBox: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '-14px',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    color: 'white',
    textAlign: 'center',
    justifyContent: 'space-evenly',
  },
  cancelButton: {
    backgroundColor: '#393c40',
    alignItems: 'center',
    borderRadius: '18px',
    paddingLeft: '27px',
    paddingRight: '27px',
    height: '32px',
    display: 'inline',
    fontSize: '14px',
    fontWeight: '900',
    color: 'white',
  },
  purchaseButton: {
    backgroundColor: '#393c40',
    alignItems: 'center',
    borderRadius: '18px',
    paddingLeft: '27px',
    paddingRight: '27px',
    height: '32px',
    display:"inline",
    fontSize:"14px",
    fontWeight:"900",
    color:"white"
  },
}))

export const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function PremiumDialog({open, close, templateId}) {
  const classes = useStyles()

  const handleBackClick = () => {
    close(false)
  }

  const handlePurchasedEvent = () => {
    console.log(`templateId:${templateId}`)
    if (window.Print !== undefined)
      window.Print.postMessage(`upgradePlan-${templateId}`)
  }

  return (
    <div>
      <Dialog
        open={open}
        aria-describedby="alert-dialog-slide-description"
      >
        < Box className={classes.root}>
          <div className={classes.flexBox}>
            <DialogTitle className={classes.title}>Premium Design</DialogTitle>
            <img className={classes.icon} src={multiSided}></img>
          </div>
          <br />
          <DialogContent className={classes.content}>
            <DialogContentText className={classes.content}>
              A premium card design with multiple sides that you can edit both
              on th app and on the website.A one time purchase.
            </DialogContentText>
            <DialogContentText className={classes.price}>
              Price: $2.00
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.buttons}>
            <Button
              classes={{
                root: classes.cancelButton,
              }}
              style={{
                backgroundColor: '#393c40',
              }}
              variant="contained"
              onClick={handleBackClick}
            >
              <Box>Cancel</Box>
            </Button>
            <Button
              classes={{
                root: classes.purchaseButton,
              }}
              style={{
                backgroundColor: '#9933ff',
              }}
              variant="contained"
            >
              <Box
                onClick={handlePurchasedEvent}
              >
                Purchase
              </Box>
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  )
}
