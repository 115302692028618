import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Box, Paper, AppBar, Button, IconButton } from '@material-ui/core'
import { Menu, MenuItem } from '@material-ui/core'
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap'

import { ActionContextProvider } from '../../../../contexts/ActionContext'
import Navigation from './Navigation'
import Colors from './Colors'
import TitleBar from './TitleBar'
import Toolbar from './Toolbar'
import Side from './Side'

import HttpClient from '../../../../services/HttpClient'
import AuthContext from '../../../../contexts/AuthContext'

const zoomOptions = [300, 200, 125, 100, 75, 50, 25, 10]

const TextStyling = ({ match }) => {
  const [authData] = useContext(AuthContext)
  const [textStyling, setTextStyling] = useState({ id: 'styling-1' })
  const [navigation, setNavigation] = useState({
    activeTab: 1,
    panel: 0,
  })
  const [toolbar, setToolbar] = useState({ type: 'default' })
  const [zoomValue, setZoomValue] = useState( localStorage.getItem('be-designer-styling-zoom') )
  const [zoomEl, setZoomEl] = useState(null)

  useEffect(() => {
    if( match.params.id !== undefined ) {
      HttpClient.get(`/text_stylings/${match.params.id}`, { Authorization: `Bearer ${authData.authToken}` })
        .then(res => {
          if(res.colors ===  null)
            res.colors = []
          setTextStyling(res)
        })
        .catch(error => console.log('Error fetch text styling: ', error))
    }
  }, [authData.authToken, match.params.id])

  const handleTextStylingChange = tStylng => setTextStyling({ ...textStyling, ...tStylng })
  const handleColorsChange = colors => setTextStyling({ ...textStyling, colors })
  const handleNavigationChange = nav => setNavigation({ ...navigation, ...nav })

  const handleOpenZoomMenu = e => setZoomEl(e.currentTarget)
  const handleCloseZoomMenu = () => setZoomEl(null)

  const handleZoomChange = zoomVal => {
    localStorage.setItem('be-designer-styling-zoom', zoomVal)
    setZoomValue(zoomVal)
    setZoomEl(null)
  }

  const getWidthByZoom = zoomVal => {
    const width = window.innerWidth
    return (width * zoomVal/100)
  }

  return (
    <ActionContextProvider>
      <Box display="flex" flex="0 1 auto" bgcolor="#000">
        <Navigation
          navigation={navigation}
          onNavigationChange={handleNavigationChange}
        />
        {navigation.activePanel && navigation.activePanel === 1 ?
          <Colors
            colors={textStyling.colors}
            onColorsChange={handleColorsChange}
          />
          : null
        }
      </Box>
      <Box flex="1 1 auto" width={1} overflow="hidden">
        <Box height={1}>
          <AppBar position="relative" elevation={0}>
            <TitleBar
              textStyling={textStyling}
              onTextStylingChange={handleTextStylingChange}
            />
          </AppBar>
          <Toolbar
            toolbar={toolbar}
            onNavigationChange={handleNavigationChange}
          />
          <Box width={1} height="calc(100% - 89px)" overflow="auto">
            <Box
              m="0 auto"
              width={zoomValue ? `${getWidthByZoom(zoomValue)}px` : 1}
            >
              { Number.isInteger(textStyling.id) ?
                <Side
                  side={textStyling}
                  onSideUpdate={setTextStyling}
                  navigation={navigation}
                  onNavigationChange={handleNavigationChange}
                  onToolbarChange={setToolbar}
                />
                : null
              }
            </Box>
          </Box>
          <Box position="absolute" bottom="2%" right="2%">
            <Paper variant="outlined">
              <Box display="flex">
                <Button
                  fullWidth
                  size="small"
                  aria-label="Zomm"
                  aria-controls="text-styling-zoom"
                  aria-haspopup="true"
                  onClick={handleOpenZoomMenu}
                >
                  {`${zoomValue}%`}
                </Button>
                <Menu
                  id="text-styling-zoom"
                  anchorEl={zoomEl}
                  keepMounted
                  open={Boolean(zoomEl)}
                  onClose={handleCloseZoomMenu}
                  getContentAnchorEl={null}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                  {zoomOptions.map((zoomOption, index) => 
                    <MenuItem key={index} onClick={() => handleZoomChange(zoomOption)}>{zoomOption}%</MenuItem>  
                  )}
                </Menu>
                <IconButton size="small" aria-label="Full screen">
                  <ZoomOutMapIcon fontSize="small" />
                </IconButton>
              </Box>
            </Paper>
          </Box>
        </Box>
      </Box>
    </ActionContextProvider>
  )
}

TextStyling.propTypes = {
  match: PropTypes.object.isRequired,
}

export default TextStyling
