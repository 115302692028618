import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import { Box, Grid } from '@material-ui/core'

import HttpClient from '../../../../../../services/HttpClient'
import AuthContext from '../../../../../../contexts/AuthContext'

const useStyles = makeStyles(() => ({
  container: {
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
  },
  stylingBox: {
    fill: 'white',
    cursor: 'pointer',
    backgroundColor: 'rgb(171 166 166 / 36%)',
    '& svg': {
      fill: 'white',
      maxWidth: '100%',
      maxHeight: '100%'
    }
  },
  link: {
    '& svg': {
      maxWidth: '100%',
      maxHeight: '100%'
    },
  }
}))

const TextStylings = ({ onAddAction }) => {
  const classes = useStyles()
  const [authData] = useContext(AuthContext)
  const [textStylings, setTextStylings] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    HttpClient.get('/text_stylings/published', { Authorization: `Bearer ${authData.authToken}` })
      .then(res => {
        setTextStylings(res)
        setLoading(false)
      })
      .catch(error => {
        console.log('Error mytemplates: ', error)
        setTextStylings([])
        setLoading(false)
      })
  }, [])
  
  const handleAdd = textStyling => {
    onAddAction({
      type: 'addTextStyling', value: textStyling.json
    })
  }

  return (
    <Box height={1} paddingTop={0.25} px="2px" className={classes.container}>
      <Grid container>
        {textStylings.map((textStyling, index) => (
          <Grid key={index} item xs={3}>
            <Box
              position="relative"
              width={1}
              pb="100%"
              borderRadius={4}
              boxSizing="border-box"
            >
              <Box
                position="absolute"
                left={1}
                right={1}
                top={1}
                bottom={1}
                borderRadius={4}
                className={classes.stylingBox}
                onClick={() => handleAdd(textStyling)}
                dangerouslySetInnerHTML={{ __html: textStyling.svg }}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

TextStylings.propTypes = {
  onAddAction: PropTypes.func.isRequired
}

export default TextStylings
