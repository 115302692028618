import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import { Grid, Box, Tooltip } from '@material-ui/core'
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import SearchBar from '../../SearchBar'
import New from './New'

const useStyles = makeStyles(theme => ({
  tabs: {
    cursor: 'pointer'
  },
  tabActive: {
    backgroundColor: '#293039'
  },
  iconBox: {
    fill: 'white',
    cursor: 'pointer',
    backgroundColor: 'hsla(0,0%,100%,.07)',
    '& svg': {
      maxWidth: '100%',
      maxHeight: '100%'
    },
    '&:before': {
      display: 'none',
    },
  },
  accordion: {
    color: '#fff',
    backgroundColor: '#191d23',
    borderTop: '2px solid #293039',
    '&$accordionExpanded': {
      margin: 'auto',
    },
  },
  accordionExpanded: {},
  accordionSummary: {
    minHeight: 20,
    '&$expanded': {
      minHeight: 20,
    },
  },
  expanded: {},
  accordionSummaryContent: {
    margin: `${theme.spacing(1)/2}px 0`,
    '&$expanded': {
      margin: `${theme.spacing(1)/2}px 0`,
    },
  },
  accordionExpandIcon: {
    padding: `${theme.spacing(1)/2}px`
  },
  accordionIcon: {
    color: '#8ba8f0',
    fontSize: theme.typography.body1.fontSize,
  },
  accordionDetails: {
    backgroundColor: 'hsla(0,0%,100%,.07)',
  }
}))

const groupByCategory = icons => {
  return icons.reduce((r, ico) => {
    r[ico.category] = r[ico.category] || []
    r[ico.category].push(ico)
    return r
  }, Object.create(null))
}

const getIcons = (subPanel, icons) => {
  if(subPanel === 1)
    return icons.filter(i => i.state === 2)
  if(subPanel === 2)
    return icons.filter(i => i.state === 3)
  return icons.filter(i => i.state === 1)
}

const Icons = props => {
  const classes = useStyles()
  const iconsList = getIcons(props.subPanel, props.icons)
  const [icons, setIcons] = useState({
    all: iconsList,
    grouped: groupByCategory(iconsList)
  })
  const [mode, setMode] = useState('listing')

  useEffect(() => {
    setMode('listing')
    const iconsList = getIcons(props.subPanel, props.icons)
    setIcons({
      all: iconsList,
      grouped: groupByCategory(iconsList)
    })
  }, [props.subPanel, props.icons])

  const handleSearch = q => {}

  const handleAdd = newIcon => {
    if( Number.isInteger(newIcon.id) ) {
      const updatedIcon = { ...props.icon, category: newIcon.category, svg: newIcon.svg }
      props.onIconChange(updatedIcon)
    } else {
      const updatedIcon = { ...props.icon, category: newIcon.category, svg: newIcon.svg }
      props.onIconChange(updatedIcon)
      setMode('listing')
    }
  }

  return (
    <React.Fragment>
      <SearchBar onSearch={handleSearch} />
      {mode === 'new' ?
        <New onAdd={handleAdd} />
        :
        <React.Fragment>
          <Box mt={1} mx={2} color="white">
            <Box
              py={1}
              width={0.33}
              display="inline-block"
              borderRadius="4px 4px 0 0"
              className={props.subPanel === 0 ? classes.tabActive : classes.tabs}
              textAlign="center"
              onClick={() => props.onSubpanelChange(0)}
            >
              <Typography variant="subtitle2">Drafts</Typography>
            </Box>
            <Box
              py={1}
              width={0.33}
              display="inline-block"
              borderRadius="4px 4px 0 0"
              className={props.subPanel === 1 ? classes.tabActive : classes.tabs}
              textAlign="center"
              onClick={() => props.onSubpanelChange(1)}
            >
              <Typography variant="subtitle2">Review</Typography>
            </Box>
            <Box
              py={1}
              width={0.33}
              display="inline-block"
              borderRadius="4px 4px 0 0"
              className={props.subPanel === 2 ? classes.tabActive : classes.tabs}
              onClick={() => props.onSubpanelChange(2)}
              textAlign="center"
            >
              <Typography variant="subtitle2">Published</Typography>
            </Box>
          </Box>
          <Box height="calc(100% - 140px)" px={1} overflow="scroll" color="#fff" bgcolor="#293039">
            <Box width={1} mb={2}>
              <Button
                color="inherit"
                size="large"
                aria-label="Upload New SVG file"
                onClick={() => setMode('new')}
              >
            + Upload New SVG file
              </Button>
            </Box>
            {Object.keys(icons.grouped).map((category, i) => (
              <Box width={1} key={i}>
                <Accordion square classes={{
                  root: classes.accordion,
                  expanded: classes.accordionExpanded
                }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon className={classes.accordionIcon} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    classes={{
                      root: classes.accordionSummary,
                      expanded: classes.expanded,
                      content: classes.accordionSummaryContent,
                      expandIcon: classes.accordionExpandIcon
                    }}
                  >
                    <Typography variant="caption">{category}</Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordionDetails}>
                    <Grid container spacing={1}>
                      {icons.grouped[category].map(icon => (
                        <Grid item xs={4} key={icon.id}>
                          <Box
                            position="relative"
                            width={1}
                            pb="100%"
                            borderRadius={4}
                            boxSizing="border-box"
                          >
                            <Tooltip title={icon.name} arrow>
                              <Box
                                position="absolute"
                                left={1}
                                right={1}
                                top={1}
                                bottom={1}
                                borderRadius={4}
                                className={classes.iconBox}
                                dangerouslySetInnerHTML={{ __html: icon.svg }}
                                onClick={() => props.onSelectIcon(icon)}
                              />
                            </Tooltip>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Box>
            ))}
            <Box width={1}>
              <Accordion square classes={{
                root: classes.accordion,
                expanded: classes.accordionExpanded
              }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className={classes.accordionIcon} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  classes={{
                    root: classes.accordionSummary,
                    expanded: classes.expanded,
                    content: classes.accordionSummaryContent,
                    expandIcon: classes.accordionExpandIcon
                  }}
                >
                  <Typography variant="caption">All</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                  <Grid container spacing={1}>
                    {icons.all.map(icon => (
                      <Grid item xs={4} key={icon.id}>
                        <Box
                          position="relative"
                          width={1}
                          pb="100%"
                          borderRadius={4}
                          boxSizing="border-box"
                        >
                          <Tooltip title={icon.name} arrow>
                            <Box
                              position="absolute"
                              left={1}
                              right={1}
                              top={1}
                              bottom={1}
                              borderRadius={4}
                              className={classes.iconBox}
                              dangerouslySetInnerHTML={{ __html: icon.svg }}
                              onClick={() => props.onSelectIcon(icon)}
                            />
                          </Tooltip>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Box>
        </React.Fragment>
      }
    </React.Fragment>
  )
}

Icons.propTypes = {
  subPanel: PropTypes.number.isRequired,
  onSubpanelChange: PropTypes.func.isRequired,
  icons: PropTypes.array.isRequired,
  icon: PropTypes.object.isRequired,
  onIconChange: PropTypes.func.isRequired,
  onSelectIcon: PropTypes.func.isRequired,
}

export default Icons
