import React, { useContext, useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import PropagateLoader from 'react-spinners/PropagateLoader'
import { ToastContainer, toast } from 'react-toastify'
import Carousel from 'react-material-ui-carousel'

import { makeStyles } from '@material-ui/core/styles'
import { Grid, Box } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'

import HttpClient from '../../../../services/HttpClient'
import AuthContext from '../../../../contexts/AuthContext'
import Toolbar from './Toolbar'
import Navigation from './Navigation'
import SidesMenu from './SidesMenu'
import Menu from './Menu'
import Side from './Side'
import Panel from './Panel'
import { ReactComponent as Back } from '../../icons/Back.svg'
import { ReactComponent as Forward } from '../../icons/Forward.svg'
import Topbar from './Topbar'

// import 'react-toastify/dist/ReactToastify.css'

const useStyles = makeStyles(theme => ({
  header: {
    // background: 'linear-gradient(to right, rgba(255,153,0, 1) 25%, rgba(149,87,239, 0.8))',
    // backgroundColor: '#000',
  },
  sides: {
    overflowX: 'scroll',
    overflowY: 'hidden',
    scrollBehavior: 'smooth',
    scrollSnapType: 'x mandatory',
    // transition: 'all .2s ease-out',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  side: {
    margin: '5% 20%',
  },
  button: {
    color: '#6454FF',
    minWidth: '90px',
    marginRight: '10px',
    textAlign: 'center',
  },
  btnLabel: {
    flexDirection: 'column',
  },
  btnStartIcon: {
    margin: 0,
  },
  icon: {
    fontSize: '2em !important',
    width: '20px',
    height: '25px',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  infoText: {
    color: 'black',
    fontSize: '0.6em',
    marginLeft: '5px',
  },
  flexBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  buttonstyle: {
    backgroundColor: 'blue',
  },
  manageSides: {
    marginTop: '10%',
    color: '#000',
    textAlign: 'center',
    fontSize: '15px',
  },
  box: {
    marginTop: '20px',
    color: '#000',
    textAlign: 'center',
    fontSize: '15px',
  },
  carouselBox: {
    marginLeft: '5%',
    marginRight: '5%',
    marginTop: '20px',
  },
  loader: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    zIndex: '9999999',
    transform: 'translate(-50%, -50%)',
  },
}))

const initialCard = {
  name: 'Name Your Card',
  type: 1,
  cornerRadius: 8,
  colors: [],
}

const Editor = ({ match }) => {
  const classes = useStyles()
  const sidesBoxRef = useRef(null)
  const [authData] = useContext(AuthContext)
  const [dataFields, setDataFields] = useState([])
  const [activeSide, setActiveSide] = useState(0)
  const [activeSideIndex, setActiveSideIndex] = useState(1)
  initialCard.id = match.params.id
  const [card, setCard] = useState(initialCard)
  const [toolbar, setToolbar] = useState({ type: 'default' })
  const [action, setAction] = useState({})
  const [copiedStyle, setCopiedStyle] = useState({})
  const [showTextPanel, setShowTextPanel] = useState(false)
  const [navigation, setNavigation] = useState(null)
  const [panel, setPanel] = useState(null)
  const [publishing, setPublishing] = useState(false)
  const [callSaveSide, setCallSaveSide] = useState(false)
  const [savingInProgress, setSavingInProgress] = useState(false)
  const [event, setEvent] = useState('')
  const [initialLoading, setInitialLoading] = useState(false)
  
  useEffect(() => {
    setInitialLoading(true)
    if (match.params.id !== undefined) {
      Promise.all([
        HttpClient.get(`/cards/${match.params.id}`, {
          Authorization: `Bearer ${authData.authToken}`,
        }),
        HttpClient.get(`/cards/${match.params.id}/data_fields`, {
          Authorization: `Bearer ${authData.authToken}`,
        }),
      ])
        .then(([resCard, resDataFields]) => {
          setDataFields(resDataFields)
          if (resCard.colors === null) resCard.colors = []
          if (resCard.cornerRadius === null) resCard.cornerRadius = 8
          if (resCard.sides && resCard.sides.length !== 0) {
            setActiveSide(resCard.sides[0].id)
            resCard.sides = resCard.sides.sort(
              (s1, s2) => s1.position - s2.position
            )
            setCard(resCard)
          } else {
            notify('Card does not have any side.')
          }
          setInitialLoading(false)
        })
        .catch(error => {
          console.error('Error fetch card and data fields: ', error)
          setInitialLoading(false)
        })
    }
  }, [authData.authToken, match.params.id])

  const handleSideActivation = sideId => {
    setAction({})
    setActiveSide(sideId)
  }

  const notify = message => toast(message)

  const handleCreateSide = () => {
    const newSide = {
      position: card.sides.length,
      svg: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" xml:space="preserve"><rect x="0" y="0" width="100%" height="100%" fill="#ffffff"></rect>↵</svg>',
      json: '{"version":"3.6.6","objects":[],"background":"#ffffff"}',
      settings: card.sides[0].settings,
    }
    HttpClient.post(`/cards/${card.id}/sides`, newSide, {
      Authorization: `Bearer ${authData.authToken}`,
    }).then(res => {
      const _sides = [...card.sides]
      _sides.push(res)
      setActiveSide(res.id)
      setCard({ ...card, sides: _sides })
      scrollRightToLast()
    }).catch(error => {
      if (error.response) {
        error.response.text().then(response => {
          notify(JSON.parse(response).error.message)
        })
      }
      console.log("Error creating side: ", error);
    })
  }

  const scrollLeft = () => {
    if (activeSideIndex !== 1) {
      setActiveSideIndex(activeSideIndex - 1)
    }
  }

  const scrollRight = () => {
    if (activeSideIndex < card.sides.length) {
      setActiveSideIndex(activeSideIndex + 1)
    }
  }

  const scrollRightToLast = () => {
    setActiveSideIndex(card.sides.length + 1)
  }

  const handleDuplicateSide = () => {
    HttpClient.put(
      `/cards/${card.id}/sides/${activeSide}/clone`,
      {},
      { Authorization: `Bearer ${authData.authToken}` }
    ).then(res => {
      const _sides = [...card.sides, res]
      setActiveSide(res.id)
      setCard({ ...card, sides: _sides })
    }).catch(error => {
      if (error.response) {
        error.response.text().then(response => {
          notify(JSON.parse(response).error.message)
        })
      }
      console.log("Error cloning side: ", error);
    })
  }

  const handleDeleteSide = () => {
    const _sides = [...card.sides].filter(s => s.id !== activeSide)
    HttpClient.delete(`/cards/${card.id}/sides/${activeSide}`, {
      Authorization: `Bearer ${authData.authToken}`,
    }).then(() => {
      setCard({ ...card, sides: _sides })
    }).catch(error => {
      if (error.response) {
        error.response.text().then(response => {
          notify(JSON.parse(response).error.message)
        })
      }
      console.log("Error deleting side: ", error);
    })
  }

  const updateSideInCard = side => {
    card.sides[activeSideIndex - 1] = side
    setCard(card)
  }

  const handlePanelChange = mode => {
    setNavigation(null)
    setPanel(mode)
  }

  const hadleToolbarChange = toolbarData => {
    if (toolbarData.type === 'text') {
      setShowTextPanel(true)
    } else {
      setShowTextPanel(false)
    }
    setToolbar(toolbarData)
  }

  const handleAddActionFromPanel = action => {
    setPanel(null)
    setAction(action)
  }

  return (
    <>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className={classes.loader}>
        <PropagateLoader color={'#bdbdbd'} loading={initialLoading} size={25} />
      </div>
      {publishing ? (
        <Backdrop className={classes.backdrop} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : null}
      <Box display="flex" height={1} flexDirection="column" bgcolor="#ecf0f3">
        <Box
          width={1}
          color="#fff"
          className={classes.header}
        >
          <Box m={2}>
            <Topbar
              card={card}
              callSaveSide={callSaveSide => setCallSaveSide(callSaveSide)}
              savingInProgress={savingInProgress}
              event={event => {
                setEvent(event)
              }}
            />
          </Box>
        </Box>

        {Number.isInteger(card.id) ? (
          <>
            <Box width={1} height={1}>
              <Box
                display="flex"
                height={1}
                flexDirection="column"
                alignItems="stretch"
              >
                <Box flex="1 1 45%">
                  {navigation === 1 ? (
                    <>
                      <Box
                        className={classes.manageSides}
                        style={{
                          visibility: navigation !== 1 ? 'hidden' : 'visible',
                        }}
                      >
                        Manage Card Sides
                      </Box>
                      <Box className={classes.box}>
                        {activeSideIndex} / {card.sides.length}
                      </Box>                    
                      <div className={classes.carouselBox}>
                        <Carousel
                          navButtonsAlwaysVisible={false}
                          indicators={false}
                          ref={sidesBoxRef}
                          animation={'fade'}
                          cycleNavigation={false}
                          autoPlay={false}
                          NextIcon={<Forward />}
                          PrevIcon={<Back />}
                          next={scrollRight}
                          prev={scrollLeft}
                          navButtonsProps={{
                          // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
                            style: {
                              background: 'none',
                              borderRadius: 0,
                            },
                          }}
                          index={activeSideIndex - 1}
                        >
                          {card.sides
                            .sort((s1, s2) => s1.position - s2.position)
                            .map(side => (
                              <Box
                                key={side.id}
                                flex="0 0 45%"
                                id={side.id}
                                className={classes.side}
                              >
                                <Side
                                  sidesLength={card.sides.length}
                                  cardId={card.id}
                                  formatType={card.type}
                                  cornerRadius={card.cornerRadius}
                                  side={side}
                                  onCreateSide={handleCreateSide}
                                  dataFields={dataFields}
                                  removeAble={false}
                                  action={side.id === activeSide ? action : {}}
                                  copiedStyle={copiedStyle}
                                  onCopiedStyleChange={setCopiedStyle}
                                  onToolbarChange={hadleToolbarChange}
                                  navigation={navigation}
                                  active={activeSide === side.id}
                                  onActivate={handleSideActivation}
                                  updateSide={side => updateSideInCard(side)}
                                  callSaveSide={callSaveSide}
                                  card={card}
                                  savingInProgress={savingInProgress =>
                                    setSavingInProgress(savingInProgress)
                                  }
                                  event={event}
                                />
                              </Box>
                            ))}
                        </Carousel>
                      </div>
                    </>
                  ) : (
                    <Box ref={sidesBoxRef} display="flex" width="100vw">
                      {card.sides
                        .map((side, index) => {
                          if (index === activeSideIndex - 1)
                            return (
                              <Box key={side.id} flex="0 0 100%">
                                <Side
                                  sidesLength={card.sides.length}
                                  cardId={card.id}
                                  formatType={card.type}
                                  cornerRadius={card.cornerRadius}
                                  side={side}
                                  onCreateSide={handleCreateSide}
                                  dataFields={dataFields}
                                  removeAble={false}
                                  action={side.id === activeSide ? action : {}}
                                  copiedStyle={copiedStyle}
                                  onCopiedStyleChange={setCopiedStyle}
                                  onToolbarChange={hadleToolbarChange}
                                  navigation={navigation}
                                  active={activeSide === side.id}
                                  onActivate={handleSideActivation}
                                  currentSideIndex={activeSideIndex}
                                  updateSide={side => updateSideInCard(side)}
                                  callSaveSide={callSaveSide}
                                  card={card}
                                  savingInProgress={savingInProgress =>
                                    setSavingInProgress(savingInProgress)
                                  }
                                  event={event}
                                />
                              </Box>
                            )
                        })
                        .filter(side => {
                          if (side) return side
                        })}
                    </Box>
                  )}
                </Box>
                {navigation === 1 ? (
                  <Box flex="1 1 10%" display="flex" alignItems="center" px="25%">
                    <SidesMenu
                      sidesLength={card.sides.length}
                      onNavigationChange={setNavigation}
                      onCreateSide={handleCreateSide}
                      onDuplicateSide={handleDuplicateSide}
                      onDeleteSide={handleDeleteSide}
                    />
                  </Box>
                ) : null}

                {(!showTextPanel && panel === null) || navigation === 1 ? (
                  <Box flex="1 1 10%" display="flex" alignItems="center">
                    {/* <Menu onPanelChange={setPanel} onAddAction={setAction} /> */}
                    {navigation === 1 ? (
                      <Grid container>
                        <Grid item xs={12}>
                          <Box className={classes.button}>
                            <Button onClick={() => setNavigation(null)}>Done</Button>
                          </Box>
                        </Grid>
                      </Grid>
                    ) : null}
                  </Box>
                ) : null}
                {navigation === 2 ? (
                  <Menu
                    onPanelChange={handlePanelChange}
                    onAddAction={setAction}
                    onClose={() => setNavigation(null)}
                  />
                ) : null}
                {navigation !== 1 ? (
                  <>
                    {toolbar.type !== 'textEdit' ? (
                      <Navigation
                        sidesCount={card.sides.length}
                        onNavigationChange={setNavigation}
                      />
                    ) : null}
                    <Box
                      position="fixed"
                      width={toolbar.lockMovementX ? 'auto' : '100%'}
                      left={toolbar.lockMovementX ? '40%' : 'auto'}
                      bottom="0"
                    >
                      <Toolbar
                        toolbar={toolbar}
                        action={action}
                        onAddAction={setAction}
                        onCopiedStyleChange={setCopiedStyle}
                        onClose={() => setNavigation(null)}
                      />
                    </Box>
                  </>
                ) : null}
              </Box>
              {panel !== null ? (
                <Panel
                  panel={panel}
                  cardId={card.id}
                  onAddAction={handleAddActionFromPanel}
                  onClose={() => setPanel(null)}
                />
              ) : null}
            </Box>
          </>
        ) : null}
      </Box>
    </>
  )
}

Editor.propTypes = {
  match: PropTypes.object.isRequired,
}

export default Editor
