import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { fabric } from 'fabric'

import { makeStyles } from '@material-ui/core/styles'
import ImageListItemBar from '@material-ui/core/ImageListItemBar'
import IconButton from '@material-ui/core/IconButton'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'

import { ActionContext } from '../../../../contexts/ActionContext'

const useStyles = makeStyles(() => ({
  draggable: {
    '-moz-user-select': 'none',
    '-khtml-user-select': 'none',
    '-webkit-user-select': 'none',
    userSelect: 'none',
    /* Required to make elements draggable in old WebKit */
    '-khtml-user-drag': 'element',
    '-webkit-user-drag': 'element',
    cursor: 'pointer'
  },
  titleBar: {
    height: '19px',
    background:
      'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
      'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  icon: {
    color: 'white',
    padding: '3px'
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%'
  }
}))

const Image = ({ image }) => {
  const classes = useStyles()
  const [action, setAction] = useContext(ActionContext)
  const [dragging, setDragging] = useState(false)

  const handleDragStart = e => {
    setDragging(true)
  }

  const handleDragEnd = e => {
    setDragging(true)
  }

  const add = () => {
    fabric.Image.fromURL(image.src.original, oImg => {
      setAction({
        type: 'addObject', property: 'image', value: oImg
      })
    }, { crossOrigin: 'anonymous' })
  }

  return (
    <div
      className={`${classes.draggable} ${dragging ? 'dragging' : ''}`}
      onDragStart={handleDragStart}
      onClick={add}
    >
      <img src={image.src.tiny} alt="" className={classes.image} />
      <ImageListItemBar
        position="top"
        actionIcon={
          <IconButton aria-label="info" className={classes.icon}>
            <MoreHorizIcon />
          </IconButton>
        }
        actionPosition="right"
        className={classes.titleBar}
      />
    </div>
  )
}

Image.propTypes = {
  image: PropTypes.object.isRequired
}

export default Image
