import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import dIcon from "../../../../images/dIcon.png";
import dIcon2 from "../../../../images/dIcon2.png";
import dIcon3 from "../../../../images/dIcon3.png";
import dIcon4 from "../../../../images/dIcon4.png";
import dIcon5 from "../../../../images/dIcon5.png";
import dIcon6 from "../../../../images/dIcon6.png";
import dIcon7 from "../../../../images/dIcon7.png";
import dIcon8 from "../../../../images/uploadIcon.png";
import dIcon9 from "../../../../images/dIcon9.png";


import { Slide } from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    height: "100%",
    marginTop: "115px",
    width: "100%",
  },
  scrollPaper: {
    marginTop: "50px",
  },
  paper: { borderRadius: 25, margin: 10 },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "red",
  },
  closeIcon: {
    float: "right",
    color: "#989898",
    fontWeight: "300px",
  },
  dialogStyle: {
    marginTop: "50px",
    height: "100%",
    borderRadius: "50px !important",
    width: "100%",
    marginRight: "0px",
    padding: 0,
    "&::-webkit-scrollbar": {
      display: "none",
    },
    msOverflowStyle: "none",
    ScrollbarWidth: "none",
  },
  live: {
    color: "#49AEDD",
  },
  title: {
    color: "#0E1F4A",
    font: "lato",
    fontSize: "35px",
    lineHeight: 1.2,
    fontWeight: 900,
    alignSelf: "center",
    textAlign: "center",
    marginTop: 20,
  },
  mainDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    font: "lato",
    marginBottom: "20px",
    margin: "0px 8px",
  },
  secondHeading: {
    display: "flex",
    color: "#007AFF",
    fontSize: "21px",
    fontWeight: 700,
    alignSelf: "center",
    textAlign: "center",
    marginBottom: "20px",
    marginTop: "20px",
  },
  thirdHeading: {
    display: "flex",
    fontSize: "12px",
    font: "lato",
    fontWeight: "bold",
    margin: "0 10px",
    color: "#0E1F4A",
    marginBottom: "15px",
    padding: 12,
    justifyContent: "space-between",
  },
  row1: {
    display: "flex",
    flexDirection: "column",
    color: "#29406B",
    fontSize: "16px",
    fontFamily: "Lato",
  },
  rowsContent: {
    display: "flex",
    flexDirection: "row",
    color: "#0E1F4A",
    fontSize: "14px",
    fontFamily: "Lato",
    lineHeight: "22px",
    marginBottom:'10px'
  },
  rowsHeading: {
    display: "flex",
    flexDirection: "column",
    lineHeight: "17px",
    fontSize: "16px",
    color: "#0E1F4A",
    marginBottom: "5px",
    fontWeight: "bold",
  },
  icons1: {
    height: "48px",
    width: "48px",
    marginleft: "30px",
  },
  icons: {
    height: "48px",
    width: "48px",
    marginRight: "30px",
  },
  rowFlex: {
    display: "flex",
    alignItems: "start",
    flexDirection: "row",
  },
  columnFlex: {
    display: "flex",
    flexDirection: "column",
  },
  topHeading: {
    color: "#29406B",
    fontSize: "18px",
    marginBottom: "32px",
    marginLeft: "27px",
  },
  HintIcons: {
    width: "58px",
    height: "58px",
    marginRight: "25px",
  },
  contact:{
    color:'#29406B',
  }
}));

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function CustomizedDialogs(props) {
  const classes = useStyles();
  const { open, handleState } = props;

  const handleCloseDialogue = () => {
    handleState(false);
  };

  return (
    <Dialog
      classes={{
        root: classes.dialogStyle,
        paper: classes.paper,
      }}
      TransitionComponent={Transition}
      timeout={200}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogContent dividers>
        {/* close icon */}
        <CloseIcon
          className={classes.closeIcon}
          onClick={handleCloseDialogue}
        ></CloseIcon>

        {/* main div */}
        <div className={classes.mainDiv}>
          {/* title */}
          <Typography className={classes.title}>
            Creating Your
            <span className={classes.live}> Live</span>
            <span className={classes.contact}>Contact</span> Card
          </Typography>

          {/* heading 1 */}
          <Typography className={classes.secondHeading}>
            Design & Edit Tools
          </Typography>
          <br />

          {/* row 1 */}
          <div className={classes.rowFlex}>
            <Typography className={classes.row1}>
              <img className={classes.icons1} src={dIcon}></img>
            </Typography>
            <Typography className={classes.topHeading}>
              Use the design tools to edit your card.
            </Typography>
            <br />
          </div>

          {/* row 2 */}
          <div className={classes.rowFlex}>
            <div>
              <img className={classes.icons} src={dIcon2}></img>
            </div>
            <div className={classes.columnFlex}>
              <Typography className={classes.rowsHeading}>Text</Typography>
              <Typography className={classes.rowsContent}>
                Add text in any format, color style or size.
              </Typography>
            </div>
            <br />
          </div>
          <br />

          {/* row 3 */}
          <div className={classes.rowFlex}>
            <div>
              <img className={classes.icons} src={dIcon3}></img>
            </div>
            <div className={classes.columnFlex}>
              <Typography className={classes.rowsHeading}>
                Texts Styling
              </Typography>
              <Typography className={classes.rowsContent}>
                Pre-designed text designs, just enter your own text.
              </Typography>
            </div>
            <br />
          </div>
          <br />

          {/* row 4 */}
          <div className={classes.rowFlex}>
            <div>
              <img className={classes.icons} src={dIcon4}></img>
            </div>
            <div className={classes.columnFlex}>
              <Typography className={classes.rowsHeading}>Images</Typography>
              <Typography className={classes.rowsContent}>
                Add images from our libraries or upload your own.
              </Typography>
            </div>
            <br />
          </div>
          <br />

          {/* row 5 */}
          <div className={classes.rowFlex}>
            <div>
              <img className={classes.icons} src={dIcon5}></img>
            </div>
            <div className={classes.columnFlex}>
              <Typography className={classes.rowsHeading}>
                Backgrounds
              </Typography>
              <Typography className={classes.rowsContent}>
                Add a background image or color.
              </Typography>
            </div>
            <br />
          </div>
          <br />

          {/* row 6 */}
          <div className={classes.rowFlex}>
            <div>
              <img className={classes.icons} src={dIcon6}></img>
            </div>
            <div className={classes.columnFlex}>
              <Typography className={classes.rowsHeading}>Graphics</Typography>
              <Typography className={classes.rowsContent}>
                Add graphics that you can color.
              </Typography>
            </div>
            <br />
          </div>
          <br />

          {/* row 7 */}
          <div className={classes.rowFlex}>
            <div>
              <img className={classes.icons} src={dIcon7}></img>
            </div>
            <div className={classes.columnFlex}>
              <Typography className={classes.rowsHeading}>
                Social Media Icons
              </Typography>
              <Typography className={classes.rowsContent}>
                Add social media icons and add links in the contact information.
              </Typography>
            </div>
            <br />
          </div>
          <br />

          {/* row 8 */}
          <div className={classes.rowFlex}>
            <div>
              <img className={classes.icons} src={dIcon8}></img>
            </div>
            <div className={classes.columnFlex}>
              <Typography className={classes.rowsHeading}>Uploads</Typography>
              <Typography className={classes.rowsContent}>
                Add images from your phone.
              </Typography>
            </div>
            <br />
          </div>
          <br />
          <br />

          {/* row 9 */}
          <div className={classes.rowFlex}>
            <div>
              <img className={classes.HintIcons} src={dIcon9}></img>
            </div>
            <div className={classes.columnFlex}>
              <Typography className={classes.rowsContent}>
                Your design can focus on 
                images and key messages 
                because your contact details 
                are entered seperately and are shared in the background.
              </Typography>
            </div>
          </div>
          <br />
        </div>
        <br />
      </DialogContent>
    </Dialog>
  );
}
